import React from 'react'
import NewHomeLogo from 'assets/img/New-Logo-Home.jpg'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon2,
  TiktokIcon,
  YoutubeIcon,
} from 'components/Icons'

function Footer() {
  return (
    <div className="flex flex-col w-full h-[315px] items-center justify-center">
      <div>
        <img src={NewHomeLogo} alt="remoterecruit-logo" />
      </div>
      <div>
        <ul className="mt-5 space-y-4">
          <li className="flex space-x-4">
            <a
              target="_blank"
              href="https://www.facebook.com/RemoteRecruitOfficial"
              className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
            >
              <FacebookIcon />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/remoterecruithq"
              className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
            >
              <InstagramIcon />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/RemoteRecruitHQ"
              className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
            >
              <TwitterIcon2 />
            </a>
            <a
              target="_blank"
              href="https://www.tiktok.com/@teamremoterecruit"
              className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
            >
              <TiktokIcon />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@RemoteRecruit"
              className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
            >
              <YoutubeIcon />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/remoterecruit-com-official"
              className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
            >
              <LinkedinIcon />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
