import { Combobox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { CheckedCircleIcon, ChevronIcon, CrossIcon } from 'components/Icons'
import { Fragment, useState } from 'react'

export default function AutoComplete({
  label,
  value,
  onChange,
  data = [],
  error = '',
  errorClassName = 'text-xs',
  multiple = false,
  removeItem,
  disabled = false,
  optionClassName = '',
  optionContainerClassName = 'px-4',
  labelClassName = 'block text-[12px] md:text-xs text-gray-300',
}) {
  const [query, setQuery] = useState('')
  const filterdValues =
    query === ''
      ? data
      : data.filter((v) =>
          v.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div className="relative space-y-3">
      {label && <label className={labelClassName}>{label}</label>}
      <Combobox
        disabled={disabled}
        multiple={multiple}
        value={value}
        onChange={onChange}
      >
        <div className="">
          {multiple && (
            <div className="overflow-auto">
              {value.map((v) => (
                <div
                  onClick={() => removeItem(v)}
                  className="inline-flex items-center mb-[4px] bg-[rgba(228,228,228,0.3)] mr-2 text-[12px] md:text-xs p-1 rounded-xl cursor-pointer"
                >
                  <span>{v.name}</span>
                  <CrossIcon />
                </div>
              ))}
            </div>
          )}
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              autoComplete="off"
              className="bg-[rgba(228,228,228,0.25)] w-full border-none py-[18px] pl-5 pr-10 text-[14px] md:text-sm leading-5 text-gray-900 focus:ring-0 focus-visible:outline-none"
              displayValue={(v) => v?.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-2 flex items-center pr-2">
              <ChevronIcon className="text-black-100" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {filterdValues.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filterdValues.map((v) => (
                  <Combobox.Option
                    key={v.id}
                    className={({ active }) =>
                      `relative select-none hover:bg-gray-200 cursor-pointer py-2 ${optionContainerClassName} ${
                        active
                          ? 'text-gray-900 font-semibold bg-gray-200'
                          : 'text-gray-900'
                      }`
                    }
                    value={v}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${optionClassName} ${
                            selected
                              ? 'font-medium text-red-100'
                              : 'font-normal'
                          }`}
                        >
                          {v?.name}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {error && (
        <div
          className={clsx(
            'text-red-300 text-[12px] md:text-xs font-semibold mt-1.5',
            errorClassName
          )}
        >
          {error}
        </div>
      )}
    </div>
  )
}
