//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { v4 } from 'uuid'

//custom components
import { Carousel } from 'components/Carousel'
import { ModalCloseIcon } from 'components/Icons'

export default function ViewProjectDetailModal({
  isOpen,
  closeModal,
  project,
}) {
  if (!project) return null
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black-background-overlay bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white p-8 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer" />
                  </div>

                  {/* Body Section */}
                  <div>
                    <h3 className="text-black-100 leading-6 text-lg font-medium">
                      {project.title}
                    </h3>
                    <p className="text-description text-sm leading-6 font-medium mt-4 pr-16">
                      {project.description}
                    </p>
                  </div>
                  <div className="mt-10">
                    <Carousel fileUrls={project.imageUrls} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
