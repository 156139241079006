import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//custom components
import Dropdown from 'components/dropdown/Dropdown'
import { getStatusJobApplicantsForEmployer } from 'app/services/jobAPI'

const sortData = [
  { id: 'highest_pay', name: 'Highest Pay' },
  { id: 'lowest_pay', name: 'Lowest Pay' },
]

export default function Header({ tab, setTab, tabsData }) {
  const [info, setInfo] = useState([0, 0, 0])

  const { id } = useParams()

  const fetchInfo = useCallback(async () => {
    const result = await getStatusJobApplicantsForEmployer(id)
    setInfo(result.data)
  }, [])

  useEffect(() => {
    fetchInfo()
  }, [])

  return (
    <div className="flex items-center space-x-4 justify-start">
      <div className="flex space-x-[17px] md:space-x-6">
        <Dropdown
          data={tabsData}
          value={tab}
          labelClassName="text-[#11142D] text-[14px] font-semibold font-inter"
          btnClassName="text-black-100 w-auto bg-white border pt-4.5 pb-5 border-gray-500 pr-10 min-w-[320px] rounded-[16px] bg-white border border-gray-500 text-black-100 text-opacity-50 text-sm font-semibold relative w-full cursor-default rounded-lg py-2 px-[18px] text-left h-full flex items-center"
          onChange={setTab}
        />
      </div>
    </div>
  )
}
