import clsx from 'clsx'
import React from 'react'

export default function Button({
  variant,
  prefix,
  suffix,
  className,
  children,
  onClick,
  ...rest
}) {
  let commonClassName = ''
  switch (variant) {
    case 'circle':
      commonClassName =
        'w-12 h-12 flex justify-center items-center rounded-full'
      break
    case 'primary':
      commonClassName =
        'text-[white] bg-blue-100 leading-5 font-bold inline-block flex justify-center py-4 rounded-2xl transition-all duration-300 hover:scale-105 disabled:scale-100 disabled:opacity-50 items-center'
      break
    case 'error':
      commonClassName =
        'text-[white] bg-[#C83F3F] leading-5 font-bold inline-block flex justify-center py-4 hover:scale-105 transition-all duration-300 rounded-2xl disabled:opacity-50'
      break
    case 'secondary':
      commonClassName =
        'text-white bg-green-100 leading-5 font-bold inline-block flex justify-center py-4 rounded-2xl transition-all duration-300 hover:scale-105 disabled:scale-100 disabled:opacity-50'
      break
    case 'error':
      commonClassName =
        'text-white bg-[#C83F3F] leading-5 font-bold inline-block flex justify-center py-4 rounded-2xl'
      break
    case 'outline':
      commonClassName =
        'border border-gray-400 border-solid leading-5 font-bold inline-block flex justify-center py-4 rounded-2xl'
      break
    case 'apply':
      commonClassName =
        'text-white bg-[#6C5DD3] font-bold inline-block flex justify-center py-4 rounded-2xl disabled:opacity-50'
      break
    case 'upload':
      commonClassName =
        'text-green-100 font-bold inline-block flex justify-center py-2 rounded-2xl bg-white'
      break
    case 'text':
      commonClassName = 'inline-block flex justify-center py-2'
      break
    case 'menu':
      commonClassName =
        'bg-white text-blue-100 font-bold text-[12px] leading-[20px]'
      break
    case 'black':
      commonClassName =
        'bg-[#808191] text-white font-bold text-[12px] leading-[20px]'
      break
    case 'gray':
      commonClassName =
        'text-white bg-[#808191] leading-5 font-bold inline-block flex justify-center py-4 rounded-2xl'
      break
    case 'grayRed':
      commonClassName =
        'text-[#C83F3F] bg-[#DEDFE3] leading-5 font-bold inline-block flex justify-center py-4 rounded-2xl'
      break
    default:
      break
  }
  return (
    <button
      {...rest}
      className={clsx(commonClassName, className)}
      onClick={onClick}
    >
      {prefix} {children} {suffix}
    </button>
  )
}
