import axios from 'axios'
import UserServiceClient from 'app/clients/userService'

const API_URL = process.env.REACT_APP_USERS_API_URL

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')

  if (!refreshToken) {
    throw new Error('No refresh token available')
  }

  try {
    const response = await axios.post(API_URL + '/user/refresh/token', {
      refreshToken,
    })
    const { accessToken } = response.data
    localStorage.setItem('token', accessToken)
    return accessToken
  } catch (error) {
    console.error('Refresh token error:', error)
    throw error
  }
}

const autoLogin = async () => {
  const accessToken = localStorage.getItem('token')
  if (!accessToken) {
    return null
  }

  try {
    // Validate accessToken (this could be a simple API call to validate the token)
    await axios.post(API_URL + '/user/access/token/verify', {
      token: accessToken,
    })

    // If validation succeeds, return the user info or token
    return accessToken
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // Token might be expired, try refreshing it
      try {
        const newAccessToken = await refreshToken()
        return newAccessToken
      } catch (refreshError) {
        console.error('Auto login refresh token error:', refreshError)
        return null
      }
    } else {
      console.error('Auto login validation error:', error)
      return null
    }
  }
}

const createUser = (data) => {
  return axios.post(API_URL + '/user', data)
}

export const updateUserLastActivity = (userID) => {
  return axios.post(API_URL + '/user/update-last-activity', { userID })
}

const login = async (email, password, rememberMe) => {
  return axios.post(API_URL + '/user/login', {
    email,
    password,
    rememberMe,
  })
}

const verifyRefreshToken = (refreshToken) => {
  return axios.post(API_URL + '/user/refresh/token/verify', {
    refreshToken,
  })
}

const forgotPassword = ({ email }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + '/user/forgot-password', {
        email,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const verify = ({ token, id }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + '/user/verify-email', {
        token,
        id,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error.response.data)
      })
  })
}

const resendVerification = (token, email) => {
  return axios
    .post(API_URL + '/resend', {
      token,
      email,
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
      return error.response.data
    })
}

const setSession = (access_token) => {
  if (access_token) {
    localStorage.setItem('jwt_access_token', access_token)
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
  } else {
    localStorage.removeItem('jwt_access_token')
    delete axios.defaults.headers.common.Authorization
  }
}

const updateUserData = (user) => {
  return axios.post('/api/auth/user/update', {
    user,
  })
}

/** Upload file to the amazon s3 and get url */
const uploadFileToS3 = ({ fileContent, fileName }) => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
  }
  return new Promise((resolve, reject) => {
    UserServiceClient.post(
      '/user/upload-file-s3',
      {
        fileContent,
        fileName,
      },
      {
        headers: headers,
      }
    )
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/** Upload Multiple files to the amazon s3 and get urls */
const uploadFilesToS3 = (data) => {
  return UserServiceClient.post('/user/upload-files-s3', data)
}

/** id (user id) send the verification link to email again */
const resendVerifyLink = ({ id, email }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + '/user/resend-verify-link', {
        id,
        email,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addProjectToProfileAPI = (data) => {
  return UserServiceClient.post('/user/add-project', data)
}

export const editProjectAPI = (data) => {
  return UserServiceClient.post(API_URL + '/user/edit-project', data)
}

export const deleteProjectAPI = (data) => {
  return UserServiceClient.post('/user/delete-project', data)
}

export const editUserAPI = ({ data, userId }) => {
  return UserServiceClient.put('/user/' + userId, data)
}

export const getPaypalToken = () => {
  return UserServiceClient.post('/user/generate-client-token')
}

export const createPaypalOrder = () => {
  return fetch(API_URL + '/user/create-order', { method: 'POST' })
}

export const capturePaymentForPaypalOrder = (orderId) => {
  return fetch(API_URL + `/user/capture-order-paypal-payment/${orderId}`)
}

export const checkEmailExists = (data) => {
  return axios.post(API_URL + '/user/check-email-existence', data)
}

export const getClientSecret = () => {
  return UserServiceClient.get('/user/stripe/get-token')
}

export const resetPassword = ({ id, data }) => {
  return axios.post(API_URL + `/user/reset-password/${id}`, data)
}

const logout = () => {
  localStorage.removeItem('user')
}

export default {
  createUser,
  login,
  verify,
  updateUserData,
  logout,
  setSession,
  resendVerification,
  uploadFileToS3,
  resendVerifyLink,
  forgotPassword,
  uploadFilesToS3,
  verifyRefreshToken,
  autoLogin,
  refreshToken,
}
