//packages
import React from 'react'

import { AnimateSpinIcon } from 'components/Icons'

export default function CardFooter({ formik, deleteProfile }) {
  return (
    <div className="flex flex-col md:flex-row md:justify-end md:space-x-2.5 mt-[30px] md:mt-5 md:mb-10">
      <button
        onClick={deleteProfile}
        className="order-last md:order-first w-full md:w-auto text-[#FF754C] text-[14px] md:text-sm font-bold py-[18px] px-[20px] rounded-2xl hover:bg-gray-200"
      >
        Delete Profile
      </button>
      <button
        type="submit"
        className="flex justify-center items-center order-1 md:order-2 w-full md:w-auto uppercase text-white font-bold text-[14px] md:text-sm bg-blue-100 py-[18px] md:px-20 rounded-2xl hover:opacity-80 diabled:opacity-50"
        onClick={formik.handleSubmit}
        disabled={formik.isSubmitting}
      >
        Update Profile
        {formik.isSubmitting && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
      </button>
    </div>
  )
}
