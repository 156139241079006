//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

//custom components
import { ChatIcon2 } from 'components/Icons'
import { ModalCloseIcon } from 'components/Icons'
import Button from 'components/Button'

export default function ChatNoPermissionModal({
  isOpen,
  closeModal,
  upgradeNow,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative pt-[30px] md:pt-24 pb-[30px] md:pb-12 px-[25px] md:px-7 bg-white shadow-[14px_41px_50px_0.07] w-full rounded-2xl max-w-[492px] transform overflow-hidden text-left align-middle transition-all">
                <div>
                  <div
                    className="absolute top-5 right-3 cursor-pointer"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon />
                  </div>
                  <div className="flex justify-center">
                    <ChatIcon2 className="text-green-100 w-16" />
                  </div>
                  <h4 className="text-gray-100 leading-8 text-2xl font-inter text-center mt-14">
                    You need to upgrade to Premium account to send a message
                  </h4>
                  <div className="mt-14">
                    <Button
                      onClick={upgradeNow}
                      variant="secondary"
                      className="w-full font-inter font-bold leading-5 text-sm text-white py-4.5"
                    >
                      Upgrade Now
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
