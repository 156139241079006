//packages
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import clsx from 'clsx'
import heic2any from 'heic2any'
import AWS from 'aws-sdk'

//custom components
import Button from 'components/Button'
import { DeleteIcon } from 'components/Icons'
// import CroppingImageModal from 'components/Dialog/CroppingImageModal'
import CropImageModal from 'components/Dialog/CropImageModal'
import { AnimateSpinIcon } from 'components/Icons'

//redux & api
import { getUser } from 'app/store/profileSlice'
import AuthService from 'app/services/auth.service'

import { fetchBlobFromUrl, blobToBase64, generateRandomString } from 'app/utils/function'
import { CrossIcon } from 'components/Icons'

export default function CardHeader({ formik }) {
  const [isAvatarUploading, setIsAvatarUploading] = useState(false)
  const [isOpenCroppingImageModal, setIsOpenCroppingImageModal] =
    useState(false)
  const [avatarFileData, setAvatarFileData] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  const profile = useSelector(getUser)

  // const handleFileChange = (e) => {
  //   setIsAvatarUploading(true)
  //   const file = e.target.files[0]
  //   if (!file) {
  //     return
  //   }
  //   const reader = new FileReader()
  //   reader.readAsBinaryString(file)
  //   reader.onload = async () => {
  //     const array = file.name.split('.')
  //     const extension = array[array.length - 1]
  //     const param = {
  //       fileContent: btoa(reader.result),
  //       fileName: `${v4()}.${extension}`,
  //     }
  //     console.log('loaded', reader.result)
  //     AuthService.uploadFileToS3(param)
  //       .then((res) => {
  //         console.log('debig: res', res)
  //         setIsAvatarUploading(false)
  //         formik.setFieldValue('avatar', res.data.url)
  //       })
  //       .catch((err) => {
  //         setIsAvatarUploading(false)
  //         console.log(err)
  //       })
  //   }
  //   reader.onerror = () => {
  //     console.log('error on load image')
  //   }
  // }

  const convertHEICToJpeg = async (heicFile) => {
    try {
      const arrayBuffer = await heicFile.arrayBuffer()
      const blob = await heic2any({
        blob: new Blob([new Uint8Array(arrayBuffer)]),
        toType: 'image/jpeg',
        quality: 0.8, // Optional: Set JPEG quality (0-1)
      })

      return URL.createObjectURL(blob)
    } catch (error) {
      console.error('HEIC to JPEG conversion failed:', error)
      return null
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      if (extension.toLowerCase() === 'heic') {
        try {
          const jpegURL = await convertHEICToJpeg(file)
          console.log("jpegURL", jpegURL)
          console.log('HEIC to JPEG conversion successful')
          // Handle the converted JPEG data as needed
          setAvatarFileData(jpegURL)
          setIsOpenCroppingImageModal(true)
        } catch (error) {
          console.error('HEIC to JPEG conversion failed:', error)
        }
      } else {
        setFileExtension(extension)
        const reader = new FileReader()
        reader.onload = handleFileLoad
        reader.readAsDataURL(file)
      }
    }
  }

  const handleFileLoad = (event) => {
    const fileData = event.target.result
    setAvatarFileData(fileData)
    setIsOpenCroppingImageModal(true)
  }

  async function handleCroppedAvatarUpload(url) {
    try {
      const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
      const REGION = process.env.REACT_APP_AWS_REGION

      setIsAvatarUploading(true)
      const result = await fetchBlobFromUrl(url)
      // const fileContent = await blobToBase64(result)
      setIsOpenCroppingImageModal(false)

      const fileName = generateRandomString(8) + ".png"
      const file = new File([result], fileName, { type: 'image/png' });

      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
        ContentType: file.type,
      };

      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
          console.log('File uploaded successfully:', data);

          const uploadedFileName = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`
          formik.setFieldValue('avatar', uploadedFileName)
          setIsAvatarUploading(false)
        }
      });

      // const param = {
      //   fileContent,
      //   fileName: `${v4()}.${fileExtension}`,
      // }
      // let response = await AuthService.uploadFileToS3(param)
      // formik.setFieldValue('avatar', response.data.url)
    } catch (err) {
      console.log(err)
    }
  }

  const removeAvatar = () => {
    formik.setFieldValue('avatar', '')
  }

  return (
    <div className="w-full bg-blue-100 rounded-t-3xl py-[30px] md:py-10 px-[25px] md:px-9 flex items-center">
      <div className="flex space-x-6">
        {formik.values.avatar ? (
          <div className="w-[50px] md:w-24 h-[50px] md:h-24 relative">
            <img
              className="w-[50px] md:w-24 h-[50px] md:h-24 object-cover rounded-full"
              alt="user-avatar"
              src={formik.values.avatar}
            />

            <div
              onClick={() => formik.setFieldValue('avatar', '')}
              className="w-[24px] h-[24px] bg-[#52B4DA] rounded-full absolute bottom-0 right-0 cursor-pointer block md:hidden"
            >
              <CrossIcon className="text-white" />
            </div>
          </div>
        ) : (
          <div
            className={clsx(
              'w-24 h-24 font-semibold rounded-full bg-white flex justify-center items-center text-yellow-100 leading-10 text-5xl font-poppins relative',
              isAvatarUploading && 'blur-sm'
            )}
          >
            <input
              type="file"
              className="absolute w-full h-full opacity-0 block md:hidden"
              onChange={(e) => handleFileChange(e)}
              accept="image/*, .heic"
            />
            {isAvatarUploading ? (
              <AnimateSpinIcon className="text-green-100" />
            ) : (
              profile.name[0]
            )}
          </div>
        )}
        <div className="flex flex-col justify-center">
          <h3 className="text-white font-bold leading-[27px] md:leading-5 text-[18px] md:text-base">
            {profile.name}
          </h3>
          <p className="text-white opacity-80 text-[12px] md:text-xs mt-2 font-normal leading-[15px] md:leading-4 overflow-none truncate overflow-hidden flex-nowrap max-w-[calc(100%-50px)] md:max-w-full">
            {profile.email}
          </p>
          <div className="space-x-5 items-center mt-4 hidden md:flex">
            <div className="relative">
              <input
                type="file"
                className="absolute w-full opacity-0"
                onChange={(e) => handleFileChange(e)}
                accept="image/*, .heic"
              />
              <Button
                className="px-4 disabled:opacity-50 font-bold leading-4 text-xs rounded-lg cursor-pointer"
                variant="upload"
                disabled={isAvatarUploading}
              >
                {isAvatarUploading && (
                  <AnimateSpinIcon size={15} className="text-green-100 mr-2" />
                )}
                Upload Image
              </Button>
            </div>

            <Button
              className="cursor-pointer flex items-center space-x-2 font-bold text-xs leading-4 text-white"
              variant="text"
              onClick={removeAvatar}
              disabled={isAvatarUploading}
            >
              <DeleteIcon />
              <span>Remove</span>
            </Button>
          </div>
        </div>
      </div>
      {/* <CroppingImageModal
        image={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      /> */}
      <CropImageModal
        imageData={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      />
    </div>
  )
}
