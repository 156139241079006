import React from 'react'

export default function ThreeDotIcon({ className = 'text-black', size = 10 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 10 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1262 3.12003C0.821511 3.12003 0.559911 3.01231 0.341397 2.79688C0.125962 2.57836 0.018244 2.31676 0.018244 2.01207C0.018244 1.71046 0.125962 1.45194 0.341397 1.23651C0.559911 1.02107 0.821511 0.913352 1.1262 0.913352C1.42165 0.913352 1.68018 1.02107 1.90177 1.23651C2.12336 1.45194 2.23415 1.71046 2.23415 2.01207C2.23415 2.2152 2.18183 2.4014 2.07719 2.57067C1.97563 2.73686 1.84175 2.87074 1.67556 2.9723C1.50937 3.07079 1.32625 3.12003 1.1262 3.12003ZM4.99827 3.12003C4.69358 3.12003 4.43198 3.01231 4.21347 2.79688C3.99803 2.57836 3.89031 2.31676 3.89031 2.01207C3.89031 1.71046 3.99803 1.45194 4.21347 1.23651C4.43198 1.02107 4.69358 0.913352 4.99827 0.913352C5.29372 0.913352 5.55225 1.02107 5.77384 1.23651C5.99543 1.45194 6.10622 1.71046 6.10622 2.01207C6.10622 2.2152 6.0539 2.4014 5.94926 2.57067C5.8477 2.73686 5.71382 2.87074 5.54763 2.9723C5.38144 3.07079 5.19832 3.12003 4.99827 3.12003ZM8.87034 3.12003C8.56565 3.12003 8.30405 3.01231 8.08554 2.79688C7.8701 2.57836 7.76238 2.31676 7.76238 2.01207C7.76238 1.71046 7.8701 1.45194 8.08554 1.23651C8.30405 1.02107 8.56565 0.913352 8.87034 0.913352C9.16579 0.913352 9.42432 1.02107 9.64591 1.23651C9.8675 1.45194 9.97829 1.71046 9.97829 2.01207C9.97829 2.2152 9.92597 2.4014 9.82133 2.57067C9.71977 2.73686 9.58589 2.87074 9.4197 2.9723C9.25351 3.07079 9.07039 3.12003 8.87034 3.12003Z"
        fill="currentColor"
      />
    </svg>
  )
}
