import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  fetchJobsById,
  createJobAPI,
  updateJobAPI,
  deactivateJobAPI,
  activateJobAPI,
  fetchHomePageJobById,
} from 'app/services/jobAPI'
import { fetchJobsByFilter, fetchJobByID } from 'app/services/jobAPI'
import {
  getApplicantsByJobIDAPI,
  rejectApplicantAPI,
  acceptApplicantAPI,
} from 'app/services/applicantAPI'

const API_URL = `${process.env.REACT_APP_JOBS_API_URL}`

export const getJobsByUser = createAsyncThunk(
  'jobs/getJobsByUser',
  async (userId) => {
    const response = await fetchJobsById(userId)
    return response.data
  }
)

export const addNewJob = createAsyncThunk(
  'jobs/createJob',
  async ({ userId, data }) => {
    const response = await createJobAPI({ userId, data })
    return response.data
  }
)

export const updateJob = createAsyncThunk(
  'jobs/updateJob',
  async ({ userId, data }) => {
    const response = await updateJobAPI({ userId, data })
    return response.data
  }
)

export const deactivateJob = createAsyncThunk(
  'jobs/deactivateJob',
  async ({ jobId }) => {
    const response = await deactivateJobAPI({ jobId })
    return response.data
  }
)

export const activateJob = createAsyncThunk(
  'jobs/activateJob',
  async ({ jobId }) => {
    const response = await activateJobAPI({ jobId })
    return response.data
  }
)

export const deleteJob = (id) => async (dispatch) => {
  return await axios
    .delete(`${API_URL}/jobs/${id}`)
    .then((res) => {
      // return dispatch(getJobsByUser(user.userId));
    })
    .catch((errors) => {
      return dispatch(deleteError(errors))
    })
}

export const findJobsByTitle = createAsyncThunk(
  'jobs/findByTitle',
  async ({ title }) => {
    const res = await axios.get(`${API_URL}/jobs?title=${title}`)
    return res.data
  }
)

export const searchJobsByFilter = createAsyncThunk(
  'jobs/searchJobsByFilter',
  async (data) => {
    const result = await fetchJobsByFilter(data)
    return result.data
  }
)

export const getJobWithID = createAsyncThunk(
  'jobs/getJobWithID',
  async ({ id, data }) => {
    const result = await fetchJobByID({ id, data })
    return result.data
  }
)

export const getHomePageJobWithID = createAsyncThunk(
  'jobs/getHomePageJobWithID',
  async ({ id }) => {
    const result = await fetchHomePageJobById(id)
    return result.data
  }
)

export const getApplicantsByJobID = createAsyncThunk(
  'jobs/getApplicantsByJobID',
  async (data) => {
    const result = await getApplicantsByJobIDAPI(data)
    return result.data
  }
)

export const rejectApplicant = createAsyncThunk(
  'jobs/rejectApplicant',
  async (data) => {
    const response = await rejectApplicantAPI(data)
    return response.data
  }
)

export const acceptApplicant = createAsyncThunk(
  'applicants/acceptApplicant',
  async (data) => {
    const response = await acceptApplicantAPI(data)
    return response.data
  }
)

const initialState = {
  jobs: [],
  applicants: [], //applicants to the selected job
  selectedJob: null,
  searchedJob: null,
  loading: 'idle', // 'idle' : 'pending' : 'succeeded' : 'failed
  createProcessLoading: 'idle',
  updateProcessLoading: 'idle',
  deactivatingProcessLoading: 'idle',
  fetchingJobWithID: 'idle',
  fetchingHomePageJobWithID: 'idle',
  fetchingApplicantsLoading: 'idle',
  count: 0,
  applicantsCount: 0,
}

const jobSlice = createSlice({
  name: 'employer/job/',
  initialState,
  reducers: {
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload
    },
  },
  extraReducers: (builder) => {
    //get jobs
    builder.addCase(getJobsByUser.pending, (state, action) => {
      state.loading = 'pending'
    })
    builder.addCase(getJobsByUser.fulfilled, (state, action) => {
      state.jobs = action.payload
      state.loading = 'succeeded'
    })
    builder.addCase(getJobsByUser.rejected, (state, action) => {
      state.loading = 'failed'
    })

    //create job
    builder.addCase(addNewJob.pending, (state, action) => {
      state.createProcessLoading = 'pending'
    })
    builder.addCase(addNewJob.fulfilled, (state, action) => {
      state.jobs = [...state.jobs, action.payload]
      state.createProcessLoading = 'succeeded'
    })
    builder.addCase(addNewJob.rejected, (state, action) => {
      state.createProcessLoading = 'failed'
    })

    //update Job
    builder.addCase(updateJob.pending, (state, action) => {
      state.updateProcessLoading = 'pending'
    })
    builder.addCase(updateJob.fulfilled, (state, action) => {
      const replace2Job = action.payload.updatedJob
      state.jobs = [
        ...state.jobs.map((job) => {
          if (job.id === replace2Job.id) {
            return replace2Job
          } else return job
        }),
      ]
      state.selectedJob = replace2Job
      state.updateProcessLoading = 'succeeded'
    })
    builder.addCase(updateJob.rejected, (state, action) => {
      state.updateProcessLoading = 'failed'
    })

    //deactivate Job
    builder.addCase(deactivateJob.pending, (state, action) => {
      state.deactivatingProcessLoading = 'pending'
    })
    builder.addCase(deactivateJob.fulfilled, (state, action) => {
      const replace2Job = action.payload.updatedJob
      state.jobs = [
        ...state.jobs.map((job) => {
          if (job.id === replace2Job.id) {
            return replace2Job
          } else return job
        }),
      ]
      state.selectedJob = replace2Job
      state.deactivatingProcessLoading = 'succeeded'
    })
    builder.addCase(deactivateJob.rejected, (state, action) => {
      state.deactivatingProcessLoading = 'failed'
    })

    //activate Job
    builder.addCase(activateJob.fulfilled, (state, action) => {
      const replace2Job = action.payload.updatedJob
      state.jobs = [
        ...state.jobs.map((job) => {
          if (job.id === replace2Job.id) {
            return replace2Job
          } else return job
        }),
      ]
      state.selectedJob = replace2Job
    })

    //searchJobsByFilter
    builder.addCase(searchJobsByFilter.fulfilled, (state, action) => {
      // console.log('debug: action', action.payload)
      state.jobs = action.payload.jobs
      state.count = action.payload.count
    })

    //Get Job withg ID
    builder.addCase(getJobWithID.pending, (state, action) => {
      state.fetchingJobWithID = 'pending'
    })
    builder.addCase(getJobWithID.rejected, (state, action) => {
      state.fetchingJobWithID = 'failed'
    })
    builder.addCase(getJobWithID.fulfilled, (state, action) => {
      state.fetchingJobWithID = 'succeeded'
      state.selectedJob = action.payload
    })

    //Get Home Page Job with Job ID
    builder.addCase(getHomePageJobWithID.pending, (state, action) => {
      state.fetchingHomePageJobWithID = 'pending'
    })
    builder.addCase(getHomePageJobWithID.rejected, (state, action) => {
      state.fetchingHomePageJobWithID = 'failed'
    })
    builder.addCase(getHomePageJobWithID.fulfilled, (state, action) => {
      state.fetchingHomePageJobWithID = 'succeeded'
      state.searchedJob = action.payload
    })

    //getApplicantsByJobID
    builder.addCase(getApplicantsByJobID.pending, (state, action) => {
      state.fetchingApplicantsLoading = 'pending'
    })
    builder.addCase(getApplicantsByJobID.rejected, (state, action) => {
      state.fetchingApplicantsLoading = 'failed'
    })
    builder.addCase(getApplicantsByJobID.fulfilled, (state, action) => {
      state.fetchingApplicantsLoading = 'succeeded'
      state.applicants = action.payload.applicants
      state.applicantsCount = action.payload.count
    })

    builder.addCase(rejectApplicant.fulfilled, (state, action) => {
      state.applicants = state.applicants.map((v) => {
        if (v.id === action.payload.id) {
          return {
            ...v,
            status: 'rejected',
          }
        }
        return v
      })
    })
    builder.addCase(acceptApplicant.fulfilled, (state, action) => {
      state.applicants = state.applicants.map((v) => {
        if (v.id === action.payload.id) {
          return {
            ...v,
            status: 'accepted',
          }
        }
        return v
      })
    })

    //Clear data after user logout
    builder.addCase('auth/LogOut', (state) => {
      state.jobs = []
      state.applicants = []
    })
  },
})

export const getJobsCount = (state) => state.job.count
export const getJobsList = (state) => state.job.jobs

export const {
  getJobsByUserSuccess,
  getJobsByUserError,
  jobSuccess,
  jobError,
  addJobSuccess,
  addJobError,
  deleteSuccess,
  deleteError,
  clearJobState,
  setSelectedJob,
} = jobSlice.actions

export default jobSlice.reducer
