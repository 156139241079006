import React from 'react'
import logo from 'assets/img/logo-updated.png'
import { Link } from 'react-router-dom'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon2,
  TiktokIcon,
  YoutubeIcon,
} from 'components/Icons'

function Footer() {
  return (
    <div className="relative bottom-0 w-full h-[594px] bg-[#00113C] flex flex-col justify-center items-center">
      <div className="w-full h-[153px] mb-[141px]">
        <p className="text-white text-center font-sans not-italic text-[40px] font-normal">
          Let's Connect and Make It Happen!
        </p>
        <p className="text-white text-center font-sans text-sm not-italic font-normal opacity-80 self-stretch">
          Have questions or need support? Reach out, and we’ll help you bridge
          the gap between talent and <br /> opportunity.
        </p>
        <div className="flex justify-center items-end">
          <a href="mailto:team@remoterecruit.com">
            <button className="w-[125px] h-[34px] bg-[#F7F9FB] mt-[15px] color-[#00113C] px-[5px] py-[6px] rounded-[4px] shrink-0">
              👋 Contact Us
            </button>
          </a>
        </div>
      </div>

      <div className="flex items-center justify-between w-[1031px] h-[50px] mb-[78px]">
        <div>
          <Link href="/" className="flex items-center gap-2">
            <img
              src={logo}
              alt="RemoteRecruit"
              width={79.702}
              height={31.887}
            />
          </Link>
        </div>
        <div className="text-white text-center font-sans not-italic text-sm font-normal">
          <Link to="/privacy-policy">Privacy Policy</Link> |{' '}
          <Link to="/terms-of-service">Terms of Use</Link>
        </div>
        <div className="flex items-center">
          <ul className="space-y-4">
            <li className="flex space-x-4">
              <a
                target="_blank"
                href="https://www.facebook.com/RemoteRecruitOfficial"
                className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <FacebookIcon />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/remoterecruithq"
                className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                href="https://twitter.com/RemoteRecruitHQ"
                className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <TwitterIcon2 />
              </a>
              <a
                target="_blank"
                href="https://www.tiktok.com/@teamremoterecruit"
                className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <TiktokIcon />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@RemoteRecruit"
                className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <YoutubeIcon />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/remoterecruit-com-official"
                className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer"
              >
                <LinkedinIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="text-white text-center font-sans text-sm not-italic font-normal">
        © {new Date().getFullYear()} Remote Recruit All Rights Reserved.
      </p>
    </div>
  )
}

export default Footer
