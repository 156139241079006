//packages
import clsx from 'clsx'
import React, { useState } from 'react'

//custom components
import { CircleCheckedIcon, PencilIcon } from 'components/Icons'

export default function TextInput({
  label,
  value,
  valid = true,
  onChange,
  error,
  errorClassName = 'text-xs',
  disabled: intialDefault = false,
  enableEdit = true,
  variant = 'checkbox',
  containerClassName = 'w-full space-y-2',
  inputClassName = 'disabled:bg-[rgba(228,228,228,0.3)] py-[19px] px-[18px] w-full text-black-100 text-opacity-50 rounded-lg focus-visible:outline-none focus:text-opacity-100',
}) {
  const [disabled, setDisabled] = useState(intialDefault)
  return (
    <div className={containerClassName}>
      <label
        className={
          variant === 'checkbox'
            ? clsx(
                'text-[12px] md:text-xs block',
                valid && !disabled ? 'text-blue-100' : 'text-gray-300'
              )
            : clsx(
                'text-[12px] font-medium font-inter leading-[16px] text-[#B2B3BD]'
              )
        }
      >
        {label}
      </label>
      <div className="relative">
        <input
          onChange={onChange}
          disabled={disabled}
          className={
            variant === 'checkbox'
              ? clsx(
                  inputClassName,
                  valid && !disabled
                    ? 'border border-green-100 bg-white'
                    : disabled
                    ? ''
                    : 'border border-gray-500 bg-white'
                )
              : clsx(inputClassName)
          }
          autoComplete="off"
          value={value}
          onBlur={() => {
            if (variant === 'checkbox') {
              setDisabled(true)
            }
          }}
        />
        {variant === 'checkbox' && (
          <div
            className={clsx(
              'absolute  right-[15px] cursor-pointer',
              error ? 'top-[calc(50%-20px)]' : 'top-[calc(50%-7px)]'
            )}
            onClick={() => enableEdit && setDisabled(false)}
          >
            {!error ? <CircleCheckedIcon /> : <PencilIcon />}
          </div>
        )}
        {error && (
          <div
            className={clsx(
              'text-red-300 text-[12px] md:text-xs font-semibold',
              errorClassName
            )}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  )
}
