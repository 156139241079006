//packages
import { useFormik } from 'formik'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

//custom components
import EmployerAboutForm from 'components/Forms/EmployerAboutForm'
import ListingForm from 'components/Forms/ListingForm'
import MembershipForm from 'components/Forms/MembershipForm'
import AuthCard from 'components/Card/AuthCard'
import { AnimateSpinIcon } from 'components/Icons'

//api && redux
import { setUserInfo } from 'app/store/authSlice'
import { getPaypalToken } from 'app/services/auth.service'
import { addNewJob } from 'app/store/jobSlice'
import AuthService from 'app/services/auth.service'
import { Helmet } from 'react-helmet'

const initialValues = {
  avatar: '', // individual user's avatar
  logo: '', // company logo
  firstName: '',
  lastName: '',
  location: '',
  bio: '',
  IndividualDescription: '',

  companyName: '',
  country: '',
  companySize: null,
  industry: [],
  website: '',
  description: '',
  membership: 'basic',
  type: 'individual',

  //information of new job posting
  title: '',
  language: [],
  duration: '',
  payPreference: [],
  payPreferenceMode: [],
  maxHourlyRate: '',
  minHourlyRate: '',
  maxAnnualSalary: '',
  minAnnualSalary: '',
  exactAnnualSalary: '',
  exactHourlyRate: '',
  jobSkills: [],
  jobDescription: '',
  coverLetterVideoRequirement: '',
  coverLetterVideoInstructions: '',
}

export default function RegisterForm() {
  // const history = useHistory();
  const [activeStep, setActiveStep] = useState(0)
  const [skipping, setSkipping] = useState(false)
  const [finishing, setFinishing] = useState(false)
  const [clientToken, setClientToken] = useState(null)
  const [upgraded, setUpgraded] = useState(false)
  const steps = ['Profile', 'Last']

  const dispatch = useDispatch()
  const [success, setSuccess] = useState(false)

  const location = useLocation()

  const fetchClientToken = useCallback(async () => {
    let response = await getPaypalToken()
    setClientToken(response.data.client_token)
  }, [setClientToken])

  useEffect(() => {
    fetchClientToken()
  }, [fetchClientToken])

  if (!location.state) {
    return <Navigate to="/auth/register" />
  }

  const validationSchema = [
    //validation for step1
    yup.object().shape({
      firstName: yup.string().required('You must enter your first name'),
      lastName: yup.string().required('You must enter your last name'),
      location: yup.object().required('You must enter your location'),
      // bio: yup.string(),
      IndividualDescription: yup.string().required('You must enter your bio'),
      companyName: yup.string().when('type', {
        is: (type) => type === 'company',
        then: yup.string().required('Company Name is required'),
      }),
      country: yup.object().when('type', {
        is: (type) => type === 'company',
        then: yup.object().required('You must enter HQ Country'),
      }),

      companySize: yup
        .object()
        .nullable()
        .when('type', {
          is: (type) => type === 'company',
          then: yup
            .object()
            .typeError('You must enter your company size')
            .required('You must enter your Company Size'),
        }),
      industry: yup
        .array()
        .of(yup.object())
        .when('type', {
          is: (type) => type === 'company',
          then: yup
            .array()
            .of(yup.object())
            .required('Industry Tags are required')
            .test('Min test', 'Must have at least 1 item', function (item) {
              return item.length >= 1
            }),
        }),
      website: yup.string().when('type', {
        is: (type) => type === 'company',
        then: yup.string().required('You must enter your website link'),
      }),
      description: yup.string().when('type', {
        is: (type) => type === 'company',
        then: yup.string().required('You must enter your description'),
      }),
    }),

    //validation for step2
    // yup.object().shape({
    //   // cardName: yup.string().required('You must enter Name on the Card'),
    //   // cardNumber: yup.string().required('You must enter Card Number'),
    //   // expiry: yup.string().required('You must enter expiry date'),
    //   // csv: yup.string().required('You must enter CSV'),
    //   // membership: yup.string().required('You must choose membership option'),
    // }),
    //validation for step2
    yup.object().shape({
      title: yup.string().required('You must enter Job title'),
      payPreference: yup
        .array()
        .min(1, 'You must select at least one Pay Preference')
        .of(yup.string())
        .required('You must select at least one Pay Preference'),
      payPreferenceMode: yup.array().of(yup.string()),
      jobSkills: yup
        .array()
        .of(yup.object())
        .test('Min test', 'Must have at least 2 items', function (item) {
          return item.length >= 2
        }),
      jobDescription: yup.string().required('You must enter Job Description'),
      maxHourlyRate: yup.string().when(['payPreference', 'payPreferenceMode'], {
        is: (payPreference, payPreferenceMode) =>
          payPreference.includes('hourly') &&
          payPreferenceMode.includes('hourlyRange'),
        then: yup.string().required('Max Hourly rate is required.'),
      }),
      minHourlyRate: yup.string().when(['payPreference', 'payPreferenceMode'], {
        is: (payPreference, payPreferenceMode) =>
          payPreference.includes('hourly') &&
          payPreferenceMode.includes('hourlyRange'),
        then: yup.string().required('Min Hourly rate is required.'),
      }),
      maxAnnualSalary: yup
        .string()
        .when(['payPreference', 'payPreferenceMode'], {
          is: (payPreference, payPreferenceMode) =>
            payPreference.includes('annual') &&
            payPreferenceMode.includes('annualRange'),
          then: yup.string().required('Max Annual rate is required.'),
        }),
      minAnnualSalary: yup
        .string()
        .when(['payPreference', 'payPreferenceMode'], {
          is: (payPreference, payPreferenceMode) =>
            payPreference.includes('annual') &&
            payPreferenceMode.includes('annualRange'),
          then: yup.string().required('Min Annual rate is required.'),
        }),
      exactAnnualSalary: yup
        .string()
        .when(['payPreferenceMode', 'payPreference'], {
          is: (payPreferenceMode, payPreference) =>
            !payPreferenceMode.includes('annualRange') &&
            payPreference.includes('annual'),
          then: yup.string().required('Exact Annual salary is required.'),
        }),
      exactHourlyRate: yup
        .string()
        .when(['payPreferenceMode', 'payPreference'], {
          is: (payPreferenceMode, payPreference) =>
            !payPreferenceMode.includes('hourlyRange') &&
            payPreference.includes('hourly'),
          then: yup.string().required('Exact Hourly rate is required.'),
        }),
      language: yup
        .array()
        .of(yup.object())
        .required('You must enter Language needed'),
      duration: yup.object().required('You must enter Job Duration needed'),
      coverLetterVideoRequirement: yup
        .string()
        .required('You must tick one of the Cover Letter Video CheckBox'),
      coverLetterVideoInstructions: yup
        .string()
        .when('coverLetterVideoRequirement', {
          is: (value) => value === 'mandatory' || value === 'encouraged',
          then: yup
            .string()
            .required(
              'Instructions are required when the cover letter video is mandatory or encouraged'
            ),
          otherwise: yup.string(), // Not required if 'not_required' or not selected
        }),
    }),
  ]
  const currentValidationSchema = validationSchema[activeStep]
  // const step3Schema = validationSchema[2];

  // step3Schema.test({
  //   name: 'eitherFieldSelected',
  //   exclusive: true,
  //   message: 'Either field1 or field2 must be selected',
  //   test: function (values) {
  //     const { field1, field2 } = values;
  //     return field1 !== undefined || field2 !== undefined;
  //   },
  // });

  const formik = useFormik({
    initialValues,
    validationSchema: currentValidationSchema,
    onSubmit: (values) => {
      if (activeStep < steps.length - 1) {
        setActiveStep((prev) => prev + 1)
      } else if (activeStep === 1) {
        registerAndPostJob()
      }
    },
  })

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <EmployerAboutForm formik={formik} />
      // case 1:
      //   return (
      //     <MembershipForm
      //       formik={formik}
      //       onMoveNextStep={handleNext}
      //       clientToken={clientToken}
      //       upgraded={upgraded}
      //       setUpgraded={setUpgraded}
      //       handleBack={handleBack}
      //     />
      //   )
      case 1:
        return <ListingForm formik={formik} />
      default:
        return 'Unknown step'
    }
  }

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = async () => {
    formik.setSubmitting(true)
    setSkipping(true)
    registerUser()
      .then((res) => {
        console.log('success')
        toast.success('User created successfully.')
        dispatch(setUserInfo(res))

        const userData = res.data.user
        localStorage.setItem('verifId', userData.id)
        localStorage.setItem('regEmail', userData.email)

        setSuccess(true)
      })
      .catch((err) => {
        toast.error('There is error when creating user.')
        console.log('debug: err', err)
      })
      .finally(() => {
        formik.setSubmitting(false)
        setSkipping(false)
      })
  }

  // check if the active step is on 2nd step to open the popup notification when click X
  useEffect(() => {
    if (activeStep !== 0) {
      localStorage.setItem('show_signup_notification', true)
    } else {
      localStorage.setItem('show_signup_notification', false)
    }
  }, [activeStep])

  const registerAndPostJob = async () => {
    formik.setSubmitting(true)
    try {
      setFinishing(true)
      let result = await registerUser()

      const token = result.data.token
      localStorage.setItem('token', token)

      const userData = result.data.user
      const userId = result.data.user.id
      localStorage.setItem('verifId', userData.id)
      localStorage.setItem('regEmail', userData.email)
      const {
        title,
        language,
        duration,
        minHourlyRate,
        maxHourlyRate,
        maxAnnualSalary,
        minAnnualSalary,
        exactAnnualSalary,
        exactHourlyRate,
        payPreference,
        payPreferenceMode,
        jobSkills,
        jobDescription,
        coverLetterVideoRequirement,
        coverLetterVideoInstructions,
      } = formik.values

      const postJobParams = {
        title,
        language: language.map((v) => v.id),
        duration: duration.name,
        min_hourly_rate: minHourlyRate.replaceAll(',', ''),
        max_hourly_rate: maxHourlyRate.replaceAll(',', ''),
        min_annual_salary: minAnnualSalary.replaceAll(',', ''),
        max_annual_salary: maxAnnualSalary.replaceAll(',', ''),
        exact_annual_salary: exactAnnualSalary.replaceAll(',', ''),
        exact_hourly_rate: exactHourlyRate.replaceAll(',', ''),
        pay_preference: payPreference,
        pay_preference_mode: payPreferenceMode,
        skills: jobSkills.map((v) => v.id),
        description: jobDescription,
        coverLetterVideoRequirement,
        coverLetterVideoInstructions,
      }

      let resultAction = await dispatch(
        addNewJob({ userId, data: postJobParams })
      )

      if (addNewJob.fulfilled.match(resultAction)) {
        toast.success('User created successfully.')
        dispatch(setUserInfo(result))
        setSuccess(true)
      } else {
        if (resultAction.payload) {
          toast.error(
            'User Creation failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('User Creation failed' + resultAction.error.message)
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error('There is error when creating user.')
    } finally {
      formik.setSubmitting(false)
      setFinishing(false)
    }
  }

  const registerUser = () => {
    const { email, password } = location.state
    const {
      avatar,
      logo,
      firstName,
      lastName,
      location: userLocation,
      bio,
      companyName,
      country,
      companySize,
      industry,
      website,
      IndividualDescription,
      description,
      membership,
      type,
    } = formik.values
    let param

    if (type === 'individual') {
      param = {
        avatar,
        firstName,
        lastName,
        location: userLocation.name,
        IndividualDescription,
        password,
        plan: membership,
        type: 'employer',
        emType: type,
        email,
      }
    } else {
      param = {
        avatar,
        logo,
        firstName,
        lastName,
        location: userLocation.name,
        companyName,
        country: country.name,
        companySize: companySize.id,
        industry: industry.map((v) => v.id),
        password,
        website,
        IndividualDescription,
        bio: description,
        plan: membership,
        type: 'employer',
        emType: type,
        email,
      }
    }

    return AuthService.createUser(param)
  }

  return (
    <div className="w-116 md:w-[628px] mx-auto py-[10vh]">
      <Helmet>
        <title>Employer Registration - Remoterecruit.com</title>
        <meta
          name="description"
          content="Create an employer account to hire top remote talent efficiently and globally."
        />
      </Helmet>
      <AuthCard className="py-8 px-8">
        <div>
          {success ? (
            <Navigate to="/auth/verify" />
          ) : (
            <div>
              <div>{getStepContent(activeStep)}</div>
              {activeStep === 0 && (
                <div className="flex justify-center">
                  <button
                    onClick={formik.handleSubmit}
                    className="font-bold text-[14px] md:text-sm text-white leading-5 bg-blue-100 py-[18px] px-[80px] rounded-2xl"
                  >
                    {/* Next: Membership */}
                    Next: Add Job Post
                  </button>
                </div>
              )}
              {activeStep === 1 && (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-2.5 mt-2">
                  <button
                    disabled={formik.isSubmitting}
                    onClick={handleBack}
                    className="disabled:opacity-70 py-[18px] cursor-pointer flex justify-center items-center text-white leading-5 text-[14px] md:text-sm font-bold border-[2px] border-solid border-green-100 rounded-2xl bg-green-100"
                  >
                    Back
                  </button>
                  <button
                    disabled={formik.isSubmitting}
                    onClick={handleSkip}
                    className="disabled:opacity-70 py-[18px] cursor-pointer hover:text-blue-100 hover:border-blue-100 flex justify-center items-center text-black-100 leading-5 text-[14px] md:text-sm font-bold border-[2px] border-solid border-gray-400 rounded-2xl"
                  >
                    Skip
                    {skipping && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
                  </button>
                  <button
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="col-span-2 md:col-span-1 disabled:opacity-70 py-[18px] cursor-pointer hover:text-blue-100 hover:border-blue-100 hover:bg-white flex justify-center items-center text-white leading-5 text-[14px] md:text-sm font-bold border-[2px] border-solid border-blue-100 rounded-2xl bg-blue-100"
                  >
                    Finish
                    {finishing && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </AuthCard>
    </div>
  )
}
