import React from "react"

const PlusIcon = ({
  className,
  size = 30
}) => {
  return (
    <svg className={className} width={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 3C12 1.34315 13.3431 0 15 0C16.6569 0 18 1.34315 18 3V27C18 28.6569 16.6569 30 15 30C13.3431 30 12 28.6569 12 27V3Z" fill="currentColor" />
      <path d="M0 15C0 13.3431 1.34315 12 3 12H27C28.6569 12 30 13.3431 30 15C30 16.6569 28.6569 18 27 18H3C1.34315 18 0 16.6569 0 15Z" fill="currentColor" />
    </svg>
  )
}

export default PlusIcon