import clsx from 'clsx'
import React from 'react'

export default function Checkbox({
  value,
  variant = 'secondary',
  label,
  onChange,
  disabled = false,
  className = 'flex items-center mr-4 mb-2',
  labelClassName = '',
}) {
  const checkboxId = `checkbox-${Math.random().toString(36).substr(2, 9)}`

  const renderCheckbox = () => (
    <div className={className}>
      <input
        type="checkbox"
        id={checkboxId}
        value={value}
        checked={value}
        onChange={onChange}
        className="opacity-0 absolute h-4 w-4 cursor-pointer"
        disabled={disabled}
      />
      <div
        id="v1-checkbox"
        className={clsx(
          'bg-white border-[2px] rounded border-gray-400 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 border-solid cursor-pointer',
          value && 'bg-green-200 border-green-200'
        )}
      >
        {value && (
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.6151 7.86232C4.58858 7.86232 4.56234 7.85683 4.53803 7.8462C4.51373 7.83556 4.49189 7.82002 4.47389 7.80054L0.666448 3.68198C0.641067 3.65452 0.624241 3.62026 0.618029 3.58339C0.611817 3.54652 0.616489 3.50863 0.631473 3.47437C0.646458 3.44012 0.671104 3.41097 0.702396 3.3905C0.733688 3.37003 0.770268 3.35913 0.80766 3.35913H2.64035C2.66787 3.35914 2.69506 3.36504 2.7201 3.37645C2.74514 3.38786 2.76745 3.40451 2.78551 3.42527L4.05797 4.88919C4.19549 4.59523 4.4617 4.10577 4.92885 3.50934C5.61947 2.62761 6.90404 1.33086 9.10181 0.160247C9.14429 0.137626 9.19371 0.131756 9.24031 0.143794C9.2869 0.155832 9.32729 0.184904 9.3535 0.225269C9.37971 0.265635 9.38982 0.31436 9.38186 0.361822C9.37389 0.409285 9.34842 0.452035 9.31047 0.481632C9.30208 0.48819 8.4547 1.1555 7.47947 2.37779C6.58193 3.50259 5.38881 5.34182 4.80172 7.71625C4.7914 7.75796 4.76742 7.79502 4.73358 7.82151C4.69975 7.848 4.65802 7.8624 4.61504 7.8624L4.6151 7.86232Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      <label
        htmlFor={checkboxId}
        className={clsx(
          'select-none text-black-100 text-[14px] md:text-sm cursor-pointer',
          value ? '' : 'opacity-50',
          labelClassName
        )}
      >
        {label}
      </label>
    </div>
  )

  switch (variant) {
    case 'secondary':
      return renderCheckbox()
    case 'primary':
      return renderCheckbox()
    default:
      return null
  }
}
