//packages
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//custom components
import HomeLogo from 'assets/img/logo-home.png'
import Button from 'components/Button'
import { CrossIcon, HamburgerIcon } from 'components/Icons'

const menus = [
  {
    name: 'Home',
    route: '/',
  },
  {
    name: 'Features',
    route: '/features',
  },
  {
    name: 'Pricing',
    route: '/pricing',
  },
  {
    name: 'F.A.Q',
    route: '/faq',
  },
]

export default function Header({ active }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const navigate = useNavigate()
  const toSignUp = () => {
    navigate('/auth/register')
  }

  const toSignIn = () => {
    navigate('/auth')
  }

  const handleToSignUp = () => {
    document.body.style.overflow = null
    setShowMobileMenu(false)
    navigate('/auth/register')
  }

  const handleToSignIn = () => {
    document.body.style.overflow = null
    setShowMobileMenu(false)
    navigate('/auth')
  }

  useEffect(() => {
    if (!showMobileMenu) {
      document.body.style.overflow = null
    }
  }, [showMobileMenu])

  return (
    <div className="flex justify-between px-[15px] sm:px-14 pt-[40px] sm:py-7 items-center">
      <Link to="/" className="w-[83px] md:w-30.75">
        <img src={HomeLogo} alt="home-logo" />
      </Link>
      <div className="flex items-center md:space-x-25.5">
        <ul className="hidden sm:flex space-x-11 items-center">
          {/* {menus.map((v) => (
            <Link key={v.name} to={v.route}>
              <li
                key={v.name}
                className={clsx(
                  v.name === active
                    ? 'text-white'
                    : 'text-[rgba(255,255,255,0.5)]',
                  'font-medium text-base cursor-pointer relative'
                )}
              >
                {v.name}
                {v.name === active && (
                  <div className="flex w-full justify-center mt-1 absolute">
                    <div className="w-4 h-1 bg-green-100"></div>
                  </div>
                )}
              </li>
            </Link>
          ))} */}
        </ul>
        <ul className="flex space-x-6 items-center">
          <li>
            <Button
              variant="text"
              className="text-gray-600 font-semibold text-[12px] md:text-sm px-0 md:px-4 py-[4px] md:py-2.5 leading-[18px] md:leading-4.5"
              onClick={toSignIn}
            >
              Sign In
            </Button>
          </li>
          <li className="mr-7 text-[rgba(255,255,255,0.5)] font-medium text-base cursor-pointer">
            <Button
              variant="secondary"
              className="text-gray-600 font-semibold leading-[18px] md:leading-4.5 text-[12px] md:text-sm px-[16px] md:px-4 py-[4px] md:py-2.5 flex-nowrap"
              onClick={toSignUp}
            >
              Sign Up
            </Button>
          </li>
        </ul>
      </div>

      {/* <div
        onClick={() => {
          document.body.style.overflow = 'hidden'
          setShowMobileMenu(true)
        }}
        className="block sm:hidden"
      >
        <HamburgerIcon />
      </div> */}

      {/* Mobile menu */}
      {showMobileMenu && (
        <div className="fixed h-[100vh] left-0 top-0 w-screen mobile-menu z-50 content-between grid">
          <div className="flex justify-between py-[40px] px-[15px]">
            <Link to="/" className="w-[83px] md:w-30.75">
              <img className="w-full" src={HomeLogo} alt="home-logo" />
            </Link>
            <div onClick={() => setShowMobileMenu(false)}>
              <div className="w-[24px] h-[24px] flex justify-center items-center bg-white rounded-full">
                <CrossIcon size={16} />
              </div>
            </div>
          </div>
          <div className="space-y-[40px]">
            {menus.map((v) => (
              <div className="text-center" key={v.name}>
                <Link
                  to={v.route}
                  className={clsx(
                    v.name === active ? 'text-green-100' : 'text-white',
                    'text-center text-[24px] cursor-pointer relative font-semibold'
                  )}
                >
                  {v.name}
                </Link>
              </div>
            ))}
          </div>
          <div className="p-[40px] space-y-3">
            <Button
              onClick={handleToSignIn}
              variant="secondary"
              className="w-full text-[14px] leading-[20px] font-bold text-white py-[19px] rounded-[16px]"
            >
              Sign In
            </Button>
            <Button
              onClick={handleToSignUp}
              variant="text"
              className="w-full text-[14px] leading-[20px] font-bold text-white py-[19px] rounded-[16px]"
            >
              Sign Up
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
