//packages
import clsx from 'clsx'
import React, { useState } from 'react'

//custom components
import TextField from 'components/Input/TextField'
import Checkbox from 'components/Checkbox/Checkbox'
import NumberInput from 'components/Input/NumberInput'

export default function JobInterestsPanel({ formik }) {
  const hourlyRateValue = formik.values['hourly_rate']
  const annualRateValue = formik.values['annual_rate']

  const defaultPayStyle =
    hourlyRateValue && annualRateValue
      ? 'both'
      : !hourlyRateValue && annualRateValue
      ? 'annual'
      : hourlyRateValue && !annualRateValue
      ? 'hourly'
      : !hourlyRateValue && !annualRateValue
      ? 'negotiable'
      : ''

  // console.log(defaultPayStyle)
  const [payStyle, setPayStyle] = useState(defaultPayStyle)
  // console.log(formik)

  return (
    <div>
      <div className="space-y-5">
        <div className="space-y-[16px]">
          <div className="md:flex space-y-[10px] md:space-y-0 md:space-x-[10px]">
            <div
              className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg"
              style={{ border: '2px solid var(--Border, #E0E5F9)' }}
            >
              <Checkbox
                variant="primary"
                value={payStyle === 'hourly'}
                onChange={(e) => {
                  // formik.setFieldValue('isHide', !formik.values.isHide)
                  setPayStyle('hourly')
                }}
                label="Hourly"
              />
            </div>

            <div
              className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg text-balance"
              style={{ border: '2px solid var(--Border, #E0E5F9)' }}
            >
              <Checkbox
                variant="primary"
                value={payStyle === 'annual'}
                onChange={(e) => {
                  // formik.setFieldValue('isHide', !formik.values.isHide)
                  setPayStyle('annual')
                }}
                label="Annual&nbsp;Salary"
              />
            </div>

            <div
              className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg"
              style={{ border: '2px solid var(--Border, #E0E5F9)' }}
            >
              <Checkbox
                variant="primary"
                value={payStyle === 'both'}
                onChange={(e) => {
                  // formik.setFieldValue('isHide', !formik.values.isHide)
                  setPayStyle('both')
                }}
                label="Both"
              />
            </div>

            <div
              className="inline-flex px-5 py-[18px] items-start mr-[10px] bg-[rgba(228, 228, 228, 0.25)] rounded-lg"
              style={{ border: '2px solid var(--Border, #E0E5F9)' }}
            >
              <Checkbox
                variant="primary"
                value={payStyle === 'negotiable'}
                onChange={(e) => {
                  formik.setFieldValue('hourly_rate', 0)
                  formik.setFieldValue('annual_rate', 0)
                  setPayStyle('negotiable')
                }}
                label="Negotiable"
              />
            </div>

            {/* <div
              onClick={() => setPayStyle('hourly')}
              className={clsx(
                'cursor-pointer text-[14px] md:text-sm font-semibold leading-5  border-[2px] border-solid rounded-lg py-[14px] px-[28px]',
                (hourlyRateValue !== 0 && annualRateValue === 0) ||
                  payStyle === 'hourly'
                  ? 'bg-green-100 text-white border-green-100'
                  : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
              )}
            >
              Hourly
            </div> */}
            {/* <div
              onClick={() => setPayStyle('annual')}
              className={clsx(
                'cursor-pointer text-[14px] md:text-sm font-semibold leading-5  border-[2px] border-solid rounded-lg py-[14px] px-[28px]',
                (hourlyRateValue === 0 && annualRateValue !== 0) ||
                  payStyle === 'annual'
                  ? 'bg-green-100 text-white border-green-100'
                  : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
              )}
            >
              Annual Salary
            </div> */}
            {/* <div
              onClick={() => setPayStyle('both')}
              className={clsx(
                'cursor-pointer text-[14px] md:text-sm font-semibold leading-5  border-[2px] border-solid rounded-lg py-[14px] px-[28px]',
                (hourlyRateValue !== 0 && annualRateValue !== 0) ||
                  payStyle === 'both'
                  ? 'bg-green-100 text-white border-green-100'
                  : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
              )}
            >
              Both Hourly & Salary
            </div> */}
            {/* <div
              onClick={() => setPayStyle('negotiable')}
              className={clsx(
                'cursor-pointer text-[14px] md:text-sm font-semibold leading-5  border-[2px] border-solid rounded-lg py-[14px] px-[28px]',
                (hourlyRateValue === 0 && annualRateValue === 0) ||
                  payStyle === 'negotiable'
                  ? 'bg-green-100 text-white border-green-100'
                  : 'bg-[rgba(228,228,228,0.25)] text-black-100 border-gray-500'
              )}
            >
              Negotiable
            </div> */}
          </div>
        </div>
        {payStyle === 'hourly' && (
          <div>
            <div className="grid grid-cols-2 gap-x-5">
              <NumberInput
                label="Expected Hourly Rate"
                name="hourly_rate"
                id="hourly_rate"
                onChange={formik.handleChange}
                value={formik.values['hourly_rate']}
                error={
                  formik.touched['hourly_rate']
                    ? formik.errors['hourly_rate']
                    : ''
                }
                placeholder="Min Hourly Rate"
              />
              <div className="flex items-center mt-[35px]">
                <p className="text-[14px] md:text-sm text-gray-300 leading-[16px] font-medium">
                  *($)USD only
                </p>
              </div>
            </div>
          </div>
        )}
        {payStyle === 'annual' && (
          <div>
            <div className="grid grid-cols-2 gap-x-5">
              <NumberInput
                label="Expected Annual Salary"
                name="annual_rate"
                id="annual_rate"
                onChange={formik.handleChange}
                value={formik.values['annual_rate']}
                error={
                  formik.touched['annual_rate']
                    ? formik.errors['annual_rate']
                    : ''
                }
                placeholder="Min Annual Salary"
              />
              <div className="flex items-center mt-[30px]">
                <p className="text-[14px] md:text-sm text-gray-300 leading-[16px] font-medium">
                  *($)USD only
                </p>
              </div>
            </div>
          </div>
        )}
        {payStyle === 'both' && (
          <div>
            {/* <div className="grid grid-cols-2 gap-x-5"> */}
            <div className="gap-x-5">
              <div className="mb-5">
                <NumberInput
                  prefix="$"
                  suffix="USD"
                  label="Expected Hourly Rate"
                  name="hourly_rate"
                  id="hourly_rate"
                  onChange={formik.handleChange}
                  value={formik.values['hourly_rate']}
                  error={
                    formik.touched['hourly_rate']
                      ? formik.errors['hourly_rate']
                      : ''
                  }
                  placeholder="Min Hourly Rate"
                />
              </div>
              <NumberInput
                prefix="$"
                suffix="USD"
                label="Expected Annual Salary"
                name="annual_rate"
                id="annual_rate"
                onChange={formik.handleChange}
                value={formik.values['annual_rate']}
                error={
                  formik.touched['annual_rate']
                    ? formik.errors['annual_rate']
                    : ''
                }
                placeholder="Min Annual Salary"
              />
            </div>
          </div>
        )}
      </div>
      {/* <div className="mt-[20px] md:mt-5">
        <div>
          <Checkbox
            value={formik.values.isHide}
            onChange={(e) => {
              formik.setFieldValue('isHide', !formik.values.isHide)
            }}
            label={
              <div className="text-gray-100 font-semibold text-[13px] leading-[18px]">
                Hide pay preferences in profile
              </div>
            }
            sizeClass="w-6 h-6"
            rectClass="border-gray-500 focus-within:border-gray-500 w-6 h-6"
            checkClass="w-4 h-4 text-green-200"
          />
        </div>
      </div> */}
    </div>
  )
}
