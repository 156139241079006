import React, { useState } from 'react'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import ReactPlayer from 'react-player'
import { Document, Page } from 'react-pdf'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import axios from 'axios';

export default function Carousel({ fileUrls = [] }) {
  const [numPdfPages, setNumPdfPages] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [csvContent, setCsvContent] = useState('');
  
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
  };

  console.log(fileUrls)
  return (
    <ResponsiveCarousel
      showIndicators
      showStatus
      infiniteLoop
      stopOnHover
      autoPlay
      showArrows
      swipeable
      showThumbs={false}
    >
      {fileUrls.map((fileUrl, index) => {
        if(fileUrl.endsWith('.html')) {
          async function fetchExternalHTML() {
            const response = await axios.get(fileUrl);
            const html = response.data;
            setHtmlContent(html);
          }
          fetchExternalHTML();
        }

        return (
          <div key={index}>
            {fileUrl.endsWith('.pdf') ? (
              <Document
                file={fileUrl}
                onLoadSuccess={({ numPages })=>setNumPdfPages(numPages)}
              >
                {Array.apply(null, Array(numPdfPages))
                .map((x, i)=>i+1)
                .map(page => <Page pageNumber={page}/>)}
              </Document>
            ) : fileUrl.endsWith('.mp4') ? (
              <ReactPlayer url={fileUrl} controls />
            ) : fileUrl.endsWith('.csv') ? (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={[
                  { uri: fileUrl, fileType: 'csv'}
                ]}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: false,
                    retainURLParams: false,
                  },
                }}
              />
            ) : fileUrl.endsWith('.txt') ? (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={[
                  { uri: fileUrl, fileType: 'txt'}
                ]}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: false,
                    retainURLParams: false,
                  },
                }}
              />
            ) : fileUrl.endsWith('.html') ? (
              <div dangerouslySetInnerHTML={{ __html:  htmlContent}} />
            ) : fileUrl.endsWith('.ppt') || fileUrl.endsWith('.doc') || fileUrl.endsWith('.docx') 
                || fileUrl.endsWith('.xls') ? (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                width="100%"
                height="600px"
                frameBorder="0"
              >
              </iframe>
            ) : (
              <img src={fileUrl} alt={fileUrl} />
            )}
          </div>
        )
      })}
    </ResponsiveCarousel>
  )
}
