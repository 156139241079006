import { createStore, applyMiddleware, combiReducers } from 'redux'
import thunk from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from '../reducers'

import authSlice from 'app/store/authSlice'
import profileSlice from 'app/store/profileSlice'
import jobSlice from 'app/store/jobSlice'
import candidateSlice from 'app/store/candidateSlice'
import applicantsSlice from 'app/store/applicantsSlice'
import inviteSlice from 'app/store/inviteSlice'
import refreshReducer from 'app/store/refreshSlice'

// const store = createStore(rootReducer, applyMiddleware(thunk));

export const store = configureStore({
  reducer: {
    auth: authSlice,
    profile: profileSlice,
    job: jobSlice,
    candidate: candidateSlice,
    applicants: applicantsSlice,
    invite: inviteSlice,
    refresh: refreshReducer,
  },
})

export default store
