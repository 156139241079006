import InviteServiceClient from 'app/clients/inviteService'

export const sendInviteAPI = (data) => {
  return InviteServiceClient.post(`/invite`, data)
}

export const getInvitesList = (data) => {
  return InviteServiceClient.post(`/invites/list`, data)
}

export const rejectInviteAPI = (data) => {
  return InviteServiceClient.post(`/invites/reject`, data)
}

export const unRejectInviteAPI = (data) => {
  return InviteServiceClient.post('/invites/un-reject', data)
}
export const approveInviteAPI = (data) => {
  return InviteServiceClient.post(`/invites/accept`, data)
}

export const markInviteNotificationAPI = (data) => {
  return InviteServiceClient.post(`/invites/mark/notification`, data)
}

export const getInvitesWithJobId = (data) => {
  return InviteServiceClient.post(`/invites/list-with-jobId`, data)
}
