import React from 'react'

export default function PlusCircleIcon({
  className = 'text-[#52B4DA]',
  size = 30,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3187_16261)">
        <path
          d="M15 30C6.72867 30 0 23.2713 0 15C0 6.72867 6.72867 0 15 0C23.2713 0 30 6.72867 30 15C30 23.2713 23.2713 30 15 30ZM15 1.875C7.76253 1.875 1.875 7.76253 1.875 15C1.875 22.2375 7.76253 28.125 15 28.125C22.2375 28.125 28.125 22.2375 28.125 15C28.125 7.76253 22.2375 1.875 15 1.875Z"
          fill="currentColor"
        />
        <path
          d="M21.5625 15.9375H8.4375C7.92 15.9375 7.5 15.5175 7.5 15C7.5 14.4825 7.92 14.0625 8.4375 14.0625H21.5625C22.08 14.0625 22.5 14.4825 22.5 15C22.5 15.5175 22.08 15.9375 21.5625 15.9375Z"
          fill="currentColor"
        />
        <path
          d="M15 22.5C14.4825 22.5 14.0625 22.08 14.0625 21.5625V8.4375C14.0625 7.92 14.4825 7.5 15 7.5C15.5175 7.5 15.9375 7.92 15.9375 8.4375V21.5625C15.9375 22.08 15.5175 22.5 15 22.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3187_16261">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
