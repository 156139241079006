import TagServiceClient from 'app/clients/tagService'

class TagService {
  getAllTags(param) {
    if (param) {
      return TagServiceClient.get(`/tags?category=${param}`)
    } else {
      return TagServiceClient.get(`/tags`)
    }
  }
  //Todo createTag is not completed since the api is not clear
  createTag(tag) {
    return TagService.post(`/tag`, {})
  }

  updateTagCount(tagId) {
    return TagServiceClient.post(`/update-tag-search-count`, { tagId: tagId })
  }
}

export default new TagService()
