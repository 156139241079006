import axios from 'axios'

export const createUser = (data) => {
  return axios.post(`https://api.chatengine.io/users/`, data, {
    headers: {
      'PRIVATE-KEY': process.env.REACT_APP_CHAT_ENGINE_PROJECT_KEY,
    },
  })
}


export const getChats = ({ userName, userSecret }) => {
  return axios.get(`https://api.chatengine.io/chats/`, {
    headers: {
      'Project-ID': process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
      'User-Name': userName,
      'User-Secret': userSecret
    }
  })
}