import React from 'react'

export default function InstagramIcon({
  size = 22,
  className = 'text-[#DFE1F2]',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6668 6.33398C18.6668 4.67722 17.3236 3.33398 15.6668 3.33398H6.3335C4.67673 3.33398 3.3335 4.67722 3.3335 6.33398V15.6673C3.3335 17.3241 4.67673 18.6673 6.3335 18.6673H15.6668C17.3236 18.6673 18.6668 17.3241 18.6668 15.6673V6.33398ZM6.8335 11.0007C6.8335 8.69939 8.69914 6.83398 11.0002 6.83398C13.3016 6.83398 15.1668 8.69917 15.1668 11.0007C15.1668 13.3017 13.3014 15.1673 11.0002 15.1673C8.69935 15.1673 6.8335 13.3015 6.8335 11.0007ZM11.0002 7.83398C12.7494 7.83398 14.1668 9.25146 14.1668 11.0007C14.1668 12.7494 12.7491 14.1673 11.0002 14.1673C9.25164 14.1673 7.8335 12.7492 7.8335 11.0007C7.8335 9.2517 9.25139 7.83398 11.0002 7.83398ZM14.6668 6.33398C14.6668 5.78198 15.1148 5.33398 15.6668 5.33398C16.2195 5.33398 16.6668 5.78198 16.6668 6.33398C16.6668 6.88598 16.2195 7.33398 15.6668 7.33398C15.1148 7.33398 14.6668 6.88598 14.6668 6.33398Z"
        fill="currentColor"
      />
    </svg>
  )
}
