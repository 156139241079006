import React from 'react'

export default function LocationIcon({
  className = 'text-black-100',
  size = 24,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M12.0001 22.72C11.9016 22.7204 11.8039 22.7012 11.7129 22.6634C11.6219 22.6256 11.5393 22.5701 11.4701 22.5L5.7101 16.74C4.46449 15.4956 3.61594 13.9098 3.27179 12.1831C2.92763 10.4565 3.10335 8.6665 3.77669 7.0397C4.45004 5.4129 5.59077 4.02237 7.05457 3.04402C8.51837 2.06566 10.2395 1.54346 12.0001 1.54346C13.7607 1.54346 15.4818 2.06566 16.9456 3.04402C18.4094 4.02237 19.5502 5.4129 20.2235 7.0397C20.8969 8.6665 21.0726 10.4565 20.7284 12.1831C20.3843 13.9098 19.5357 15.4956 18.2901 16.74L12.5301 22.5C12.4609 22.5701 12.3783 22.6256 12.2873 22.6634C12.1963 22.7012 12.0986 22.7204 12.0001 22.72ZM12.0001 3.04996C10.5373 3.05098 9.10756 3.48554 7.89159 4.29873C6.67562 5.11192 5.72794 6.26726 5.16828 7.6188C4.60862 8.97033 4.46208 10.4574 4.74718 11.8922C5.03229 13.327 5.73624 14.6451 6.7701 15.68L12.0001 20.91L17.2301 15.68C18.264 14.6451 18.9679 13.327 19.253 11.8922C19.5381 10.4574 19.3916 8.97033 18.8319 7.6188C18.2723 6.26726 17.3246 5.11192 16.1086 4.29873C14.8926 3.48554 13.4629 3.05098 12.0001 3.04996Z"
          fill="currentColor"
        />
        <path
          d="M12 13.75C11.2583 13.75 10.5333 13.5301 9.91661 13.118C9.29993 12.706 8.81928 12.1203 8.53545 11.4351C8.25163 10.7498 8.17736 9.99584 8.32206 9.26841C8.46675 8.54098 8.8239 7.8728 9.34835 7.34835C9.8728 6.8239 10.541 6.46675 11.2684 6.32206C11.9958 6.17736 12.7498 6.25162 13.4351 6.53545C14.1203 6.81928 14.706 7.29993 15.118 7.91661C15.5301 8.5333 15.75 9.25832 15.75 10C15.75 10.9946 15.3549 11.9484 14.6517 12.6517C13.9484 13.3549 12.9946 13.75 12 13.75ZM12 7.75C11.555 7.75 11.12 7.88196 10.75 8.12919C10.38 8.37643 10.0916 8.72783 9.92127 9.13896C9.75098 9.5501 9.70642 10.0025 9.79323 10.439C9.88005 10.8754 10.0943 11.2763 10.409 11.591C10.7237 11.9057 11.1246 12.12 11.561 12.2068C11.9975 12.2936 12.4499 12.249 12.861 12.0787C13.2722 11.9084 13.6236 11.62 13.8708 11.25C14.118 10.88 14.25 10.445 14.25 10C14.25 9.40326 14.0129 8.83097 13.591 8.40901C13.169 7.98705 12.5967 7.75 12 7.75Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
