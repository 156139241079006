import React from 'react'

export default function BackIcon({ className = 'text-[#52B4DA]', size = 30 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 30 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.34371 8.82952C0.344063 8.82987 0.344357 8.83028 0.344767 8.83063L6.46805 14.9244C6.92678 15.3809 7.66875 15.3792 8.12537 14.9204C8.58193 14.4617 8.58018 13.7197 8.12144 13.2631L4.01027 9.17188L28.8281 9.17188C29.4754 9.17188 30 8.64723 30 8C30 7.35278 29.4754 6.82813 28.8281 6.82813L4.01033 6.82813L8.12139 2.73688C8.58012 2.28032 8.58187 1.53834 8.12531 1.07961C7.66869 0.620766 6.92666 0.619184 6.46799 1.07563L0.344707 7.16938C0.344355 7.16973 0.344063 7.17014 0.343651 7.17049C-0.115313 7.62858 -0.113848 8.37295 0.34371 8.82952Z"
        fill="currentColor"
      />
    </svg>
  )
}
