import React, { useCallback, useState } from 'react'
import useTagList from 'hooks/useTagList'
import { BackIcon } from 'components/Icons'
import SearchAutoComplete from './SearchAutoComplete'
import tagService from 'app/services/tag.service'

export default function SearchFilter({
  filter,
  setFilter,
  disabled,
  onShowFilterModal,
  setKeywords,
  keywords = [],
}) {
  const [key, setKey] = useState(null)
  const [skillsTags] = useTagList()

  const tagMapping = {}

  const mergedData = [
    ...skillsTags.map((v) => {
      const uniqueId = `skill-${v.id}`
      tagMapping[uniqueId] = v
      return { id: uniqueId, name: v.label, mainId: v.mainId }
    }),
  ]

  const updateFilter = useCallback(
    (updatedKeywords) => {
      if (updatedKeywords.length === 0) {
        setFilter((prev) => {
          const temp = { ...prev }
          delete temp.skills
          return temp
        })
      } else {
        const actualKeywords = updatedKeywords
          .map((key) => tagMapping[key]?.name)
          .filter(Boolean)
        setFilter((prev) => ({
          ...prev,
          skills: actualKeywords,
        }))
      }
    },
    [setFilter, tagMapping]
  )

  // Handle tag selection/deselection and trigger search tracking
  const handleChange = useCallback(
    async (value) => {
      let newKeywords
      if (keywords.includes(value.id)) {
        newKeywords = keywords.filter((v) => v !== value.id)
      } else {
        newKeywords = [...keywords, value.id]

        // Track the search by calling updateTagCount
        tagService.updateTagCount(value.mainId)
      }

      setKeywords(newKeywords)
      localStorage.setItem('filter_jobs_keywords', JSON.stringify(newKeywords))
      updateFilter(newKeywords)
    },
    [keywords, setKeywords, updateFilter]
  )

  const handleRemoveKeyword = useCallback(
    (key) => {
      const newKeywords = keywords.filter((v) => v !== key)
      setKeywords(newKeywords)
      localStorage.setItem('filter_jobs_keywords', JSON.stringify(newKeywords))
      updateFilter(newKeywords)
    },
    [keywords, setKeywords, updateFilter]
  )

  const getLabelById = (id) => {
    const item = id.replace(/^(skill-|job-)/, '')
    return item
  }

  return (
    <div className="relative">
      <div className="flex justify-center">
        <SearchAutoComplete
          disabled={disabled}
          data={mergedData}
          value={key}
          onChange={handleChange}
          placeholder="Search by skills or job titles"
          onShowFilterModal={onShowFilterModal}
        />
      </div>

      <div className="flex flex-row flex-wrap gap-[10px] mt-[10px] cursor-pointer top-16 left-0 z-0">
        {keywords.length > 0 &&
          keywords.map((key) => (
            <div
              className="flex text-white text-center text-xs leading-[20px] not-italic font-semibold font-inter pt-[6px] pb-[6px] pl-[15px] pr-[15px] min-w-[62px] min-h-[20px] justify-center items-center rounded-lg bg-[#52B4DA] bg-opacity-30"
              key={key}
              style={{ border: '1px solid rgba(82, 180, 218, 0.75)' }}
              onClick={() => handleRemoveKeyword(key)}
            >
              {getLabelById(key)}
              <span className="ml-[5px] h-[14px] w-[14px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M9.33464 4.66797L4.66797 9.33464"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.33464 9.33464L4.66797 4.66797"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          ))}
      </div>
      {keywords.length > 0 && (
        <div
          onClick={() => setKeywords([])}
          className="flex items-center space-x-4.5 cursor-pointer mt-8"
        >
          <BackIcon />
          <div className="text-[#52B4DA] leading-8 text-2xl font-medium">
            Go back
          </div>
        </div>
      )}
    </div>
  )
}
