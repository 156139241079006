import React, { useRef, useState, useEffect } from 'react'
import CategoryCard from './CategoryCard'
import designCardImg from 'assets/img/DesignCardImg.png'
import EngineeringImg from 'assets/img/EngineeringImg.png'
import DevImg from 'assets/img/DevImg.png'
import WritingImg from 'assets/img/WritingImg.png'
import AdminImg from 'assets/img/AdminImg.png'

function CategorySection() {
  const scrollContainerRef = useRef(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  // Check scroll position to determine arrow visibility
  const updateArrowsVisibility = () => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer

    // Show left arrow if not at the start
    setShowLeftArrow(scrollLeft > 0)

    // Show right arrow if not at the end
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth)
  }

  useEffect(() => {
    updateArrowsVisibility()
    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateArrowsVisibility)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', updateArrowsVisibility)
      }
    }
  }, [])

  // Scroll handler
  const scroll = (direction) => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const scrollAmount = 300 // Adjust as needed for the scroll distance
    scrollContainer.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      behavior: 'smooth',
    })
  }

  return (
    <div className="relative w-[calc(100%-128px)] ml-[128px] mt-[291px] mb-[120.83px]">
      <div className="flex flex-col items-start w-[826px]">
        {/* Section Title */}
        <div className="mb-[16px]">
          <p className="text-white font-manrope text-[56px] not-italic leading-[67.2px] font-light">
            Explore{' '}
            <span className="text-[#FFC628] font-semibold">Real Talent</span> by
            Category
          </p>
        </div>
      </div>

      {/* Scrollable Cards Section */}
      <div className="relative overflow-hidden">
        {/* Scrollable container */}
        <div
          ref={scrollContainerRef}
          className="flex gap-[13px] overflow-x-auto "
        >
          <CategoryCard
            color="#C161E6"
            image={designCardImg}
            text="Design & Creative"
          />
          <CategoryCard
            image={EngineeringImg}
            color="#BC9EC1"
            text="Engineering & Architecture"
          />
          <CategoryCard
            image={DevImg}
            color="#6DBF74"
            text="Development & IT"
          />
          <CategoryCard
            image={WritingImg}
            color="#FFC857"
            text="Writing and Translation"
          />
          <CategoryCard
            image={EngineeringImg}
            color="#FACB96"
            text="Finance & Accounting"
          />
          <CategoryCard
            image={AdminImg}
            color="#C161E6"
            text="Admin & Customer Support"
          />
          <CategoryCard
            image={AdminImg}
            color="#BC9EC1"
            text="Sales & Marketing"
          />
          <CategoryCard image={AdminImg} color="#6DBF74" text="Legal" />
        </div>

        {/* Left Arrow */}
        {showLeftArrow && (
          <div
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[39px] h-[39px] flex justify-center items-center bg-white rounded-full shadow-lg cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
            >
              <path
                d="M11.8215 2.53987L18.5983 9.65549M18.5983 9.65549L11.8215 16.7711M18.5983 9.65549L2.33398 9.65549"
                stroke="#011137"
                strokeWidth="3.25286"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}

        {/* Right Arrow */}
        {showRightArrow && (
          <div
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 w-[39px] h-[39px] flex justify-center items-center bg-white rounded-full shadow-lg cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
            >
              <path
                d="M11.8215 2.53987L18.5983 9.65549M18.5983 9.65549L11.8215 16.7711M18.5983 9.65549L2.33398 9.65549"
                stroke="#011137"
                strokeWidth="3.25286"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

export default CategorySection
