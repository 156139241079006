import React from 'react'

export default function ModalCloseIcon({
  size = 94,
  fill = 'white',
  className = 'text-black-100',
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_402_13655)">
        <circle cx="47" cy="47" r="19" fill="#E3E6EC" />
      </g>
      <circle cx="47" cy="34" r="24" fill={fill} />
      <path
        d="M51 30L43 38"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 38L43 30"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_f_402_13655"
          x="0.817183"
          y="0.817183"
          width="92.3656"
          height="92.3656"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="13.5914"
            result="effect1_foregroundBlur_402_13655"
          />
        </filter>
      </defs>
    </svg>
  )
}
