//packages
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fetchCandidatesAPI } from 'app/services/candidateAPI'
import { sendInviteAPI } from 'app/services/inviteAPI'

export const fetchCandidates = createAsyncThunk(
  'candidates/fetchCandidates',
  async (data, { rejectWithValue }) => {
    try {
      const { signal, ...searchParams } = data
      const response = await fetchCandidatesAPI(searchParams, signal)
      return response.data
    } catch (error) {
      // Add `error` here
      if (error.name === 'AbortError') {
        throw error // Let the AbortError propagate
      }
      return rejectWithValue(error.response?.data)
    }
  }
)

export const sendInvite = createAsyncThunk(
  'candidates/sentInvite',
  async (data) => {
    const response = await sendInviteAPI(data)
    return response.data
  }
)

const initialState = {
  candidates: [],
  loading: 'idle', // 'idle' : 'pending' : 'succeeded' : 'failed
  sendInviteLoading: 'idle',
  count: 0,
}

const candidateSlice = createSlice({
  name: 'employer/candidate',
  initialState,
  reducers: {
    resetAllStatus: (state, action) => {
      state.loading = 'idle'
      state.sendInviteLoading = 'idle'
    },
  },
  extraReducers: (builder) => {
    //get candidates
    builder.addCase(fetchCandidates.fulfilled, (state, action) => {
      state.candidates = action.payload.candidates
      state.count = action.payload.count
    })

    //sentInvite
    builder.addCase(sendInvite.pending, (state, action) => {
      state.sendInviteLoading = 'pending'
    })
    builder.addCase(sendInvite.fulfilled, (state, action) => {
      state.sendInviteLoading = 'succeeded'
    })
    builder.addCase(sendInvite.rejected, (state, action) => {
      state.sendInviteLoading = 'failed'
    })

    //Clear data after user logout
    builder.addCase('auth/LogOut', (state) => {
      state.candidates = []
    })
  },
})

export const getCandidates = (state) => state.candidate.candidates
export const getCandidatesCount = (state) => state.candidate.count

export const { resetAllStatus } = candidateSlice.actions

export default candidateSlice.reducer
