import { SearchIcon } from 'components/Icons'
import React from 'react'
import clsx from 'clsx'

export default function SearchInput({
  placeholder,
  value,
  onChange,
  disabled = false,
  className = 'py-[18px] pr-[20px] pl-[50px] md:pl-[64px] rounded-2xl w-full text-sm',
}) {
  return (
    <div className="w-full relative">
      <input
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete="off"
        className={clsx(
          'bg-[rgba(228,228,228,0.49776)] placeholder:text-gray-100 placeholder:font-semibold',
          className
        )}
      />
      <div className="absolute flex h-full top-0 items-center px-6">
        <SearchIcon className="" />
      </div>
    </div>
  )
}
