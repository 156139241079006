import clsx from 'clsx'
import React from 'react'

const AuthCard = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'bg-white rounded-[20px] md:rounded-[40px] shadow-md mt-10 py-[30px] px-[25px] md:p-[34px]',
        className
      )}
    >
      {children}
    </div>
  )
}

export default AuthCard
