import Button from 'components/Button'
import { Link } from 'react-router-dom'
import logo from 'assets/img/logo-updated.png'

export function NavBar() {
  const handleClick = (event) => {
    event.preventDefault() // Prevent the default navigation behavior
    const targetElement = document.getElementById('getStarted')
    targetElement?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <nav className="fixed top-0 w-full z-50 bg-[#00113C]/90 backdrop-blur-sm">
      <div className="container mx-auto px-4 h-16 flex items-center justify-between">
        <div className="flex items-center gap-8">
          <Link href="/" className="flex items-center gap-2">
            <img
              src={logo}
              alt="RemoteRecruit"
              width={79.702}
              height={31.887}
            />
          </Link>
          <div className="hidden md:flex gap-6 ml-[90px]">
            <Link
              href="/"
              className="text-white hover:text-white/80 text-base not-italic font-normal font-sans"
            >
              Home
            </Link>
            <Link
              href="/how-it-works"
              onClick={handleClick}
              className="text-white hover:text-white/80 text-base not-italic font-normal font-sans"
            >
              How it Works?
            </Link>
          </div>
        </div>
        <div className="flex items-center gap-[14.676px]">
          <Button
            variant="ghost"
            className="text-white hover:text-white/80 py-[11.741px] px-[33.8px] text-base not-italic font-normal font-sans"
          >
            Sign In
          </Button>
          <Button className="bg-white text-[#062B7C] hover:bg-white/90 py-[11.741px] px-[33.8px] text-base not-italic font-normal font-sans rounded-[4.4px]">
            Sign Up
          </Button>
        </div>
      </div>
    </nav>
  )
}
