import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import PaymentForm from 'components/Forms/PaypalPaymentForm'
import StripePaymentForm from 'components/Forms/StripePaymentForm'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'

//custom components
import Checkbox from 'components/Checkbox'
import { ModalCloseIcon } from 'components/Icons'

//api & redux
import { getPaypalToken, getClientSecret } from 'app/services/auth.service'
import { getUser, updateEmployerPlan } from 'app/store/profileSlice'

//assets
import Paypal from 'assets/img/Paypal.png'
import ActiveIcon from 'assets/images/active-icon.png'
import InActiveIcon from 'assets/images/inactive-icon-free-card.png'

export default function UpgradingPlanModal({ isOpen, closeModal }) {
  const [method, setMethod] = useState('stripe')
  const [clientToken, setClientToken] = useState(null)
  const [stripeClientToken, setStripeClientToken] = useState('')

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  useEffect(() => {
    const fetchStripeClientToken = async () => {
      let result = await getClientSecret()
      setStripeClientToken(result?.data?.paymentIntent?.client_secret)
    }
    fetchStripeClientToken()
  }, [])

  const fetchClientToken = useCallback(async () => {
    let response = await getPaypalToken()
    setClientToken(response.data.client_token)
  }, [setClientToken])

  useEffect(() => {
    fetchClientToken()
  }, [fetchClientToken])

  const onPaymentComplete = async () => {
    try {
      const param = {
        userId: profile.id,
        premium: true,
      }
      const resultAction = await dispatch(updateEmployerPlan(param))
      if (updateEmployerPlan.fulfilled.match(resultAction)) {
        toast.success('Upgraded Account Successfully')
        closeModal()
      } else {
        if (resultAction.payload) {
          toast.error(
            'Upgrade account failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Upgrade account failed' + resultAction.error.message)
        }
      }
    } catch (err) {
      toast.error('There is error when upgrading your account.')
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="pt-[30px] md:pt-14 pb-[30px] md:pb-12 px-[25px] md:px-8 bg-white shadow-[14px_41px_50px_0.07] w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle transition-all">
                <div
                  className="absolute top-2 right-1 cursor-pointer"
                  onClick={closeModal}
                >
                  <ModalCloseIcon />
                </div>
                <div className="relative">
                  <h3 className="md:text-center text-black-100 text-[18px] md:text-2xl font-medium leading-[24px] md:leading-9 mt-14 mb-10">
                    You are going to upgrade your current subscription to a
                    Premium plan.
                  </h3>
                  {/* <div className="mt-6 cursor-pointer md:p-[8px] md:border-[4px] md:border-blue-100 rounded-2xl border-solid">
                    <div className="rounded-2xl pt-5 pb-11 px-8 h-full card-gradient">
                      <div className="membership-option">
                        <div className="md:flex justify-between items-end">
                          <h4 className="tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold uppercase text-white">
                            Premium
                          </h4>
                          <h5 className="leading-[17px] md:leading-7 font-bold text-[14px] md:mt-[9px] text-[#96F154] ">
                            $79.99/month
                          </h5>
                        </div>
                        <div className="space-y-3 mt-[20px] md:mt-4 md:mb-0 text-white">
                          <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                            Up to 25 active job posts
                          </h6>
                          <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                            Premium Placement & Visibility
                          </h6>
                          <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                            Messaging anyone, unlimited
                          </h6>
                          <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                            Unlimited invites
                          </h6>
                          <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                            View all applicants
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  
                  <div className="grid md:grid-cols-2 gap-x-[5px] gap-y-[20px] h-full">
                    <div
                      className=''
                    >
                      <div
                        className='rounded-2xl pt-5 px-8 h-full border-[2px] border-gray-400 border-solid pb-12'
                      >
                        <div className="membership-option">
                          <h4
                            className='tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold text-blue-100'
                          >
                            BASIC
                          </h4>
                          <h5
                            className='leading-[19px] text-[16px] font-bold md:mt-[9px] text-green-100'
                          >
                            Free
                          </h5>
                          {/* <div
                            className='space-y-5 md:space-y-3 mt-[20px] md:mt-4 mb-[24px] md:mb-0 text-black-100'
                          >
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              1 Active Job
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Basic Placement
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Up to 24-hour delay to job posts
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              View first 25 applicants that have applied to your job
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Send 5 free messages to job seekers
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Send up to 5 invites to job seekers
                            </h6>
                          </div> */}
                          <ul className="space-y-5 mt-[20px]">
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={ActiveIcon}
                                className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium mt-1"
                              />
                              1 Active Job
                            </li>
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={ActiveIcon}
                                className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium mt-1"
                              />
                              Basic List Placement
                            </li>
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={InActiveIcon}
                                className="w-5 h-5 mr-3 text-[#808191] text-base leading-6 font-medium mt-1"
                              />
                              Unlimited Job Applicants
                            </li>
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={InActiveIcon}
                                className="w-5 h-5 mr-3 text-[#808191] text-base leading-6 font-medium mt-1"
                              />
                              Invite Anyone to Apply to Your Jobs
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className='cursor-pointer md:p-[8px] md:border-[4px] md:border-blue-100 rounded-2xl border-solid pb-12'
                    >
                      <div
                        className='rounded-2xl pt-5 px-8 h-full card-gradient pb-8'
                      >
                        <div className="membership-option">
                          <h4
                            className='tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold uppercase text-white'
                          >
                            Premium
                          </h4>
                          <h5
                            className='leading-[19px] text-[16px] font-bold md:mt-[9px] text-[#96F154]'
                          >
                            $79.99/month
                          </h5>
                          {/* <div
                            className='space-y-3 mt-[20px] md:mt-4 mb-[22px] md:mb-0 text-white'
                          >
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              25 active jobs
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Premium placement
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Instant job posting
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              View all applicants that have applied to your job
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Unlimited Messages to job seekers
                            </h6>
                            <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                              Unlimited invites to jobseekers
                            </h6>
                          </div> */}
                          <ul className="space-y-5 mt-[20px] text-white">
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={ActiveIcon}
                                className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                              />
                              Unlimited Job Posts
                            </li>
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={ActiveIcon}
                                className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                              />
                              Instant Job Post Approval
                            </li>
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={ActiveIcon}
                                className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                              />
                              Premium List Placement
                            </li>
                            <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                              <img
                                src={ActiveIcon}
                                className="mt-[2px] w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                              />
                              Unlimited Job Applicants
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="mt-[40px] md:mt-12">
                    <p className="text-[14px] md:text-sm leading-[16px] md:leading-4 text-black-100 font-semibold">
                      Payment Method
                    </p>
                    <div className="flex space-x-[17px] mt-[20px] md:mt-8">
                      <div
                        onClick={() => {
                          setMethod((prev) =>
                            prev === 'stripe' ? '' : 'stripe'
                          )
                        }}
                        className="flex-1 md:flex-none border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl"
                      >
                        <Checkbox
                          onChange={(e) => {
                            // e.stopPropagation()
                            // setMethod((prev) =>
                            //   prev === 'stripe' ? '' : 'stripe'
                            // )
                          }}
                          value={method === 'stripe'}
                          label={
                            <div className="text-black-100 text-[12px] md:text-base font-medium md:mt-1">
                              Credit Card
                            </div>
                          }
                        />
                      </div>
                      <div
                        onClick={() => {
                          setMethod((prev) =>
                            prev === 'paypal' ? '' : 'paypal'
                          )
                        }}
                        className="flex-1 md:flex-none border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl"
                      >
                        <Checkbox
                          onChange={(e) => {
                            // e.stopPropagation()
                            // setMethod((prev) =>
                            //   prev === 'paypal' ? '' : 'paypal'
                            // )
                          }}
                          value={method === 'paypal'}
                          label={
                            <img
                              alt="paypal"
                              className="w-[71px] md:w-auto"
                              src={Paypal}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div>
                      {method === 'paypal' && (
                        <PaymentForm
                          clientToken={clientToken}
                          clientID={process.env.REACT_APP_PAYPAL_CLIENT_ID}
                          onPaymentComplete={onPaymentComplete}
                        />
                      )}
                      {method === 'stripe' && (
                        <StripePaymentForm
                          token={stripeClientToken}
                          onSuccessPaid={onPaymentComplete}
                          onBack={closeModal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
