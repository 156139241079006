import React from 'react'

export default function FacebookIcon({
  size = 22,
  className = 'text-[#DFE1F2]',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="40"
      viewBox="0 0 39 40"
      fill="none"
    >
      <rect
        opacity="0.25"
        x="0.75"
        y="1.25"
        width="37.5"
        height="37.5"
        rx="18.75"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9938 27.5V19.9994H16V17.4146H17.9938V15.8626C17.9938 13.7539 19.1196 12.5 22.3182 12.5H24.9812V15.0851H23.3166C22.0715 15.0851 21.9891 15.4464 21.9891 16.1205L21.9846 17.4143H25L24.6472 19.9991H21.9846V27.5H17.9938Z"
        fill="white"
      />
    </svg>
  )
}
