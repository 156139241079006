import {
  PayPalButtons
} from '@paypal/react-paypal-js'

import { toast } from 'react-toastify'

const product = {
  description: "Premium Subscription",
  price: 79.99
}

const PaymentForm = ({ onPaymentComplete, formik }) => {
  const handleApprove = async (orderId) => {
    try {
      if (formik) {
        formik.setFieldValue('method', 'paypal')
      }
      onPaymentComplete();
    } catch (err) {
      console.log(err)
      toast.error('There is error when paying via paypal')
    }
  }

  return (
    <div className='mt-5'>
      <PayPalButtons
        fundingSource='paypal'
        style={{ color: 'silver', height: 48, tagline: false, shape: "pill" }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: product.description,
                amount: {
                  value: product.price
                }
              }
            ]
          })
        }}
        onApprove={async (data, actions) => {
          const order = await actions.order.capture();
          console.log('order', order);
          handleApprove(data.orderID)
        }}
        onClick={(data, actions) => {
          //Validate on button click, client or server side
          const hasAlreadySubscribed = false;
          if (hasAlreadySubscribed) {
            return actions.reject()
          } else {
            return actions.resolve()
          }
        }}
        onCancel={() => {
          //display cancel message, modal or redirect uesr to cancel page
        }}
        onError={(err) => {
          console.error('Paypal Checkout onerror', err)
        }}
      />
    </div>
  )
}

export default PaymentForm