import React from 'react'
import { NavBar } from './components/NavBar'
import HeroSection from './components/HeroSection'
import Footer from './components/Footer'
import GetStartedSection from './components/GetStartedSection'
import Client from './components/Client'
import EarthSection from './components/EarthSection'
import CategorySection from './components/CategorySection'
import CarauselSection from './components/CarauselSection'
import { SearchSection } from './components/SearchSection'

function LatestHomePage() {
  return (
    // <div className="bg-[#00113C]">
    <div className="bg-[#00091D]">
      <NavBar />
      <div
        className="flex justify-center items-center gap-[8.026px] absolute h-[570px] w-full top-[-148px] opacity-[0.2]"
        // style={{ filter: 'blur(5px)' }}
      >
        <HeroSection name="Loyid Tsige" />
      </div>
      <div
        className="absolute w-[1106px] h-[942.706px] rounded-[1106px] bg-[#002477] ml-[62px] mr-auto"
        style={{ filter: 'blur(82.432px)' }}
      ></div>
      <SearchSection />
      <HeroSection name="Loyid Tsige" />
      <CategorySection />
      <CarauselSection />
      <EarthSection />
      <Client />
      <GetStartedSection />
      <Footer />
    </div>
  )
}

export default LatestHomePage
