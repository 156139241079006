import React from 'react'

export default function MessageIcon({ className = 'text-white', size = 22 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9588 0.199951C19.2888 0.199951 21.7998 2.91695 21.7998 6.51995V13.388C21.7998 15.232 21.1478 16.898 19.9628 18.08C18.8998 19.139 17.5208 19.7 15.9748 19.7H6.1218C4.5788 19.7 3.2008 19.1399 2.1368 18.08C0.951805 16.898 0.299805 15.232 0.299805 13.388V6.51995C0.299805 2.91695 2.8108 0.199951 6.1408 0.199951H15.9588ZM15.9588 1.69995H6.1408C3.6258 1.69995 1.7998 3.72695 1.7998 6.51995V13.388C1.7998 14.831 2.2958 16.12 3.1958 17.017C3.9718 17.792 4.9848 18.2 6.1248 18.2H15.9588C15.9608 18.198 15.9688 18.2 15.9748 18.2C17.1158 18.2 18.1278 17.792 18.9038 17.017C19.8048 16.12 20.2998 14.831 20.2998 13.388V6.51995C20.2998 3.72695 18.4738 1.69995 15.9588 1.69995ZM17.5348 6.32875C17.7958 6.64975 17.7468 7.12175 17.4258 7.38375L12.9818 10.9958C12.4198 11.4418 11.7478 11.6648 11.0768 11.6648C10.4078 11.6648 9.7408 11.4438 9.1828 11.0018L4.6978 7.38575C4.3748 7.12575 4.3248 6.65275 4.5838 6.33075C4.8448 6.00975 5.3168 5.95875 5.6388 6.21775L10.1198 9.82975C10.6828 10.2758 11.4758 10.2758 12.0428 9.82575L16.4788 6.21975C16.8008 5.95675 17.2728 6.00675 17.5348 6.32875Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
