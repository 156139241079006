import ApplicantServiceClient from 'app/clients/ApplicantService'
import axios from 'axios'
const API_URL = `${process.env.REACT_APP_APPLICANT_API_URL}`

export const createApplicationAPI = (data) => {
  return ApplicantServiceClient.post(`/applicants`, data)
}

export const fetchApplicantsAPI = (data) => {
  return ApplicantServiceClient.post(`/applicants/list`, data)
}

export const withdrawApplicationAPI = ({ id }) => {
  return ApplicantServiceClient.get(`/applicants/withdraw/${id}`)
}

export const getApplicantsByJobIDAPI = (data) => {
  return ApplicantServiceClient.post(`/applicants/getApplicantsByJobID`, data)
}

export const markApplicationNotificationAPI = (data) => {
  return ApplicantServiceClient.post(`/applicants/mark/notification`, data)
}

export const rejectApplicantAPI = (id) => {
  return ApplicantServiceClient.get(`/applicants/reject/${id}`)
}

export const acceptApplicantAPI = (id) => {
  return ApplicantServiceClient.get(`/applicants/accept/${id}`)
}
