import React from 'react'

export default function ProfileIcon({ className = 'text-white', size = 17 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4789 10.1394C14.4071 10.0664 14.3216 10.0084 14.2271 9.96867C14.1326 9.92896 14.0312 9.90836 13.9286 9.90808C13.8261 9.90781 13.7245 9.92785 13.6298 9.96705C13.5351 10.0062 13.4492 10.0638 13.3771 10.1364C13.305 10.209 13.2481 10.2951 13.2098 10.3898C13.1714 10.4845 13.1524 10.5858 13.1538 10.6879C13.1553 10.79 13.1771 10.8907 13.218 10.9843C13.2589 11.0779 13.3182 11.1625 13.3923 11.2331C14.0478 11.8753 14.568 12.6413 14.9221 13.4863C15.2763 14.3313 15.4573 15.2382 15.4546 16.1538C15.4546 17.0947 12.7455 18.4615 8.50004 18.4615C4.2546 18.4615 1.54549 17.094 1.54549 16.1523C1.54289 15.243 1.7215 14.3422 2.07097 13.5021C2.42045 12.662 2.93386 11.8992 3.58147 11.2579C3.65472 11.1868 3.71306 11.102 3.7531 11.0083C3.79315 10.9145 3.8141 10.8138 3.81475 10.712C3.8154 10.6102 3.79573 10.5092 3.75688 10.415C3.71803 10.3208 3.66078 10.2352 3.58844 10.1632C3.5161 10.0912 3.43012 10.0342 3.33549 9.99554C3.24085 9.95686 3.13944 9.93728 3.03714 9.93793C2.93485 9.93857 2.83369 9.95943 2.73956 9.99929C2.64542 10.0392 2.56017 10.0972 2.48876 10.1701C1.69704 10.9541 1.06942 11.8867 0.642222 12.9138C0.215028 13.9409 -0.00324613 15.0422 3.64817e-05 16.1538C3.64817e-05 18.652 4.37908 20 8.50004 20C12.621 20 17 18.652 17 16.1538C17.0035 15.0347 16.7822 13.9261 16.3493 12.8933C15.9163 11.8605 15.2804 10.9241 14.4789 10.1394Z"
        fill="currentColor"
      />
      <path
        d="M8.50016 10.7692C9.56997 10.7692 10.6158 10.4534 11.5053 9.86176C12.3948 9.27009 13.0881 8.42913 13.4975 7.44522C13.9069 6.46131 14.014 5.37864 13.8053 4.33413C13.5966 3.28962 13.0814 2.33017 12.325 1.57712C11.5685 0.824068 10.6047 0.311233 9.55542 0.103466C8.50616 -0.1043 7.41857 0.00233306 6.43019 0.409882C5.4418 0.817431 4.59702 1.50759 4.00266 2.39309C3.4083 3.27858 3.09106 4.31964 3.09106 5.38462C3.09272 6.8122 3.66314 8.18084 4.67718 9.19029C5.69122 10.1997 7.06608 10.7676 8.50016 10.7692ZM8.50016 1.53846C9.26431 1.53846 10.0113 1.76404 10.6467 2.18666C11.282 2.60928 11.7773 3.20997 12.0697 3.91276C12.3621 4.61555 12.4386 5.38888 12.2896 6.13496C12.1405 6.88105 11.7725 7.56636 11.2322 8.10426C10.6918 8.64215 10.0034 9.00846 9.25391 9.15687C8.50444 9.30527 7.72759 9.22911 7.02161 8.938C6.31562 8.64689 5.7122 8.15392 5.28766 7.52143C4.86312 6.88893 4.63652 6.14532 4.63652 5.38462C4.63773 4.36492 5.04517 3.38734 5.76948 2.6663C6.4938 1.94527 7.47583 1.53967 8.50016 1.53846Z"
        fill="currentColor"
      />
    </svg>
  )
}
