//packages
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'

//custom components

//custom hooks
import useTagList from 'hooks/useTagList'

import tagService from 'app/services/tag.service'
import TextField from 'components/Input/TextField'
import NumberInput from 'components/Input/NumberInput'
import UploadResume from 'components/Upload/UploadResume'

import AuthService from 'app/services/auth.service'
import TagInput from 'components/Input/TagInput'

export default function SkillsForm(props) {
  const [skills] = useTagList()

  const onEdit = props.edit
  const formik = props.formik

  const { setFileUrl, fileName, setFileName, data } = props

  function handleUploadChange(e) {
    const file = e.target.files[0]
    const fileName = e.target.files[0].name
    if (!file) {
      return
    }
    const reader = new FileReader()

    reader.readAsBinaryString(file)

    reader.onload = () => {
      // setFileUrl(`data:${file.type};base64,${btoa(reader.result)}`);
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      const param = {
        fileContent: btoa(reader.result),
        fileName: `${v4()}.${extension}`,
      }
      AuthService.uploadFileToS3(param)
        .then((res) => {
          formik.setFieldValue('resume', res.data.url)
        })
        .catch((err) => {
          console.log(err)
        })
      setFileName(fileName)
    }

    reader.onerror = () => {
      console.log('error on load image')
    }
  }

  const resume = data != null ? data.resume_filename : ''

  return (
    <div>
      {!onEdit && (
        <header>
          <h3 className="text-[18px] md:text-lg leading-[24px] md:leading-6 font-medium text-black-100">
            Your Experience
          </h3>
        </header>
      )}
      <div className="mt-[42px]">
        {/* <p className="text-[12px] md:text-sm leading-[16px] md:leading-4 text-[#11142D] font-semibold">
          Experience
        </p> */}
        <div className="grid md:grid-cols-2 gap-x-4 gap-y-[20px] mt-[20px] md:mt-8">
          <TextField
            name="job_title"
            id="job_title"
            value={formik.values['job_title']}
            onChange={formik.handleChange}
            error={
              formik.touched['job_title'] ? formik.errors['job_title'] : ''
            }
            label="Professional Experience Tagline"
            placeholder="Expert full-stack developer"
          />
          <NumberInput
            label="Years of Professional Experience"
            id="num_experience"
            name="num_experience"
            onChange={formik.handleChange}
          />
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-gray-200 my-[32px]"></div>

        {/* Skills section */}
        <div>
          <p className="text-[12px] md:text-sm leading-[16px] md:leading-4 text-[#11142D] font-semibold uppercase">
            Skills
          </p>
          <div className="mt-[25px]">
            <TagInput
              list={skills}
              placeholder="Please choose your skills"
              id="skills"
              label="Enter Skills Here"
              limitText="*Required at least 3 tags"
              name="skill"
              value={formik.values['skills']}
              onChange={(value) => formik.setFieldValue('skills', value)}
              error={formik.touched['skills'] ? formik.errors['skills'] : ''}
            />
          </div>
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-gray-200 my-[32px]" />

        <div>
          <p className="text-[12px] md:text-sm leading-[16px] md:leading-4 text-[#11142D] font-semibold uppercase">
            Resume
          </p>
          <div className="my-4">
            <UploadResume
              handleUploadChange={handleUploadChange}
              fileName={fileName !== '' ? fileName : resume}
              cancelUpload={() => {
                setFileName('')
                setFileUrl('')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
