import React from 'react'

export default function EarthIcon({ className = 'text-black-100', size = 24 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" clip-path="url(#clip0_403_15274)">
        <path
          d="M12 0.75C9.77497 0.75 7.59989 1.4098 5.74984 2.64597C3.89979 3.88213 2.45785 5.63914 1.60636 7.69481C0.754875 9.75048 0.532087 12.0125 0.966171 14.1948C1.40025 16.3771 2.47171 18.3816 4.04505 19.955C5.61839 21.5283 7.62295 22.5998 9.80524 23.0338C11.9875 23.4679 14.2495 23.2451 16.3052 22.3936C18.3609 21.5422 20.1179 20.1002 21.354 18.2502C22.5902 16.4001 23.25 14.225 23.25 12C23.2466 9.01735 22.0603 6.15783 19.9512 4.04878C17.8422 1.93972 14.9827 0.753375 12 0.75ZM12 21.75C10.4513 21.75 8.82975 19.7535 8.025 16.5H15.975C15.1703 19.7535 13.5488 21.75 12 21.75ZM7.7205 15C7.42651 13.0108 7.42651 10.9892 7.7205 9H16.2795C16.4283 9.99304 16.502 10.9959 16.5 12C16.502 13.0041 16.4283 14.007 16.2795 15H7.7205ZM2.25001 12C2.25055 10.9812 2.41152 9.96876 2.72701 9H6.21451C5.9285 10.9898 5.9285 13.0102 6.21451 15H2.72701C2.41152 14.0312 2.25055 13.0188 2.25001 12ZM12 2.25C13.5488 2.25 15.1703 4.2465 15.975 7.5H8.025C8.82975 4.2465 10.4513 2.25 12 2.25ZM17.7855 9H21.273C21.9088 10.9495 21.9088 13.0505 21.273 15H17.7855C17.9277 14.0063 17.9994 13.0038 18 12C17.9994 10.9962 17.9277 9.99368 17.7855 9ZM20.6408 7.5H17.5155C17.1817 5.86876 16.5273 4.32006 15.5903 2.94375C17.7639 3.8106 19.5554 5.42683 20.6408 7.5ZM8.40976 2.94375C7.47268 4.32006 6.81827 5.86876 6.4845 7.5H3.35926C4.4446 5.42683 6.23615 3.8106 8.40976 2.94375ZM3.35926 16.5H6.4845C6.81827 18.1312 7.47268 19.6799 8.40976 21.0563C6.23615 20.1894 4.4446 18.5732 3.35926 16.5ZM15.5903 21.0563C16.5273 19.6799 17.1817 18.1312 17.5155 16.5H20.6408C19.5554 18.5732 17.7639 20.1894 15.5903 21.0563Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_403_15274">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
