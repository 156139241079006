import tagService from 'app/services/tag.service'
import React, { useEffect, useState } from 'react'

export default function useTagList() {
  const [industryTags, setIndustryTags] = useState([])
  const [skillsTags, setSkillsTags] = useState([])
  const [interestsTags, setInterestsTags] = useState([])
  const [jobTitleTags, setJobTitleTags] = useState([])

  const fetchTags = async () => {
    try {
      const result = await tagService.getAllTags()
      if (result.status === 200) {
        setIndustryTags(
          result.data
            .filter((v) => v.category === 'industry')
            .map((v) => {
              return {
                id: v.name,
                label: v.name,
                name: v.name,
              }
            })
        )
        setSkillsTags(
          result.data
            .filter((v) => v.category === 'skills')
            .map((v) => {
              return {
                id: v.name,
                label: v.name,
                name: v.name,
                mainId: v.id,
              }
            })
        )
        setInterestsTags(
          result.data
            .filter((v) => v.category === 'skills')
            .map((v) => {
              return {
                id: v.name,
                label: v.name,
                name: v.name,
              }
            })
        )
        setJobTitleTags(
          result.data
            .filter((v) => v.category === 'JobTitle')
            .map((v) => {
              return {
                id: v.id,
                label: v.name,
                name: v.name,
              }
            })
        )
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchTags()
  }, [])

  return [skillsTags, industryTags, interestsTags, jobTitleTags]
}
