import clsx from 'clsx'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'
import axios from 'axios'

import GetPremiumCard from 'components/Card/GetPremiumCard'
import IconRender from 'components/Icons/IconRender'
import ProfileDropdown from 'components/dropdown/Profile'

//redux
import { getUser } from 'app/store/profileSlice'
import Button from 'components/Button'
import { HamburgerIcon, CrossIcon } from 'components/Icons'
import UpgradingPlanModal from 'components/Dialog/UpgradingPlanModal'
import {
  fetchApplicantsList,
  fetchInvitesList,
  resetAllStatus,
} from 'app/store/applicantsSlice'
import { getChats } from 'app/services/chat.service'
import { selectShouldRefresh, toggleRefresh } from 'app/store/refreshSlice'
import { getJobsByUser } from 'app/store/jobSlice'
import { getApplicantsByJobIDAPI } from 'app/services/applicantAPI'
import { getInvitesWithJobId } from 'app/services/inviteAPI'

const Logo = require('assets/images/logo.png')

const menus = {
  employer: [
    {
      id: 'find_candidates',
      name: 'Find Candidates',
      icon: 'search',
      route: '/employer/find-candidates',
    },
    {
      id: 'your_job_posts',
      name: 'Your Job Posts',
      icon: 'file',
      route: '/employer/job-posts',
    },
    {
      id: 'setting',
      name: 'Settings',
      icon: 'setting',
      route: '/employer/setting',
    },
    {
      id: 'profile',
      name: 'Profile',
      icon: 'profile',
      route: '/employer/profile',
    },
    {
      id: 'messages',
      name: 'Messages',
      icon: 'message',
      route: '/employer/messages',
    },
  ],
  contractor: [
    {
      id: 'find_jobs',
      name: 'Find Jobs',
      icon: 'search',
      route: '/jobseeker/jobs',
    },
    {
      id: 'your_applications',
      name: 'Your Applications',
      icon: 'file',
      route: '/jobseeker/your-applications',
    },
    {
      id: 'settings',
      name: 'Settings',
      icon: 'setting',
      route: '/jobseeker/setting',
    },
    {
      id: 'profile',
      name: 'Profile',
      icon: 'profile',
      route: '/jobseeker/profile',
    },
    {
      id: 'messages',
      name: 'Messages',
      icon: 'message',
      route: '/jobseeker/messages',
    },
  ],
}

export default function Sidebar({ type = 'employer', className }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showGetPremiumModal, setShowGetPremiumModal] = useState(false)
  const [chats, setChats] = useState([])
  const [unreadChatsCount, setUnreadChatsCount] = useState([])
  const [allEmployerJobsApplications, setAllEmployerJobsApplications] =
    useState([])
  const [allEmployerJobsInvites, setAllEmployerJobsInvites] = useState([])
  const user = useSelector(getUser)

  const dispatch = useDispatch()
  const applications = useSelector((state) => state.applicants.applicants)
  const invites = useSelector((state) => state.applicants.invites)
  const allEmployerJobs = useSelector((state) => state.job.jobs)
  const shouldRefresh = useSelector(selectShouldRefresh)

  useEffect(() => {
    if (user) {
      // Replace with your actual project_id, user_name, and user_secret
      const project_id = process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID
      const user_name = user.id
      const user_secret = user.id

      // Create WebSocket connection
      const socket = new WebSocket(
        `wss://api.chatengine.io/person/?publicKey=${project_id}&username=${user_name}&secret=${user_secret}`
      )

      // WebSocket event listeners
      socket.onopen = (event) => {
        console.log('WebSocket opened:', event)
      }

      socket.onclose = (event) => {
        console.log('WebSocket closed:', event)
      }

      socket.onmessage = (event) => {
        dispatch(toggleRefresh())
      }

      socket.onerror = (error) => {
        console.error('WebSocket error:', error)
      }

      // Clean up the WebSocket connection when the component is unmounted
      return () => {
        socket.close()
      }
    }
  }, [user])

  const fetchApplications = useCallback(() => {
    if (user) {
      const params = {
        limit: 100,
        offset: 0,
        userId: user?.id,
      }
      dispatch(fetchApplicantsList(params))
      dispatch(fetchInvitesList(params))
    }
  }, [dispatch, user, shouldRefresh])

  const fetchEmployerJobs = useCallback(() => {
    if (user && user.type === 'employer') {
      dispatch(getJobsByUser(user.id))
    }
  }, [dispatch, user])

  useEffect(() => {
    fetchApplications()
    fetchEmployerJobs()
    return () => {
      dispatch(resetAllStatus())
    }
  }, [fetchApplications])

  const location = useLocation()

  useEffect(() => {
    if (!showMobileMenu) {
      document.body.style.overflow = null
    }
  }, [showMobileMenu])

  useEffect(() => {
    setShowMobileMenu(false)
  }, [location])

  if (!user) return null

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const response = await getChats({
            userName: user.id,
            userSecret: user.id,
          })

          // Access the data from the response
          setChats(response.data)
        } catch (error) {
          // Handle errors
          console.error('Error fetching data:', error)
        }
      }
      fetchData()
    }
  }, [user, shouldRefresh])

  useEffect(() => {
    if (user) {
      const filteredChats = chats.filter((chat) => {
        const person = chat.people.find((p) => p.person.username === user.id)
        const lastMessage = chat.last_message.id - person.last_read

        return lastMessage !== 0
      })
      setUnreadChatsCount(filteredChats.length)
    }
  }, [chats, user, dispatch])

  //Pull all employer jobs applications
  // async function fetchApplicationsForJob(jobId) {
  //   const params = {
  //     limit: 100,
  //     offset: 0,
  //     jobId: jobId,
  //   }
  //   const applications = await getApplicantsByJobIDAPI(params)
  //   return applications.data
  // }

  // async function getAllApplications(jobsArray) {
  //   let allApplications = []
  //   let applications = []

  //   for (const job of jobsArray) {
  //     const applications = await fetchApplicationsForJob(job.id)
  //     allApplications = allApplications.concat(applications)
  //   }

  //   for (const application of allApplications) {
  //     applications = applications.concat(application.applicants)
  //   }

  //   setAllEmployerJobsApplications(applications)
  // }

  // useEffect(() => {
  //   if (user.type === 'employer') {
  //     getAllApplications(allEmployerJobs)
  //   }
  // }, [user, allEmployerJobs])

  // Pull all employer jobs invites
  // async function fetchInvitesForJob(jobId) {
  //   const params = {
  //     limit: 100,
  //     offset: 0,
  //     jobId: jobId,
  //   }
  //   const invites = await getInvitesWithJobId(params)
  //   return invites.data
  // }

  // async function getAllInvites(jobsArray) {
  //   let allInvites = []
  //   let invites = []

  //   for (const job of jobsArray) {
  //     const invites = await fetchInvitesForJob(job.id)
  //     allInvites = allInvites.concat(invites)
  //   }

  //   for (const invite of allInvites) {
  //     invites = invites.concat(invite.invites)
  //   }

  //   setAllEmployerJobsInvites(invites)
  // }

  // useEffect(() => {
  //   if (user.type === 'employer') {
  //     getAllInvites(allEmployerJobs)
  //   }
  // }, [user, allEmployerJobs])

  // console.log(allEmployerJobsInvites)

  // useEffect(() => {
  //   console.log('refreshing')
  //   console.log('useEffect triggered with shouldRefresh:', shouldRefresh)

  //   if (shouldRefresh) {
  //     console.log('Refreshing component')
  //     // Add any additional logic you need when refreshing
  //     // ...

  //     // Reset the refresh state
  //     dispatch(toggleRefresh())
  //   }
  // }, [shouldRefresh, dispatch])

  // const pendingContractorUnviewedApplications = applications.filter(
  //   (item) => item.status !== 'pending' && !item.statusUpdateViewed
  // )

  // const pendingContractorUnviewedInvites = invites.filter(
  //   (item) => item.status === 'pending' && !item.statusUpdateViewed
  // )

  // const pendingEmployerUnviewedApplications =
  //   allEmployerJobsApplications.filter(
  //     (item) => item.status === 'pending' && !item.statusUpdateViewed
  //   )

  // const pendingEmployerUnviewedInvites = allEmployerJobsInvites.filter(
  //   (item) => item.status !== 'pending' && !item.statusUpdateViewed
  // )

  // const contractorNotificationsCount =
  //   pendingContractorUnviewedApplications.length +
  //   pendingContractorUnviewedInvites.length

  // const employerNotificationsCount =
  //   pendingEmployerUnviewedApplications.length +
  //   pendingEmployerUnviewedInvites.length

  // console.log(employerNotificationsCount)

  return (
    <div
      className={clsx(
        'w-full md:w-64 h-[100px] md:h-auto bg-blue-100 md:min-h-screen shadow-sidebar pt-[44px] pb-[22px] px-5 md:py-12 justify-between flex md:flex-col flex-none space-y-4',
        className
      )}
    >
      <div className="flex-none">
        {/* <NavLink> */}
        <div className="md:ml-10 w-[82px] md:w-27.5">
          <img className="w-full" src={Logo} alt="logo" />
        </div>
        {/* </NavLink> */}
        <div className="hidden md:block mt-16 space-y-2">
          {menus[type].map((v) => (
            <li key={v.id} className="list-none font-inter">
              <NavLink key={v.id} to={v.route}>
                <div
                  key={v.id}
                  className={clsx(
                    'hover:bg-green-100 hover:bg-opacity-40 hover:rounded-xl text-sm cursor-pointer py-3 px-4 relative',
                    location.pathname.includes(v.route)
                      ? 'bg-green-100 bg-opacity-40 rounded-xl'
                      : ''
                  )}
                >
                  <div className="flex items-center justify-between space-x-[18px]">
                    <div className="w-[22px] flex justify-center">
                      <IconRender type={v.icon} />
                    </div>
                    <div className="flex-grow text-[rgba(225,227,242,0.8)] text-sm flex items-center">
                      {v.name}{' '}
                    </div>
                    {/* {((type === 'contractor' && type === 'employer') ||
                      v.id === 'your_applications' ||
                      v.id === 'your_job_posts') &&
                      (contractorNotificationsCount > 0 ||
                        employerNotificationsCount > 0) && (
                        <div
                          className="flex items-center absolute"
                          style={{ right: '0.62rem' }}
                        >
                          <div
                            className="text-white leading-4 text-xs font-semibold bg-green-100 w-8 h-8 rounded-full flex justify-center items-center"
                            style={{
                              width: '1.25rem',
                              height: '1.25rem',
                              flexShrink: 0,
                              fill: '#52B4DA',
                              opacity: 0.3995,
                              color: '#FFF',
                              textAlign: 'center',
                              fontFamily: 'Inter',
                              fontSize: '0.6875rem',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {type === 'contractor'
                              ? contractorNotificationsCount
                              : employerNotificationsCount}
                          </div>
                        </div>
                      )} */}

                    {(type === 'contractor' && type === 'employer') ||
                      (v.id === 'messages' && unreadChatsCount > 0 && (
                        <div
                          className="flex items-center absolute"
                          style={{ right: '0.62rem' }}
                        >
                          <div
                            className="text-white leading-4 text-xs font-semibold bg-green-100 w-8 h-8 rounded-full flex justify-center items-center"
                            style={{
                              width: '1.25rem',
                              height: '1.25rem',
                              flexShrink: 0,
                              fill: '#52B4DA',
                              opacity: 0.3995,
                              color: '#FFF',
                              textAlign: 'center',
                              fontFamily: 'Inter',
                              fontSize: '0.6875rem',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            {unreadChatsCount}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </NavLink>
            </li>
          ))}
        </div>
      </div>
      <div className="hidden flex-1 md:flex items-end">
        <div className="w-full">
          {/* PREMIUM start comment this out no premium user */}
          {/* {type === 'employer' && user?.plan === 'basic' && (
            <div className="flex justify-center w-full">
              <GetPremiumCard
                user={user}
                getPremium={() => setShowGetPremiumModal(true)}
              />
            </div>
          )} */}
          {/* end comment this out no premium user */}
          <div className="mt-6 xl:mt-10">
            <ProfileDropdown />
          </div>
        </div>
      </div>
      <div className="flex md:hidden items-center space-x-[20px]">
        {/* PREMIUM start comment this out no premium user */}
        {/* {type === 'employer' && user?.plan === 'basic' && (
          <div>
            <Button
              className="rounded-[7px] py-[6px] px-[12px] font-inter"
              variant="menu"
            >
              Get Premium
            </Button>
          </div>
        )} */}
        {/* end comment this out no premium user */}
        <div
          onClick={() => {
            document.body.style.overflow = 'hidden'
            setShowMobileMenu(true)
          }}
        >
          <HamburgerIcon />
        </div>
      </div>

      {showMobileMenu && (
        <div className="fixed pb-[40px] overflow-y-auto bg-cover md:h-[100vh] h-[100%] bg-[url('assets/images/mobile-menu-bg.png')] left-0 top-0 w-screen z-50 content-between grid">
          <div>
            <div className="flex justify-end py-[30px] px-[30px]">
              <div onClick={() => setShowMobileMenu(false)}>
                <div className="w-[24px] h-[24px] flex justify-center items-center bg-white rounded-full">
                  <CrossIcon size={16} />
                </div>
              </div>
            </div>
            <div className="px-[30px] space-y-[2px]">
              {menus[type].map((v) => (
                <div className="text-center" key={v.name}>
                  <NavLink key={v.id} to={v.route}>
                    <div
                      key={v.id}
                      className={clsx(
                        'hover:bg-green-100 hover:bg-opacity-40 hover:rounded-xl text-sm cursor-pointer py-[18px] px-[22px]',
                        location.pathname.includes(v.route)
                          ? 'bg-green-100 bg-opacity-40 rounded-xl text-white'
                          : 'text-[rgba(225,227,242,0.8)]'
                      )}
                    >
                      <div className="flex items-center space-x-[18px]">
                        <div className="w-[20px] flex justify-center">
                          <IconRender type={v.icon} />
                        </div>
                        <div
                          className={clsx(
                            location.pathname.includes(v.route) ? '' : '',
                            'text-[14px]'
                          )}
                        >
                          {v.name}
                        </div>
                        {/* {((type === 'contractor' && type === 'employer') ||
                          v.id === 'your_applications' ||
                          v.id === 'your_job_posts') &&
                          (contractorNotificationsCount > 0 ||
                            employerNotificationsCount > 0) && (
                            <div
                              className="flex items-center absolute"
                              style={{ right: '0.62rem' }}
                            >
                              <div
                                className="text-white leading-4 text-xs font-semibold bg-green-100 w-8 h-8 rounded-full flex justify-center items-center"
                                style={{
                                  width: '1.25rem',
                                  height: '1.25rem',
                                  flexShrink: 0,
                                  fill: '#52B4DA',
                                  opacity: 0.3995,
                                  color: '#FFF',
                                  textAlign: 'center',
                                  fontFamily: 'Inter',
                                  fontSize: '0.6875rem',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {type === 'contractor'
                                  ? contractorNotificationsCount
                                  : employerNotificationsCount}
                              </div>
                            </div>
                          )} */}

                        {(type === 'contractor' && type === 'employer') ||
                          (v.id === 'messages' && unreadChatsCount > 0 && (
                            <div
                              className="flex items-center absolute"
                              style={{ right: '0.62rem' }}
                            >
                              <div
                                className="text-white leading-4 text-xs font-semibold bg-green-100 w-8 h-8 rounded-full flex justify-center items-center"
                                style={{
                                  width: '1.25rem',
                                  height: '1.25rem',
                                  flexShrink: 0,
                                  fill: '#52B4DA',
                                  opacity: 0.3995,
                                  color: '#FFF',
                                  textAlign: 'center',
                                  fontFamily: 'Inter',
                                  fontSize: '0.6875rem',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {unreadChatsCount}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-[30px] px-[30px] mt-[15px]">
            {/* PREMIUM start comment this out no premium user */}
            {/* {type === 'employer' && user?.plan === 'basic' && (
              <div className="flex justify-center w-full">
                <GetPremiumCard
                  user={user}
                  getPremium={() => {
                    setShowMobileMenu(false)
                    setShowGetPremiumModal(true)
                  }}
                />
              </div>
            )} */}
            {/* end comment this out no premium user */}
            <div className="px-[30px] xl:px-10 mt-6 xl:mt-10">
              <ProfileDropdown />
            </div>
          </div>
        </div>
      )}
      <UpgradingPlanModal
        isOpen={showGetPremiumModal}
        closeModal={() => setShowGetPremiumModal(false)}
      />
    </div>
  )
}
