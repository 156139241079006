import React from 'react'

export default function PolygonIcon({ className = 'text-blue-100', size = 7 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 5L0.25 9.33013L0.25 0.669873L7 5Z" fill="currentColor" />
    </svg>
  )
}
