import React from 'react'

export default function CrossIcon2({ size = 8, className = 'text-black' }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2378_10642)">
        <path
          d="M0.758381 7.94976C0.562243 7.96116 0.369349 7.89596 0.220375 7.76789C-0.0734328 7.47234 -0.0734328 6.995 0.220375 6.69944L6.65379 0.266C6.95938 -0.0199469 7.43889 -0.00405124 7.72484 0.301534C7.98341 0.577874 7.99848 1.00261 7.76012 1.29657L1.2888 7.76789C1.14175 7.89411 0.951948 7.95919 0.758381 7.94976Z"
          fill="currentColor"
        />
        <path
          d="M7.18423 7.94978C6.98545 7.94893 6.79493 7.87002 6.65379 7.73003L0.220343 1.29656C-0.0518543 0.978701 -0.0148476 0.500334 0.303015 0.228112C0.586716 -0.0148394 1.00512 -0.0148394 1.28879 0.228112L7.76012 6.66156C8.06563 6.94758 8.08142 7.42712 7.7954 7.73263C7.78402 7.74478 7.77227 7.75653 7.76012 7.76791C7.60166 7.90571 7.39312 7.97156 7.18423 7.94978Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
