//packages
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { getChats } from 'react-chat-engine'

//components
import SearchInput from 'components/Input/SearchInput'
import ChannelList from './ChannelList'

export default function Sidebar({
  chats,
  setSelectedChat,
  selectedChatId,
  connected,
}) {
  const [searchKey, setSearchKey] = useState('')

  return (
    <div className="md:w-full md:pr-7 md:border-r-[1px] border-solid border-y-0 border-l-0 border-[#E4E4E4] h-full md:min-h-[calc(100vh-200px)]">
      <SearchInput
        placeholder="Search"
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        disabled={!connected}
        className="py-[18px] pr-[20px] pl-[60px] md:pl-[64px] rounded-2xl w-full text-[14px] md:text-sm"
      />
      {connected ? (
        <ChannelList
          list={chats}
          setSelectedChat={setSelectedChat}
          selectedChatId={selectedChatId}
          searchKey={searchKey}
        />
      ) : (
        <div className="text-gray-300 leading-[16px] text-xs font-medium p-3">
          Connecting...
        </div>
      )}
    </div>
  )
}
