import React from 'react'

export default function DoubleNextIcon({
  className = 'text-[#52B4DA]',
  size = 12,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99998 9.50001C5.90067 9.50059 5.80343 9.47158 5.72067 9.41668C5.63791 9.36178 5.57338 9.28348 5.53529 9.19176C5.49721 9.10003 5.4873 8.99905 5.50684 8.90167C5.52637 8.8043 5.57446 8.71495 5.64498 8.64502L8.29498 6.00001L5.64498 3.35501C5.56306 3.25936 5.52026 3.13632 5.52512 3.01049C5.52998 2.88465 5.58215 2.76528 5.6712 2.67623C5.76024 2.58718 5.87961 2.53502 6.00545 2.53016C6.13129 2.5253 6.25433 2.5681 6.34998 2.65001L9.34998 5.65001C9.4431 5.7437 9.49537 5.87042 9.49537 6.00251C9.49537 6.13461 9.4431 6.26133 9.34998 6.35501L6.34998 9.35501C6.25685 9.44739 6.13115 9.49946 5.99998 9.50001Z"
        fill="currentColor"
      />
      <path
        d="M2.99998 9.50009C2.90067 9.50067 2.80343 9.47166 2.72067 9.41676C2.63791 9.36186 2.57338 9.28356 2.53529 9.19183C2.49721 9.10011 2.4873 8.99913 2.50684 8.90175C2.52637 8.80438 2.57446 8.71503 2.64498 8.64509L5.29498 6.00009L2.64498 3.35509C2.55083 3.26094 2.49793 3.13324 2.49793 3.00009C2.49793 2.86694 2.55083 2.73924 2.64498 2.64509C2.73913 2.55094 2.86683 2.49805 2.99998 2.49805C3.13313 2.49805 3.26083 2.55094 3.35498 2.64509L6.35498 5.64509C6.4481 5.73877 6.50037 5.8655 6.50037 5.99759C6.50037 6.12969 6.4481 6.25641 6.35498 6.35009L3.35498 9.35009C3.30867 9.39734 3.25345 9.43492 3.19251 9.46067C3.13157 9.48642 3.06613 9.49982 2.99998 9.50009Z"
        fill="currentColor"
      />
    </svg>
  )
}
