import React from 'react'

export default function Left({ className = 'text-black', size = 14 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74981 11.0831C8.67304 11.0835 8.59694 11.0688 8.52587 11.0398C8.45479 11.0108 8.39015 10.968 8.33564 10.9139L4.83564 7.41394C4.727 7.30464 4.66602 7.1568 4.66602 7.00269C4.66602 6.84858 4.727 6.70073 4.83564 6.59144L8.33564 3.09144C8.44724 2.99587 8.59078 2.94594 8.73759 2.95161C8.8844 2.95728 9.02367 3.01814 9.12756 3.12203C9.23145 3.22591 9.29231 3.36518 9.29798 3.51199C9.30365 3.6588 9.25371 3.80235 9.15814 3.91394L6.07231 6.99977L9.15814 10.0856C9.24006 10.1668 9.29604 10.2706 9.31902 10.3836C9.34199 10.4967 9.33092 10.614 9.28722 10.7208C9.24351 10.8275 9.16913 10.919 9.07347 10.9834C8.97781 11.0479 8.86518 11.0826 8.74981 11.0831Z"
        fill="currentColor"
      />
    </svg>
  )
}
