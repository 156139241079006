//packages
import { getNames } from 'country-list'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import languageNameMap from 'language-name-map/map'

//custom components
import { ModalCloseIcon } from 'components/Icons'
import Checkbox from 'components/Checkbox/Checkbox'
import TextField from 'components/Input/TextField'
import AutoComplete from 'components/Select/AutoComplete'
import TagInput from 'components/Input/TagInput'
import NumberInput from 'components/Input/NumberInput'

const typeDurations = [
  {
    id: 'Full-time',
    name: 'Full-time',
  },
  {
    id: 'Part-time',
    name: 'Part-time',
  },
  {
    id: 'Contract/Freelance',
    name: 'Contract/Freelance',
  },
]

export default function FilterBarModal({
  setFilter,
  setCurrentPage,
  isOpen,
  closeModal,
  disabled = false,
}) {
  const languageStored =
    JSON.parse(localStorage.getItem('filter_jobs_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_jobs_location')) ?? []
  const durationsStored =
    JSON.parse(localStorage.getItem('filter_jobs_duration')) ?? []
  const minHourlyRateStored =
    localStorage.getItem('filter_jobs_minHourlyRate') ?? ''
  const minAnnualRateStored =
    localStorage.getItem('filter_jobs_minAnnualRate') ?? ''

  const [languages, setLanguages] = useState(languageStored)
  const [locations, setLocations] = useState(locationsStored)
  const [durations, setDurations] = useState(durationsStored)
  const [minHourlyRate, setMinHourlyRate] = useState(minHourlyRateStored)
  const [enableHourly, setEnableHourly] = useState(
    minHourlyRateStored !== '' ? true : false
  )

  const [minAnnualRate, setMinAnnualRate] = useState(minAnnualRateStored)
  const [enableAnnual, setEnableAnnual] = useState(
    minAnnualRateStored !== '' ? true : false
  )
  const [enableBoth, setEnableBoth] = useState(
    minAnnualRateStored !== '' && minHourlyRateStored !== '' ? true : false
  )
  const [typingTimeout, setTypingTimeout] = useState(null)

  useEffect(() => {
    // Update "Both" checkbox based on the individual checkboxes
    setEnableBoth(enableHourly && enableAnnual)
  }, [enableHourly, enableAnnual])

  useEffect(() => {
    if (languages.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['languages']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          languages: languages.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem(
        'fj_page',
        1
      )
    }
  }, [languages])

  useEffect(() => {
    if (locations.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['countries']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          countries: locations.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem(
        'fj_page',
        1
      )
    }
  }, [locations])

  useEffect(() => {
    if (enableHourly) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            hourly:
              minHourlyRate.length >= 5
                ? Number(minHourlyRate.replace(/,/g, ''))
                : Number(minHourlyRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem(
        'fj_page',
        1
      )
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['hourly']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [minHourlyRate, enableHourly])

  useEffect(() => {
    if (enableAnnual) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            annual:
              minAnnualRate.length >= 5
                ? Number(minAnnualRate.replace(/,/g, ''))
                : Number(minAnnualRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem(
        'fj_page',
        1
      )
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['annual']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [minAnnualRate, enableAnnual])

  useEffect(() => {
    if (durations.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['duration']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          duration: durations.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem(
        'fj_page',
        1
      )
    }
  }, [durations])

  const onHandleReset = () => {
    setEnableHourly(false)
    setMinHourlyRate('')
    setEnableAnnual(false)
    setMinAnnualRate('')
    setLanguages([])
    setLocations([])
    setDurations([])
    setFilter(null)

    localStorage.removeItem('filter_jobs_language')
    localStorage.removeItem('filter_jobs_location')
    localStorage.removeItem('filter_jobs_duration')
    localStorage.removeItem('filter_jobs_minHourlyRate')
    localStorage.removeItem('filter_jobs_minAnnualRate')
  }

  const handleCheckboxChange = (checkboxType) => {
    if (checkboxType === 'hourly') {
      setEnableHourly(!enableHourly)
    } else if (checkboxType === 'annually') {
      setEnableAnnual(!enableAnnual)
    }
  }

  const handleBothCheckboxChange = () => {
    setEnableBoth(!enableBoth)
    setEnableHourly(!enableBoth)
    setEnableAnnual(!enableBoth)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1E3E85] bg-opacity-90">
          <div className="flex min-h-full items-center justify-center p-10 py-[59px] text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white p-8 pb-[70px] relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon
                      size={70}
                      className="text-[#52B4DA] hover:text-green-100 cursor-pointer"
                    />
                  </div>
                  <div>
                    <div className="text-black-100 leading-[24px] text-[18px] font-bold">
                      Filter
                    </div>
                    <p className="text-black-100 leading-[20px] text-[14px] font-inter font-bold mt-6">
                      Pay
                    </p>
                    <div className="space-y-[30px] mt-5">
                      <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Hourly"
                          value={enableHourly}
                          onChange={() => handleCheckboxChange('hourly')}
                          disabled={disabled}
                        />
                        <NumberInput
                          placeholder="Min Hourly Rate"
                          value={minHourlyRate}
                          prefix="$"
                          suffix="USD"
                          onChange={(e) => {
                            setEnableHourly(e.target.value.length > 0)
                            setMinHourlyRate(e.target.value)
                            localStorage.setItem(
                              'filter_jobs_minHourlyRate',
                              e.target.value
                            )
                          }}
                          // type="number"
                          disabled={disabled}
                        />
                      </div>
                      <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Annual Salary"
                          value={enableAnnual}
                          onChange={() => handleCheckboxChange('annually')}
                          disabled={disabled}
                        />
                        <NumberInput
                          value={minAnnualRate}
                          prefix="$"
                          suffix="USD"
                          onChange={(e) => {
                            setEnableAnnual(e.target.value.length > 0)
                            setMinAnnualRate(e.target.value)
                            localStorage.setItem(
                              'filter_jobs_minAnnualRate',
                              e.target.value
                            )
                          }}
                          placeholder="Min Annual Salary"
                          // type="number"
                          disabled={disabled}
                        />
                      </div>
                      <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Both Hourly & Salary"
                          value={enableBoth}
                          onChange={handleBothCheckboxChange}
                          disabled={disabled}
                        />
                      </div>
                      <div className="space-y-[15px]">
                        <p className="text-black-100 leading-[20px] text-[14px] font-inter font-bold">
                          Languages Required:
                        </p>
                        <AutoComplete
                          disabled={disabled}
                          multiple={true}
                          data={_.map(languageNameMap, (v, k) => {
                            return {
                              id: v.name,
                              name: v.native,
                            }
                          })}
                          value={languages}
                          name="language"
                          id="language"
                          onChange={(value) => {
                            setLanguages(
                              _.unionBy(value, function (e) {
                                return e.id
                              })
                            )
                            const unionVal = _.unionBy(value, function (e) {
                              return e.id
                            })
                            localStorage.setItem(
                              'filter_jobs_language',
                              JSON.stringify(unionVal)
                            )
                          }}
                          removeItem={(v) => {
                            setLanguages((prev) =>
                              prev.filter((i) => i.id !== v.id)
                            )
                            const filterRemoveVal = languages.filter(
                              (i) => i.id !== v.id
                            )
                            localStorage.setItem(
                              'filter_jobs_language',
                              JSON.stringify(filterRemoveVal)
                            )
                          }}
                        />
                      </div>
                      <div className="space-y-[15px]">
                        <p className="text-black-100 leading-[20px] text-[14px] font-inter font-bold">
                          Employer Location:
                        </p>
                        <AutoComplete
                          multiple={true}
                          disabled={disabled}
                          data={_.map(getNames(), (v, k) => {
                            return {
                              id: v,
                              name: v,
                            }
                          })}
                          value={locations}
                          name="location"
                          id="location"
                          onChange={(value) => {
                            setLocations(
                              _.unionBy(value, function (e) {
                                return e.id
                              })
                            )
                            const unionVal = _.unionBy(value, function (e) {
                              return e.id
                            })
                            localStorage.setItem(
                              'filter_jobs_location',
                              JSON.stringify(unionVal)
                            )
                          }}
                          removeItem={(v) => {
                            setLocations((prev) =>
                              prev.filter((i) => i.id !== v.id)
                            )
                            const filterRemoveVal = locations.filter(
                              (i) => i.id !== v.id
                            )
                            localStorage.setItem(
                              'filter_jobs_location',
                              JSON.stringify(filterRemoveVal)
                            )
                          }}
                        />
                      </div>
                      <div className="space-y-[15px]">
                        <p className="text-black-100 leading-[20px] text-[14px] font-inter font-bold">
                          Job Duration:
                        </p>
                        <AutoComplete
                          multiple={true}
                          disabled={disabled}
                          data={_.map(typeDurations, (v, k) => {
                            return {
                              id: v.id,
                              name: v.name,
                            }
                          })}
                          value={durations}
                          name="durations"
                          id="durations"
                          onChange={(value) => {
                            setDurations(
                              _.unionBy(value, function (e) {
                                return e.id
                              })
                            )
                            const unionVal = _.unionBy(value, function (e) {
                              return e.id
                            })
                            localStorage.setItem(
                              'filter_jobs_duration',
                              JSON.stringify(unionVal)
                            )
                          }}
                          removeItem={(v) => {
                            setDurations((prev) =>
                              prev.filter((i) => i.id !== v.id)
                            )
                            const filterRemoveVal = durations.filter(
                              (i) => i.id !== v.id
                            )
                            localStorage.setItem(
                              'filter_jobs_duration',
                              JSON.stringify(filterRemoveVal)
                            )
                          }}
                        />
                      </div>
                      <div className="space-y-[15px]">
                        <p className="text-black-100 leading-[20px] text-[14px] font-inter font-bold">
                          Job Duration:
                        </p>
                        <AutoComplete
                          multiple={true}
                          disabled={disabled}
                          data={_.map(typeDurations, (v, k) => {
                            return {
                              id: v.id,
                              name: v.name,
                            }
                          })}
                          value={durations}
                          name="durations"
                          id="durations"
                          onChange={(value) => {
                            setDurations(
                              _.unionBy(value, function (e) {
                                return e.id
                              })
                            )
                          }}
                          removeItem={(v) => {
                            setDurations((prev) =>
                              prev.filter((i) => i.id !== v.id)
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div class="flex justify-between items-end">
                      <div
                        onClick={onHandleReset}
                        class="text-gray-100 leading-20 text-14 font-bold mt-40 cursor-pointer"
                      >
                        Reset all filters
                      </div>
                      <div class="sm:hidden">
                        <button
                          class="w-28 h-8 my-100 flex-shrink-0 rounded-lg bg-blue-200 text-white"
                          onClick={closeModal}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
