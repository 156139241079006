import clsx from 'clsx'
import React, { useState } from 'react'

export default function TextFieldError({
  className = '',
  label,
  error,
  errorClassName,
  ...rest
}) {
  const [isFocus, setIsFocus] = useState(false)
  return (
    <div className={clsx('space-y-3 group relative', className)}>
      {label && (
        <label
          className={clsx(
            'block text-[12px] md:text-xs',
            isFocus ? 'text-blue-100' : 'text-gray-300'
          )}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          {...rest}
          autoComplete="off"
          onFocus={() => setIsFocus(true)}
          className={clsx(
            'leading-5 border-solid border-[2px] box-sizing ext-[#11142D] text-[14px] md:text-sm py-[18px] px-[23px] w-full bg-[rgba(228,228,228,0.25)] rounded-lg',
            isFocus ? 'border-blue-100' : 'border-transparent'
          )}
          onBlur={() => setIsFocus(false)}
        />
        {error && (
          <div
            className={clsx(
              'text-red-300 text-sm flex font-semibold absolute top-0 w-full h-full justify-center items-center bg-[rgb(228,228,228)] border-[#C06464] border-[1px] border-solid rounded-lg',
              errorClassName
            )}
          >
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  )
}
