//packages
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { toast } from 'react-toastify'

//custom components
import AuthCard from 'components/Card/AuthCard'
import TextField from 'components/Input/TextField'
import { AnimateSpinIcon } from 'components/Icons'

//api & redux
import { resetPassword } from 'app/services/auth.service'

import { getCharacterValidationError } from 'app/utils/function'
import { Helmet } from 'react-helmet'

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
  confirmPassword: yup
    .string()
    .label('confirm password')
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export default function ResetPassword() {
  let [searchParams, setSearchParams] = useSearchParams()
  const [userId, setUserId] = useState('')
  const [token, setToken] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const tUserId = searchParams.get('userId')
    const tToken = searchParams.get('token')
    if (!tUserId || !tToken) {
      navigate('/auth')
    } else {
      setUserId(tUserId)
      setToken(tToken)
    }
  }, [searchParams])

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async (values) => {
    formik.setSubmitting(true)
    try {
      const params = {
        id: userId,
        data: {
          password: values.password,
          token,
        },
      }
      await resetPassword(params)
      toast.success('Updated Password successfully.')
      navigate('/auth')
    } catch (err) {
      if (err?.response?.data.error) {
        toast.error(err?.response?.data.error)
      } else {
        toast.error('There is error when updating password. Please try again!')
      }
    } finally {
      formik.setSubmitting(false)
    }
  }

  return (
    <div className="w-116 mx-auto">
      <Helmet>
        <title>Reset Your Remote Recruit Password - Remoterecruit.com</title>
        <meta
          name="description"
          content="Easily reset and recover your Remote Recruit account password. Secure your account today."
        />
      </Helmet>
      <AuthCard>
        <form onSubmit={formik.handleSubmit}>
          <h2 className="text-black-100 text-[24px] md:text-m4xl font-semibold leading-[36px] md:leading-13">
            Reset Password
          </h2>
          <div className="text-[12px] md:text-[16px] text-gray-300 md:text-black-100 leading-5 mt-[10px] md:mt-5">
            Please enter a new password below
          </div>
          <div className="grid md:grid-cols-2 gap-x-[16px] gap-y-[26px] mt-[40px]">
            <TextField
              label="Password"
              type="password"
              name="password"
              id="password"
              error={
                formik.touched['password'] ? formik.errors['password'] : ''
              }
              onChange={formik.handleChange}
            />
            <TextField
              label="Confirm"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              error={
                formik.touched['confirmPassword']
                  ? formik.errors['confirmPassword']
                  : ''
              }
              onChange={formik.handleChange}
            />
          </div>
          <div className="text-[11142D] leading-[16px] text-[12px] md:text-xs opacity-80 mt-4"></div>
          <button
            type="submit"
            className="flex justify-center items-center mt-[40px] bg-blue-100 hover:opacity-80 rounded-2xl font-bold text-[14px] md:text-sm text-white w-full text-center py-[18px] md:py-4.5 disabled:opacity-50"
          >
            Reset
            {formik.isSubmitting && (
              <AnimateSpinIcon className="w-6 h-6 ml-2" />
            )}
          </button>
        </form>
      </AuthCard>
    </div>
  )
}
