import React from 'react'

function IntroductionText() {
  return (
    <div>
      <div>
        <h2 className="text-white font-poppins text-4xl not-italic font-semibold leading-13 text-center mb-[15px] sm:mb-[25px] uppercase">
          A New Way to Hire Globally
        </h2>
      </div>
      {/* <div className="flex justify-center">
        <h6 className="w-[21.5625rem] sm:w-[57.125rem] h-14 text-white text-center font-poppins text-sm not-italic font-medium leading-7 mb-[60px] sm:mb-[70px]">
          Remoterecruit is helping connecting jobseekers and employers from
          around the globe through{' '}
          <span className="hidden sm:inline">
            <br />
          </span>{' '}
          video-based recruiting. Express yourself through videos rather than
          boring resumes.
        </h6>
      </div> */}
    </div>
  )
}

export default IntroductionText
