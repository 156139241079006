import React from 'react'

export default function TickSquareIcon({ size = 14, className }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.89301 0.333008C12.153 0.333008 13.6663 1.91967 13.6663 4.27967V9.72701C13.6663 12.0797 12.153 13.6663 9.89301 13.6663H4.11301C1.85301 13.6663 0.333008 12.0797 0.333008 9.72701V4.27967C0.333008 1.91967 1.85301 0.333008 4.11301 0.333008H9.89301ZM9.78634 4.99967C9.55967 4.77301 9.18634 4.77301 8.95967 4.99967L6.20634 7.75301L5.03967 6.58634C4.81301 6.35967 4.43967 6.35967 4.21301 6.58634C3.98634 6.81301 3.98634 7.17967 4.21301 7.41301L5.79967 8.99301C5.91301 9.10634 6.05967 9.15967 6.20634 9.15967C6.35967 9.15967 6.50634 9.10634 6.61967 8.99301L9.78634 5.82634C10.013 5.59967 10.013 5.23301 9.78634 4.99967Z"
        fill="#52B4DA"
      />
    </svg>
  )
}
