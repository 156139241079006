//packages
import React, { useState } from 'react'

//custom components
import Button from 'components/Button'

export default function ChatForm({ handleSendMessage, value, setValue }) {
  return (
    <div className="w-full">
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="bg-[rgba(228,228,228,0.25)] rounded-xl w-full h-32 md:h-32 focus-visible:outline-none py-5 px-4 text-gray-100"
      />
      <Button
        onClick={handleSendMessage}
        variant="secondary"
        className="w-full rounded-2xl"
        disabled={!value}
      >
        Send Message
      </Button>
    </div>
  )
}
