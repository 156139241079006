//packages
import { getNames } from 'country-list'
import languageNameMap from 'language-name-map/map'
import React, { useState } from 'react'
import AWS from 'aws-sdk'
import ReactPlayer from 'react-player'
import { Editor } from '@tinymce/tinymce-react'

//custom components
import TextAreaInput from 'components/Input/TextAreaInput'
import TextInput from 'components/Input/TextInput'
import AutoComplete from 'components/Select/AutoComplete'
import UpdatePasswordModal from 'views/employer/setting/components/UpdatePasswordModal'
import TagInput from 'components/Input/TagInput'
import EmailUpdateInput from 'components/Input/EmailUpdateInput'
import { PlusCircleIcon } from 'components/Icons'
import PasswordInput from 'components/Input/PasswordInput'
import UpdateEmailModal from 'views/employer/setting/components/UpdateEmailModal'
import { AnimateSpinIcon, DeleteIcon } from 'components/Icons'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import Tooltip from 'components/Tooltip'

//redux & api

export default function DetailPanel({ formik }) {
  const [isVideoUploading, setIsVideoUploading] = useState(false)
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false)
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false)
  const [progress, setProgress] = useState(0)
  const [maxSizeError, setMaxSizeError] = useState(false)

  async function handleVideoUploadChange(e, type) {
    const file = e.target.files[0]

    const MAX_FILE_SIZE = 500 * 1024 * 1024
    if (file && file.size > MAX_FILE_SIZE) {
      // File size exceeds the limit, show an error message or handle it accordingly
      setMaxSizeError(true)
      return
    }

    setProgress(0)
    setMaxSizeError(false)
    setIsVideoUploading(true)
    const totalBytes = file.size
    console.log(totalBytes)
    let uploadedBytes = 0
    let totalBytes2 = file.size

    if (!file) {
      return
    }

    try {
      const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
      const REGION = process.env.REACT_APP_AWS_REGION
      const PART_SIZE = 5 * 1024 * 1024 // 5 MB part size

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      })

      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      })

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
      }

      const uploadIdResp = await s3.createMultipartUpload(params).promise()
      const uploadId = uploadIdResp.UploadId
      const numParts = Math.ceil(file.size / PART_SIZE)

      const uploadPromises = Array.from({ length: numParts }, (_, index) => {
        const start = index * PART_SIZE
        const end = Math.min(start + PART_SIZE, file.size)
        const partNumber = index + 1
        const part = file.slice(start, end)

        const partParams = {
          Bucket: S3_BUCKET,
          Key: file.name,
          UploadId: uploadId,
          PartNumber: partNumber,
          Body: part,
        }

        return s3
          .uploadPart(partParams)
          .on('httpUploadProgress', (progress) => {
            if (numParts <= 1) {
              const currentUploadedBytes = progress.loaded + start
              const percentUploaded = Math.round(
                (currentUploadedBytes / totalBytes) * 100
              )
              console.log(`Overall upload progress: ${percentUploaded}%`)
              console.log(currentUploadedBytes, totalBytes)
              setProgress(percentUploaded)
            } else {
              totalBytes2 += progress.total
              uploadedBytes += progress.loaded
              const percentUploaded = Math.round(
                (uploadedBytes / totalBytes2) * 200
              )
              setProgress(percentUploaded)
              console.log(`Overall upload progress: `, percentUploaded)

              console.log(progress.total)
              console.log(progress)
            }
            console.log(numParts)
          })
          .promise()
          .then((data) => {
            console.log(`Uploaded Part #${partNumber}: ETag - ${data.ETag}`)
            return {
              ETag: data.ETag,
              PartNumber: partNumber,
            }
          })
      })

      const uploadedParts = await Promise.all(uploadPromises)

      const completedParams = {
        Bucket: S3_BUCKET,
        Key: file.name,
        UploadId: uploadId,
        MultipartUpload: {
          Parts: uploadedParts,
        },
      }

      await s3.completeMultipartUpload(completedParams).promise()

      const objectUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`
      formik.setFieldValue(type, objectUrl)
      setIsVideoUploading(false)
    } catch (err) {
      console.error('Error uploading video:', err)
      setIsVideoUploading(false)
    }
  }

  const handleUpdatePassword = () => {
    setShowUpdatePasswordModal(true)
  }

  const onEmailUpdate = () => {
    setShowUpdateEmailModal(true)
  }

  const removeVideo = () => {
    formik.setFieldValue('video', '')
  }

  return (
    <div>
      {formik.values.video ? (
        <div className="md:max-w-[338px] mx-auto space-y-[10px]">
          <div className="flex justify-between">
            <div className="text-[#B2B3BD] text-[12px]">Your Intro Video</div>
            <button
              className="text-red-200 text-[12px] flex"
              onClick={removeVideo}
            >
              Delete <DeleteIcon className="text-red-200 ml-2" />
            </button>
          </div>
          <ReactPlayer
            height="auto"
            width="100%"
            url={formik.values.video}
            controls={true}
          />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-1 mb-2">
            <p className="text-[#B2B3BD] font-inter text-[12px] font-medium leading-[16px]">
              Upload your Intro Video
            </p>
            <Tooltip text="Profiles with intro videos are shown at the top of search results for employers. Job seekers with intro videos are much more likely to receive job offers.">
              <div className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M5.00001 0C2.2395 0 0 2.23668 0 4.99737C0 7.76071 2.2395 10 4.99999 10C7.762 10 9.99961 7.76068 9.99961 4.99737C9.99963 2.23668 7.762 0 5.00001 0ZM5.35631 7.77951C5.24019 7.88285 5.1056 7.93479 4.95314 7.93479C4.79541 7.93479 4.65781 7.88378 4.54037 7.78157C4.42273 7.67954 4.3638 7.5367 4.3638 7.35316C4.3638 7.19035 4.42084 7.05332 4.53453 6.94226C4.64823 6.8312 4.7877 6.77568 4.95314 6.77568C5.11595 6.77568 5.25299 6.8312 5.36423 6.94226C5.47529 7.05332 5.531 7.19033 5.531 7.35316C5.53079 7.53406 5.47262 7.67619 5.35631 7.77951ZM6.80432 4.18969C6.7151 4.35514 6.60914 4.49781 6.48623 4.6181C6.36369 4.73837 6.14329 4.94052 5.82517 5.22474C5.73746 5.30493 5.66688 5.37532 5.61399 5.43592C5.5611 5.49673 5.52158 5.55223 5.49578 5.60269C5.4698 5.65312 5.44986 5.70358 5.43574 5.75401C5.42161 5.80426 5.40035 5.89293 5.37155 6.01959C5.32262 6.28838 5.16884 6.42276 4.9104 6.42276C4.776 6.42276 4.66307 6.3789 4.57103 6.291C4.47935 6.2031 4.43362 6.07266 4.43362 5.8995C4.43362 5.68249 4.46731 5.49444 4.53451 5.3354C4.60134 5.17634 4.69074 5.03688 4.8018 4.9166C4.91304 4.79633 5.06286 4.65365 5.25165 4.4882C5.4171 4.34345 5.53663 4.23429 5.61022 4.16068C5.68399 4.0869 5.74593 4.00484 5.79618 3.91448C5.84682 3.82395 5.87167 3.72588 5.87167 3.61992C5.87167 3.41307 5.79507 3.23877 5.64111 3.09666C5.48733 2.95456 5.28893 2.8834 5.04593 2.8834C4.76153 2.8834 4.55222 2.95511 4.41783 3.09854C4.28343 3.24197 4.16994 3.45315 4.07676 3.73229C3.98868 4.02443 3.82191 4.17047 3.57664 4.17047C3.43189 4.17047 3.30975 4.11946 3.21018 4.01744C3.11079 3.91541 3.0611 3.80493 3.0611 3.68598C3.0611 3.44053 3.13997 3.1917 3.29752 2.93967C3.45526 2.68764 3.68527 2.47889 3.98773 2.31362C4.29001 2.14817 4.64295 2.06535 5.04593 2.06535C5.42069 2.06535 5.7514 2.13461 6.03825 2.27296C6.32511 2.41111 6.54683 2.59916 6.70325 2.83706C6.85948 3.07478 6.93777 3.33323 6.93777 3.61237C6.93814 3.83168 6.89354 4.02424 6.80432 4.18969Z"
                    fill="#52B4DA"
                  />
                </svg>
              </div>
            </Tooltip>
          </div>

          <div className="relative cursor-pointer border-[1px] border-dashed border-[#E0E5F9] rounded-lg space-y-4 py-10 px-7">
            <div className="flex justify-center">
              {isVideoUploading ? (
                <AnimateSpinIcon className="w-6 h-6 ml-2" />
              ) : (
                <PlusCircleIcon />
              )}
            </div>
            <div className="text-[#0e0f14] text-center text-[14px] md:text-base font-semibold">
              {isVideoUploading ? (
                `Your Video is Uploading ${
                  progress <= 100 ? progress : 100
                }%. Please wait...`
              ) : maxSizeError ? (
                <h6 className="text-[#FF0000]">
                  Video file size is too large. Please upload a video less than
                  500 MB.
                </h6>
              ) : (
                'Add an intro video to improve your employment chances'
              )}
            </div>
            <input
              disabled={isVideoUploading}
              onChange={(e) => handleVideoUploadChange(e, 'video')}
              type="file"
              accept="video/*"
              className="absolute w-full h-full opacity-0 top-0 left-0"
            />
          </div>
        </>
      )}
      <div className="grid md:grid-cols-2 mt-[20px] md:mt-6 gap-4">
        <div>
          <TextInput
            label="Your Full Name"
            name="name"
            id="name"
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
            error={formik.touched['name'] ? formik.errors['name'] : ''}
          />
        </div>
        <div>
          <PasswordInput
            label="Password"
            updateHandler={handleUpdatePassword}
          />
        </div>
        <div>
          <TagInput
            label="Language You Speak"
            list={_.map(languageNameMap, (v, k) => {
              return {
                id: v?.name,
                name: v.name,
              }
            })}
            placeholder="Select Languages"
            name="languages"
            id="languages"
            onChange={(value) => {
              formik.setFieldValue('language', value)
            }}
            value={formik.values['language']}
          />
          {formik.touched['language'] && formik.errors['language'] && (
            <div className="text-red-300 text-[12px] md:text-xs font-semibold">
              {formik.errors['language']}
            </div>
          )}
        </div>
        <AutoComplete
          data={_.map(getNames(), (v, k) => {
            return {
              id: v,
              name: v,
            }
          })}
          label="Location"
          value={formik.values['country']}
          name="country"
          id="country"
          onChange={(value) => {
            formik.setFieldValue('country', value)
          }}
          error={formik.touched['country'] ? formik.errors['country'] : ''}
        />
        <EmailUpdateInput
          value={formik.values.email}
          onUpdate={onEmailUpdate}
        />
      </div>
      {/* <div className="mt-5">
        <TextInput
          name="website"
          label="Website"
          value={formik.values['website']}
          id="website"
          className="mt-5"
          onChange={(e) => formik.setFieldValue('website', e.target.value)}
          error={formik.touched['website'] ? formik.errors['website'] : ''}
        />
      </div> */}
      <div className="mt-6">
        {/* <TextAreaInput
          name="bio"
          value={formik.values['bio']}
          label="Bio"
          id="bio"
          onChange={formik.handleChange}
          error={formik.touched['bio'] ? formik.errors['bio'] : ''}
        /> */}

        <label class="text-[#1E3E85] font-inter text-[12px] leading-[16px] font-medium">
          Bio
        </label>
        {/* <Editor
          apiKey='9grr4uefxu0r2uuiir8y6v503y2jfccj2t325h3m2ly34s0n'
          // onInit={(evt, editor) => editorRef.current = editor}
          onEditorChange={(content, editor) => {
            formik.handleChange({ target: { name: 'bio', value: content } })
          }}
          value={formik.values['bio']}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        /> */}
        <RichTextEditor
          name="bio"
          value={formik.values.bio}
          onChange={formik.handleChange}
          placeholder="Enter your Bio"
        />
        {formik.errors['bio'] && formik.touched['bio'] ? (
          <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-3">
            {formik.errors['bio']}
          </div>
        ) : (
          ''
        )}
      </div>
      <UpdatePasswordModal
        isOpen={showUpdatePasswordModal}
        closeModal={() => setShowUpdatePasswordModal(false)}
      />
      <UpdateEmailModal
        isOpen={showUpdateEmailModal}
        closeModal={() => setShowUpdateEmailModal(false)}
      />
    </div>
  )
}
