import React from 'react'

export default function VideoPlayIcon({ size = 34 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.8" width="34" height="34" rx="15" fill="#52B4DA" />
      <g clip-path="url(#clip0_1685_12674)">
        <path
          d="M28.3274 16.6299C28.1373 10.6756 23.3241 5.8626 17.3699 5.67245C14.13 5.56966 11.0989 6.80286 8.85878 9.1157C6.70522 11.3391 5.5737 14.2703 5.67267 17.3697C5.86266 23.3239 10.6758 28.137 16.63 28.327C16.754 28.331 16.8768 28.3329 17 28.3329C20.0955 28.3329 22.9868 27.108 25.1411 24.8838C27.2947 22.6605 28.4264 19.7292 28.3274 16.6299ZM23.4268 23.2233C21.7261 24.9792 19.4436 25.9462 17 25.9462C16.9023 25.9462 16.8046 25.9446 16.7061 25.9416C12.0069 25.7916 8.20829 21.993 8.05808 17.2936C7.97995 14.846 8.8731 12.5314 10.5732 10.7763C12.2739 9.02039 14.5563 8.0534 17 8.0534C17.0977 8.0534 17.1954 8.05499 17.2939 8.05801C21.9931 8.20807 25.7916 12.0066 25.9418 16.706C26.02 19.1536 25.1267 21.4682 23.4268 23.2233Z"
          fill="white"
        />
        <path
          d="M20.7172 16.3412L15.6915 12.7377C15.1554 12.3533 14.4091 12.7364 14.4091 13.3961V20.6033C14.4091 21.263 15.1554 21.6462 15.6915 21.2617L20.717 17.6581C21.168 17.3349 21.168 16.6645 20.7172 16.3412Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1685_12674">
          <rect
            width="22.6667"
            height="22.6667"
            fill="white"
            transform="translate(5.66663 5.6665)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
