import React from 'react'
import globeAnimation from 'assets/animations/globe.json'
import Lottie from 'lottie-react'
import ChatBubble from './ChatBubble'
import GlobeImg from 'assets/img/globe.png'

const style = {
  height: 888,
  width: 888,
}

function EarthSection() {
  return (
    <div className="flex justify-center w-full h-[1245.7px]">
      <div className="flex flex-col items-center justify-center relative">
        {/* Lottie animation container */}
        <div className="flex items-center justify-center w-[1065.978px] h-[1065.978px]">
          <div
            className="opacity-[0.6] "
            style={{ width: '888px', height: '888px' }}
          >
            <img src={GlobeImg} alt="Globe Image" />
            {/* <Lottie animationData={globeAnimation} loop={true} style={style} /> */}
          </div>
        </div>

        {/* Chat bubbles container */}
        <div className="absolute top-[181px]">
          <div className="flex flex-col w-[1065.978px] h-[1065.978px]">
            <div className="flex justify-between items-center mb-[40px]">
              <ChatBubble
                country="United States!"
                jobPosition="Server analyst in the "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/usa_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/u.s.a_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
              <ChatBubble
                country="Philipines"
                jobPosition="Video Editor from "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/philipines_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/u.s.a_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
            </div>
            <div className="flex justify-center items-center mb-[40px]">
              <ChatBubble
                country="in Kenya"
                jobPosition="Full-Stack Developer "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/kenya_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/kenya_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
            </div>
            <div className="flex justify-between mb-[75px]">
              <ChatBubble
                country="in India"
                jobPosition="Admin Executive "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/india_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/india_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
              <ChatBubble
                country=""
                jobPosition="Product Designer from Bangladesh"
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/bangladesh_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/bangladesh_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#fff' }}
              />
            </div>
            <p className="self-stretch text-white text-center font-manrope text-[44px] not-italic font-light leading-normal mb-[16px]">
              Hire A{' '}
              <span className="text-[#FFC628] font-semibold">
                World of Talent,
              </span>{' '}
              Beyond Borders
            </p>
            <p className=" text-[#DBE6FF] text-center font-manrope text-[24px] not-italic font-normal leading-[28.8px] mb-[65px]">
              We’re a global community, connecting experts from every field and
              every corner of the world. Whether you need a developer in Berlin,
              a designer in Lagos, or a teacher in Tokyo, our network brings
              skills and passion together—ready to make an impact for you.
            </p>
            <div className="flex justify-between mb-[40px]">
              <ChatBubble
                country=""
                jobPosition="FilmMaker in Spain"
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/spain_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/spain_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#fff' }}
              />
              <ChatBubble
                country=""
                jobPosition="Web and Mobile Game Development  in China"
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/china_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/china_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#fff' }}
              />
            </div>
            <div className="flex justify-center items-center mb-[40px]">
              <ChatBubble
                country="in UAE"
                jobPosition="Logistics Coordinator "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uae_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uae_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
            </div>
            <div className="flex justify-between">
              <ChatBubble
                country="in Ethiopia"
                jobPosition="Medical Interpreter "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/ethipia_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/ethiopia_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
              <ChatBubble
                country="in United Kingdom"
                jobPosition="Customer Advisor "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uk_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uk_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarthSection
