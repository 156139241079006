import React, { useState, useEffect, useCallback } from 'react'
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { setUserInfo } from 'app/store/authSlice'
import { setProfile, getProfile } from 'app/store/profileSlice'

const AuthRoutes = () => {
  const [loading, setLoading] = useState(false)
  let user = localStorage.getItem('user')
  const location = useLocation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const auth = useSelector((state) => state.auth)

  const verifyUser = useCallback(async () => {
    user = JSON.parse(user)
    try {
      setLoading(true)
      let result = await getProfile(user.id)
      if (result.status === 200) {
        if (!auth.id) {
          const payload = {
            id: user.id,
            token: user.token,
            type: user.type,
          }
          dispatch(setUserInfo(payload))
          dispatch(setProfile(result.data.user))
        }
        setLoading(false)
      }
    } catch (err) {
      console.log('catched error', err)
      return navigate('/auth')
    }
  }, [])

  useEffect(() => {
    if (user) {
      verifyUser()
    }
  }, [verifyUser])

  const jobSharedUrlPattern = /^\/jobseeker\/jobs\/([0-9a-fA-F-]{36})$/
  const candidateSharedUrlPattern = /^\/employer\/profile\/([0-9a-fA-F-]{36})$/
  const nonProtectedJobBaseUrl = '/home/job'
  const nonProtectedCandidateBaseUrl = '/home/candidate'

  const jobURLMatch = location.pathname.match(jobSharedUrlPattern)
  const candidateURLMatch = location.pathname.match(candidateSharedUrlPattern)
  console.log(jobURLMatch)
  console.log(candidateURLMatch)
  if (!user) {
    const jobURLMatch = location.pathname.match(jobSharedUrlPattern)
    const candidateURLMatch = location.pathname.match(candidateSharedUrlPattern)
    console.log(jobURLMatch)
    console.log(candidateURLMatch)
    if (jobURLMatch) {
      const jobId = jobURLMatch[1]
      return <Navigate to={`${nonProtectedJobBaseUrl}/${jobId}`} replace />
    } else if (candidateURLMatch) {
      const candidateId = candidateURLMatch[1]
      return (
        <Navigate
          to={`${nonProtectedCandidateBaseUrl}/${candidateId}`}
          replace
        />
      )
    } else {
      const redirectUrl = location.pathname + location.search
      localStorage.setItem('redirectTo', redirectUrl)
      return (
        <Navigate
          to={`/auth?redirect=${encodeURIComponent(redirectUrl)}`}
          replace
        />
      )
    }
  }

  if (loading) return null

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default AuthRoutes
