import React from 'react'

function SearchTag({ name }) {
  return (
    <div className="flex justify-center items-center gap-[3.187px] py-[3.82px] pl-[7.65px] pr-[6.37px] rounded-[6.374px] bg-[#BBB]">
      <div className="text-white text-center font-inter text-[7.649px] not-italic font-semibold leading-[12.748px]">
        {name}
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <path
            d="M6.7851 3.8374L3.81055 6.81196"
            stroke="white"
            stroke-width="1.27481"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.7851 6.81196L3.81055 3.8374"
            stroke="white"
            stroke-width="1.27481"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  )
}

export default SearchTag
