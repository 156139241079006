//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

//custom components
import Button from 'components/Button'
import { ModalCloseIcon } from 'components/Icons'
import { AnimateSpinIcon } from 'components/Icons'

//redux
import { updateUser } from 'app/store/profileSlice'

export default function DeleteConfirmModal({ isOpen, closeModal, profile }) {
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDelete = async () => {
    setIsDeleting(true)
    const data = {
      deleted: true,
    }
    const params = {
      userId: profile.id,
      data,
    }
    const resultAction = await dispatch(updateUser(params))
    if (updateUser.fulfilled.match(resultAction)) {
      toast.success('Deleted Profile Successfully')
      localStorage.clear()
      navigate('/auth')
    } else {
      if (resultAction.payload) {
        toast.error(
          'Deleted Profile failed: ' + resultAction.payload.errorMessage
        )
      } else {
        toast.error('Deleted Profile failed' + resultAction.error.message)
      }
    }
    setIsDeleting(false)
    closeModal()
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white pt-28 pb-20 px-12 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer" />
                  </div>
                  <h3 className="text-black-100 text-[24px] leading-[36px] font-semibold text-center">
                    Are you sure that you want to delete the account
                    permanently?
                  </h3>
                  <div className="flex space-x-[15px] justify-center mt-[35px]">
                    <Button
                      variant="black"
                      className="flex items-center justify-center leading-5 font-bold text-sm px-[63px] rounded-[16px]"
                      onClick={handleDelete}
                    >
                      Delete
                      {isDeleting && (
                        <AnimateSpinIcon className="w-6 h-6 ml-2" />
                      )}
                    </Button>
                    <Button
                      variant="error"
                      className="leading-5 font-bold text-sm px-[63px]"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
