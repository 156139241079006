//packages
import { getName, getNames } from 'country-list'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { v4 } from 'uuid'
import heic2any from 'heic2any'
import AWS from 'aws-sdk'
import { Editor } from '@tinymce/tinymce-react'

//custom components
import MemberShipCard from 'components/Card/MemberShipCard'
import PasswordInput from 'components/Input/PasswordInput'
import TextAreaInput from 'components/Input/TextAreaInput'
import TextInput from 'components/Input/TextInput'
import AutoComplete from 'components/Select/AutoComplete'
import CardFooter from './CardFooter'
import CardHeader from './CardHeader'
import DeleteConfirmModal from './DeleteConfirmModal'
import UpgradingPlanModal from 'components/Dialog/UpgradingPlanModal'
import UpdatePasswordModal from './UpdatePasswordModal'
// import CroppingImageModal from 'components/Dialog/CroppingImageModal'
import CropImageModal from 'components/Dialog/CropImageModal'

//custom hooks
import useTagList from 'hooks/useTagList'

import {
  fetchBlobFromUrl,
  blobToBase64,
  generateRandomString,
} from 'app/utils/function'

//redux & api
import { updateUser } from 'app/store/profileSlice'
import TagInput from 'components/Input/TagInput'

import { COMPANIES_LIST } from 'app/utils/list'
import Dropdown from 'components/dropdown/Dropdown'
import Checkbox from 'components/Checkbox'
import EmailUpdateInput from 'components/Input/EmailUpdateInput'
import UpdateEmailModal from './UpdateEmailModal'

import AuthService from 'app/services/auth.service'
import { CrossIcon } from 'components/Icons'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

const schema = yup.object().shape({
  firstName: yup.string().required('You must enter your first name'),
  lastName: yup.string().required('You must enter your last name'),
  location: yup.object().required('You must enter your location'),
  IndividualDescription: yup.string().required('You must enter your bio'),
  // IndividualDescription: yup.string().when('emType', {
  //   is: (type) => type === 'individual',
  //   then: yup.string().required('You must enter your bio'),
  // }),

  companyName: yup.string().when('emType', {
    is: (type) => type === 'company',
    then: yup.string().required('Company Name is required'),
  }),
  country: yup
    .object()
    .nullable()
    .when('emType', {
      is: (type) => type === 'company',
      then: yup
        .object()
        .typeError('You must enter country')
        .required('You must enter HQ Country'),
    }),
  companySize: yup
    .object()
    .nullable()
    .when('emType', {
      is: (type) => type === 'company',
      then: yup
        .object()
        .typeError('You must enter your company size')
        .required('You must enter your Company Size'),
    }),
  industry: yup
    .array()
    .of(yup.object())
    .when('emType', {
      is: (type) => type === 'company',
      then: yup
        .array()
        .of(yup.object())
        .required('Industry Tags are required')
        .test('Min test', 'Must have at least 1 item', function (item) {
          return item.length >= 1
        }),
    }),
  description: yup.string().when('emType', {
    is: (type) => type === 'company',
    then: yup.string().required('You must enter your description'),
  }),
  website: yup.string().when('emType', {
    is: (type) => type === 'company',
    then: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .required('Please enter website'),
  }),
})

export default function CardBody({
  isPremium,
  profile,
  setShowCancelMembershipModal,
}) {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [showGetPremiumModal, setShowGetPremiumModal] = useState(false)
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false)
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false)
  const [isAvatarUploading, setIsAvatarUploading] = useState(false)
  const [isOpenCroppingImageModal, setIsOpenCroppingImageModal] =
    useState(false)
  const [fileExtension, setFileExtension] = useState('')
  const [avatarFileData, setAvatarFileData] = useState('')
  const [initialValues, setInitialValues] = React.useState({
    //details
    avatar: '',
    firstName: '',
    lastName: '',
    location: '',
    // email: '',
    // password: '',

    logo: '',
    companyName: '',
    country: '',
    industry: [],
    companySize: null,
    plan: 'basic',
    images: [''],
    description: '',
    IndividualDescription: '',
    emType: 'individual',
    website: '',
  })

  const dispatch = useDispatch()
  const [, industryTags] = useTagList()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
    enableReinitialize: true,
  })

  const openDeleteConfirmModal = async () => {
    setShowDeleteConfirmModal(true)
  }

  const handleSubmit = async (values) => {
    // formik.setSubmitting(true)

    const {
      avatar,
      firstName,
      lastName,
      location,
      // email,
      companyName,
      country,
      companySize,
      industry,
      website,
      description,
      IndividualDescription,
      membership,
      emType,
      plan,
      logo,
      // password,
    } = values

    let param

    if (emType === 'individual') {
      param = {
        avatar,
        firstName,
        lastName,
        location: location.name,
        IndividualDescription: IndividualDescription,
        // password,
        plan,
        type: 'employer',
        // email,
        emType,
      }
    } else {
      param = {
        avatar,
        firstName,
        lastName,
        location: location.name,
        companyName,
        country: country.name,
        companySize: companySize.id,
        industry: industry.map((v) => v.id),
        IndividualDescription: IndividualDescription,
        // password,
        website,
        bio: description,
        plan,
        type: 'employer',
        emType,
        logo,
        // email,
      }
    }

    const params = {
      userId: profile.id,
      data: param,
    }

    const resultAction = await dispatch(updateUser(params))
    if (updateUser.fulfilled.match(resultAction)) {
      toast.success('Edit Profile Successfully')
      formik.resetForm()
      navigate(`/${profile.type}/profile`)
    } else {
      if (resultAction.payload) {
        toast.error('Edit Profile failed: ' + resultAction.payload.errorMessage)
      } else {
        toast.error('Edit Profile failed' + resultAction.error.message)
      }
    }
    formik.setSubmitting(false)
  }

  useEffect(() => {
    if (profile) {
      // console.log('debug: profile', profile)
      const params = {
        //details
        avatar: profile.avatar,
        logo: profile.logo,
        firstName: profile.firstName,
        lastName: profile.lastName,
        location: profile.location
          ? { id: profile.location, name: profile.location }
          : null,
        emType: profile.emType,

        companyName: profile.companyName ?? '',
        email: profile.email,
        country: profile.country
          ? {
              id: profile.country,
              name: profile.country,
            }
          : null,
        industry: profile.industry
          ? profile.industry.map((v) => {
              return {
                id: v,
                label: v,
                name: v,
              }
            })
          : [],
        companySize: profile.companySize
          ? { id: profile.companySize, name: profile.companySize }
          : null,
        images: profile.images ? profile.images : [''],
        bio: profile.bio,
        IndividualDescription: profile.IndividualDescription,
        plan: profile.plan,
        description: profile.bio ?? '',
        website: profile.website,
      }
      setInitialValues(params)
    }
  }, [profile])

  const updatePasswordHandler = () => {
    setShowUpdatePasswordModal(true)
  }

  const onEmailUpdate = () => {
    setShowUpdateEmailModal(true)
  }

  const handleFileLoad = (event) => {
    const fileData = event.target.result
    setAvatarFileData(fileData)
    setIsOpenCroppingImageModal(true)
  }

  async function handleCroppedAvatarUpload(url) {
    try {
      const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
      const REGION = process.env.REACT_APP_AWS_REGION

      setIsAvatarUploading(true)
      const result = await fetchBlobFromUrl(url)
      // const fileContent = await blobToBase64(result)
      setIsOpenCroppingImageModal(false)

      const fileName = generateRandomString(8) + '.png'
      const file = new File([result], fileName, { type: 'image/png' })

      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: REGION,
      })

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
        ContentType: file.type,
      }

      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err)
        } else {
          console.log('File uploaded successfully:', data)

          const uploadedFileName = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`
          formik.setFieldValue('logo', uploadedFileName)
          setIsAvatarUploading(false)
        }
      })

      // const param = {
      //   fileContent,
      //   fileName: `${v4()}.${fileExtension}`,
      // }
      // let response = await AuthService.uploadFileToS3(param)
      // formik.setFieldValue('logo', response.data.url)
    } catch (err) {
      console.log(err)
    }
  }

  const convertHEICToJpeg = async (heicFile) => {
    try {
      const arrayBuffer = await heicFile.arrayBuffer()
      const blob = await heic2any({
        blob: new Blob([new Uint8Array(arrayBuffer)]),
        toType: 'image/jpeg',
        quality: 0.8, // Optional: Set JPEG quality (0-1)
      })

      return URL.createObjectURL(blob)
    } catch (error) {
      console.error('HEIC to JPEG conversion failed:', error)
      return null
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      if (extension.toLowerCase() === 'heic') {
        try {
          const jpegURL = await convertHEICToJpeg(file)
          console.log('jpegURL', jpegURL)
          console.log('HEIC to JPEG conversion successful')
          // Handle the converted JPEG data as needed
          setAvatarFileData(jpegURL)
          setIsOpenCroppingImageModal(true)
        } catch (error) {
          console.error('HEIC to JPEG conversion failed:', error)
        }
      } else {
        setFileExtension(extension)
        const reader = new FileReader()
        reader.onload = handleFileLoad
        reader.readAsDataURL(file)
      }
    }
  }

  const removeAvatar = () => {
    formik.setFieldValue('logo', '')
  }

  const isIndividualUser = formik.values.emType === 'individual'

  return (
    <div>
      <CardHeader
        isPremium={profile.plan === 'premium'}
        profile={profile}
        formik={formik}
      />
      <div className="px-9 pt-10 space-y-6">
        <section id="individual-user-section">
          <h3 className="font-semibold text-[14px] text-blue-100 uppercase mb-5">
            Personal Information
          </h3>
          <div className="grid md:grid-cols-2 gap-x-4 gap-y-6">
            <TextInput
              disabled={false}
              variant="general"
              label="First Name"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={(e) =>
                formik.setFieldValue('firstName', e.target.value)
              }
              error={
                formik.touched['firstName'] && formik.errors['firstName']
                  ? formik.errors['firstName']
                  : ''
              }
              containerClassName="w-full space-y-[16px]"
              inputClassName="bg-[#F8F8F8] h-[56px] w-full rounded-md text-[14px] font-semibold leading-[20px] font-inter px-[23px] focus:border-[#1E3E85] focus:border-[2px]"
            />
            <TextInput
              disabled={false}
              variant="general"
              label="Last Name"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
              error={
                formik.touched['lastName'] && formik.errors['lastName']
                  ? formik.errors['lastName']
                  : ''
              }
              containerClassName="w-full space-y-[16px]"
              inputClassName="bg-[#F8F8F8] h-[56px] w-full rounded-md text-[14px] font-semibold leading-[20px] font-inter px-[23px] focus:border-[#1E3E85] focus:border-[2px]"
            />
            <PasswordInput
              label="Password"
              id="password"
              name="password"
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue('password', e.target.value)}
              error={
                formik.touched['password'] ? formik.errors['password'] : ''
              }
              containerClassName="w-full space-y-3"
              updateHandler={updatePasswordHandler}
              inputClassName="h-[56px] disabled:bg-[rgba(228,228,228,0.3)] py-[19px] px-[18px] w-full text-black-100 text-opacity-50 rounded-lg focus-visible:outline-none focus:text-opacity-100"
            />
            <AutoComplete
              data={_.map(getNames(), (v, k) => {
                return {
                  id: v,
                  name: v,
                }
              })}
              label="Location"
              value={formik.values['location']}
              name="location"
              id="location"
              optionClassName="text-[13px]"
              onChange={(value) => {
                formik.setFieldValue('location', value)
              }}
              error={
                formik.touched['location'] && formik.errors['location']
                  ? formik.errors['location']
                  : ''
              }
            />
          </div>
          <div className="mt-[25px]">
            <EmailUpdateInput
              value={formik.values.email}
              onUpdate={onEmailUpdate}
            />
          </div>
          <div className="mt-[25px]">
            {/* <TextAreaInput
              label="Bio"
              name="IndividualDescription"
              value={formik.values['IndividualDescription']}
              id="IndividualDescription"
              onChange={formik.handleChange}
              error={formik.errors['IndividualDescription']}
            /> */}
            <label class="text-[#1E3E85] font-inter text-[12px] leading-[16px] font-medium">
              Bio
            </label>
            {/* <Editor
              apiKey='9grr4uefxu0r2uuiir8y6v503y2jfccj2t325h3m2ly34s0n'
              // onInit={(evt, editor) => editorRef.current = editor}
              onEditorChange={(content, editor) => {
                formik.handleChange({ target: { name: 'IndividualDescription', value: content } })
              }}
              value={formik.values['IndividualDescription']}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
            /> */}
            <RichTextEditor
              name="IndividualDescription"
              value={formik.values.IndividualDescription}
              onChange={formik.handleChange}
              placeholder="Enter your Bio"
            />
            {formik.errors['IndividualDescription'] &&
            formik.touched['IndividualDescription'] ? (
              <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-3">
                {formik.errors['IndividualDescription']}
              </div>
            ) : (
              ''
            )}
          </div>
          {/* PREMIUM start comment this out no premium user */}
          {/* <div className="mt-[20px]">
            <MemberShipCard
              profile={profile}
              isPremium={isPremium}
              setShowGetPremiumModal={setShowGetPremiumModal}
              setShowCancelMembershipModal={setShowCancelMembershipModal}
            />
          </div> */}
          {/* end comment this out no premium user */}
        </section>
        <section id="select-type">
          <div className="space-y-[20px]">
            <p className="text-[#B2B3BD] text-[12px] font-medium font-inter">
              Do you represent a company?
            </p>
            <div className="flex items-center space-x-[44px]">
              <Checkbox
                sizeClass="w-[16px] h-[16px]"
                variant="primary"
                label="No"
                labelClass="font-inter text-[12px] font-semibold"
                rectClass="border-blue-400 focus-within:border-blue-500 w-[16px] h-[16px]"
                value={isIndividualUser}
                onChange={(e) => formik.setFieldValue('emType', 'individual')}
              />
              <Checkbox
                sizeClass="w-[16px] h-[16px]"
                variant="primary"
                label="Yes"
                labelClass="font-inter text-[12px] font-semibold"
                rectClass="border-blue-400 focus-within:border-blue-500 w-[16px] h-[16px]"
                value={!isIndividualUser}
                onChange={(e) => formik.setFieldValue('emType', 'company')}
              />
            </div>
          </div>
        </section>
        <div className="h-[1px] bg-gray-200 my-8 w-full" />
        <section id="company-info" className="relative">
          <h3 className="font-semibold text-[14px] text-blue-100 uppercase mb-5">
            Company Information
          </h3>
          {formik.values.emType === 'individual' && (
            <div className="w-full h-full opacity-50 bg-white absolute top-0 left-0 z-10"></div>
          )}
          <p className="text-[12px] font-medium font-inter leading-[16px] text-[#B2B3BD] mb-3">
            Company Logo
          </p>
          <div className="flex items-center space-x-[20px]">
            <div className="relative w-[82px] h-[82px] bg-[#F7F7F7] border-dashed rounded-[10px] border-[#E0E5F9] border-[1px] flex items-center justify-center">
              {formik.values.logo && (
                <div
                  onClick={removeAvatar}
                  className="w-[18px] h-[18px] rounded-full bg-white absolute top-[5px] right-[5px] flex items-center justify-center cursor-pointer"
                >
                  <CrossIcon className="text-[#FF0000]" />
                </div>
              )}
              {formik.values.logo && (
                <div className="w-[70px] h-[70px]">
                  <img
                    src={formik.values.logo}
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
              )}
            </div>
            <div className="space-y-[14px]">
              <p className="text-[12px] text-[#B2B3BD] leading-[18px] font-inter max-w-[249px]">
                Must be a .jpg, .gif or .png file smaller than 10MB and at least
                400px by 400px.
              </p>
              <div className="relative">
                <input
                  type="file"
                  className="absolute w-full opacity-0 cursor-pointer"
                  onChange={(e) => handleFileChange(e)}
                  accept="image/*, .heic"
                />
                <button className="bg-[#52B4DA] rounded-[10px] py-[5px] px-[18px] font-inter leading-[20px] text-[12px] font-bold text-white cursor-pointer">
                  Upload Image
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-[16px] gap-y-[25px] mt-[25px]">
            <TextInput
              disabled={false}
              variant="general"
              label="Company Name"
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              onChange={(e) =>
                formik.setFieldValue('companyName', e.target.value)
              }
              error={
                formik.touched['companyName'] && formik.errors['companyName']
                  ? formik.errors['companyName']
                  : ''
              }
              containerClassName="w-full space-y-[10px]"
              inputClassName="bg-[#F8F8F8] h-[56px] w-full rounded-md text-[14px] font-semibold leading-[20px] font-inter px-[23px] focus:border-[#1E3E85] focus:border-[2px]"
            />
            <TextInput
              disabled={false}
              variant="general"
              label="Website"
              id="website"
              name="website"
              value={formik.values.website}
              onChange={(e) => formik.setFieldValue('website', e.target.value)}
              error={
                formik.touched['website'] && formik.errors['website']
                  ? formik.errors['website']
                  : ''
              }
              containerClassName="w-full space-y-[10px]"
              inputClassName="bg-[#F8F8F8] h-[56px] w-full rounded-md text-[14px] font-semibold leading-[20px] font-inter px-[23px] focus:border-[#1E3E85] focus:border-[2px]"
            />
            <AutoComplete
              data={_.map(getNames(), (v, k) => {
                return {
                  id: v,
                  name: v,
                }
              })}
              label="HQ Country"
              value={formik.values['country']}
              name="country"
              id="country"
              onChange={(value) => {
                formik.setFieldValue('country', value)
              }}
              error={formik.touched['country'] ? formik.errors['country'] : ''}
            />
            <div>
              <Dropdown
                parentClassName="space-y-[6px]"
                data={COMPANIES_LIST}
                label="Company Size"
                value={formik.values['companySize']}
                onChange={(data) => {
                  formik.setFieldValue('companySize', data)
                }}
                className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                btnClassName="h-[58px] bg-white border border-gray-500 text-black-100 text-opacity-50 text-sm font-semibold relative w-full cursor-default rounded-lg py-2 px-[18px] text-left flex items-center"
              />
              {formik.touched['companySize'] && formik.errors['companySize'] ? (
                <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-2.5">
                  {formik.errors['companySize']}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="mt-5 w-full">
            <TagInput
              label="Industry Tags"
              list={_.map(industryTags, (v, k) => {
                return {
                  id: v.id,
                  name: v.name,
                }
              })}
              placeholder=""
              name="industry"
              id="industry"
              onChange={(value) => {
                formik.setFieldValue('industry', value)
              }}
              value={formik.values.industry}
              limitText="*Required at least 1 tag"
            />
            {formik.errors['industry'] && formik.touched['industry'] ? (
              <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-2.5">
                {formik.errors['industry']}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="mt-[25px]">
            {/* <TextAreaInput
              label="Company Bio"
              name="description"
              value={formik.values['description']}
              id="description"
              onChange={formik.handleChange}
              error={formik.errors['description']}
            /> */}
            <label class="text-[#1E3E85] font-inter text-[12px] leading-[16px] font-medium">
              Company Bio
            </label>
            {/* <Editor
              apiKey="9grr4uefxu0r2uuiir8y6v503y2jfccj2t325h3m2ly34s0n"
              // onInit={(evt, editor) => editorRef.current = editor}
              onEditorChange={(content, editor) => {
                formik.handleChange({
                  target: { name: 'description', value: content },
                })
              }}
              value={formik.values['description']}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            /> */}
            <RichTextEditor
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              placeholder="Enter your Company Bio"
            />
            {formik.errors['description'] && formik.touched['description'] ? (
              <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-3">
                {formik.errors['description']}
              </div>
            ) : (
              ''
            )}
          </div>
        </section>
      </div>
      <CardFooter formik={formik} deleteProfile={openDeleteConfirmModal} />
      <UpdatePasswordModal
        isOpen={showUpdatePasswordModal}
        closeModal={() => setShowUpdatePasswordModal(false)}
      />
      <UpdateEmailModal
        isOpen={showUpdateEmailModal}
        closeModal={() => setShowUpdateEmailModal(false)}
      />
      <DeleteConfirmModal
        isOpen={showDeleteConfirmModal}
        closeModal={() => setShowDeleteConfirmModal(false)}
        profile={profile}
      />
      <UpgradingPlanModal
        isOpen={showGetPremiumModal}
        closeModal={() => setShowGetPremiumModal(false)}
      />
      {/* <CroppingImageModal
        image={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      /> */}
      <CropImageModal
        imageData={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      />
    </div>
  )
}
