//packages
import React, { useEffect } from 'react'

//assets
import NoSearchIcon from 'assets/images/no-result-search.png'

//custom components
import SkeletonCandidateItem from 'components/Loader/SkeletonCandidateItem'
import JobItem from './JobItem'
import Pagination from 'components/pagination'

export default function Results({
  jobs,
  loading,
  onPageChange,
  currentPage,
  count,
  pageSize,
}) {
  const fj_selected = localStorage.getItem('fj_selected')
  jobs = [...jobs].sort((a, b) => {
    if (a.createdBy?.plan === 'basic' && b.createdBy?.plan === 'premium') {
      return 1 // 'basic' plans should come first
    } else if (
      a.createdBy?.plan === 'premium' &&
      b.createdBy?.plan === 'basic'
    ) {
      return -1 // 'premium' plans should come after 'basic' plans
    } else {
      return 0 // maintain original order for other plans
    }
  })

  // if user already viewed a job, then go back to Results, it will auto scroll into the last click item
  useEffect(() => {
    if (!loading) {
      const element = document.getElementById(`${fj_selected}`)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [loading, fj_selected])

  return (
    <div className="w-full shadow-candiate-bar rounded-3xl bg-white p-[20px] md:p-7 pt-[20px] md:pt-9 mb-20">
      <h5 className="text-black-100 leading-[24px] md:leading-6 text-[16px] md:text-lg font-medium">
        Recent Jobs
      </h5>
      {loading ? (
        <SkeletonCandidateItem />
      ) : (
        <div className="mt-[20px] md:mt-8">
          <div className="space-y-[20px]">
            {jobs.map((v, i) => (
              <JobItem data={v} isLast={jobs.length === i - 1} id={`res${i}`}>
                {v.job_title}
              </JobItem>
            ))}
          </div>
          {jobs.length === 0 && (
            <div className="py-[60px] md:py-25 w-full text-center bg-white rounded-3xl">
              <div className="w-full flex justify-center">
                <img alt="no-search-image" src={NoSearchIcon} />
              </div>
              <p className="text-gray-100 leading-[24px] md:leading-6 text-[16px] md:text-xl mt-[60px] md:mt-16">
                No results found, please try again
              </p>
            </div>
          )}
        </div>
      )}
      {!loading && (
        <div className="mt-6 mb-[44px]">
          <Pagination
            onPageChange={onPageChange}
            currentPage={currentPage}
            totalCount={count}
            pageSize={pageSize}
            toTop={() => {
              localStorage.removeItem('fj_selected')
            }}
          />
        </div>
      )}
    </div>
  )
}
