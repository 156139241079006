//packages
import React from 'react'

//custom components
import LoginCard from 'components/Login/LoginCard'
import { Helmet } from 'react-helmet'

function LoginPage() {
  return (
    <div className="w-116 mx-auto">
      <Helmet>
        <title>Remote Recruit Account Login - Remoterecruit.com</title>
        <meta
          name="description"
          content="Securely log in to your Remote Recruit account to explore new remote job opportunities."
        />
      </Helmet>
      <LoginCard />
    </div>
  )
}

export default LoginPage
