//packages
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import ReactPlayer from 'react-player'

//custom components
import Button from 'components/Button'
import { AddNewIcon, PortfolioPlaceholderIcon } from 'components/Icons'
import AddNewProjectModal from './AddNewProjectModal'
import DeleteProjectModal from './DeleteProjectModal'
import ViewProjectDetailModal from './ViewProjectDetailModal'
import { ModalCloseIcon } from 'components/Icons'

//assets
import PortfolioSample from 'assets/images/portfolio-placeholder.png'

//redux & api
import { getUser } from 'app/store/profileSlice'
import { FolderIcon } from 'components/Icons'

export default function PortfolioSection({ profile, enableAddNew = true }) {
  const [showAddNewProjectModal, setShowAddNewProjectModal] = useState(false)
  const [showViewProjectDetailModal, setShowViewProjectDetailModal] =
    useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false)
  const user = useSelector(getUser)

  if (!profile) return null

  const isNoPortfolios =
    !_.isArray(profile.projects) || profile.projects.length === 0

  return (
    <div className="rounded-3xl bg-white p-8">
      <div className="flex justify-between">
        <p className="text-black-100 font-medium text-lg leading-6">
          {!isNoPortfolios && profile.projects.length + ' Portfolio Items'}
        </p>
        {enableAddNew && user?.type === 'contractor' && (
          <div
            className="text-green-100 inline-flex items-center space-x-2 cursor-pointer"
            onClick={() => setShowAddNewProjectModal(true)}
          >
            <div className="text-sm font-bold leading-5">Add New</div>
            <AddNewIcon />
          </div>
        )}
      </div>
      <div className="grid md:grid-cols-3 gap-10 mt-8">
        {profile.projects &&
          profile.projects.map((project) => (
            <div className="bg-white rounded-3xl shadow-xl w-full relative mb-5">
              {user?.type === 'contractor' && (
                <button
                  onClick={() => {
                    setSelectedProject(project)
                    setShowDeleteItemModal(true)
                  }}
                  className="absolute right-[-25px] top-[-15px]"
                >
                  <ModalCloseIcon
                    fill="#ce2423"
                    className="text-white"
                    size={60}
                  />
                </button>
              )}
              <div
                onClick={() => {
                  setSelectedProject(project)
                  setShowViewProjectDetailModal(true)
                }}
              >
                {project.imageUrls &&
                  project.imageUrls.length > 0 &&
                  (project.imageUrls[0].endsWith('.mp4') ? (
                    <ReactPlayer
                      width="100%"
                      url={
                        project.imageUrls && project.imageUrls.length > 0
                          ? project.imageUrls[0]
                          : PortfolioSample
                      }
                    />
                  ) : project.imageUrls[0].endsWith('.ppt') ||
                    project.imageUrls[0].endsWith('.doc') ||
                    project.imageUrls[0].endsWith('.docx') ||
                    project.imageUrls[0].endsWith('.xls') ||
                    project.imageUrls[0].endsWith('.html') ||
                    project.imageUrls[0].endsWith('.csv') ||
                    project.imageUrls[0].endsWith('.pdf') ||
                    project.imageUrls[0].endsWith('.txt') ? (
                    <div className="w-full md:h-44 rounded-t-3xl bg-[#E0E5F9] flex justify-center items-center">
                      <PortfolioPlaceholderIcon />
                    </div>
                  ) : (
                    <img
                      className="w-full md:h-44 rounded-t-3xl"
                      src={
                        project.imageUrls && project.imageUrls.length > 0
                          ? project.imageUrls[0]
                          : PortfolioSample
                      }
                    />
                  ))}
                <div
                  className="border-b-gray-200 border-b-[1px] text-black-100 leading-6 font-medium text-lg p-6 text-center h-32 truncate"
                  dangerouslySetInnerHTML={{
                    __html: project.description,
                  }}
                />
                <div className="py-5 px-6">
                  <Button variant="secondary" className="w-full">
                    View Project
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
      {isNoPortfolios && (
        <div className="w-full">
          <div className="w-full flex justify-center">
            <FolderIcon />
          </div>
          {user?.type === 'employer' ? (
            <div className="text-center text-gray-100 leading-6 text-xl font-inter mt-16">
              No portfolio.
            </div>
          ) : (
            <div className="text-center text-gray-100 leading-6 text-xl font-inter mt-16">
              You haven't added anything to your portfolio yet.
            </div>
          )}
          {user?.type === 'contactor' && (
            <div className="flex justify-center mt-12">
              <Button
                onClick={() => setShowAddNewProjectModal(true)}
                variant="primary"
                className="px-21 py-4 text-white leading-5 text-[14px] md:text-sm font-bold font-inter"
              >
                Add Portfolio
              </Button>
            </div>
          )}
        </div>
      )}
      <AddNewProjectModal
        isOpen={showAddNewProjectModal}
        closeModal={() => setShowAddNewProjectModal(false)}
      />
      <ViewProjectDetailModal
        isOpen={showViewProjectDetailModal}
        closeModal={() => setShowViewProjectDetailModal(false)}
        project={selectedProject}
      />

      <DeleteProjectModal
        isOpen={showDeleteItemModal}
        closeModal={() => setShowDeleteItemModal(false)}
        project={selectedProject}
      />
    </div>
  )
}
