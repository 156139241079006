import React, { useState } from 'react'

import SignupCard from 'components/Register/SignUpCard'
import { Helmet } from 'react-helmet'

function Register() {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="w-116 mx-auto">
      <Helmet>
        <title>Join Remote Recruit Today - Remoterecruit.com</title>
        <meta
          name="description"
          content="Sign up and start navigating the global remote job market with Remote Recruit today!"
        />
      </Helmet>
      <SignupCard open={open} handleClose={handleClose} />
    </div>
  )
}

export default Register
