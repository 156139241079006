import Avatar from 'components/Avatar'
import React, { useState, useEffect } from 'react'
import { getProfile } from 'app/store/profileSlice'

export default function AvatarRenderer({ sender, fromChannelList }) {
  const [profile, setProfile] = useState("");
  // const rendererJSON = JSON.parse(sender.custom_json)

  useEffect(async() => {
    await getProfile(sender?.username).then((response) => {
      if(response.data.user.emType === "company") {
        setProfile(response.data.user.logo);
      } else {
        setProfile(response.data.user.avatar);
      }
    });
  }, [sender])

  return (
    <>
      {fromChannelList ? (
        <div className="w-[32px] md:w-12">
          <Avatar
            className="w-[32px] md:w-12 h-[32px] md:h-12 object-cover rounded-lg"
            image={profile}
          />
        </div>
      ) : (
        <div>
          <Avatar className="w-[40px] md:w-[60px] h-[40px] md:h-[60px] object-cover rounded-lg" image={profile} />
        </div>
      )}
    </>
    
  )
}
