// Packages
import React, { Fragment, useState, useRef, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

// Custom components and utilities
import { CrossIcon, RotateIcon } from 'components/Icons';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

export default function CropImageModal({ isOpen, closeModal, imageData, onComplete }) {
  const initialCrop = { unit: '%', x: 0, y: 0, width: 80, height: 70 };
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(initialCrop);
  const [completedCrop, setCompletedCrop] = useState(initialCrop);
  const [rotate, setRotate] = useState(0);

  useDebounceEffect(
    useCallback(async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, rotate);
      }
    }, [completedCrop, rotate]),
    100,
    [completedCrop, rotate]
  );

  const rotateHandler = useCallback(() => {
    setRotate(prev => prev + 45);
  }, []);

const onSave = useCallback(async () => {
  const image = imgRef.current;
  const canvas = previewCanvasRef.current;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  // Convert the percentage-based crop dimensions to pixel values
  const pixelCrop = {
    x: completedCrop.x * image.naturalWidth / 100,
    y: completedCrop.y * image.naturalHeight / 100,
    width: completedCrop.width * image.naturalWidth / 100,
    height: completedCrop.height * image.naturalHeight / 100
  };

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      // Use resolve to return the blob itself instead of the object URL
      resolve(onComplete(URL.createObjectURL(blob)));
    }, 'image/jpeg');
  });
}, [completedCrop, imgRef, previewCanvasRef, onComplete]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[700px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-[#1B1D21] relative">
                  <div className="relative h-21 grid items-center">
                    <div className="font-medium text-white text-center leading-6 text-lg">
                      Crop & Rotate
                    </div>
                    <div
                      onClick={closeModal}
                      className=" cursor-pointer absolute top-6 right-6 w-9 h-9 flex items-center justify-center bg-white rounded-full"
                    >
                      <CrossIcon className="text-[#11142D]" />
                    </div>
                  </div>
                  
                  {!!imageData && (
                    <div className="flex justify-center md:px-16 px-24 py-7">
                      <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(crop, percentCrop) => setCompletedCrop(percentCrop)}
                        aspect={1}
                        // minWidth={400}
                        minHeight={100}
                      >
                        <img
                          ref={imgRef}
                          alt="Cropped Image"
                          src={imageData}
                        />
                      </ReactCrop>
                    </div>
                  )}

                  {!!completedCrop && (
                    <div className="hidden">
                      <div className="font-medium text-white text-center leading-6 text-lg mt-10">
                        Preview Here
                      </div>
                      <div className="flex justify-center">
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            border: "1px solid black",
                            objectFit: "contain",
                            width: completedCrop.width,
                            height: completedCrop.height,
                          }}
                        />
                      </div>
                    </div>
                  )}


                  <div className="h-28 flex justify-between px-10 md:px-25">
                    <div
                      onClick={rotateHandler}
                      className="flex items-center space-x-4 cursor-pointer"
                    >
                      <div>
                        <RotateIcon />
                      </div>
                      <div className="text-[#52B4DA] leading-6 text-lg font-medium">
                        Rotate
                      </div>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={onSave}
                        className={`font-inter leading-5 text-sm font-bold px-14 py-4 rounded-2xl bg-[#52B4DA] text-white ${!completedCrop ? 'opacity-50' : ''}`}
                        disabled={!completedCrop}
                      >
                        Save
                      </button>
                    </div>
                  </div>


                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
