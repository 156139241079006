import Button from 'components/Button'
import SearchTag from './SearchTag'
import SuggestedTag from './SuggestedTag'

export function SearchSection() {
  const suggestedTags = [
    'Remote',
    'Full Stack',
    'Frontend',
    'Backend',
    'DevOps',
    'UI/UX',
    'Product',
    'Marketing',
    'Sales',
    'Customer Service',
  ]

  return (
    <section className="pt-32 pb-16 text-center relative">
      <h1 className="text-[#FFC628] font-manrope text-[56px] not-italic font-semibold leading-[55.44px] mb-[20px]">
        Global recruitment{' '}
        <span className="text-white font-light">meets video</span>
      </h1>
      <p className="text-[#ABC3FA] text-center font-manrope text-[19.073px] not-italic font-normal leading-normal mb-[20px]">
        Explore the world's limitless talent pool with video-based hiring
      </p>
      <div className="flex flex-col items-center justify-center">
        <div className="flex h-[59px] w-[587.4px] px-[22px] py-[13px] items-center rounded-[8px] bg-white">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M11.7949 2.5C16.7629 2.5 20.7949 6.532 20.7949 11.5C20.7949 16.468 16.7629 20.5 11.7949 20.5C6.82692 20.5 2.79492 16.468 2.79492 11.5C2.79492 6.532 6.82692 2.5 11.7949 2.5ZM11.7949 18.5C15.6624 18.5 18.7949 15.3675 18.7949 11.5C18.7949 7.6325 15.6624 4.5 11.7949 4.5C7.92742 4.5 4.79492 7.6325 4.79492 11.5C4.79492 15.3675 7.92742 18.5 11.7949 18.5ZM20.2802 18.5711L23.1086 21.3995L21.6944 22.8137L18.866 19.9853L20.2802 18.5711Z"
                fill="#011137"
              />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Search for Candidates"
            className="w-full ml-[10px] h-full text-[rgba(1, 17, 55, 0.43)] font-manrope text-[20px] not-italic font-light leading-normal border-none"
          />
          <Button className="flex ml-[201px] px-[28.7px] py-[9.98px] justify-center items-center rounded-[6.8px] bg-[#00091D] text-white font-manrope text-[14.977px] not-italic font-normal leading-normal">
            Search
          </Button>
        </div>
        <div className="flex flex-col justify-start w-[541px] h-[180px] bg-white rounded-[12px] mt-[10px] pl-[24px] pt-[22px] pr-[44px]">
          <div className="flex flex-wrap w-[473px] h-[45.5px] justify-start items-start gap-[4.219px] mb-[14.48px]">
            <SearchTag name="Creative" />
            <SearchTag name="Animation" />
            <SearchTag name="Illustration" />
            <SearchTag name="Photoshop" />
            <SearchTag name="Marketing" />
            <SearchTag name="Designer" />
            <SearchTag name="Developer" />
            <SearchTag name="Creative" />
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="490"
              height="2"
              viewBox="0 0 490 2"
              fill="none"
            >
              <path
                d="M0.981445 1L489.018 1"
                stroke="black"
                stroke-width="0.2"
              />
            </svg>
          </div>
          <div className="flex flex-col items-start">
            <p className="text-[#000000] text-center font-poppins text-[14px] italic font-light leading-normal">
              Suggested Tags:
            </p>
            <div className="flex w-[490px] items-center justify-start flex-wrap gap-x-[6.563px] gap-y-[4px]">
              <SuggestedTag name="Creative" />
              <SuggestedTag name="Design & Creative" />
              <SuggestedTag name="Engineering & Architecture" />
              <SuggestedTag name="Writing & Translation" />
              <SuggestedTag name="Sales & Marketing" />
              <SuggestedTag name="Legal" />
              <SuggestedTag name="Admin & Customer Support" />
              <SuggestedTag name="Finance & Accounting" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
