import React from 'react'

export default function EmailUpdateInput({
  label = 'Email',
  value = 'dante@salariasales.com',
  containerClassName = 'space-y-[10px]',
  onUpdate,
}) {
  return (
    <div className={containerClassName}>
      <label className="text-[#1E3E85] font-inter text-[12px] leading-[16px] font-medium">
        {label}
      </label>
      <div className="relative bg-[#F8F8F8] space-x-[20px] h-[56px] w-full rounded-md flex justify-between items-center pl-[18px] pr-[10px] text-[#11142D] font-inter font-semibold">
        <span className="truncate flex-1 max-w-[200px]">{value}</span>
        <div
          onClick={onUpdate}
          className=" bg-[#52B4DA] rounded-md py-[5px] px-[18px] text-white font-bold font-inter leading-[20px] text-[12px] cursor-pointer"
        >
          Update
        </div>
      </div>
    </div>
  )
}
