//packages
import React, { useState } from 'react'
import { getOrCreateChat } from 'react-chat-engine'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import clsx from 'clsx'

//custom functions
import { kFormatter } from 'app/utils/function'
import { ChatIcon, VideoPlayIcon } from 'components/Icons'
import VideoPlayerModal from 'components/Dialog/VideoPlayerModal'

//redux & apis
import { getUser } from 'app/store/profileSlice'
import { MessageIcon } from 'components/Icons'
import { ChatIcon2 } from 'components/Icons'
import { CrossIcon } from 'components/Icons'

//assets
import DefaultAvatar from 'assets/images/default-avatar.png'
import { rejectApplicant, acceptApplicant } from 'app/store/jobSlice'
import { CheckIcon } from 'components/Icons'
import { removeExtraSpaces } from 'app/utils/sanitizeText'

export default function CandidateItem({
  data,
  selectedJob,
  applicantData,
  isBlur,
  tab,
  id,
}) {
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false)
  const profile = useSelector(getUser)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const moveToCandidateDetail = () => {
    if (!isBlur) {
      navigate(`/employer/profile/${data.id}`)
      localStorage.setItem('ai_selected', id)
    }
  }

  const handleSendMessage = () => {
    const creds = {
      projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
      userName: profile.id,
      userSecret: profile.id,
    }
    getOrCreateChat(
      creds,
      { is_direct_chat: true, usernames: [data.id], title: selectedJob.title },
      (data) => {
        navigate('/employer/messages')
      }
    )
  }

  const onReject = async () => {
    let resultAction = await dispatch(rejectApplicant(applicantData.id))
    if (rejectApplicant.fulfilled.match(resultAction)) {
      toast.success('Denied Applicant Successfully')
    } else {
      if (resultAction.payload) {
        toast.error(
          'Deny applicant failed: ' + resultAction.payload.errorMessage
        )
      } else {
        toast.error('Deny applicant failed' + resultAction.error.message)
      }
    }
  }

  const onAccept = async () => {
    let resultAction = await dispatch(acceptApplicant(applicantData.id))
    if (acceptApplicant.fulfilled.match(resultAction)) {
      toast.success('Accepted Applicant Successfully')
    } else {
      if (resultAction.payload) {
        toast.error(
          'Accept applicant failed: ' + resultAction.payload.errorMessage
        )
      } else {
        toast.error('Accept applicant failed' + resultAction.error.message)
      }
    }
  }

  if (!data) return null

  const sanitisedBio = removeExtraSpaces(data?.bio)

  const handleContextMenu = (event) => {
    // Prevent the default right-click menu from appearing
    event.preventDefault()

    // Open the component (or a specific path) in a new tab
    window.open(`/employer/profile/${data.id}`, '_blank')
  }

  return (
    <div
      onContextMenu={handleContextMenu}
      className={clsx(
        'py-[25px] md:py-7.5 pl-1 border-b border-gray-500 border-solid border-x-0 border-t-0 cursor-pointer',
        isBlur && 'blur-sm'
      )}
      id={id}
    >
      <div className="flex space-x-[15px] md:space-x-7.5">
        {data?.video ? (
          <div className="w-28 h-36 rounded-xl relative">
            <img
              className="object-cover w-full h-full rounded-xl"
              src={data?.avatar ? data.avatar : DefaultAvatar}
            />
            <div
              onClick={() => setShowVideoPlayerModal(true)}
              className="absolute bottom-1 left-1 cursor-pointer"
            >
              <VideoPlayIcon />
            </div>
          </div>
        ) : (
          <div className="w-25 h-25">
            <img
              className="object-cover w-full h-full rounded-2xl"
              src={data?.avatar ? data.avatar : DefaultAvatar}
            />

            {/* <button
              onClick={handleSendMessage}
              className="text-[12px] font-bold leading-[17px] text-blue-300 flex items-center gap-2 p-3 mt-2"
            >
              <ChatIcon className="text-blue-300" />
              <span className="">Message</span>
            </button> */}
          </div>
        )}

        <div className="space-y-3 flex-1" onClick={moveToCandidateDetail}>
          <div className="flex items-center justify-between md:justify-start space-x-[9px]">
            <h6 className="text-black-100 leading-[17px] md:leading-6 text-[14px] md:text-xl font-semibold">
              {data.name}
            </h6>
            <div>
              <div className="block md:hidden">
                {applicantData?.status === 'rejected' &&
                tab.id === 'invites' ? (
                  <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#C83F3F] block w-[70px] rounded-xl mb-1 mx-auto text-center">
                    INVITE REJECTED
                  </div>
                ) : applicantData?.status === 'accepted' &&
                  tab.id === 'invites' ? (
                  <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#35BE35] block w-[110px] rounded-xl mb-1 mx-auto text-center">
                    INVITE ACCEPTED
                  </div>
                ) : applicantData?.status === 'pending' &&
                  tab.id === 'invites' ? (
                  <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#52b4da] block w-[110px] rounded-xl mb-1 mx-auto text-center">
                    INVITE PENDING
                  </div>
                ) : applicantData?.status === 'accepted' &&
                  tab.id === 'applicants' ? (
                  <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#35BE35] block w-[70px] rounded-xl mb-1 mx-auto text-center">
                    APPLICATION ACCEPTED
                  </div>
                ) : applicantData?.status === 'rejected' &&
                  tab.id === 'applicants' ? (
                  <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#C83F3F] block w-[70px] rounded-xl mb-1 mx-auto text-center">
                    APPLICATION REJECTED
                  </div>
                ) : applicantData?.status === 'pending' &&
                  tab.id === 'applicants' ? (
                  <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#35BE35] block w-[70px] rounded-xl mb-1 mx-auto text-center">
                    APPLIED
                  </div>
                ) : null}
              </div>
              <div className="inline-block uppercase text-green-100 text-[14px] md:text-xs font-bold leading-4 px-3 py-[1px] md:border-[1px] border-green-100 border-solid rounded-lg">
                {data.num_experience} Years Exp.
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center space-x-[10px] md:space-x-2.5">
            <div className="text-blue-100 leading-[18px] md:leading-4 text-[11px] md:text-sm font-semibold">
              {data.job_title}
            </div>
            <div className="w-[2px] h-[2px] bg-[#73747A]" />
            <div className="text-gray-1200 text-[11px] font-semibold leading-[18px] md:leading-4">
              {data.language}
            </div>
            <div className="w-[2px] h-[2px] bg-[#73747A]" />
            <div className="text-gray-1200 text-[11px] font-semibold leading-[18px] md:leading-4">
              {data.country}
            </div>
          </div>
          <div className="text-sm leading-6 text-gray-100 whitespace-pre-wrap hidden md:block">
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: sanitisedBio.substring(0, 200),
              }}
            />{' '}
            {sanitisedBio.length > 200 && (
              <span className="font-bold text-blue-300 cursor-pointer">
                Read More
              </span>
            )}
          </div>
          <div className="hidden md:flex flex-wrap space-x-[5px] space-y-[5px]">
            {data.skills.map((v) => (
              <div
                className="text-gray-100 leading-5 text-xs font-semibold py-1 px-4 rounded-lg border border-gray-500 border-solid"
                key={v}
              >
                {v}
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between md:hidden">
            {!data.isHide && (
              <div>
                {data.hourly_rate && data.annual_rate ? (
                  <div className="space-y-2">
                    <div className="flex space-x-[7px]">
                      <div className="text-green-100 leading-[17px] text-[14px] font-bold">
                        ${data.hourly_rate}
                      </div>
                      <div className="text-gray-100 leading-[15px] text-[12px]">
                        Per hour
                      </div>
                    </div>
                    <div className="flex space-x-[7px]">
                      <div className="text-green-100 leading-[17px] text-[14px] font-bold">
                        ${data.annual_rate}
                      </div>
                      <div className="text-gray-100 leading-[15px] text-[12px]">
                        Annual
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex space-x-[7px]">
                    <div className="text-green-100 leading-[17px] text-[14px] font-bold">
                      $
                      {data.hourly_rate
                        ? data.hourly_rate
                        : kFormatter(data.annual_rate)}
                    </div>
                    {data.hourly_rate ? (
                      <div className="text-gray-100 leading-[15px] text-[12px]">
                        per hour
                      </div>
                    ) : (
                      <div className="text-gray-100 leading-[15px] text-[12px]">
                        Annual
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="flex items-center space-x-[5px]">
              <div
                onClick={handleSendMessage}
                className="flex justify-center items-center w-[28px] h-[28px] rounded-[5px] cursor-pointer bg-blue-300"
              >
                <ChatIcon2 />
              </div>

              {/* <div className="flex justify-center items-center w-[28px] h-[28px] rounded-[5px] cursor-pointer bg-red-200">
                <CrossIcon className="text-white" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="hidden md:block md:w-[110px]">
          {applicantData?.status === 'rejected' && tab.id === 'invites' ? (
            <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#C83F3F] block w-[70px] rounded-xl mb-1 mx-auto text-center">
              INVITE REJECTED
            </div>
          ) : applicantData?.status === 'accepted' && tab.id === 'invites' ? (
            <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#35BE35] block w-[110px] rounded-xl mb-1 mx-auto text-center">
              INVITE ACCEPTED
            </div>
          ) : applicantData?.status === 'pending' && tab.id === 'invites' ? (
            <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#52b4da] block w-[110px] rounded-xl mb-1 mx-auto text-center">
              INVITE PENDING
            </div>
          ) : applicantData?.status === 'accepted' &&
            tab.id === 'applicants' ? (
            <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#35BE35] block w-[70px] rounded-xl mb-1 mx-auto text-center">
              APPLICATION ACCEPTED
            </div>
          ) : applicantData?.status === 'rejected' &&
            tab.id === 'applicants' ? (
            <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#C83F3F] block w-[70px] rounded-xl mb-1 mx-auto text-center">
              APPLICATION REJECTED
            </div>
          ) : applicantData?.status === 'pending' && tab.id === 'applicants' ? (
            <div className="px-2 py-1 font-semibold text-[10px] text-white bg-[#35BE35] block w-[70px] rounded-xl mb-1 mx-auto text-center">
              APPLIED
            </div>
          ) : null}

          {!data.isHide && (
            <div>
              {data.hourly_rate && data.annual_rate ? (
                <div className="space-y-2">
                  <div className="rounded-xl border border-solid border-gray-500 bg-[#F7F8FC] py-[12px]">
                    <div className="text-green-100 leading-6 text-[20px] font-bold text-center">
                      ${data.hourly_rate}
                    </div>
                    <div className="text-gray-100 text-xs leading-4 font-medium text-center">
                      per hour
                    </div>
                  </div>
                  <div className="rounded-xl border border-solid border-gray-500 bg-[#F7F8FC] py-[12px]">
                    <div className="text-green-100 leading-6 text-[20px] font-bold text-center">
                      ${kFormatter(data.annual_rate)}
                    </div>
                    <div className="text-gray-100 text-xs leading-4 font-medium text-center">
                      Annual
                    </div>
                  </div>
                </div>
              ) : (
                <div className="rounded-xl border border-solid border-gray-500 bg-[#F7F8FC] py-[12px]">
                  <div className="text-green-100 font-bold text-[26px] text-center">
                    $
                    {data.hourly_rate
                      ? data.hourly_rate
                      : kFormatter(data.annual_rate)}
                  </div>
                  {data.hourly_rate ? (
                    <div className="text-center font-medium text-gray-100 leading-4 text-xs">
                      per hour
                    </div>
                  ) : (
                    <div className="text-center font-medium text-gray-100 leading-4 text-xs">
                      Annual
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {/* <div className="rounded-xl bg-green-100 mt-2.5">
            <div
              onClick={handleSendMessage}
              className="flex items-center justify-center py-[11px] space-x-2 cursor-pointer"
            >
              <ChatIcon />
              <span className="text-sm font-bold leading-[17px] text-white">
                Message
              </span>
            </div>
          </div> */}
          {applicantData?.status === 'pending' && tab.id !== 'invites' && (
            <div
              onClick={onReject}
              className="bg-[#C83F3F] h-[28px] rounded-xl text-xs font-bold leading-5 text-center mt-[5px] cursor-pointer text-white py-1 px-6 flex justify-center items-center"
            >
              <CrossIcon className="text-white" />
              Deny
            </div>
          )}
          {/* {applicantData?.status === 'rejected' && (
            <div
              onClick={onAccept}
              className="bg-[#808191] h-[28px] rounded-xl text-xs font-bold leading-5 text-center mt-[5px] cursor-pointer text-white py-1 px-3 flex justify-center items-center"
            >
              <CrossIcon className="text-white" />
              Un-Deny
            </div>
          )} */}
          {/* {applicantData?.status === 'pending' && tab.id !== 'invites' && (
            <div
              onClick={onAccept}
              className="bg-[#1E3E85] h-[28px] rounded-xl text-white text-xs font-bold leading-5 text-center mt-[5px] cursor-pointer py-1 flex justify-center items-center space-x-2"
            >
              <CheckIcon />
              <span>Accept</span>
            </div>
          )} */}
          {/* {applicantData?.status === 'rejected' && (
            <div
              onClick={onAccept}
              className="bg-[#1E3E85] h-[28px] rounded-xl text-white text-xs font-bold leading-5 text-center mt-[5px] cursor-pointer py-1 flex justify-center items-center space-x-2"
            >
              <CheckIcon />
              <span>Accept</span>
            </div>
          )} */}
        </div>
      </div>
      <div className="flex flex-wrap md:hidden mt-[20px] text-gray-100 text-[13px] leading-[20px]">
        <div
          class="content"
          dangerouslySetInnerHTML={{
            __html: data.bio,
          }}
        />
      </div>
      <div className="flex flex-wrap md:hidden mt-[20px]">
        {data.skills.map((v) => (
          <div
            className="mr-[5px] mb-[5px] text-gray-100 leading-5 text-[12px] md:text-xs font-semibold py-[5px] px-[15px] rounded-lg border border-gray-500 border-solid"
            key={v}
          >
            {v}
          </div>
        ))}
      </div>
      <VideoPlayerModal
        isOpen={showVideoPlayerModal}
        closeModal={() => setShowVideoPlayerModal(false)}
        url={data.video}
      />
    </div>
  )
}
