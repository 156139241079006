import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import { getUser } from 'app/store/profileSlice'

export default function Badge({ v }) {
  const profile = useSelector(getUser)

  const person = _.find(v.people, (p) => p.person.username === profile.id)

  if (!person) return null

  const lastMessage = v.last_message.id - person.last_read

  if (lastMessage === 0) return null
  return (
    <div
      id="chat-channel-badge"
      className="right-2 md:right-6 top-1 md:top-6 text-white leading-4 text-xs font-semibold absolute bg-green-100 w-3 h-3 md:w-8 md:h-8 rounded-full flex justify-center items-center"
    />
  )
}
