//packages
import React, { useCallback, useEffect, useState } from 'react'

//custom hooks
import useTagList from 'hooks/useTagList'

//custom components
import { CrossIcon } from 'components/Icons'
import SearchAutoComplete from 'components/Select/SearchAutoComplete'

export default function SearchFilter({
  filter,
  setFilter,
  disabled = false,
  onShowFilterModal,
}) {
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_jobs_keywords')) ?? []
  const [keywords, setKeywords] = useState(keywordsStored)
  const [key, setKey] = useState(null)
  const [skillsTags] = useTagList()

  useEffect(() => {
    if (keywords.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['skills']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          skills: keywords,
        }
      })
    }
  }, [keywords])

  return (
    <div className="space-y-6">
      <SearchAutoComplete
        disabled={disabled}
        data={skillsTags.map((v) => {
          return { id: v.id, name: v.label }
        })}
        value={key}
        onChange={(value) => {
          if (keywords.includes(value.id)) {
            setKeywords((prev) => prev.filter((v) => v !== value.id))
            const filterRemoveVal = keywords.filter((v) => v !== value.id)
            localStorage.setItem(
              'filter_jobs_keywords',
              JSON.stringify(filterRemoveVal)
            )
          } else {
            setKeywords((prev) => [...prev, value.id])
            const unionVal = [...keywords, value.id]
            localStorage.setItem(
              'filter_jobs_keywords',
              JSON.stringify(unionVal)
            )
          }
        }}
        placeholder="Search job skills and job title tags"
        onShowFilterModal={onShowFilterModal}
      />
      <div className="space-y-[12px] md:space-y-3">
        {keywords.map((key) => (
          <div
            key={key}
            className="group hover:border-blue-100 transition-all cursor-pointer duration-200 hover:bg-blue-100 inline-flex py-[6px] px-[17px] border-[2px] border-solid border-gray-500 rounded-xl space-x-[15px] box-border mr-[7px]"
          >
            <span className="leading-[20px] text-[12px] md:text-sm group-hover:text-white text-black-100 font-semibold items-center flex">
              {key}
            </span>
            <div
              className="cursor-pointer"
              onClick={() => {
                setKeywords((prev) => prev.filter((v) => v !== key))
                const filterRemoveVal = keywords.filter((v) => v !== key)
                localStorage.setItem(
                  'filter_jobs_keywords',
                  JSON.stringify(filterRemoveVal)
                )
              }}
            >
              <CrossIcon className="text-[#000000] group-hover:text-white" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
