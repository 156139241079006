import React from 'react'
import { useSelector } from 'react-redux'

//redux
import { getUser } from 'app/store/profileSlice'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function EmployerProfileContainer() {
  const profile = useSelector(getUser)

  if (!profile) return null

  return (
    <div>
      <Helmet>
        <title>Job Seeker Profile - Remoterecruit.com</title>
        <meta
          name="description"
          content="Enhance your profile to showcase your skills and experience to potential employers."
        />
      </Helmet>
      <Outlet />
    </div>
  )
}
