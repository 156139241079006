import React from 'react'

export default function PencilWithRectIcon({
  className = 'text-green-100',
  size = 24,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2770_14967)">
        <path
          d="M18.9999 12.046C18.447 12.046 18 12.494 18 13.0459V21.046C18 21.5969 17.5519 22.0459 17.0001 22.0459H3C2.44794 22.0459 2.00006 21.5969 2.00006 21.046V7.0459C2.00006 6.49493 2.44794 6.04596 3 6.04596H11.0001C11.553 6.04596 12 5.5979 12 5.04602C12 4.49396 11.553 4.0459 11.0001 4.0459H3C1.34601 4.0459 0 5.39191 0 7.0459V21.046C0 22.7 1.34601 24.046 3 24.046H17.0001C18.6541 24.046 20.0001 22.7 20.0001 21.046V13.0459C20.0001 12.4929 19.5529 12.046 18.9999 12.046Z"
          fill="currentColor"
        />
        <path
          d="M9.37588 11.1351C9.30594 11.2051 9.25888 11.2941 9.23892 11.39L8.53195 14.9261C8.49899 15.09 8.55099 15.259 8.66891 15.3781C8.76394 15.4731 8.89194 15.524 9.02304 15.524C9.0549 15.524 9.08804 15.5211 9.121 15.5141L12.656 14.8071C12.754 14.787 12.843 14.7401 12.912 14.67L20.824 6.758L17.289 3.22314L9.37588 11.1351Z"
          fill="currentColor"
        />
        <path
          d="M23.2678 0.778152C22.293 -0.196884 20.7069 -0.196884 19.7328 0.778152L18.3489 2.16206L21.8839 5.6971L23.2678 4.313C23.7399 3.84206 23.9999 3.214 23.9999 2.54604C23.9999 1.87807 23.7399 1.25002 23.2678 0.778152Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2770_14967">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
