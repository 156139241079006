import UserServiceClient from 'app/clients/userService'
/**
 * userId: string
 * premium: boolean
 */
export const updateUserPlan = (data) => {
  return UserServiceClient.post('/user/update-employer-plan', data)
}

export const updateUserPassword = (data, userId) => {
  return UserServiceClient.put(`/user/update-password/${userId}`, data)
}

export const sendEmailCode = ({ userId, data }) => {
  return UserServiceClient.post(`/user/send-code/${userId}`, data)
}

export const logout = (data) => {
  return UserServiceClient.post(`/user/logout`, data)
}

export const verifyEmailCode = ({ userId, data }) => {
  return UserServiceClient.post(`/user/verify-code/${userId}`, data)
}
