export const DURATION_LIST = [
  { id: 'part-time', name: 'Part-time' },
  { id: 'full-time', name: 'Full-time' },
  { id: 'contract', name: 'Contract/Freelance' },
]

export const COMPANIES_LIST = [
  { name: '1-10', id: '1-10' },
  { name: '11-50', id: '11-50' },
  { name: '51-200', id: '51-200' },
  { name: '200-500', id: '200-500' },
  { name: '500-1000', id: '500-1000' },
  { name: '1000-5000', id: '1000-5000' },
  { name: '5000-10000', id: '5000-10000' },
  { name: '10000+', id: '10000+' },
]

export const PAGINATION_LIST = [
  { name: 25, id: '25' },
  { name: 50, id: '50' },
  { name: 75, id: '75' },
  { name: 100, id: '100' },
]
