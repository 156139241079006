//packages
import { getNames } from 'country-list'
import languageNameMap from 'language-name-map/map'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { v4 } from 'uuid'
import AWS from 'aws-sdk'
import _ from 'lodash'
import clsx from 'clsx'
import heic2any from 'heic2any'
import { Editor } from '@tinymce/tinymce-react'

//custom components
import AuthService from 'app/services/auth.service'
import { CrossIcon, PlusIcon } from 'components/Icons'
import TextArea from 'components/Input/TextArea'
import TextField from 'components/Input/TextField'
import AutoComplete from 'components/Select/AutoComplete'
import { SpinIcon } from 'components/Icons'
// import CroppingImageModal from 'components/Dialog/CroppingImageModal'
import CropImageModal from 'components/Dialog/CropImageModal'
import TagInput from 'components/Input/TagInput'

//functions & assets
import {
  fetchBlobFromUrl,
  blobToBase64,
  generateRandomString,
} from 'app/utils/function'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import Tooltip from 'components/Tooltip'

export default function AboutForm(props) {
  const [avatarUploading, setAvatarUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [uploader, setUploader] = useState([])
  const [maxSizeError, setMaxSizeError] = useState(false)
  // const [uploadedBytes, setUploadedBytes] = useState(0)
  // console.log(uploadedBytes)
  const [videoUploading, setVideoUploading] = useState(false)
  const [isOpenCroppingImageModal, setIsOpenCroppingImageModal] =
    useState(false)
  const [avatarFileData, setAvatarFileData] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  const onEdit = props.edit
  const formik = props.formik

  async function handleCroppedAvatarUpload(url) {
    try {
      const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
      const REGION = process.env.REACT_APP_AWS_REGION

      setAvatarUploading(true)
      const result = await fetchBlobFromUrl(url)
      // const fileContent = await blobToBase64(result)
      setIsOpenCroppingImageModal(false)

      const fileName = generateRandomString(8) + '.png'
      const file = new File([result], fileName, { type: 'image/png' })

      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: REGION,
      })

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
        ContentType: file.type,
      }

      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err)
        } else {
          console.log('File uploaded successfully:', data)

          const uploadedFileName = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`
          formik.setFieldValue('avatar', uploadedFileName)
          setAvatarUploading(false)
        }
      })

      // const param = {
      //   fileContent,
      //   fileName: `${v4()}.${fileExtension}`,
      // }
      // let response = await AuthService.uploadFileToS3(param)
      // formik.setFieldValue('avatar', response.data.url)
    } catch (err) {
      console.log(err)
    }
  }

  // Declare the abortController outside the function scope
  let abortController = new AbortController()

  async function handleVideoUploadChange(e, type) {
    const file = e.target.files[0]

    const MAX_FILE_SIZE = 500 * 1024 * 1024
    if (file && file.size > MAX_FILE_SIZE) {
      // File size exceeds the limit, show an error message or handle it accordingly
      setMaxSizeError(true)
      return
    }

    setProgress(0)
    setMaxSizeError(false)
    setVideoUploading(true)
    const totalBytes = file.size
    console.log(totalBytes)

    if (!file) {
      return
    }

    const signal = abortController.signal
    const currentUploaders = []

    try {
      const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
      const REGION = process.env.REACT_APP_AWS_REGION
      const PART_SIZE = 5 * 1024 * 1024 // 5 MB part size
      const uploadedBytesPerPart = Array(Math.ceil(file.size / PART_SIZE)).fill(
        0
      ) // Initialize an array to track uploaded bytes for each part
      let uploadedBytes = 0

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      })

      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      })

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
      }

      const uploadIdResp = await s3.createMultipartUpload(params).promise()
      const uploadId = uploadIdResp.UploadId
      const numParts = Math.ceil(file.size / PART_SIZE)

      const uploadPromises = Array.from({ length: numParts }, (_, index) => {
        const start = index * PART_SIZE
        const end = Math.min(start + PART_SIZE, file.size)
        const partNumber = index + 1
        const part = file.slice(start, end)

        const partParams = {
          Bucket: S3_BUCKET,
          Key: file.name,
          UploadId: uploadId,
          PartNumber: partNumber,
          Body: part,
        }

        return new Promise((resolve, reject) => {
          const uploadRequest = s3.uploadPart(partParams)
          currentUploaders.push(uploadRequest)

          // Attach event listeners for progress and cancellation
          uploadRequest.on('httpUploadProgress', (progress) => {
            // Handle progress event
            // Calculate the total uploaded bytes and update UI here
            uploadedBytesPerPart[partNumber - 1] = progress.loaded
            uploadedBytes = uploadedBytesPerPart.reduce((a, b) => a + b, 0) // Calculate total uploaded bytes

            if (totalBytes > 0) {
              const percentUploaded = Math.round(
                (uploadedBytes / totalBytes) * 100
              )

              setProgress(percentUploaded)
              console.log(`Overall upload progress: ${percentUploaded}%`)
            }
          })

          // Listen for abort event and reject the promise if aborted
          signal.addEventListener('abort', () => {
            uploadRequest.abort()
            reject(new Error('Upload aborted'))
          })

          uploadRequest.send((err, data) => {
            if (err) {
              reject(err)
            } else {
              resolve({
                ETag: data.ETag,
                PartNumber: partNumber,
              })
            }
          })
        })
      })

      setUploader([...uploader, ...currentUploaders]) // Update the uploaders state

      const uploadedParts = await Promise.all(uploadPromises)

      const completedParams = {
        Bucket: S3_BUCKET,
        Key: file.name,
        UploadId: uploadId,
        MultipartUpload: {
          Parts: uploadedParts,
        },
      }

      await s3.completeMultipartUpload(completedParams).promise()

      const objectUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`
      formik.setFieldValue(type, objectUrl)
      setVideoUploading(false)
    } catch (err) {
      console.error('Error uploading video:', err)
      setVideoUploading(false)
    }
  }

  // Function to abort the upload process
  function abortUpload() {
    uploader.forEach((uploadRequest) => {
      uploadRequest.abort() // Abort individual upload requests
    })
    abortController.abort() // Abort all requests associated with this controller
    console.log('Upload aborted')
    formik.setFieldValue('video', '')
    setVideoUploading(false)
    setUploader([]) // Reset uploaders state array
    abortController = new AbortController() // Reset AbortController instance
  }

  const convertHEICToJpeg = async (heicFile) => {
    try {
      const arrayBuffer = await heicFile.arrayBuffer()
      const blob = await heic2any({
        blob: new Blob([new Uint8Array(arrayBuffer)]),
        toType: 'image/jpeg',
        quality: 0.8, // Optional: Set JPEG quality (0-1)
      })

      return URL.createObjectURL(blob)
    } catch (error) {
      console.error('HEIC to JPEG conversion failed:', error)
      return null
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      console.log(file)
      if (extension.toLowerCase() === 'heic') {
        // if (file.type !== 'image/heif') {
        //   return URL.createObjectURL(file) // File is already a JPEG
        // } else {
        //   try {
        //     const jpegURL = await convertHEICToJpeg(file)
        //     console.log('HEIC to JPEG conversion successful')
        //     // Handle the converted JPEG data as needed
        //     setAvatarFileData(jpegURL)
        //     setIsOpenCroppingImageModal(true)
        //   } catch (error) {
        //     console.error('HEIC to JPEG conversion failed:', error)
        //   }
        // }
        try {
          const jpegURL = await convertHEICToJpeg(file)
          console.log('HEIC to JPEG conversion successful')
          // Handle the converted JPEG data as needed
          setAvatarFileData(jpegURL)
          setIsOpenCroppingImageModal(true)
        } catch (error) {
          console.error('HEIC to JPEG conversion failed:', error)
        }
      } else {
        setFileExtension(extension)
        const reader = new FileReader()
        reader.onload = handleFileLoad
        reader.readAsDataURL(file)
      }
    }
  }

  const handleFileLoad = (event) => {
    const fileData = event.target.result
    setAvatarFileData(fileData)
    setIsOpenCroppingImageModal(true)
  }

  return (
    <div className="pb-[32px]">
      {!onEdit && (
        <header>
          <h3 className="text-black-100 leading-6 text-lg">
            Please tell us more about you
          </h3>
        </header>
      )}

      {/* Avatar and Video Part */}
      <div className="grid md:grid-cols-2 gap-x-4 gap-y-[20px] mt-12">
        <div className="space-y-4">
          <p className="text-gray-300 text-[12px] md:text-xs leading-4">
            Your Profile Picture
          </p>
          <div className="bg-[rgba(228,228,228,0.25)] rounded-lg p-2.5 flex space-x-4">
            {formik.values.avatar ? (
              <div className="w-[70px] h-[70px] md:w-25 md:h-25 flex-none rounded-lg relative">
                <img className="w-full h-full" src={formik.values.avatar} />
                <div
                  onClick={() => {
                    formik.setFieldValue('avatar', '')
                  }}
                  className="w-[18px] h-[18px] rounded-full bg-[#fffffd] flex items-center justify-center absolute top-[5px] right-[5px] cursor-pointer"
                >
                  <CrossIcon className="text-[#FF0000]" />
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  'cursor-pointer transition-all duration-150 w-[70px] md:w-25 h-[70px] md:h-25 flex justify-center items-center border-[1px] border-gray-500 hover:border-green-100 border-dashed rounded-lg group flex-none relative',
                  avatarUploading && 'blur-sm cursor-progress'
                )}
              >
                <input
                  onChange={(e) => handleFileChange(e)}
                  disabled={avatarUploading}
                  type="file"
                  accept="image/*, .heic"
                  className="absolute w-full h-full opacity-0"
                />
                {avatarUploading ? (
                  <SpinIcon className="h-5 w-5 text-black" />
                ) : (
                  <PlusIcon className="text-[#E0E5F9] group-hover:text-green-100" />
                )}
              </div>
            )}
            <div className="text-gray-300 text-[12px] md:text-sm leading-[18px] md:leading-4 flex items-center">
              Upload your profile picture
            </div>
          </div>
        </div>
        <div className="space-y-[16px]">
          <div className="flex items-center gap-1">
            <p className="text-gray-300 text-[12px] md:text-xs leading-[16px] md:leading-4">
              Your Intro Video
            </p>
            <Tooltip
              text={
                <p className="p-2">
                  Profiles with intro videos are shown at the top of search
                  results for employers. Job seekers with intro videos are much
                  more likely to receive job offers.
                </p>
              }
            >
              <div className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M5.00001 0C2.2395 0 0 2.23668 0 4.99737C0 7.76071 2.2395 10 4.99999 10C7.762 10 9.99961 7.76068 9.99961 4.99737C9.99963 2.23668 7.762 0 5.00001 0ZM5.35631 7.77951C5.24019 7.88285 5.1056 7.93479 4.95314 7.93479C4.79541 7.93479 4.65781 7.88378 4.54037 7.78157C4.42273 7.67954 4.3638 7.5367 4.3638 7.35316C4.3638 7.19035 4.42084 7.05332 4.53453 6.94226C4.64823 6.8312 4.7877 6.77568 4.95314 6.77568C5.11595 6.77568 5.25299 6.8312 5.36423 6.94226C5.47529 7.05332 5.531 7.19033 5.531 7.35316C5.53079 7.53406 5.47262 7.67619 5.35631 7.77951ZM6.80432 4.18969C6.7151 4.35514 6.60914 4.49781 6.48623 4.6181C6.36369 4.73837 6.14329 4.94052 5.82517 5.22474C5.73746 5.30493 5.66688 5.37532 5.61399 5.43592C5.5611 5.49673 5.52158 5.55223 5.49578 5.60269C5.4698 5.65312 5.44986 5.70358 5.43574 5.75401C5.42161 5.80426 5.40035 5.89293 5.37155 6.01959C5.32262 6.28838 5.16884 6.42276 4.9104 6.42276C4.776 6.42276 4.66307 6.3789 4.57103 6.291C4.47935 6.2031 4.43362 6.07266 4.43362 5.8995C4.43362 5.68249 4.46731 5.49444 4.53451 5.3354C4.60134 5.17634 4.69074 5.03688 4.8018 4.9166C4.91304 4.79633 5.06286 4.65365 5.25165 4.4882C5.4171 4.34345 5.53663 4.23429 5.61022 4.16068C5.68399 4.0869 5.74593 4.00484 5.79618 3.91448C5.84682 3.82395 5.87167 3.72588 5.87167 3.61992C5.87167 3.41307 5.79507 3.23877 5.64111 3.09666C5.48733 2.95456 5.28893 2.8834 5.04593 2.8834C4.76153 2.8834 4.55222 2.95511 4.41783 3.09854C4.28343 3.24197 4.16994 3.45315 4.07676 3.73229C3.98868 4.02443 3.82191 4.17047 3.57664 4.17047C3.43189 4.17047 3.30975 4.11946 3.21018 4.01744C3.11079 3.91541 3.0611 3.80493 3.0611 3.68598C3.0611 3.44053 3.13997 3.1917 3.29752 2.93967C3.45526 2.68764 3.68527 2.47889 3.98773 2.31362C4.29001 2.14817 4.64295 2.06535 5.04593 2.06535C5.42069 2.06535 5.7514 2.13461 6.03825 2.27296C6.32511 2.41111 6.54683 2.59916 6.70325 2.83706C6.85948 3.07478 6.93777 3.33323 6.93777 3.61237C6.93814 3.83168 6.89354 4.02424 6.80432 4.18969Z"
                    fill="#52B4DA"
                  />
                </svg>
              </div>
            </Tooltip>
          </div>
          <div className="bg-[rgba(228,228,228,0.25)] rounded-lg p-[10px] flex space-x-[15px]">
            {formik.values.video ? (
              <div className="w-[100px] h-[100px] overflow-hidden flex-none relative">
                <ReactPlayer
                  url={formik.values.video}
                  width={100}
                  height={100}
                />
                <div
                  onClick={() => {
                    formik.setFieldValue('video', '')
                  }}
                  className="w-[18px] h-[18px] rounded-full bg-[#fffffd] flex items-center justify-center absolute top-[5px] right-[5px] cursor-pointer"
                >
                  <CrossIcon className="text-[#FF0000]" />
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  'cursor-pointer transition-all duration-150 w-[70px] md:w-25 h-[70px] md:h-25 flex justify-center items-center border-[1px] border-gray-500 hover:border-green-100 border-dashed rounded-lg group flex-none relative'
                )}
              >
                <input
                  disabled={videoUploading}
                  onChange={(e) => handleVideoUploadChange(e, 'video')}
                  type="file"
                  accept="video/*"
                  className="absolute w-full h-full opacity-0"
                />
                {videoUploading ? (
                  // <SpinIcon className="h-5 w-5 text-black" />
                  <div className="">
                    <div
                      onClick={abortUpload}
                      className="w-[18px] h-[18px] rounded-full bg-[#fffffd] flex items-center justify-center absolute top-[5px] right-[5px] cursor-pointer"
                    >
                      <CrossIcon className="text-[#FF0000]" />
                    </div>
                    <div class="text-[#B2B3BD] font-inter text-xs font-normal leading-4">
                      Uploading
                    </div>
                    <div class="text-[#52B4DA] text-center font-poppins font-bold text-2xl leading-6 mt-2">
                      {progress > 100 ? 100 : progress}%
                    </div>
                  </div>
                ) : (
                  <PlusIcon className="text-[#E0E5F9] group-hover:text-green-100" />
                )}
              </div>
            )}
            <div className="text-gray-300 text-[12px] md:text-sm leading-[18px] md:leading-4 flex items-center">
              Get more visible to employers with an intro video
            </div>
          </div>
          <h6>Maximum Upload Size: 500MB</h6>
        </div>
      </div>
      {maxSizeError && (
        <div class="text-center font-inter text-base font-medium leading-4 text-[#C83F3F] pt-4">
          Video file size is too large. Please upload a video less than 500 MB.
        </div>
      )}
      <div className="h-[1px] w-full my-[30px] bg-gray-200"></div>
      <div>
        {/* <TextField
          value={formik.values['name']}
          label="Your Full Name"
          name="name"
          id="name"
          onChange={formik.handleChange}
          error={formik.touched['name'] ? formik.errors['name'] : ''}
        /> */}
        <div className="grid md:grid-cols-2 gap-x-[18px] space-y-[20px] md:space-y-0 my-5">
          <div>
            <TagInput
              label="Language You Speak"
              list={_.map(languageNameMap, (v, k) => {
                return {
                  id: v.name,
                  name: v.name,
                }
              })}
              placeholder="Select Languages"
              name="languages"
              id="languages"
              onChange={(value) => {
                formik.setFieldValue('language', value)
              }}
              value={formik.values['language']}
            />
          </div>
          <AutoComplete
            data={_.map(getNames(), (v, k) => {
              return {
                id: v,
                name: v,
              }
            })}
            label="Country"
            value={formik.values['country']}
            name="country"
            id="country"
            onChange={(value) => {
              formik.setFieldValue('country', value)
            }}
            error={formik.touched['country'] ? formik.errors['country'] : ''}
          />
        </div>
        {/* <TextField
          name="website"
          value={formik.values['website']}
          label="Website"
          id="website"
          className="mt-5"
          onChange={formik.handleChange} error={formik.touched["website"] ? formik.errors["website"] : ""}
        /> */}
        {/* <TextArea
          name="bio"
          value={formik.values['bio']}
          label="Bio"
          id="bio"
          className="mt-5"
          onChange={formik.handleChange}
          error={formik.touched['bio'] ? formik.errors['bio'] : ''}
        /> */}
        {/* <Editor
          apiKey='9grr4uefxu0r2uuiir8y6v503y2jfccj2t325h3m2ly34s0n'
          // onInit={(evt, editor) => editorRef.current = editor}
          onEditorChange={(content, editor) => {
            formik.handleChange({ target: { name: 'bio', value: content } })
          }}
          value={formik.values['bio']}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        /> */}
        <RichTextEditor
          name="bio"
          value={formik.values.bio}
          onChange={formik.handleChange}
          placeholder="Enter your Bio"
        />
        {formik.errors['bio'] && formik.touched['bio'] ? (
          <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-3">
            {formik.errors['bio']}
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <CroppingImageModal
        image={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      /> */}
      <CropImageModal
        imageData={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      />
    </div>
  )
}
