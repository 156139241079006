import clsx from 'clsx'
import React, { useState } from 'react'

import DefaultAvatar from 'assets/images/default-avatar.png'

export default function Avatar({ className, variant, image }) {
  const [error, setError] = useState(false)
  let commonClassName = ''

  const imageOnError = (event) => {
    setError(true)
  }

  switch (variant) {
    case 'circle':
      commonClassName = 'rounded-full'
      break
    case 'job':
      commonClassName = 'p-[4px] border-[3px] rounded-full border-solid'
    default:
      break
  }
  return (
    <div className={clsx(commonClassName, className)}>
      {image ? (
        <img
          onError={imageOnError}
          className="w-full h-full rounded-full object-cover"
          src={error ? DefaultAvatar : image}
          alt="job-avatar"
        />
      ) : (
        <img
          className="w-full h-full rounded-full object-cover"
          src={DefaultAvatar}
          alt="job-avatar"
        />
      )}
    </div>
  )
}
