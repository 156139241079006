import React from 'react'

export default function ChvronIcon({
  className = 'text-[rgba(255,255,255,0.2)]',
  size = 10,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1L5 5L1 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
