//packages
import { Popover } from '@headlessui/react'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { getMessages, sendMessage, deleteChat } from 'react-chat-engine'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

//custom components
import Button from 'components/Button'
import { DeleteIcon, DetailPopoverIcon } from 'components/Icons'
import { BlockIcon } from 'components/Icons'

//utils & function
import { stripTags } from 'app/utils/function'
import Avatar from 'components/Avatar'
import ChatForm from './ChatForm'
import { useSelector } from 'react-redux'
import { getUser, getProfile } from 'app/store/profileSlice'
import clsx from 'clsx'
import _ from 'lodash'
import { DownloadIcon } from 'components/Icons'
import AvatarRenderer from './AvatarRenderer'
import NameRenderer from './NameRenderer'

export default function ({
  selectedChat,
  messages = [],
  setMessages,
  connected,
  chats,
}) {
  const navigate = useNavigate()
  const [newMessage, setNewMessage] = useState('')
  const [freelancer, setFreelancer] = useState()
  const [nameSender, setNameSender] = useState('')
  const [idSender, setIdSender] = useState('')
  const [image, setImage] = useState()

  const bottomRef = useRef(null)
  const profile = useSelector(getUser)

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  useEffect(async () => {
    if (selectedChat) {
      try {
        const headers = {
          projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
          chatID: selectedChat.id,
          chatAccessKey: selectedChat.access_key,
        }
        getMessages(headers, selectedChat.id, (_, result) => {
          setMessages(result)
        })

        // get sender name
        let freelancerData = _.find(
          selectedChat.people,
          (v) => v.person.username !== profile.id
        )
        setFreelancer(freelancerData)
        await getProfile(freelancerData.person.username).then((response) => {
          setIdSender(response.data.user.id)
          if (response.data.user.emType === 'company') {
            setNameSender(response.data.user.companyName)
          } else if (response.data.user.emType === 'individual') {
            let nameIndi =
              response.data.user.firstName + ' ' + response.data.user.lastName
            setNameSender(nameIndi)
          } else {
            setNameSender(response.data.user.name)
          }
        })
      } catch (err) {
        console.log('debug: err', err)
      }
    }
  }, [selectedChat, chats])

  const onFileAttach = (file) => {
    setImage(file)
    setNewMessage('Attached an image')
  }

  const handleSendMessage = useCallback(async () => {
    let chatConfig = {
      userSecret: profile.id,
      userName: profile.id,
      projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
    }
    if (image) {
      await sendMessage(chatConfig, selectedChat.id, {
        text: newMessage,
        files: [image],
        sender_username: 'matthew',
      })
    } else {
      await sendMessage(chatConfig, selectedChat.id, {
        text: newMessage,
        files: [],
        sender_username: 'matthew',
      })
    }
    setNewMessage('')
    setImage()
  }, [newMessage])

  const deleteChatHandler = () => {
    let headerConfig = {
      userSecret: profile.id,
      userName: profile.id,
      projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
    }
    deleteChat(headerConfig, selectedChat.id)
  }

  const onClickProfile = () => {
    navigate(`/employer/profile/${idSender}`)
  }

  if (!selectedChat || !connected) return null

  return (
    <div className="mt-[20px] md:mt-0 relative bg-white rounded-3xl py-[20px] px-[15px] md:px-8 md:py-8 md:ml-7 md:mr-15 w-full max-h-[calc(100vh-100px)] flex flex-col">
      <div className={`${messages.length === 0 ? 'h-96' : ''}`}>
        <div className="justify-between flex">
          <p
            onClick={onClickProfile}
            className="text-black-100 leading-[22px] md:leading-8 text-[16px] md:text-2xl font-medium cursor-pointer"
          >
            {nameSender}
          </p>
          <Popover className="relative">
            <Popover.Button>
              <DetailPopoverIcon className="cursor-pointer text-black-100" />
            </Popover.Button>

            <Popover.Panel className="absolute z-10 right-0 bg-white rounded-lg shadow-popover w-[206px] md:w-56 p-[10px] space-y-1">
              <Button
                onClick={deleteChatHandler}
                style={{ justifyContent: 'flex-start' }}
                className="w-full justify-start rounded-md text-[13px] md:text-sm flex items-center space-x-3 px-3"
                variant="secondary"
              >
                <DeleteIcon />
                <span>Delete Conversation</span>
              </Button>
              <Button
                style={{ justifyContent: 'flex-start' }}
                className="w-full  rounded-md text-[13px] md:text-sm flex items-center space-x-3 justify-start px-3"
                variant="error"
              >
                <BlockIcon />
                <span>Block User</span>
              </Button>
            </Popover.Panel>
          </Popover>
        </div>
        <div className="text-[12px] md:text-base leading-[15px] md:leading-6 text-gray-100">
          {selectedChat?.title}
        </div>
      </div>

      {/* Main Panel for the Message */}
      {messages.length > 0 && (
        <div
          className="mt-10 flex-1 overflow-auto space-y-[5px]"
          style={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto' }}
        >
          {messages.map((v) => (
            <div
              className="flex space-2 space-x-[11px] md:space-x-0"
              key={v.id}
            >
              <div className="w-18 h-18 md:w-21 md:h-21 p-3">
                {/* <Avatar src={v.sender.avatar} /> */}
                <AvatarRenderer sender={v.sender} />
              </div>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <NameRenderer sender={v.sender} />

                  <div className="text-gray-300 leading-[20px] md:leading-4 text-[12px] md:text-xs leading-medium">
                    {moment(v.created).fromNow()}
                  </div>
                </div>
                <div
                  className={clsx(
                    profile.id === v.sender.username
                      ? 'bg-blue-200 text-white'
                      : 'text-gray-100 bg-[rgba(228,228,228,0.25)]',
                    'text-[14px] md:text-sm rounded-t-3xl rounded-br-md rounded-bl-3xl py-3 px-6'
                  )}
                >
                  {stripTags(v.text)}
                  {v.attachments.length !== 0 &&
                    v.attachments.map((img) => (
                      <div className="w-24 h-24 rounded-md overflow-hidden">
                        <img
                          src={img.file}
                          alt="img"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
          <div ref={bottomRef} />
        </div>
      )}

      <ChatForm
        handleSendMessage={handleSendMessage}
        value={newMessage}
        setValue={setNewMessage}
        freelancer={freelancer}
        onFileAttach={onFileAttach}
      />
    </div>
  )
}
