//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//custom components
import Button from 'components/Button'
import Checkbox from 'components/Checkbox/Checkbox'
import { ModalCloseIcon } from 'components/Icons'
import TextArea from 'components/Input/TextArea'

//redux
import { createApplication, resetAllStatus } from 'app/store/applicantsSlice'

export default function WithdrawConfirmModal({
  isOpen,
  closeModal,
  handleWithdraw,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[ #1B1D21] bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[480px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white py-[50px] md:py-[95px] px-[20px] md:px-[50px] relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer w-[50px] h-[50px]" />
                  </div>
                  <h3 className="text-black-100 text-[18px] md:text-[24px] leading-[24px] md:leading-[36px] font-semibold text-center">
                    Are you sure want to withdraw your application?
                  </h3>
                  <div className="md:flex md:space-x-4 space-y-4 md:space-y-0 justify-center mt-9">
                    <Button
                      variant="secondary"
                      className="leading-5 font-bold text-sm px-16 w-full md:w-auto"
                      onClick={handleWithdraw}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="error"
                      className="leading-5 font-bold text-sm px-16 w-full md:w-auto"
                      onClick={closeModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
