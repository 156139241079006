import React from 'react'

export default function BlockIcon({ size = 14, className = 'text-white' }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99998 0C3.13251 0 0 3.13251 0 6.99998C0 10.8675 3.13251 14 6.99998 14C10.8675 14 14 10.8675 14 6.99998C14 3.13248 10.8675 0 6.99998 0ZM1.40001 6.99998C1.40001 3.906 3.906 1.39998 7.00002 1.39998C8.29503 1.39998 9.48153 1.84449 10.43 2.57949L2.57949 10.43C1.84449 9.48149 1.40001 8.29499 1.40001 6.99998ZM6.99998 12.6C5.70497 12.6 4.51848 12.1555 3.57 11.4205L11.4205 3.57C12.1555 4.51851 12.6 5.70501 12.6 6.99998C12.6 10.094 10.094 12.6 6.99998 12.6Z"
        fill="white"
      />
    </svg>
  )
}
