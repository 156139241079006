//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

//custom components
import TextField from 'components/Input/TextField'
import Button from 'components/Button'
import { AnimateSpinIcon } from 'components/Icons'

//assets
import { getCharacterValidationError } from 'app/utils/function'
import { useSelector } from 'react-redux'
import { getUser } from 'app/store/profileSlice'
import { updateUserPassword } from 'app/services/user.service'

const schema = yup.object().shape({
  currentPassword: yup.string().required('Please enter your password.'),
  newPassword: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
  confirmPassword: yup
    .string()
    .label('confirm password')
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})

export default function UpdatePasswordModal({ isOpen, closeModal }) {
  const profile = useSelector(getUser)
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async (values) => {
    formik.setSubmitting(true)
    try {
      const data = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      }
      const result = await updateUserPassword(data, profile.id)
      toast.success('Password updated successfully')
      formik.resetForm()
      closeModal()
    } catch (err) {
      if (err?.response?.status === 404) {
        toast.error('Your current password input is wrong')
      } else {
        toast.error('Server Error, Please try again')
      }
    } finally {
      formik.setSubmitting(false)
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#18326A] bg-opacity-90">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[420px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white rounded-2xl mx-[13px] pt-[40px] px-[58px] pb-[50px] space-y-5">
                  <h3 className="text-black-100 text-[24px] font-semibold text-center mt-2.5">
                    Update Password
                  </h3>
                  <div className="space-y-5">
                    <div className="space-y-[10px]">
                      <TextField
                        label="Current Password"
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        error={
                          formik.touched['currentPassword']
                            ? formik.errors['currentPassword']
                            : ''
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="space-y-[10px]">
                      <TextField
                        type="password"
                        label="New Password"
                        name="newPassword"
                        id="newPassword"
                        error={
                          formik.touched['newPassword']
                            ? formik.errors['newPassword']
                            : ''
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="space-y-[10px]">
                      <TextField
                        type="password"
                        label="Confirm Password"
                        name="confirmPassword"
                        id="confirmPassword"
                        error={
                          formik.touched['confirmPassword']
                            ? formik.errors['confirmPassword']
                            : ''
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        formik.handleSubmit()
                      }}
                      variant="secondary"
                      className="w-full"
                      disabled={formik.isSubmitting}
                    >
                      Save
                      {formik.isSubmitting && (
                        <AnimateSpinIcon className="w-6 h-6 ml-2" />
                      )}
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
