// Switch.js
import React, { useState } from 'react'
import './Switch.css' // Add this line to import the styles

const Switch = ({ isChecked, onChange, isDisabled }) => {
  return (
    <div
      className={`switch ${isChecked ? 'bg-blue-500' : ''}, ${
        isDisabled ? 'pointer-events-none filter blur' : ''
      }`}
      onClick={onChange}
    >
      <div
        className={`switch-handle ${
          isChecked ? 'translate-x-full' : 'translate-x-0'
        }`}
      ></div>
    </div>
  )
}

export default Switch
