import React from 'react'

export default function CirclePlusIcon({
  className = 'text-green-100',
  size = 13,
  circleFillColor = 'white',
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill={circleFillColor} />
      <path d="M6 4H7V9H6V4Z" fill="currentColor" />
      <path d="M4 6H9V7H4V6Z" fill="currentColor" />
    </svg>
  )
}
