import React from 'react'

export default function RotateIcon({
  className = 'text-[#52B4DA]',
  size = 24,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2510_13996)">
        <path
          d="M14.8573 6.45026C14.608 6.02026 14.1982 5.70683 13.718 5.57885C13.2377 5.45087 12.7263 5.51882 12.2961 5.76776L6.45356 9.14276C6.02292 9.39141 5.7087 9.80094 5.58 10.2813C5.4513 10.7616 5.51868 11.2734 5.76731 11.704L9.14231 17.5465C9.39096 17.9772 9.80049 18.2914 10.2808 18.4201C10.7611 18.5488 11.2729 18.4814 11.7036 18.2328L17.5461 14.8578C17.7596 14.735 17.9469 14.5713 18.0971 14.3761C18.2474 14.1809 18.3577 13.958 18.4218 13.7201C18.4859 13.4823 18.5024 13.2341 18.4706 12.9898C18.4387 12.7456 18.359 12.51 18.2361 12.2965L14.8573 6.45026ZM16.9723 13.3315C16.9595 13.3794 16.9373 13.4243 16.9071 13.4635C16.8768 13.5028 16.8391 13.5357 16.7961 13.5603L10.9536 16.9353C10.9108 16.9601 10.8635 16.9761 10.8144 16.9826C10.7654 16.989 10.7156 16.9857 10.6678 16.9728C10.6201 16.9599 10.5753 16.9377 10.5362 16.9074C10.4971 16.8772 10.4643 16.8395 10.4398 16.7965L7.06481 10.954C7.04002 10.9112 7.02393 10.8639 7.01749 10.8149C7.01105 10.7658 7.01437 10.716 7.02727 10.6683C7.04016 10.6205 7.06238 10.5758 7.09264 10.5366C7.12289 10.4975 7.16059 10.4648 7.20356 10.4403L13.0461 7.06526C13.0889 7.04047 13.1361 7.02439 13.1852 7.01794C13.2342 7.0115 13.2841 7.01482 13.3318 7.02772C13.3796 7.04061 13.4243 7.06283 13.4634 7.09309C13.5026 7.12334 13.5353 7.16104 13.5598 7.20401L16.9348 13.0465C16.9595 13.0892 16.9755 13.1364 16.982 13.1853C16.9884 13.2342 16.9851 13.2839 16.9723 13.3315Z"
          fill="currentColor"
        />
        <path
          d="M23.25 19.2037H21.5963C22.9343 17.4212 23.7494 15.3012 23.9502 13.0814C24.1511 10.8616 23.7298 8.62974 22.7334 6.63595C21.7371 4.64216 20.2052 2.96525 18.3094 1.79318C16.4136 0.621098 14.2289 0.000173912 12 0C11.8011 0 11.6103 0.0790176 11.4697 0.21967C11.329 0.360322 11.25 0.551088 11.25 0.75C11.25 0.948912 11.329 1.13968 11.4697 1.28033C11.6103 1.42098 11.8011 1.5 12 1.5C13.906 1.49963 15.7761 2.01806 17.4099 2.99969C19.0436 3.98132 20.3793 5.38913 21.2739 7.07216C22.1684 8.75519 22.5879 10.65 22.4875 12.5533C22.387 14.4566 21.7704 16.2967 20.7037 17.8762V16.6612C20.7037 16.4623 20.6247 16.2716 20.4841 16.1309C20.3434 15.9903 20.1527 15.9112 19.9537 15.9112C19.7548 15.9112 19.5641 15.9903 19.4234 16.1309C19.2828 16.2716 19.2037 16.4623 19.2037 16.6612V19.9537C19.2037 20.1527 19.2828 20.3434 19.4234 20.4841C19.5641 20.6247 19.7548 20.7037 19.9537 20.7037H23.25C23.4489 20.7037 23.6397 20.6247 23.7803 20.4841C23.921 20.3434 24 20.1527 24 19.9537C24 19.7548 23.921 19.5641 23.7803 19.4234C23.6397 19.2828 23.4489 19.2037 23.25 19.2037Z"
          fill="currentColor"
        />
        <path
          d="M12 22.4996C10.094 22.5 8.2239 21.9816 6.59015 21C4.9564 20.0183 3.62066 18.6105 2.72615 16.9275C1.83164 15.2445 1.41211 13.3497 1.51254 11.4464C1.61298 9.54304 2.22959 7.70294 3.29625 6.1234V7.3384C3.29625 7.53731 3.37527 7.72808 3.51592 7.86873C3.65657 8.00938 3.84734 8.0884 4.04625 8.0884C4.24516 8.0884 4.43593 8.00938 4.57658 7.86873C4.71723 7.72808 4.79625 7.53731 4.79625 7.3384V4.0459C4.79625 3.84699 4.71723 3.65622 4.57658 3.51557C4.43593 3.37492 4.24516 3.2959 4.04625 3.2959H0.75C0.551088 3.2959 0.360322 3.37492 0.21967 3.51557C0.0790176 3.65622 0 3.84699 0 4.0459C0 4.24481 0.0790176 4.43558 0.21967 4.57623C0.360322 4.71688 0.551088 4.7959 0.75 4.7959H2.40375C1.06574 6.57847 0.25062 8.69845 0.0497619 10.9182C-0.151096 13.138 0.270242 15.3699 1.26655 17.3637C2.26286 19.3575 3.79477 21.0344 5.69057 22.2065C7.58637 23.3785 9.77114 23.9995 12 23.9996C12.1989 23.9996 12.3897 23.9206 12.5303 23.78C12.671 23.6393 12.75 23.4486 12.75 23.2496C12.75 23.0507 12.671 22.86 12.5303 22.7193C12.3897 22.5787 12.1989 22.4996 12 22.4996Z"
          fill="currentColor"
        />
        <path
          d="M17.2914 21.079C17.1586 21.1453 17.0484 21.2496 16.9749 21.3785C16.9014 21.5075 16.8678 21.6553 16.8784 21.8034C16.8889 21.9515 16.9432 22.0931 17.0343 22.2103C17.1254 22.3275 17.2492 22.415 17.3901 22.4618C17.531 22.5086 17.6826 22.5125 17.8257 22.4731C17.9688 22.4336 18.097 22.3526 18.194 22.2403C18.291 22.128 18.3526 21.9894 18.3708 21.8421C18.389 21.6948 18.3631 21.5454 18.2964 21.4128C18.2071 21.2355 18.0512 21.1008 17.8628 21.0382C17.6745 20.9757 17.469 20.9903 17.2914 21.079Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2510_13996">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
