import clsx from 'clsx'
import React, { useState } from 'react'

export default function PasswordInput({
  label,
  value = 'password',
  valid = false,
  onChange,
  updateHandler,
  containerClassName = 'w-full space-y-2',
  inputClassName = 'disabled:bg-[rgba(228,228,228,0.3)] py-[19px] px-[18px] w-full text-black-100 text-opacity-50 rounded-lg focus-visible:outline-none focus:text-opacity-100',
}) {
  const [disabled, setDisabled] = useState(true)
  return (
    <div className={containerClassName}>
      <label
        className={clsx(
          'text-[12px] md:text-xs block',
          valid && !disabled ? 'text-blue-100' : 'text-gray-300'
        )}
      >
        {label}
      </label>
      <div className="relative">
        <input
          type="password"
          onChange={onChange}
          disabled={disabled}
          autoComplete="off"
          className={clsx(
            inputClassName,
            disabled ? 'pr-[100px]' : '',
            valid && !disabled
              ? 'border border-green-100 bg-white'
              : valid
              ? ''
              : 'border border-gray-500 bg-white'
          )}
          value={value}
          onBlur={() => setDisabled(true)}
        />
        {disabled && (
          <button
            onClick={updateHandler}
            className="text-white font-bold py-[8px] px-[19px] text-xs absolute top-[calc(50%-17px)] right-[10px] cursor-pointer bg-green-100 rounded-lg"
          >
            Update
          </button>
        )}
      </div>
    </div>
  )
}
