import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'

export default function Tab({ list, activeTab, onChange }) {
  return (
    <div className="inline-flex space-x-2">
      {list.map((item) => (
        <div
          onClick={onChange}
          key={item}
          className={clsx(
            'rounded-xl font-semibold text-[14px] md:text-sm inline-flex items-center justify-center py-2 px-4 cursor-pointer',
            item === activeTab
              ? 'bg-blue-100 text-white'
              : 'text-gray-100 leading-5'
          )}
        >
          {item}
        </div>
      ))}
    </div>
  )
}
