//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { Document, pdfjs, Page } from 'react-pdf'
import 'styles/resume.styles.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

//custom components
import { ModalCloseIcon } from 'components/Icons'

export default function ResumeModal({ isOpen, closeModal, pdfUrl }) {
  const [numPages, setNumPages] = useState(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black-background-overlay bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="">
                <div className="relative pt-10 md:w-[650px] w-[350px] max-w-[650px]">
                  <div
                    className="absolute -right-6 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon
                      size={70}
                      className="text-black-100 hover:text-green-100 cursor-pointer"
                    />
                  </div>

                  {/* Body Section */}
                  <div className="mt-6 bg-white w-full rounded-2xl max-w-[950px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                    {pdfUrl?.endsWith('.pdf') ? (
                      <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(e) => console.log(e)}
                        onLoadProgress={(e) => console.log(e)}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        ))}
                      </Document>
                    ) : (
                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdfUrl}`}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                      ></iframe>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
