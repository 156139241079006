import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import PasswordTextField from 'components/Input/PasswordTextField'
import TextField from 'components/Input/TextField'
import { AnimateSpinIcon } from 'components/Icons'

import AuthService from 'app/services/auth.service'
import { createUser } from 'app/services/chat.service'

import { setProfile } from 'app/store/profileSlice'
import { isEmailValid } from 'app/utils/function'
import Checkbox from 'components/Checkbox/Checkbox'
import authService from 'app/services/auth.service'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
  password: yup.string().required('Please enter your password.'),
})

const LoginForm = () => {
  const [success, setSuccess] = useState(false)
  const [redirectTo, setRedirectTo] = useState('/')
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    localStorage.removeItem('forgetEmail')
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const redirect = queryParams.get('redirect')
    if (redirect) {
      setRedirectTo(redirect)
    }
  }, [location])

  useEffect(() => {
    if (success) {
      //Dynamic change of the url
      const user = JSON.parse(localStorage.getItem('user'))
      // Define a mapping for user type
      const typeMapping = {
        contractor: 'jobseeker',
      }

      // Fallback to original user type if there's no mapping
      const userType = typeMapping[user.type] || user.type

      const defaultRedirectUrl = `/${userType}/profile`
      setRedirectTo(defaultRedirectUrl)
      localStorage.removeItem('redirectTo')
      // If there's a specific redirect URL from the query parameter, use it; otherwise, use the default one
      navigate(
        redirectTo !== '/'
          ? redirectTo
          : defaultRedirectUrl || defaultRedirectUrl
      )
    }
  }, [success, navigate, redirectTo])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async (values) => {
    console.log(values)
    try {
      formik.setSubmitting(true)
      const result = await AuthService.login(
        values.email,
        values.password,
        values.rememberMe
      )
      if (result.status === 200) {
        if (!localStorage.getItem('user')) {
          const params = {
            username: result.data.id,
            secret: result.data.id,
            first_name: result.data.type,
            last_name: result.data.name,
            custom_json: JSON.stringify({ image: result.data.avatar }),
          }
          createUser(params)
        }
        localStorage.setItem('token', result.data.token)
        if (values.rememberMe) {
          localStorage.setItem('refreshToken', result.data.refreshToken)
        }
        localStorage.setItem('user', JSON.stringify(result.data))
        dispatch(setProfile(result.data))
        setSuccess(true)
      }
    } catch (err) {
      toast.error(
        err.response?.data?.message ? err.response.data.message : 'Server Error'
      )
    } finally {
      formik.setSubmitting(false)
    }
  }

  const handleForgot = useCallback(() => {
    navigate('/auth/forgot-password')
  }, [navigate])

  return (
    <div className="form-main mt-10">
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-[20px] md:space-y-6">
          <TextField
            error={formik.touched['email'] ? formik.errors['email'] : ''}
            label="Email"
            name="email"
            id="name"
            onChange={(e) => {
              if (isEmailValid(e.target.value)) {
                localStorage.setItem('forgetEmail', e.target.value)
              }
              formik.setFieldValue('email', e.target.value)
            }}
          />
          <PasswordTextField
            error={formik.touched['password'] ? formik.errors['password'] : ''}
            label="Password"
            name="password"
            id="password"
            onChange={formik.handleChange}
            handleForgot={handleForgot}
            value={formik.values.password}
          />
        </div>
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="flex items-center justify-center mt-[50px] md:mt-6 bg-blue-100 hover:opacity-80 rounded-2xl font-bold text-[14px] md:text-sm text-white w-full text-center py-[18px] disabled:opacity-50"
        >
          Sign In
          {formik.isSubmitting && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
        </button>
        <div className="flex items-center mt-6">
          <Checkbox
            value={formik.values.rememberMe}
            onChange={(e) =>
              formik.setFieldValue('rememberMe', e.target.checked)
            }
            variant="primary"
            className=""
          />
          <span className="text-gray-300 block text-[12px] md:text-xs">
            Remember me
          </span>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
