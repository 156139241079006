import React from 'react'
import clsx from 'clsx'

export default function Checkbox({
  label,
  sizeClass = 'w-4 h-4',
  rectClass = 'border-blue-400 focus-within:border-blue-500 w-4 h-4',
  value = false,
  onChange,
  labelClass = '',
}) {
  return (
    <div
      onClick={onChange}
      className="flex items-center relative cursor-pointer"
    >
      <input
        type="checkbox"
        id="A3-yes"
        name="A3-confirmation"
        value="yes"
        onChange={onChange}
        checked={value}
        className={clsx('opacity-0 absolute', sizeClass)}
      />
      <div
        id="v1-checkbox"
        className={clsx(
          'bg-white border-[1px] rounded-md cursor-pointer border-solid flex flex-shrink-0 justify-center items-center mr-2',
          rectClass
        )}
      >
        <svg
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 5.375L1.5 3.375L0 4.875L3.5 8.375L10 1.875L8.5 0.375L3.5 5.375Z"
            fill="white"
          />
        </svg>
      </div>
      <div for="A3-yes" className={clsx('select-none', labelClass)}>
        {label}
      </div>
    </div>
  )
}
