import React from 'react'

function ChatBubble({
  country,
  jobPosition,
  avatar,
  flag,
  highlightField,
  highlightStyle,
}) {
  const getDynamicStyle = (field) =>
    highlightField === field ? highlightStyle : {}

  return (
    <div className="flex gap-[8.99px]">
      {/* Avatar */}
      <div className="w-[56px] h-[56px] rounded-[56px] bg-white flex items-end justify-center">
        <img
          src={avatar}
          alt={`${jobPosition} avatar`}
          className="w-full h-full object-cover rounded-full"
        />
      </div>

      {/* Chat bubble */}
      <div className="flex justify-center items-center gap-[6.914px] rounded-[16.593px] px-[10.4px] py-[10.4px] bg-[#0DA6A6]">
        <p className="text-black-100 text-center font-manrope text-[13.827px] font-normal not-italic leading-[16.593px]">
          <span style={getDynamicStyle('jobPosition')}>{jobPosition}</span>
          <span style={getDynamicStyle('country')}>{country}</span>
        </p>
        <img src={flag} alt={`${country} flag`} className="w-[16px] h-[15px]" />
      </div>
    </div>
  )
}

export default ChatBubble
