import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//custom components
import Avatar from 'components/Avatar'
import ChevronIcon from 'components/Icons/ChevronIcon'
import Badge from './Badge'
import AvatarRenderer from 'views/employer/messages/components/AvatarRenderer'
import { getUser } from 'app/store/profileSlice'

//utils
import { stripTags } from 'app/utils/function'

export default function ChannelList({
  list,
  setSelectedChat,
  selectedChatId,
  searchKey,
}) {
  const [data, setData] = useState([])
  const [showPanel, setShowPanel] = useState(true)

  const profile = useSelector(getUser)

  useEffect(() => {
    setData(
      list.filter((v) =>
        v.title.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }, [list, searchKey])

  return (
    <div>
      <div
        className="w-full flex justify-between items-center pt-[23px] pb-[18px] md:p-5 cursor-pointer"
        onClick={() => setShowPanel((prev) => !prev)}
      >
        <h5 className="text-gray-300 leading-[16px] md:leading-4 text-[12px] md:text-xs font-medium">
          Your Messages
        </h5>
        <ChevronIcon
          className={clsx(
            'text-gray-100 transition-all duration-300 hidden md:block',
            showPanel ? 'rotate-180' : ''
          )}
        />
      </div>
      {showPanel && (
        <div className="flex md:block space-x-[10px] md:space-x-0 md:space-y-2 overflow-x-scroll">
          {data.map((v) => {
            const customObj = JSON.parse(v.admin.custom_json)

            // get sender name
            let userData = _.find(
              v.people,
              (d) => d?.person?.username !== profile.id
            )
            return (
              <div className="relative">
                <div
                  onClick={() => setSelectedChat(v)}
                  className={clsx(
                    selectedChatId === v.id ? 'bg-blue-100 text-white' : '',
                    'cursor-pointer flex space-x-4 p-[2px] md:p-4 rounded-full md:rounded-2xl overflow-hidden md:border border-solid border-gray-400 hover:bg-blue-100 group'
                  )}
                  key={v.id}
                >
                  <AvatarRenderer sender={userData?.person} fromChannelList={true}/>
                  <div
                    className={clsx(
                      'hidden md:block leading-4 text-sm min-w-0 font-semibold group-hover:text-white space-y-1 items-center mt-2'
                    )}
                  >
                    <div className="truncate pr-12">
                      {stripTags(v.last_message.text)}
                    </div>
                    <div className="text-gray-300 leading-4 text-sm font-medium block">
                      {v?.last_message?.created
                        ? moment(v?.last_message?.created).fromNow()
                        : 'No messages'}
                    </div>
                  </div>
                  {/* Badges */}
                  <Badge
                    v={v}
                    className="absolute z-[30] right-0 md:right-6 top-0 md:top-6 w-[18px] md:w-8 h-[18px] md:h-8"
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
