import React from 'react'

export default function PortfolioPlaceholderIcon({ size = 100 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" clip-path="url(#clip0_7460_21195)">
      <path d="M72.7208 99.9999C72.0208 99.9999 71.3041 99.9124 70.5958 99.7249L6.16665 82.4708C1.74998 81.2541 -0.883351 76.6791 0.274982 72.2624L8.40415 41.9624C8.70415 40.8499 9.84581 40.2041 10.9541 40.4874C12.0666 40.7833 12.725 41.9291 12.4291 43.0374L4.30415 73.3291C3.72498 75.5374 5.04998 77.8374 7.26248 78.4499L71.6666 95.6958C73.8792 96.2791 76.1625 94.9624 76.7375 92.7624L79.9916 80.7041C80.2916 79.5916 81.4333 78.9291 82.5458 79.2333C83.6583 79.5333 84.3125 80.6791 84.0166 81.7874L80.7666 93.8291C79.7875 97.5374 76.4125 99.9999 72.7208 99.9999Z" fill="#1E3E85"/>
      <path d="M91.666 74.9999H24.9993C20.4035 74.9999 16.666 71.2624 16.666 66.6666V16.6666C16.666 12.0708 20.4035 8.33325 24.9993 8.33325H91.666C96.2619 8.33325 99.9994 12.0708 99.9994 16.6666V66.6666C99.9994 71.2624 96.2619 74.9999 91.666 74.9999ZM24.9993 12.4999C22.7035 12.4999 20.8327 14.3708 20.8327 16.6666V66.6666C20.8327 68.9624 22.7035 70.8333 24.9993 70.8333H91.666C93.9619 70.8333 95.8327 68.9624 95.8327 66.6666V16.6666C95.8327 14.3708 93.9619 12.4999 91.666 12.4999H24.9993Z" fill="#1E3E85"/>
      <path d="M37.4993 37.4999C32.9035 37.4999 29.166 33.7624 29.166 29.1666C29.166 24.5708 32.9035 20.8333 37.4993 20.8333C42.0952 20.8333 45.8327 24.5708 45.8327 29.1666C45.8327 33.7624 42.0952 37.4999 37.4993 37.4999ZM37.4993 24.9999C35.2035 24.9999 33.3327 26.8708 33.3327 29.1666C33.3327 31.4624 35.2035 33.3333 37.4993 33.3333C39.7952 33.3333 41.666 31.4624 41.666 29.1666C41.666 26.8708 39.7952 24.9999 37.4993 24.9999Z" fill="#1E3E85"/>
      <path d="M19.0414 70.5416C18.5081 70.5416 17.9747 70.3374 17.5664 69.9333C16.7539 69.1208 16.7539 67.7999 17.5664 66.9874L37.2456 47.3083C39.6039 44.9499 43.7247 44.9499 46.0831 47.3083L51.9414 53.1666L68.1581 33.7083C69.3372 32.2958 71.0706 31.4749 72.9164 31.4583H72.9622C74.7872 31.4583 76.5164 32.2499 77.7081 33.6374L99.4997 59.0624C100.25 59.9333 100.15 61.2499 99.2747 61.9999C98.4039 62.7499 97.0914 62.6541 96.3372 61.7749L74.5456 36.3499C74.1414 35.8833 73.5831 35.6249 72.9622 35.6249C72.5289 35.5874 71.7664 35.8874 71.3622 36.3749L53.6831 57.5874C53.3081 58.0374 52.7622 58.3083 52.1747 58.3333C51.5831 58.3749 51.0206 58.1416 50.6081 57.7249L43.1372 50.2541C42.3497 49.4708 40.9789 49.4708 40.1914 50.2541L20.5122 69.9333C20.1081 70.3374 19.5747 70.5416 19.0414 70.5416Z" fill="#1E3E85"/>
      </g>
      <defs>
      <clipPath id="clip0_7460_21195">
      <rect width="100" height="100" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
