//packages
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//custom components
import CardBody from './components/CardBody'

//redux & apis
import { getUser } from 'app/store/profileSlice'
import { updateUserLastActivity } from 'app/services/auth.service'
import { Helmet } from 'react-helmet'

const tabs = ['Details', 'Skills & Experience', 'Pay Preferences', 'Portfolio']

export default function Setting() {
  const [selectedTab, setSelectedTab] = useState('Details')
  const profile = useSelector(getUser)
  console.log(profile)

  useEffect(async () => {
    //Update user last activity
    if (profile) {
      await updateUserLastActivity(profile.id)
    }
  }, [])

  if (!profile) return null

  return (
    <div className="md:overflow-auto md:h-screen w-full md:pb-32 ">
      <Helmet>
        <title>Job Seeker Profile Settings - Remoterecruit.com</title>
        <meta
          name="description"
          content="Update your settings to increase your visibility to top employers on Remote Recruit."
        />
      </Helmet>
      <div className="mb-[95px]">
        <div className="text-black-100 px-[15px] md:px-16 pt-[30px] md:pt-12 pb-[20px] md:pb-12 ">
          <h2 className="text-[14px] md:text-2xl">
            Welcome back, {profile?.name}!
          </h2>
          <h1 className="text-[24px] md:text-5xl font-semibold">
            Account Settings
          </h1>
        </div>
        <div className="flex md:justify-center justify-start space-x-3 mb-[24px] md:mb-10 overflow-auto pl-[15px] md:pl-0">
          {tabs.map((tab) => (
            <div
              key={tab}
              className={clsx(
                'cursor-pointer text-[12px] md:text-sm leading-5 font-semibold py-2 px-4 whitespace-nowrap',
                selectedTab === tab
                  ? 'bg-blue-100 rounded-xl text-white border-[2px] border-blue-100'
                  : 'border-[2px] border-gray-400 rounded-xl text-black-100'
              )}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center">
          <div
            className={clsx(
              'bg-white shadow-[14px_41px_100px_rgba(49,89,211,0.15)] rounded-b-3xl',
              selectedTab === 'Portfolio'
                ? 'md:max-w-7xl md:mx-[65px] mx-[15px] w-full'
                : 'md:max-w-2xl mx-[15px] w-full md:mx-auto'
            )}
          >
            <CardBody selectedTab={selectedTab} />
          </div>
        </div>
      </div>
    </div>
  )
}
