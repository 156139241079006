import React, { useState } from 'react'
import { EyeIcon, EyeCloseIcon } from 'components/Icons'

export default function PasswordTextField({
  label,
  handleForgot,
  error,
  value,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false)

  const onClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  return (
    <div className="relative space-y-[10px] md:space-y-3">
      {label && (
        <label className="block text-gray-300 text-[12px] md:text-xs">
          {label}
        </label>
      )}
      <div className="relative">
        {showPassword ? (
          <input
            {...rest}
            autoComplete="off"
            type="text"
            className="text-black-100 text-sm py-[18px] px-[23px] w-full bg-[rgba(228,228,228,0.25)] rounded-lg"
          />
        ) : (
          <input
            {...rest}
            autoComplete="off"
            type="password"
            className="text-black-100 text-sm py-[18px] px-[23px] w-full bg-[rgba(228,228,228,0.25)] rounded-lg"
          />
        )}

        <span
          tabIndex={10}
          onClick={onClickShowPassword}
          className="cursor-pointer"
        >
          {showPassword ? (
            <EyeIcon className="text-[#0049C6] absolute top-5.5 md:top-4.5 right-20 cursor-pointer" />
          ) : (
            <EyeCloseIcon fill="#0049C6" className="text-[#0049C6] absolute top-5.5 md:top-4.5 right-20 cursor-pointer" />
          )}

        </span>
        <h6
          className="text-[#0049C6] text-[12px] absolute top-5.5 md:top-4.5 right-5 cursor-pointer font-inter font-medium"
          onClick={handleForgot}
        >
          Forgot?
        </h6>
      </div>
      {error && (
        <div
          className="text-red-300 text-[12px] md:text-xs font-semibold"
          style={{ marginTop: '6px' }}
        >
          {error}
        </div>
      )}
    </div>
  )
}
