import React from 'react'

export default function MessageDownloadIcon({
  size = 100,
  className = 'text-black',
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M99.6512 55.9303L86.5116 27.7907C86.1264 27.0028 85.2911 26.5387 84.4186 26.628H73.2559C71.9714 26.628 70.9303 27.6691 70.9303 28.9536C70.9303 30.238 71.9714 31.2792 73.2559 31.2792H82.907L93.721 54.535H80.4652C74.455 54.5054 68.9891 58.0126 66.5117 63.4886C64.7174 67.2551 60.9162 69.6532 56.7442 69.6514H43.2558C39.0838 69.6532 35.2829 67.2551 33.4883 63.4886C31.0109 58.0126 25.545 54.5054 19.5348 54.535H6.5116L19.3022 31.2792H26.7441C28.0286 31.2792 29.0697 30.238 29.0697 28.9536C29.0697 27.6691 28.0286 26.628 26.7441 26.628H17.9069C17.0665 26.5483 16.2544 26.9545 15.8139 27.6745L0.465162 55.8141C0.19608 56.0527 0.0293786 56.3859 0 56.7444V85.698C0.185174 92.2151 5.4582 97.437 11.9767 97.5585H88.0233C94.542 97.437 99.8148 92.2151 100 85.698V56.7444C99.9557 56.4475 99.8355 56.1671 99.6512 55.9303ZM95.3488 85.6978C95.1681 89.6467 91.9747 92.7896 88.0233 92.9071H11.9767C8.02527 92.7896 4.8319 89.647 4.65117 85.6978V59.1862H19.5348C23.7068 59.1844 27.5078 61.5826 29.3023 65.349C31.7797 70.825 37.2457 74.3322 43.2558 74.3026H56.7442C62.7543 74.3322 68.2203 70.825 70.6977 65.349C72.492 61.5826 76.2932 59.1844 80.4652 59.1862H95.3488V85.6978Z"
          fill="currentColor"
        />
        <path
          d="M48.3722 56.279C49.197 57.1782 50.5947 57.2383 51.4936 56.4132C51.5404 56.3705 51.5851 56.3255 51.6278 56.279L67.4418 40.3487C68.2123 39.3211 68.0042 37.8635 66.9766 37.0928C66.1498 36.4727 65.0127 36.4727 64.1859 37.0928L52.3254 48.9533V4.76736C52.3254 3.48293 51.2842 2.44177 49.9998 2.44177C48.7153 2.44177 47.6742 3.48293 47.6742 4.76736V48.9535L35.8137 37.093C34.7861 36.3225 33.3285 36.5306 32.5578 37.5582C31.9377 38.385 31.9377 39.5221 32.5578 40.3489L48.3722 56.279Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
