//packages
import { getNames } from 'country-list'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import languageNameMap from 'language-name-map/map'

//custom components
import { ModalCloseIcon } from 'components/Icons'
import Checkbox from 'components/Checkbox/Checkbox'
import TextField from 'components/Input/TextField'
import AutoComplete from 'components/Select/AutoComplete'
import TagInput from 'components/Input/TagInput'

//redux
import useTagList from 'hooks/useTagList'
import NumberInput from 'components/Input/NumberInput'

export default function FilterBarModal({
  setFilter,
  setCurrentPage,
  isOpen,
  closeModal,
  disabled,
}) {
  // this is for filters that are saved
  const languageStored =
    JSON.parse(localStorage.getItem('filter_candidates_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_candidates_location')) ?? []
  const maxHourlyRateStored =
    localStorage.getItem('filter_candidates_maxHourlyRate') ?? ''
  const maxAnnualRateStored =
    localStorage.getItem('filter_candidates_maxAnnualRate') ?? ''

  const [languages, setLanguages] = useState(languageStored)
  const [locations, setLocations] = useState(locationsStored)
  // const [industries, setIndustries] = useState([])
  const [maxHourlyRate, setMaxHourlyRate] = useState(maxHourlyRateStored)
  const [enableHourly, setEnableHourly] = useState(
    maxHourlyRateStored !== '' ? true : false
  )

  const [maxAnnualRate, setMaxAnnualRate] = useState(maxAnnualRateStored)
  const [enableAnnual, setEnableAnnual] = useState(
    maxAnnualRateStored !== '' ? true : false
  )
  const [enableNegotiable, setEnableNegotiable] = useState(false)
  const [enableCoverLetterVideoIncluded, setEnableCoverLetterVideoIncluded] =
    useState(false)
  const [enableBoth, setEnableBoth] = useState(
    maxAnnualRateStored !== '' && maxHourlyRateStored !== '' ? true : false
  )
  const [typingTimeout, setTypingTimeout] = useState(null)

  // const [skillsTags, industryTags] = useTagList()
  useEffect(() => {
    if (languages.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['languages']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          languages: languages.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    }
  }, [languages])

  useEffect(() => {
    if (locations.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['countries']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          countries: locations.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    }
  }, [locations])

  // useEffect(() => {
  //   if (industries.length === 0) {
  //     setFilter((prev) => {
  //       let temp = Object.assign({}, prev)
  //       delete temp['industries']
  //       return temp
  //     })
  //   } else {
  //     setFilter((prev) => {
  //       return {
  //         ...prev,
  //         industries: industries.map((v) => v.id),
  //       }
  //     })
  //   }
  // }, [industries])

  useEffect(() => {
    if (enableHourly) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            hourly:
              maxHourlyRate.length >= 5
                ? Number(maxHourlyRate.replace(/,/g, ''))
                : Number(maxHourlyRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['hourly']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [maxHourlyRate, enableHourly])

  useEffect(() => {
    if (enableAnnual) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            annual:
              maxAnnualRate.length >= 5
                ? Number(maxAnnualRate.replace(/,/g, ''))
                : Number(maxAnnualRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['annual']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [maxAnnualRate, enableAnnual])

  const onHandleReset = () => {
    setEnableHourly(false)
    setMaxHourlyRate('')
    setEnableAnnual(false)
    setEnableNegotiable(false)
    setEnableCoverLetterVideoIncluded(false)
    setMaxAnnualRate('')
    setFilter(null)
  }

  useEffect(() => {
    // Update "Both" checkbox based on the individual checkboxes
    setEnableBoth(enableHourly && enableAnnual)
  }, [enableHourly, enableAnnual])

  const handleCheckboxChange = (checkboxType) => {
    if (checkboxType === 'hourly') {
      setEnableHourly(!enableHourly)
    } else if (checkboxType === 'annually') {
      setEnableAnnual(!enableAnnual)
    } else if (checkboxType === 'negotiable') {
      setEnableNegotiable(!enableNegotiable)
    } else if (checkboxType === 'coverLetterVideo') {
      setEnableCoverLetterVideoIncluded(!enableCoverLetterVideoIncluded)
    }
  }

  const handleBothCheckboxChange = () => {
    setEnableBoth(!enableBoth)
    setEnableHourly(!enableBoth)
    setEnableAnnual(!enableBoth)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1E3E85] bg-opacity-90">
          <div className="flex min-h-full items-center justify-center p-10 py-[59px] text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white p-8 pb-[70px] relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon
                      size={70}
                      className="text-[#52B4DA] hover:text-green-100 cursor-pointer"
                    />
                  </div>
                  <div>
                    <div className="text-black-100 leading-[24px] text-[18px] font-bold">
                      Filter
                    </div>
                    <p className="font-inter text-black-100 font-bold text-[14px] leading-[20px] mt-[25px]">
                      Pay
                    </p>
                    <div className="space-y-[30px] mt-[21px]">
                      <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Hourly"
                          value={enableHourly}
                          onChange={() => handleCheckboxChange('hourly')}
                          disabled={disabled}
                        />
                        <div className="space-y-[10px]">
                          <NumberInput
                            placeholder=""
                            value={maxHourlyRate}
                            prefix="$"
                            suffix="USD"
                            onChange={(e) => {
                              setEnableHourly(e.target.value.length > 0)
                              setMaxHourlyRate(e.target.value)
                              localStorage.setItem(
                                'filter_candidates_maxHourlyRate',
                                e.target.value
                              )
                            }}
                            // type="number"
                            disabled={disabled}
                          />
                          <p className="font-inter font-bold leading-[16px] text-[12px] text-gray-300">
                            Max Hourly Rate
                          </p>
                        </div>
                      </div>
                      <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Annual Salary"
                          value={enableAnnual}
                          onChange={() => handleCheckboxChange('annually')}
                          disabled={disabled}
                        />
                        <div className="space-y-[10px]">
                          <NumberInput
                            value={maxAnnualRate}
                            prefix="$"
                            suffix="USD"
                            onChange={(e) => {
                              setEnableAnnual(e.target.value.length > 0)
                              setMaxAnnualRate(e.target.value)
                              localStorage.setItem(
                                'filter_candidates_maxAnnualRate',
                                e.target.value
                              )
                            }}
                            placeholder=""
                            // type="number"
                            disabled={disabled}
                          />
                          <p className="font-inter font-bold leading-[16px] text-[12px] text-gray-300">
                            Max Annual Salary
                          </p>
                        </div>
                      </div>
                      <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Both Hourly & Salary"
                          value={enableBoth}
                          onChange={handleBothCheckboxChange}
                          disabled={disabled}
                        />
                      </div>
                      <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Negotiable"
                          value={enableNegotiable}
                          onChange={() => handleCheckboxChange('negotiable')}
                          disabled={disabled}
                        />
                      </div>
                      <div className="space-y-[15px]">
                        <p className="font-inter text-black-100 leading-[20px] text-[14px] font-bold">
                          Language(s)
                        </p>
                        <TagInput
                          // label="Language(s)"
                          list={_.map(languageNameMap, (v, k) => {
                            return {
                              id: v.name,
                              name: v.name,
                            }
                          })}
                          placeholder="Select Languages"
                          name="languages"
                          id="languages"
                          onChange={(value) => {
                            setLanguages(value)
                            localStorage.setItem(
                              'filter_candidates_language',
                              JSON.stringify(value)
                            )
                          }}
                          value={languages}
                        />
                      </div>
                      <div className="space-y-[15px]">
                        <p className="font-inter text-black-100 leading-[20px] text-[14px] font-bold">
                          Countries
                        </p>
                        <TagInput
                          // label="Language(s)"
                          list={_.map(getNames(), (v, k) => {
                            return {
                              id: v,
                              name: v,
                            }
                          })}
                          placeholder="Select Countries"
                          name="countries"
                          id="countries"
                          onChange={(value) => {
                            setLocations(value)
                            localStorage.setItem(
                              'filter_candidates_location',
                              JSON.stringify(value)
                            )
                          }}
                          value={locations}
                        />
                      </div>
                      {/* <div className="space-y-[20px]">
                        <Checkbox
                          variant="primary"
                          label="Cover Letter Video Included"
                          value={enableCoverLetterVideoIncluded}
                          onChange={() =>
                            handleCheckboxChange('coverLetterVideo')
                          }
                          disabled={disabled}
                        />
                      </div> */}
                      {/* <TagInput
                        label="Industry"
                        list={_.map(industryTags, (v, k) => {
                          return {
                            id: v.id,
                            name: v.name,
                          }
                        })}
                        placeholder="Select industry tags"
                        name="industry"
                        id="industry"
                        onChange={(value) => {
                          setIndustries(value)
                        }}
                        value={industries}
                        labelClassName="font-inter text-black-100 font-bold text-[14px] md:text-sm leading-[20px] md:leading-5"
                      /> */}
                    </div>
                    <div class="flex justify-between items-end">
                      <div
                        onClick={() => {
                          setEnableAnnual(false)
                          setEnableHourly(false)
                          setEnableNegotiable(false)
                          setEnableCoverLetterVideoIncluded(false)
                          setMaxHourlyRate('')
                          setMaxAnnualRate('')
                          setLanguages([])
                          setLocations([])
                          // setIndustries([])
                          onHandleReset()

                          localStorage.removeItem('filter_candidates_language')
                          localStorage.removeItem('filter_candidates_location')
                          localStorage.removeItem(
                            'filter_candidates_maxHourlyRate'
                          )
                          localStorage.removeItem(
                            'filter_candidates_maxAnnualRate'
                          )
                        }}
                        className="text-gray-100 leading-5 text-[14px] md:text-sm font-bold mt-[40px] cursor-pointer"
                      >
                        Reset all filters
                      </div>
                      <div class="sm:hidden">
                        <button
                          class="w-28 h-8 my-100 flex-shrink-0 rounded-lg bg-blue-200 text-white"
                          onClick={closeModal}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
