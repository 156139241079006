function profileRank(profile) {
  const hasVideo = profile.video !== '' ? 1 : 0
  const hasPicture = profile.avatar !== '' ? 1 : 0

  if (hasVideo && hasPicture) {
    return 0
  } else if (hasVideo) {
    return 1
  } else if (hasPicture) {
    return 2
  } else {
    return 3
  }
}

export default function compareProfiles(profileA, profileB) {
  const rankA = profileRank(profileA)
  const rankB = profileRank(profileB)

  return rankA - rankB
}
