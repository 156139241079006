import React from 'react'

function SuggestedTag({ name }) {
  return (
    <div className="flex items-center justify-center h-[19.014px] pl-[9.51px] pr-[5.94px] py-[4.53px] gap-[4.527px] rounded-[18.561px] bg-[#001957]">
      <div className="text-[#DBE6FF] text-center font-manrope text-[6.338px] not-italic font-normal leading-normal">
        {name}
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
        >
          <path
            d="M6.43689 2.96545L3.66406 5.73828"
            stroke="white"
            stroke-width="1.18835"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.43689 5.73828L3.66406 2.96545"
            stroke="white"
            stroke-width="1.18835"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  )
}

export default SuggestedTag
