import { getUser } from 'app/store/profileSlice'
import clsx from 'clsx'
import { PlusIcon } from 'components/Icons'
import SearchInput from 'components/Input/SearchInput'
import React from 'react'
import { useSelector } from 'react-redux'

export default function Header({
  selected,
  setSelected,
  activeJobsNumber,
  inactiveJobsNumber,
  handleShowNowJobPosting,
}) {
  const profile = useSelector(getUser)

  if (!profile) return null

  return (
    <div className="w-full">
      <div className="text-[text-black-100]">
        <h4 className="text-[14px] md:text-2xl leading-[21px] md:leading-8 font-medium">
          Welcome, {profile.firstName}!
        </h4>
        <h3 className="text-[24px] md:text-[48px] leading-[36px] md:leading-[72px] font-semibold">
          Your Jobs
        </h3>
      </div>
      <div className="md:flex justify-between w-full md:space-x-21 space-y-[25px] md:space-y-0 mt-[20px] md:mt-10">
        <div className="flex-none w-full">
          <div className="flex justify-between md:justify-end items-center md:space-x-10">
            <div className="flex space-x-[20px] md:space-x-10 md:justify-end items-center px-0">
              <div
                onClick={() => {
                  setSelected('active')
                }}
                className={clsx(
                  'text-black-100 leading-5 font-semibold text-[14px] md:text-sm pb-[1px] cursor-pointer',
                  selected === 'active' &&
                    'border-b-[2px] border-solid border-green-100 border-x-0 border-t-0 text-green-100'
                )}
              >
                Active Jobs ({activeJobsNumber})
              </div>
              <div
                onClick={() => {
                  setSelected('inactive')
                }}
                className={clsx(
                  'text-black-100 leading-5 font-semibold text-[14px] md:text-sm pb-[1px] cursor-pointer',
                  selected === 'inactive' &&
                    'border-b-[2px] border-solid border-green-100 border-x-0 border-t-0 text-green-100'
                )}
              >
                Inactive Jobs ({inactiveJobsNumber})
              </div>
            </div>
            <div className="block">
              <div
                onClick={handleShowNowJobPosting}
                className="cursor-pointer w-[42px] md:w-12 h-[42px] md:h-12 bg-green-100 rounded-full flex justify-center items-center"
              >
                <PlusIcon size={12} className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
