//packages
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

//custom components
import router from 'app/route/Routes'

import store from 'app/reducers/rootReducer'
import Snackbar from 'components/Snackbar/Snackbar'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E3E85',
    },
    secondary: {
      main: '#52B4DA',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 16,
      },
      containedSecondary: {
        color: '#fff',
      },
    },
  },
})

// Google Anaylytics
const TRACKING_ID = 'G-G6P9RF1KTS'
ReactGA.initialize(TRACKING_ID)
ReactGA.send({ hitType: 'pageview', page: window.location.pathname })

ReactDOM.render(
  <React.StrictMode>
    <PayPalScriptProvider
      options={{
        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
      }}
    >
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Snackbar />
          <ToastContainer />
          <RouterProvider router={router} />
        </MuiThemeProvider>
      </Provider>
    </PayPalScriptProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
