import React from 'react'

export default function VerifyEmailWarning({ resendHandler }) {
  return (
    <div className="rounded bg-[#FFDDDC]">
      <div className="py-2 px-3 leading-4 inline-flex space-x-2">
        <h2 className="text-sm font-medium text-[#FF504C]">
          Verify your email address
        </h2>
        <div
          onClick={resendHandler}
          className="text-sm text-[#FF504C] underline cursor-pointer"
        >
          Resend
        </div>
      </div>
    </div>
  )
}
