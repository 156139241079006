import JobServiceClient from 'app/clients/jobService'
import axios from 'axios'

const API_URL = `${process.env.REACT_APP_JOBS_API_URL}`

export const fetchJobsById = (userId) => {
  return JobServiceClient.get(`/jobs/${userId}`)
}

export const fetchHomePageJobById = (jobId) => {
  return JobServiceClient.post(`/home/job/${jobId}`)
}

export const createJobAPI = ({ userId, data }) => {
  return JobServiceClient.post(`/job/${userId}`, data)
}

export const updateJobAPI = ({ userId, data }) => {
  return JobServiceClient.put(`/job/${userId}`, data)
}

export const deactivateJobAPI = ({ jobId }) => {
  return JobServiceClient.get(`/job/deactivate/${jobId}`)
}

export const activateJobAPI = ({ jobId }) => {
  return JobServiceClient.get(`/job/activate/${jobId}`)
}

export const fetchJobsByFilter = (data) => {
  return JobServiceClient.post(`/search/job`, data)
}

export const fetchJobByID = ({ id, data }) => {
  return JobServiceClient.post(`${API_URL}/job/get/${id}`, data)
}

export const getStatusJobApplicantsForEmployer = (id) => {
  return JobServiceClient.get(`/jobs/${id}/get-candidates-number`)
}
