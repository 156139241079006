import React from 'react'
import PinIcon from 'assets/img/pin.png'

function VideoCard({ name, location, bgImg }) {
  return (
    <div
      className="relative flex flex-col h-[152.727px] w-[120px] sm:h-[300px] sm:w-[235px] bg-green-100 bg-cover bg-center justify-end pb-3 m-1"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="absolute inset-0 flex justify-center items-center sm:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 74 74"
          fill="none"
          className="absolute top-2 right-2 m-1"
        >
          <g opacity="0.9">
            <g filter="url(#filter0_d_11005_18830)">
              <circle
                cx="37"
                cy="33"
                r="25"
                fill="#52B4DA"
                fillOpacity="0.4"
                shapeRendering="crispEdges"
              />
              <circle
                cx="37"
                cy="33"
                r="24"
                stroke="#52B4DA"
                strokeWidth="2"
                shapeRendering="crispEdges"
              />
            </g>
            <path d="M47 32.5L32.75 40.7272V24.2728L47 32.5Z" fill="#52B4DA" />
          </g>
          <defs>
            <filter
              id="filter0_d_11005_18830"
              x="0"
              y="0"
              width="74"
              height="74"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.321569 0 0 0 0 0.705882 0 0 0 0 0.854902 0 0 0 0.45 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_11005_18830"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_11005_18830"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="absolute inset-0 flex justify-center items-center hidden sm:flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 74 74"
          fill="none"
          className="absolute top-2 right-2 m-1"
        >
          <g opacity="0.9">
            <g filter="url(#filter0_d_11005_18830)">
              <circle
                cx="37"
                cy="33"
                r="25"
                fill="#52B4DA"
                fillOpacity="0.4"
                shapeRendering="crispEdges"
              />
              <circle
                cx="37"
                cy="33"
                r="24"
                stroke="#52B4DA"
                strokeWidth="2"
                shapeRendering="crispEdges"
              />
            </g>
            <path d="M47 32.5L32.75 40.7272V24.2728L47 32.5Z" fill="#52B4DA" />
          </g>
          <defs>
            <filter
              id="filter0_d_11005_18830"
              x="0"
              y="0"
              width="74"
              height="74"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="6" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.321569 0 0 0 0 0.705882 0 0 0 0 0.854902 0 0 0 0.45 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_11005_18830"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_11005_18830"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="flex justify-between items-center ml-[20px] z-10">
        <div className="flex flex-col flex-wrap shrink min-w-[15px]">
          <h6 className="capitalize text-white font-poppins text-sm not-italic font-bold">
            {name}
          </h6>
          <div className="flex items-center space-x-1.5 h-[12px] w-[12px]">
            <img src={PinIcon} alt="Pin Location Icon" />
            <span>
              <h6 className="capitalize text-white font-poppins text-xs not-italic font-normal truncate">
                {location}
              </h6>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoCard
