import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

//custom components
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { PencilIcon } from 'components/Icons'
import { Tab } from 'components/Tabs'
import { VerifyEmailWarning } from 'components/Warning'
import AboutSection from './components/AboutSection'
import PortfolioSection from './components/PortfolioSection'

//redux & API
import AuthService, { updateUserLastActivity } from 'app/services/auth.service'
import { getUser } from 'app/store/profileSlice'
import { VerifiedMarkIcon } from 'components/Icons'

const TABS = ['About', 'Portfolio']

export default function Profile() {
  const [activeTab, setActiveTab] = useState('About')
  const navigate = useNavigate()
  const user = useSelector(getUser)

  useEffect(async () => {
    //Update user last activity
    if (user) {
      await updateUserLastActivity(user.id)
    }
  }, [])

  const onEdit = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    // Define a mapping for user type
    const typeMapping = {
      contractor: 'jobseeker',
    }

    // Fallback to original user type if there's no mapping
    const userType = typeMapping[user.type] || user.type

    navigate(`/${userType}/setting`)
  }

  if (user === null) return null

  const resendHandler = () => {
    AuthService.resendVerifyLink({ id: user.id })
      .then((res) => {
        toast.success('Link resent successfully. Please check your email')
      })
      .catch((err) => {
        console.log(err)
        toast.error('There is error when resend verification link.')
      })
  }

  return (
    <div className="flex min-h-screen px-[15px] md:px-16">
      <div className="pb-[100px] md:pb-12 pt-[30px] md:pt-12 flex-1 relative">
        <div className="text-black-100">
          <h5 className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
            Welcome, {user.name}!
          </h5>
          <h2 className="leading-[26px] md:leading-[72px] font-semibold text-[24px] md:text-4xl">
            Your Profile
          </h2>
        </div>
        <div className="bg-white rounded-3xl md:pl-12 md:pr-14 px-3 py-5 relative mt-[20px] md:mt-14">
          {!user.verified && (
            <div className="absolute w-full -top-4 flex justify-center">
              <div className="left-[50%]">
                <VerifyEmailWarning resendHandler={resendHandler} />
              </div>
            </div>
          )}
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-8">
              <div className="w-[80px] md:w-28 h-[80px] md:h-28 flex-none justify-center items-center border-[4px] border-[#0049C6] rounded-full border-solid">
                <div className="flex items-center justify-center w-full h-full">
                  <Avatar image={user.avatar} className="w-20 h-20" />
                </div>
              </div>
              <div className="justify-between flex flex-col">
                <h3 className="text-black-100 text-[18px] md:text-2xl xl:text-5xl leading-[27px] md:leading-[30px] xl:leading-18 font-semibold">
                  {user.name}
                </h3>
                {/* <div className="block md:hidden mt-[15px]">
                  <Button
                    onClick={moveToMessage}
                    variant="apply"
                    className="px-[14px] md:px-16 py-[7px] md:py-4"
                  >
                    Message
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="items-center space-x-5 flex">
              {/* {user.verified && (
                <div className="space-x-15 flex">
                  <div className="hidden md:flex items-center">
                    <VerifiedMarkIcon />
                  </div>
                  {/* <Button
                    onClick={moveToMessage}
                    variant="apply"
                    className="px-16 py-4"
                  >
                    Message
                  </Button>
                </div>
              )} */}
              <div
                className="w-12 h-12 flex justify-center items-center bg-white shadow-xl rounded-full cursor-pointer"
                onClick={onEdit}
              >
                <PencilIcon
                  className="text-black-100 cursor-pointer"
                  size={15}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Tabs Section */}
        <div className="mt-8">
          <Tab
            list={TABS}
            activeTab={activeTab}
            onChange={() =>
              setActiveTab((prev) => (prev === 'About' ? 'Portfolio' : 'About'))
            }
          />
        </div>

        <div className="mt-6">
          {activeTab === 'About' && <AboutSection profile={user} />}
          {activeTab === 'Portfolio' && <PortfolioSection profile={user} />}
        </div>
      </div>
    </div>
  )
}
