//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//custom components
import Button from 'components/Button'
import { ModalCloseIcon } from 'components/Icons'

//redux & api
import { getUser, deleteProject } from 'app/store/profileSlice'


export default function DeleteProjectModal({ isOpen, closeModal, project }) {
  const dispatch = useDispatch()
  const profile = useSelector(getUser)
  
  const onClickDeleteItem = async () => {
    try {
      const params = {
        userId: profile.id,
        projectId: project.id,
      }

      let resultAction = await dispatch(deleteProject(params))
      if (deleteProject.fulfilled.match(resultAction)) {
        toast.success('Deleted Project Successfully')
        closeModal()
      } else {
        if (resultAction.payload) {
          toast.error(
            'Deleted Project failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Deleted Project failed' + resultAction.error.message)
        }
        setIsDeleting(false)
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (!project) return null
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-15" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black-background-overlay bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white p-8 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer w-[70px] md:w-auto h-[70px] md:h-auto" />
                  </div>

                  {/* Body Section */}
                  <div>
                    <h3 className="text-black-100 leading-[24px] md:leading-6 text-[18px] md:text-lg font-medium">
                      Delete this item on your portfolio?
                    </h3>
                  </div>

                  <div className="mt-[30px] w-full flex justify-center gap-5">
                    <Button
                      variant="error"
                      className="w-full space-x-[10px] cursor-pointer text-[14px] md:text-sm py-[16px] md:py-5"
                      onClick={onClickDeleteItem}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="secondary"
                      className="w-full space-x-[10px] cursor-pointer text-[14px] md:text-sm py-[16px] md:py-5"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
