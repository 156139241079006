import React from 'react'

export default function DeleteIcon({ className = 'text-white', size = 14 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0834 4.08334C10.9287 4.08334 10.7803 4.1448 10.6709 4.2542C10.5615 4.36359 10.5 4.51197 10.5 4.66668V11.1948C10.4833 11.4897 10.3507 11.7662 10.1311 11.9638C9.91147 12.1615 9.62265 12.2643 9.32754 12.25H4.67254C4.37743 12.2643 4.08861 12.1615 3.869 11.9638C3.64939 11.7662 3.51677 11.4897 3.50004 11.1948V4.66668C3.50004 4.51197 3.43858 4.36359 3.32919 4.2542C3.21979 4.1448 3.07142 4.08334 2.91671 4.08334C2.762 4.08334 2.61362 4.1448 2.50423 4.2542C2.39483 4.36359 2.33337 4.51197 2.33337 4.66668V11.1948C2.35002 11.7992 2.60555 12.3725 3.04398 12.7889C3.48241 13.2054 4.06802 13.4311 4.67254 13.4167H9.32754C9.93207 13.4311 10.5177 13.2054 10.9561 12.7889C11.3945 12.3725 11.6501 11.7992 11.6667 11.1948V4.66668C11.6667 4.51197 11.6053 4.36359 11.4959 4.2542C11.3865 4.1448 11.2381 4.08334 11.0834 4.08334Z"
        fill="currentColor"
      />
      <path
        d="M11.6667 2.33334H9.33333V1.16668C9.33333 1.01197 9.27187 0.863594 9.16248 0.754198C9.05308 0.644802 8.90471 0.583344 8.75 0.583344H5.25C5.09529 0.583344 4.94692 0.644802 4.83752 0.754198C4.72812 0.863594 4.66667 1.01197 4.66667 1.16668V2.33334H2.33333C2.17862 2.33334 2.03025 2.3948 1.92085 2.5042C1.81146 2.61359 1.75 2.76197 1.75 2.91668C1.75 3.07139 1.81146 3.21976 1.92085 3.32916C2.03025 3.43855 2.17862 3.50001 2.33333 3.50001H11.6667C11.8214 3.50001 11.9697 3.43855 12.0791 3.32916C12.1885 3.21976 12.25 3.07139 12.25 2.91668C12.25 2.76197 12.1885 2.61359 12.0791 2.5042C11.9697 2.3948 11.8214 2.33334 11.6667 2.33334ZM5.83333 2.33334V1.75001H8.16667V2.33334H5.83333Z"
        fill="currentColor"
      />
      <path
        d="M6.41667 9.91667V5.83333C6.41667 5.67862 6.35521 5.53025 6.24581 5.42085C6.13642 5.31146 5.98804 5.25 5.83333 5.25C5.67862 5.25 5.53025 5.31146 5.42085 5.42085C5.31146 5.53025 5.25 5.67862 5.25 5.83333V9.91667C5.25 10.0714 5.31146 10.2197 5.42085 10.3291C5.53025 10.4385 5.67862 10.5 5.83333 10.5C5.98804 10.5 6.13642 10.4385 6.24581 10.3291C6.35521 10.2197 6.41667 10.0714 6.41667 9.91667Z"
        fill="currentColor"
      />
      <path
        d="M8.75004 9.91667V5.83333C8.75004 5.67862 8.68858 5.53025 8.57919 5.42085C8.46979 5.31146 8.32142 5.25 8.16671 5.25C8.012 5.25 7.86362 5.31146 7.75423 5.42085C7.64483 5.53025 7.58337 5.67862 7.58337 5.83333V9.91667C7.58337 10.0714 7.64483 10.2197 7.75423 10.3291C7.86362 10.4385 8.012 10.5 8.16671 10.5C8.32142 10.5 8.46979 10.4385 8.57919 10.3291C8.68858 10.2197 8.75004 10.0714 8.75004 9.91667Z"
        fill="currentColor"
      />
    </svg>
  )
}
