//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

//custom components
import Button from 'components/Button'
import { ModalCloseIcon } from 'components/Icons'

//redux

export default function RejectInvitationConfirmModal({
  isOpen,
  closeModal,
  handleReject,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[ #1B1D21] bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[480px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white py-24 px-12 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer w-[50px] md:w-auto h-[50px] md:h-auto" />
                  </div>
                  <h3 className="text-black-100 text-2xl leading-9 font-semibold text-center">
                    Are you sure want to reject the invitation?
                  </h3>
                  <p className="text-[14px] text-gray-100 text-center mt-9">
                    The rejection is not reversable.
                  </p>
                  <div className="flex space-x-4 justify-center mt-10">
                    <Button
                      variant="secondary"
                      className="leading-5 font-bold text-sm px-16"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="grayRed"
                      className="leading-5 font-bold text-sm px-16"
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
