import testImg1 from 'assets/img/mockImgs/img1.png'
import testImg2 from 'assets/img/mockImgs/img2.png'
import testImg3 from 'assets/img/mockImgs/img3.png'
import testImg4 from 'assets/img/mockImgs/img4.png'
import testImg5 from 'assets/img/mockImgs/img5.png'
import testImg6 from 'assets/img/mockImgs/img6.png'
import testImg7 from 'assets/img/mockImgs/img7.png'

import testImg8 from 'assets/img/mockImgs/img8.png'
import testImg9 from 'assets/img/mockImgs/img9.png'
import testImg10 from 'assets/img/mockImgs/img10.png'
import testImg11 from 'assets/img/mockImgs/img11.png'
import testImg12 from 'assets/img/mockImgs/img12.png'
import testImg13 from 'assets/img/mockImgs/img13.png'
import testImg14 from 'assets/img/mockImgs/img14.png'

import testImg16 from 'assets/img/mockImgs/img16.png'
import testImg17 from 'assets/img/mockImgs/img17.png'
import testImg18 from 'assets/img/mockImgs/img18.png'
import testImg19 from 'assets/img/mockImgs/img19.png'
import testImg20 from 'assets/img/mockImgs/img20.png'
import testImg21 from 'assets/img/mockImgs/img21.png'

import testImg22 from 'assets/img/mockImgs/img22.png'
import testImg23 from 'assets/img/mockImgs/img23.png'
import testImg24 from 'assets/img/mockImgs/img24.png'
import testImg25 from 'assets/img/mockImgs/img25.png'
import testImg26 from 'assets/img/mockImgs/img26.png'
import testImg27 from 'assets/img/mockImgs/img27.png'
import testImg28 from 'assets/img/mockImgs/img28.png'

export const homePageCandidatesList1 = [
  {
    id: '01',
    name: 'Gellie',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Gellie+Ann+Carrillo+new.png',
    location: 'Philippines',
  },
  {
    id: '08',
    name: 'Sybilljulian',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Sybilljulian+Ladiromi+D.+Alexander+new.png',
    location: 'Indonesia',
  },
  {
    id: '03',
    name: 'Tanveer',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Tanveer+kaur+banwait+new.png',
    location: 'India',
  },
  {
    id: '05',
    name: 'Joel',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Joel+Diamond+new.png',
    location: 'Tokyo/Japan',
  },
  {
    id: '06',
    name: 'Maria',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Maria+Melanie+Dela+Cruz+new.png',
    location: 'Philippines',
  },
  {
    id: '117',
    name: 'Tamara',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Tamara+Mwikali+Muli+new.png',
    location: 'Kenya',
  },
]

export const homePageCandidatesList2 = [
  {
    id: '14',
    name: 'Tabitha',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Tabitha+Kitonyi+new.png',
    location: 'Kenya',
  },
  {
    id: '13',
    name: 'Pablo',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Pablo+Garc%C3%ADa-Roll%C3%A1n+new.png',
    location: 'Spain',
  },
  {
    id: '12',
    name: 'John',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/john+louis+Oliveros+new.png',
    location: 'Philippines',
  },
  {
    id: '10',
    name: 'Jenivy',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Jenivy+Briones+Salgarino+new.png',
    location: 'Philippines',
  },
  {
    id: '10',
    name: 'Julius',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Julius+Nyule+new.png',
    location: 'Kenya',
  },
  {
    id: '11',
    name: 'Esther',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Esther+David+Katuii+new.png',
    location: 'Kenya',
  },
  {
    id: '09',
    name: 'Caleb',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Caleb+Maangi+new.png',
    location: 'Kenya',
  },
]

export const homePageCandidatesList3 = [
  {
    id: '16',
    name: 'John',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/John+Gil+Epe+Saludes+new.png',
    location: 'Philippines',
  },
  {
    id: '17',
    name: 'Hellen',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Hellen+Cortes+new.png',
    location: 'Costa Rica',
  },
  {
    id: '07',
    name: 'Shantanelli',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Gellie+Ann+Carrill+new.png',
    location: 'Tokyo/Japan',
  },
  {
    id: '19',
    name: 'Markos',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Markos+Madefro+Abebe+new.png',
    location: 'Ethiopia',
  },
  {
    id: '200',
    name: 'Jane',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Jane+Muloki+new.png',
    location: 'Kenya',
  },

  {
    id: '20',
    name: 'Bamlak',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Bamlak+Daniel+Kibret+new.png',
    location: 'Ethiopia',
  },
]

export const homePageCandidatesList4 = [
  {
    id: '26',
    name: 'Halelluya',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Halelluya+Tesfaye+Abebe+new.png',
    location: 'Ethiopia',
  },

  {
    id: '23',
    name: 'Selwa',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Selwa+Adem+Abdulrhman+new.png',
    location: 'United Arab Emirates',
  },
  {
    id: '24',
    name: 'Ann',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Gellie+Ann+Carril+new.png',
    location: 'Ethiopia',
  },
  {
    id: '27',
    name: 'Precious',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Precious+Gem+Delos+Santos+new.png',
    location: 'Philippines',
  },
  {
    id: '29',
    name: 'Enock',
    avatar:
      'https://remote-recruit-email-temp.s3.amazonaws.com/Enock+Masaki+Magack+new.png',
    location: 'Kenya',
  },
]
