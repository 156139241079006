import React from 'react'

function CategoryTag({ name, setFilter, filter, setKeywords, keywords }) {
  const fetchCandidatesByCategory = (name) => {
    if (name === 'Development & IT') {
      setFilter({
        ...filter,
        skills: [
          'Software Development',
          'Software Engineering',
          'React.js',
          'IT Support/ Software Engineer',
        ],
      })
      setKeywords([
        ...keywords,
        'Software Development',
        'IT Support/ Software Engineer',
        'Software Engineering',
      ])
    }

    if (name === 'Design & Creative') {
      setFilter({
        ...filter,
        skills: ['Adobe Illustrator', 'Figma', 'Adobe Bridge', 'Adobe'],
      })
      setKeywords([
        ...keywords,
        'Adobe Illustrator',
        'Figma',
        'Adobe Bridge',
        'Adobe',
      ])
    }

    if (name === 'Finance & Accounting') {
      setFilter({
        ...filter,
        skills: [
          'Accounting',
          'Cost Accounting',
          'Financial Accounting',
          'Account Reconciliation',
          'Tax Accounting',
        ],
      })
      setKeywords([
        ...keywords,
        'Accounting',
        'Cost Accounting',
        'Financial Accounting',
        'Account Reconciliation',
        'Tax Accounting',
      ])
    }

    if (name === 'Admin & Customer Support') {
      setFilter({
        ...filter,
        skills: [
          'Office Administration',
          'Customer Service',
          'Customer Success',
          'Customer Satisfaction',
        ],
      })
      setKeywords([
        ...keywords,
        'Office Administration',
        'Customer Service',
        'Customer Success',
        'Customer Satisfaction',
      ])
    }

    if (name === 'Sales & Marketing') {
      setFilter({
        ...filter,
        skills: [
          'Sales',
          'Sales Management',
          'Marketing',
          'Marketing Strategy',
        ],
      })
      setKeywords([
        ...keywords,
        'Sales',
        'Sales Management',
        'Marketing',
        'Marketing Strategy',
      ])
    }

    if (name === 'Legal') {
      setFilter({
        ...filter,
        skills: [
          'Legal Writing',
          'Legal Advisory',
          'Legal Research',
          'Paralegal',
          'Remote Legal Assistant',
        ],
      })
      setKeywords([
        ...keywords,
        'Legal Writing',
        'Legal Advisory',
        'Legal Research',
        'Paralegal',
        'Remote Legal Assistant',
      ])
    }

    if (name === 'Writing & Translation') {
      setFilter({
        ...filter,
        skills: [
          'Writing',
          'Technical Writing',
          'Copywriting',
          'Content Writer',
          'Virtual Writer',
        ],
      })
      setKeywords([
        ...keywords,
        'Writing',
        'Technical Writing',
        'Copywriting',
        'Content Writer',
        'Virtual Writer',
      ])
    }

    if (name === 'Engineering & Architecture') {
      setFilter({
        ...filter,
        skills: [
          'Engineering',
          'Structural Engineering',
          'Network Engineering',
          'Project Engineering',
          'Solution Architecture',
          'Data Engineering',
          'Cloud Engineering',
          'Software Engineering',
        ],
      })
      setKeywords([
        ...keywords,
        'Engineering',
        'Structural Engineering',
        'Network Engineering',
        'Project Engineering',
        'Solution Architecture',
        'Data Engineering',
        'Cloud Engineering',
        'Software Engineering',
      ])
    }
  }

  return (
    <div
      className="inline-flex p-[0.75rem_1.25rem_0.8125rem_1.25rem] justify-center items-center gap-[0.625rem] 
      rounded-full bg-white shadow-[0px_5px_30px_0px_rgba(4, 28, 81, 0.75)] text-[#52B4DA] text-center text-base not-italic font-semibold cursor-pointer"
      onClick={() => fetchCandidatesByCategory(name)}
    >
      {name}
    </div>
  )
}

export default CategoryTag
