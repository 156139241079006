import React from 'react'

export default function KeyIcon({ className = 'text-white', size = 18 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6265 7.45525H13.9014H13.9012V4.90115C13.9012 2.19869 11.7025 0 9.00001 0C6.29763 0 4.09894 2.19869 4.09894 4.90118V7.45529H3.37366C2.80032 7.45529 2.33545 7.92008 2.33545 8.49346V9.12502V16.3303V16.9618C2.33545 17.5352 2.80028 18 3.37366 18H9.00005H14.6265C15.1999 18 15.6646 17.5352 15.6646 16.9618V16.3303V9.12502V8.49346C15.6646 7.92004 15.1999 7.45525 14.6265 7.45525ZM10.1051 14.8919H7.89504L8.29059 13.0071C7.92068 12.7717 7.6736 12.3607 7.6736 11.8897C7.6736 11.1572 8.26752 10.5634 9.00005 10.5634C9.73262 10.5634 10.3264 11.1572 10.3264 11.8897C10.3264 12.3607 10.0794 12.7717 9.70955 13.007L10.1051 14.8919ZM12.1524 7.45525H9.00005H5.84789H5.8477V4.90115C5.8477 3.16278 7.26175 1.74877 9.00005 1.74877C10.7384 1.74877 12.1524 3.16282 12.1524 4.90115V7.45525Z"
        fill="currentColor"
      />
    </svg>
  )
}
