//packages
import { Combobox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import _ from 'lodash'
import React, { Fragment, useRef, useState } from 'react'

//custom components
import { CrossIcon, CrossIcon2 } from 'components/Icons'

export default function TagInput({
  label,
  placeholder = 'select items',
  list = [],
  value = [],
  onChange: parentOnChange,
  limitText = '',
  disabled,
  optionClassName = '',
  optionContainerClassName = 'px-4',
  labelClassName = 'text-gray-300 leading-[16px] text-[12px] md:text-xs font-medium',
  error,
  errorClassName = 'text-xs',
}) {
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef(null)
  const inputRef = useRef(null)

  const handlePanelClose = () => {
    setIsOpen(false)
  }

  const onChange = (param) => {
    let tempValue = _.uniqBy(param, function (v) {
      return v?.id
    })
    parentOnChange(tempValue)
    buttonRef.current?.click()
  }

  const removeItem = (v) => {
    const tempValue = _.filter(value, (item) => item.id !== v.id)
    parentOnChange(tempValue)
  }

  const valueIds = value.map(object => object.id) ?? [];

  const filteredValues =
    query === ''
      ? list.filter((v) => !valueIds.includes(v.id))
      : list.filter((v) =>
          v.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')) 
          && !valueIds.includes(v.id)
        )

  const handleInputFocus = () => {
    buttonRef.current?.click()
  }

  const forceFocusHandler = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <div className="space-y-[10px] md:space-y-4 relative">
      {label && (
        <div className="flex justify-between">
          <div className={clsx(labelClassName)}>{label}</div>
          {limitText && (
            <div className="text-gray-300 leading-[16px] text-[12px] md:text-xs font-medium">
              {limitText}
            </div>
          )}
        </div>
      )}
      <div
        className="w-full bg-[rgba(228,228,228,0.25)] p-[10px] md:p-4 rounded-lg relative"
        onClick={forceFocusHandler}
      >
        <Combobox
          open={isOpen}
          onClose={handlePanelClose}
          disabled={disabled}
          multiple={true}
          value={value}
          onChange={onChange}
        >
          <div className="flex flex-wrap">
            <Combobox.Button className="hidden" ref={buttonRef}>
              Hey
            </Combobox.Button>
            <div className="space-y-2">
              <div className="overflow-auto">
                {value.map((v) => (
                  <div
                    key={v.id}
                    onClick={() => removeItem(v)}
                    className="inline-flex items-center mb-[4px] bg-[rgba(228,228,228,0.3)] mr-2 text-[12px] md:text-xs p-1 rounded-xl cursor-pointer"
                  >
                    <span>{v?.name}</span>
                    <div>
                      <CrossIcon />
                    </div>
                  </div>
                ))}
                <div className="inline-flex w-[200px] relative cursor-default bg-transparent overflow-hidden rounded-lg text-left focus:outline-none focus-visible:ring-2 sm:text-sm">
                  <Combobox.Input
                    ref={inputRef}
                    autoComplete="off"
                    className="w-full border-none pl-5 pr-10 text-[14px] md:text-sm leading-5 text-gray-900 focus:ring-0 focus-visible:outline-none shadow-none bg-transparent"
                    displayValue={(v) => v?.name}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={placeholder}
                  />
                </div>
              </div>
            </div>
            <div className="absolute w-full left-0 bottom-0">
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute right-0 top-auto flex space-[5px] flex-wrap w-full mt-1 max-h-60 overflow-auto  bg-white p-2.5 text-base ring-black border-[1px] border-gray-500 rounded-[8px] focus:outline-none sm:text-sm z-10">
                  {filteredValues.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filteredValues.map((v) => (
                      <Combobox.Option
                        key={v.id}
                        className={({ active }) =>
                          `mr-[5px] mb-[5px] relative cursor-default space-[5px] select-none inline-flex p-2.5 border-[1px] rounded-[7px] border-gray-500 ${optionContainerClassName} ${
                            active
                              ? 'bg-teal-600 text-gray-900 font-semibold'
                              : 'text-gray-900'
                          }`
                        }
                        value={v}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate text-black-100 text-xs font-semibold font-inter ${optionClassName} ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {v?.name}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                  <div
                    onClick={handleInputFocus}
                    className="absolute right-4 top-5 cursor-pointer"
                  >
                    <CrossIcon2 size={12} />
                  </div>
                </Combobox.Options>
              </Transition>
            </div>
          </div>
        </Combobox>
      </div>
      {error && (
        <div
          className={clsx(
            'text-red-300 text-[12px] md:text-xs font-semibold',
            errorClassName
          )}
          style={{ marginTop: '6px' }}
        >
          {error}
        </div>
      )}
    </div>
  )
}
