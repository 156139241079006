import React from 'react'

export default function ActivateIcon({
  className = 'text-[#52B4DA]',
  size = 18,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6264 7.45525H13.9013V4.90115C13.9013 2.19869 11.7026 0 9.00016 0H9.00001C6.29767 7.32422e-05 4.09901 2.19873 4.09901 4.90118V4.95374H5.84778V4.90118C5.84778 4.03202 6.20128 3.24393 6.77209 2.67312C6.91477 2.53044 7.07111 2.40128 7.23879 2.2879C7.57432 2.06111 7.95554 1.89712 8.36562 1.813C8.57056 1.77092 8.78281 1.7488 9.00001 1.7488H9.00016C10.7385 1.7488 12.1525 3.16285 12.1525 4.90118V7.45529H9.00001H5.84778H4.09901H3.37366C2.80024 7.45529 2.33545 7.92008 2.33545 8.49349V9.12506V16.3302V16.9618C2.33545 17.5352 2.80024 18 3.37366 18H9.00005H14.6265C15.1998 18 15.6647 17.5352 15.6647 16.9618V16.3302V9.12506V8.49349C15.6646 7.92004 15.1998 7.45525 14.6264 7.45525ZM10.0401 14.7648H7.9599L8.33226 12.9907C7.98411 12.7691 7.75153 12.3823 7.75153 11.939C7.75153 11.2495 8.31047 10.6905 9.00001 10.6905C9.68959 10.6905 10.2485 11.2494 10.2485 11.939C10.2485 12.3822 10.016 12.7691 9.66783 12.9906L10.0401 14.7648Z"
        fill="currentColor"
      />
    </svg>
  )
}
