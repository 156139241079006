//packages
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

//custom components
import { CirclePlusIcon, PolygonIcon } from 'components/Icons'
import VideoPlayerModal from 'components/Dialog/VideoPlayerModal'

//custom functions
import { kFormatter } from 'app/utils/function'

//assets
import PlayIcon from 'assets/images/play-icon.png'
import DefaultAvatar from 'assets/images/default-avatar.png'
import Button from 'components/Button'
import { removeExtraSpaces } from 'app/utils/sanitizeText'

export default function CandidateItem({
  data,
  handleInvite,
  id,
  hideInviteBtn = false,
}) {
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false)
  const [showMoreSkills, setShowMoreSkills] = useState(false)

  const navigate = useNavigate()

  const onVideo = () => {
    setShowVideoPlayerModal(true)
  }

  const moveToCandidateDetail = () => {
    navigate(`/employer/profile/${data.id}`)
    localStorage.setItem('can_selected', id)
  }

  const candidateAvatar = data.avatar
    ? data.avatar.replace(/\s+/g, '').trim()
    : ''

  const sanitisedBio = removeExtraSpaces(data?.bio)

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  return (
    <div
      className="px-[15px] pb-[25px] md:pb-7.5 pt-[25px] first:pt-0 md:first:pt-7.5 md:py-7.5 md:pl-1 border-b border-gray-500 border-solid border-x-0 border-t-0 cursor-pointer"
      id={id}
    >
      <div className="flex space-x-[15px] md:space-x-7.5">
        {data.video ? (
          <div
            onClick={onVideo}
            className="w-[120px] md:w-[160px] h-[150px] md:h-[210px] bg-blue-100 rounded-2xl relative"
          >
            <img
              className="object-cover w-full h-full md:h-[160px] rounded-2xl"
              src={candidateAvatar != '' ? candidateAvatar : DefaultAvatar}
            />
            <div
              onClick={onVideo}
              className="hidden md:flex items-center justify-center space-x-[6px] h-12.5 cursor-pointer"
            >
              <div className="flex items-center justify-center bg-white p-[4px] rounded w-[18px] h-[18px]">
                <PolygonIcon />
              </div>
              <div className="uppercase font-bold text-white leading-[17px]">
                Video
              </div>
            </div>
            <div
              onClick={onVideo}
              className="absolute bottom-[4px] left-[4px] block md:hidden"
            >
              <img
                className="w-[24px] h-[24px]"
                alt="play-icon"
                src={PlayIcon}
              />
            </div>
          </div>
        ) : (
          <div className="w-[80px] md:w-[100px] h-[110px] md:h-[150px]">
            <img
              className="object-cover w-full h-full rounded-2xl"
              src={candidateAvatar != '' ? candidateAvatar : DefaultAvatar}
            />
          </div>
        )}

        <div className="space-y-3 flex-1" onClick={moveToCandidateDetail}>
          <div className="flex items-center justify-between md:justify-start space-x-[9px]">
            <h6 className="text-black-100 leading-6 text-[14px] md:text-xl font-semibold">
              {/* Show first name on small screens */}
              <span className="block md:hidden">{data.name.split(' ')[0]}</span>
              {/* Show full name on medium and larger screens */}
              <span className="hidden md:block">{data.name}</span>
            </h6>
            {data.num_experience !== '' && (
              <div className="hidden md:inline-block uppercase text-green-100 text-[14px] md:text-xs font-bold leading-4 px-3 py-[1px] md:border-[1px] border-green-100 border-solid rounded-lg">
                {data.num_experience} Years Exp.
              </div>
            )}
          </div>
          <div className="flex flex-wrap items-center space-x-[10px] md:space-x-2.5">
            <div className="text-blue-100 leading-[18px] md:leading-4 text-[11px] md:text-sm font-semibold">
              {truncateString(data.job_title, 20)}
            </div>
            {/* <div className="w-[2px] h-[2px] bg-[#73747A]" /> */}
            {/* <div className="text-gray-1200 text-[11px] font-semibold leading-[18px] md:leading-4">
              {data.language}
            </div> */}
            <div className="w-[2px] h-[2px] bg-[#73747A]" />
            <div className="text-gray-1200 text-[11px] font-semibold leading-[18px] md:leading-4">
              {data.country}
            </div>
          </div>
          <div className="text-sm leading-6 text-gray-100 whitespace-normal hidden md:block">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: sanitisedBio.substring(0, 200),
              }}
            />{' '}
            {sanitisedBio.length > 200 && (
              <span className="font-bold text-blue-300 cursor-pointer">
                Read More
              </span>
            )}
          </div>
          <div className="hidden md:flex md:flex-wrap">
            {showMoreSkills
              ? data.skills.map((v) => (
                  <div
                    className="text-gray-100 leading-5 text-xs font-semibold py-[5px] px-[15px] rounded-lg border border-gray-500 border-solid mb-[5px] mr-[5px]"
                    key={v}
                  >
                    {v}
                  </div>
                ))
              : data.skills.slice(0, 3).map((v) => (
                  <div
                    className="text-gray-100 leading-5 text-xs font-semibold py-[5px] px-[15px] rounded-lg border border-gray-500 border-solid mb-[5px] mr-[5px]"
                    key={v}
                  >
                    {v}
                  </div>
                ))}
            {data.skills.length > 3 && (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setShowMoreSkills((prev) => !prev)
                }}
                variant="secondary"
                className="px-5 py-[6px] text-xs h-8"
              >
                {showMoreSkills ? 'Show Less' : 'Show More'}
              </Button>
            )}
          </div>
          <div className="flex items-center justify-between md:hidden">
            <div>
              {/* Show hourly rate if it's greater than 0 */}
              {data.hourly_rate > 0 && (
                <div className="flex space-x-[7px]">
                  <div className="text-green-100 leading-[17px] text-[14px] font-bold">
                    ${data.hourly_rate}
                  </div>
                  <div className="text-gray-100 leading-[15px] text-[12px]">
                    Per hour
                  </div>
                </div>
              )}

              {/* Show annual rate if it's greater than 0 */}
              {data.annual_rate > 0 && (
                <div className="flex space-x-[7px] mt-2">
                  <div className="text-green-100 leading-[17px] text-[14px] font-bold">
                    ${data.annual_rate}
                  </div>
                  <div className="text-gray-100 leading-[15px] text-[12px]">
                    Annual
                  </div>
                </div>
              )}
            </div>
            <div className="rounded-xl bg-green-100 mt-2.5">
              <div
                className="flex items-center justify-center px-[14px] py-[6px] md:py-[11px] space-x-2 cursor-pointer"
                onClick={() => handleInvite(data.id)}
              >
                <CirclePlusIcon />
                <span className="text-sm font-bold leading-[17px] text-white">
                  Invite
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block md:w-[110px]">
          {!data.isHide && (
            <div>
              {/* Check and display each rate individually */}
              <div className="space-y-2">
                {/* Show hourly rate if greater than 0 */}
                {data.hourly_rate > 0 && (
                  <div className="rounded-xl border border-solid border-gray-500 bg-[#F7F8FC] py-[12px]">
                    <div className="text-green-100 leading-6 text-[20px] font-bold text-center">
                      ${data.hourly_rate}
                    </div>
                    <div className="text-gray-100 text-xs leading-4 font-medium text-center">
                      per hour
                    </div>
                  </div>
                )}

                {/* Show annual rate if greater than 0 */}
                {data.annual_rate > 0 && (
                  <div className="rounded-xl border border-solid border-gray-500 bg-[#F7F8FC] py-[12px]">
                    <div className="text-green-100 leading-6 text-[20px] font-bold text-center">
                      ${kFormatter(data.annual_rate)}
                    </div>
                    <div className="text-gray-100 text-xs leading-4 font-medium text-center">
                      Annual
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {hideInviteBtn ? null : (
            <div className="rounded-xl bg-green-100 mt-2.5">
              <div
                className="flex items-center justify-center px-[14px] py-[6px] md:py-[11px] space-x-2 cursor-pointer"
                onClick={() => handleInvite(data.id)}
              >
                <CirclePlusIcon />
                <span className="text-sm font-bold leading-[17px] text-white">
                  Invite
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap md:hidden gap-x-[5px] gap-y-[4px] mt-[20px] items-center">
        {showMoreSkills
          ? data.skills.map((v) => (
              <div
                className="text-gray-100 leading-5 text-[12px] md:text-xs font-semibold py-[5px] px-[15px] rounded-lg border border-gray-500 border-solid flex items-center"
                key={v}
              >
                {v}
              </div>
            ))
          : data.skills.slice(0, 2).map((v) => (
              <div
                className="text-gray-100 leading-5 text-xs font-semibold py-[5px] px-[15px] rounded-lg border border-gray-500 border-solid flex items-center"
                key={v}
              >
                {v}
              </div>
            ))}
        {data.skills.length > 2 && (
          <div className="w-full mt-1">
            <Button
              onClick={(e) => {
                e.stopPropagation()
                setShowMoreSkills((prev) => !prev)
              }}
              variant="secondary"
              className="px-5 py-[6px] text-xs h-8"
            >
              {showMoreSkills ? 'Show Less' : 'Show More'}
            </Button>
          </div>
        )}
      </div>

      <VideoPlayerModal
        isOpen={showVideoPlayerModal}
        closeModal={() => setShowVideoPlayerModal(false)}
        url={data.video}
      />
    </div>
  )
}
