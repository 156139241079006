import React from 'react'
import JobIcon from 'assets/img/jobIcon.png'

function VideoCard({ name, job, location, vid }) {
  return (
    <div className="relative w-[194px] h-[251px] mt-[8.125px]">
      {/* Video Section */}
      <video
        className="w-full h-full object-cover rounded-[8.125px]"
        src={vid}
        autoPlay
        loop
        muted
      ></video>

      {/* Overlay Content */}
      <div className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-4 text-white">
        <h3 className="text-white font-manrope text-[13.358px] not-italic font-bold leading-normal">
          {name}
        </h3>
        <div className="flex items-center justify-between mt-1">
          {/* Role Section */}
          <div className="flex items-center gap-1">
            <img
              src={JobIcon}
              alt="Role Icon"
              className="w-[9.967px] h-[10.323px]"
            />
            <span className="text-white text-center font-manrope text-[8.349px] not-italic font-medium leading-normal">
              {job}
            </span>
          </div>

          {/* Country Section */}
          <div className="flex items-center gap-1">
            <img
              src={`https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/${location
                ?.toLowerCase()
                .replace(/ /g, '+')}_flag.png`}
              alt="Flag Icon"
              className="w-[13.358px] h-[13.358px]"
            />
            <span className="text-white text-center font-manrope text-[8.331px] not-italic font-medium leading-normal">
              {location}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoCard
