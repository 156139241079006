//packages
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'

//custom components
import CompanyIntroductionPanel from './components/CompanyIntroductionPanel'
import ContactInformation from './components/ContactInformation'
import TopHeader from './components/TopHeader'

//redux
import { getUser } from 'app/store/profileSlice'
import { PencilIcon } from 'components/Icons'
import { updateUserLastActivity } from 'app/services/auth.service'
import { Helmet } from 'react-helmet'

export default function Profile() {
  const profile = useSelector(getUser)
  const navigate = useNavigate()

  useEffect(async () => {
    //Update user last activity
    if (profile) {
      await updateUserLastActivity(profile.id)
    }
  }, [])

  const onEdit = () => {
    navigate(`/${profile.type}/setting`)
  }

  // console.log('debug: profile', profile)

  if (!profile) return null

  return (
    <div className="md:min-h-screen px-[15px] pb-[90px] py-[30px] md:py-12 md:pb-12 text-base max-w-[1055px] mx-auto">
      <Helmet>
        <title>Employer Profile Management - Remoterecruit.com</title>
        <meta
          name="description"
          content="Optimize your employer profile to attract the best remote talent worldwide."
        />
      </Helmet>
      <div className="flex justify-between">
        <div className="text-black-100 mb-[51px]">
          <h5 className="leading-[21px] md:leading-[32px] font-medium text-[14px] md:text-[24px]">
            Welcome,{' '}
            {profile.emType === 'individual'
              ? `${profile.firstName} ${profile.lastName}!`
              : profile.firstName + '!'}
          </h5>
          <h2 className="leading-[36px] md:leading-[72px] font-semibold text-[24px] md:text-5xl">
            Your Profile
          </h2>
        </div>
        <div className="block md:hidden">
          <div
            className="w-[42px] h-[42px] flex justify-center items-center bg-white rounded-full group cursor-pointer shadow-lg"
            onClick={onEdit}
          >
            <PencilIcon className="text-black-100 group-hover:text-green-100" />
          </div>
        </div>
      </div>

      <TopHeader profile={profile} />
      <div
        className={clsx(
          'space-y-[20px] md:space-y-0 mt-14',
          profile.emType === 'individual'
            ? ''
            : 'md:grid md:gap-x-10 xl:gap-x-16 grid-cols-[23rem_minmax(600px,_1fr)]'
        )}
      >
        {profile.emType === 'company' && (
          <div>
            <ContactInformation profile={profile} />
          </div>
        )}
        <div>
          <CompanyIntroductionPanel profile={profile} />
        </div>
      </div>
    </div>
  )
}
