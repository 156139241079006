//packages
import moment from 'moment'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'

//custom components
import Checkbox from 'components/Checkbox/Checkbox'
import { CheckBadgeIcon } from 'components/Icons'
import { AnimateSpinIcon } from 'components/Icons'

export default function CancelPopupModal({
  isOpen,
  closeModal,
  profile,
  cancelHandler,
  loading,
}) {
  const [reason, setReason] = useState('')
  useEffect(() => {
    setReason('')
  }, [isOpen])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#18326A] bg-opacity-90">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[616px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-cancel-popup bg-cover w-full h-[90px] z-[40] absolute top-[20px] flex justify-between items-center px-[33px]">
                  <div className="text-sm text-white">
                    Effective until: &nbsp;
                    {moment(profile.last_upgrade)
                      .add(1, 'month')
                      .format('MMM DD, YYYY')}
                  </div>
                  <button className="flex items-center space-x-2 py-2 px-[10px] bg-white rounded-3xl hover:bg-gray-200">
                    <CheckBadgeIcon />
                    <span className="text-black font-bold text-xs">
                      Premium
                    </span>
                  </button>
                </div>
                <div className="bg-white rounded-2xl mx-[13px] pt-[110px] px-[58px] pb-[76px]">
                  <h5 className="text-sm text-center uppercase text-gray-300 mt-10">
                    Cancel Membership
                  </h5>
                  <h3 className="text-black-100 text-[24px] font-semibold text-center mt-2.5">
                    We're sorry to see you go
                  </h3>
                  <div className="text-center text-black-100 opacity-50 text-sm mt-3">
                    Please consider helping us to improve the user experience by
                    providing a reason for your subscription cancellation.
                  </div>
                  <div className="mt-[30px]">
                    <Checkbox
                      variant="primary"
                      label="I found an alternative solution to use"
                      value={reason === 'alternative_solution'}
                      onChange={(e) =>
                        e.target.checked
                          ? setReason('alternative_solution')
                          : ''
                      }
                    />
                    <Checkbox
                      variant="primary"
                      label="No longer needed it, I've closed my company."
                      value={reason === 'no_longer_needed'}
                      onChange={(e) =>
                        e.target.checked ? setReason('no_longer_needed') : ''
                      }
                    />
                    <Checkbox
                      variant="primary"
                      label="It was too hard to use."
                      value={reason === 'hard_to_use'}
                      onChange={(e) =>
                        e.target.checked ? setReason('hard_to_use') : ''
                      }
                    />
                    <Checkbox
                      variant="primary"
                      label="This is only temporary, I'll be back soon!"
                      value={reason === 'temporary'}
                      onChange={(e) =>
                        e.target.checked ? setReason('temporary') : ''
                      }
                    />
                    <Checkbox
                      variant="primary"
                      label="It didn't meet my needs."
                      value={reason === 'not_meet_my_needs'}
                      onChange={(e) =>
                        e.target.checked ? setReason('not_meet_my_needs') : ''
                      }
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-x-[15px] mt-10">
                    <button
                      disabled={loading}
                      onClick={closeModal}
                      className="rounded-2xl w-full bg-green-100 text-white font-bold text-sm py-[18px] hover:opacity-90"
                    >
                      Go Back
                    </button>
                    <button
                      disabled={loading}
                      onClick={cancelHandler}
                      className="justify-center rounded-2xl w-full bg-[#808191] text-white font-bold text-sm py-[18px] hover:opacity-90 flex items-center"
                    >
                      Cancel Membership
                      {loading && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
