//packages
import { Dialog, Transition } from '@headlessui/react'
import { useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { PortfolioPlaceholderIcon } from 'components/Icons'
import { v4 } from 'uuid'
import * as yup from 'yup'

//custom components
import { editProject } from 'app/store/profileSlice'
import Button from 'components/Button'
import {
  CirclePlusIcon,
  CrossWithCircleIcon,
  ModalCloseIcon,
} from 'components/Icons'
import TextArea from 'components/Input/TextArea'
import TextField from 'components/Input/TextField'

//redux & api
import AuthService from 'app/services/auth.service'
import { getUser } from 'app/store/profileSlice'
import { getBase64StringFromDataURL } from 'app/utils/function'

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
})

export default function EditProjectModal({
  isOpen,
  closeModal,
  project,
  onDeleteHandler,
  isDeleting,
}) {
  const [files, setFiles] = useState([])
  const [urls, setURLs] = useState([])

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await handleAddProject(values)
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (project) {
      let param = {
        title: project.title,
        description: project.description,
      }
      setURLs(project.imageUrls ? project.imageUrls : [])
      // formik.setFieldValue(param)
      formik.setValues(param)
    }
  }, [project])

  const handleAddProject = async (values) => {
    try {
      formik.setSubmitting(true)
      let imageUrls = []
      if (files.length > 0) {
        const paramsForFileUploads = {
          files: files.map((file) => {
            return {
              ...file,
              fileContent: getBase64StringFromDataURL(file.fileContent),
            }
          }),
        }
        let results = await AuthService.uploadFilesToS3(paramsForFileUploads)
        imageUrls = results.data.urls
      }

      const params = {
        userId: profile.id,
        project: {
          id: project.id,
          title: values.title,
          description: values.description,
          imageUrls: [...urls, ...imageUrls],
        },
      }

      let resultAction = await dispatch(editProject(params))

      if (editProject.fulfilled.match(resultAction)) {
        toast.success('Edited Project Successfully')
        setURLs([])
        setFiles([])
        formik.resetForm()
        closeModal()
      } else {
        if (resultAction.payload) {
          toast.error(
            'Edited Project failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Edit Project failed' + resultAction.error.message)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const handleFileChange = (e) => {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      const array = e.target.files[0].name.split('.')
      const extension = array[array.length - 1]
      const param = {
        fileContent: reader.result,
        fileName: `${v4()}.${extension}`,
      }
      setFiles([...files, param])
    }
    reader.onerror = () => {
      console.log('error on load image')
    }
  }

  const handleRemoveFile = (fileIndex) => {
    setFiles((prev) => prev.filter((file, index) => index !== fileIndex))
  }

  const handleRemoveURL = (urlIndex) => {
    setURLs((prev) => prev.filter((url, index) => index !== urlIndex))
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black-background-overlay bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white p-8 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer" />
                  </div>

                  {/* Body Section */}
                  <div>
                    <h3 className="text-black-100 leading-6 text-lg font-medium">
                      Edit Project
                    </h3>
                    <p className="text-gray-100 text-sm leading-4 font-medium mt-4">
                      Please add a title and brief description of the project,
                      then upload some images that best exemplify the project.
                    </p>
                    <h4 className="text-sm text-black-100 leading-4 font-semibold mt-10 mb-8">
                      Details
                    </h4>
                    <TextField
                      value={formik.values['title']}
                      label="Title"
                      name="title"
                      id="title"
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      error={
                        formik.touched['title'] ? formik.errors['title'] : ''
                      }
                    />
                    <div className="mt-5">
                      <TextArea
                        value={formik.values['description']}
                        onChange={formik.handleChange}
                        label="Project Description"
                        name="description"
                        id="description"
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched['description']
                            ? formik.errors['description']
                            : ''
                        }
                      />
                    </div>

                    <div className="h-[1px] bg-gray-200 mt-8"></div>

                    <div>
                      <div className="flex justify-between mt-8">
                        <h4 className="text-black-100 font-semibold text-sm leading-4">
                          Uploads
                        </h4>
                        <div className="relative">
                          <CirclePlusIcon
                            size={24}
                            className="text-white cursor-pointer"
                            circleFillColor="#52B4DA"
                          />
                          <input
                            onChange={handleFileChange}
                            type="file"
                            disabled={formik.isSubmitting}
                            className="opacity-0 z-[10] absolute top-0 w-full h-full right-0 overflow-hidden"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-6 mt-9">
                        {urls.map((url, index) => (
                          <div key={index} className="relative">
                            {url.endsWith('.ppt') ||
                            url.endsWith('.doc') ||
                            url.endsWith('.docx') ||
                            url.endsWith('.xls') ||
                            url.endsWith('.html') ||
                            url.endsWith('.csv') ||
                            url.endsWith('.pdf') ||
                            url.endsWith('.txt') ||
                            url.endsWith('.mp4') ? (
                              <div className="w-full h-44 rounded-3xl bg-[#E0E5F9] flex justify-center items-center">
                                <PortfolioPlaceholderIcon/>
                              </div>
                            ) : (
                              <img
                                className="w-44 h-32 bg-cover"
                                src={url}
                                alt="uploaded-portfolio-image"
                              />
                            )}
                            <div
                              className="absolute top-2 right-2 cursor-pointer"
                              onClick={() => handleRemoveURL(index)}
                            >
                              <CrossWithCircleIcon className="text-black-100 hover:scale-150 transition-all duration-300" />
                            </div>
                          </div>
                        ))}
                        {files.map((file, index) => (
                          <div key={index} className="relative">
                            <img
                              className="w-44 h-32 bg-cover"
                              src={file.fileContent}
                              alt="uploaded-portfolio-image"
                            />
                            <div
                              className="absolute top-2 right-2 cursor-pointer"
                              onClick={() => handleRemoveFile(index)}
                            >
                              <CrossWithCircleIcon className="text-black-100 hover:scale-150 transition-all duration-300" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mt-7 w-full flex justify-start space-x-3">
                    <Button
                      variant="primary"
                      className="leading-5 cursor-pointer text-sm font-bold px-8"
                      onClick={formik.handleSubmit}
                      disabled={formik.isSubmitting || isDeleting}
                    >
                      Save Changes
                    </Button>
                    <Button
                      disabled={isDeleting}
                      onClick={() => {
                        onDeleteHandler()
                      }}
                      variant="text"
                      className="leading-5 text-bold text-sm text-red-100 py-4 font-bold flex justify-center items-center px-5"
                    >
                      Delete Project
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
