//packages
import { Popover } from '@headlessui/react'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { getMessages, sendMessage, deleteChat } from 'react-chat-engine'
import moment from 'moment'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

//custom components
import Button from 'components/Button'
import { DeleteIcon, DetailPopoverIcon } from 'components/Icons'
import { BlockIcon } from 'components/Icons'
import NotStartedChannelPanel from 'views/employer/messages/components/NotStartedChannelPanel'

//utils & function
import { stripTags } from 'app/utils/function'
import ChatForm from './ChatForm'
import { useSelector } from 'react-redux'
import { getUser, getProfile } from 'app/store/profileSlice'
import clsx from 'clsx'
import AvatarRenderer from 'views/employer/messages/components/AvatarRenderer'
import NameRenderer from 'views/employer/messages/components/NameRenderer'

export default function ({
  selectedChat,
  messages = [],
  setMessages,
  connected,
  creds,
  chats,
}) {
  const navigate = useNavigate()
  const [newMessage, setNewMessage] = useState('')
  const [nameSender, setNameSender] = useState('')
  const [isChatNotStarted, setIsChatNotStarted] = useState(true)

  const bottomRef = useRef(null)
  const profile = useSelector(getUser)

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  useEffect(async () => {
    if (selectedChat) {
      try {
        getMessages(creds, selectedChat.id, (_, result) => {
          setMessages(result)
        })

        // get sender name
        let userData = _.find(
          selectedChat.people,
          (v) => v.person.username !== profile.id
        )
        await getProfile(userData.person.username).then((response) => {
          if (response.data.user.emType === 'company') {
            setNameSender(response.data.user.companyName)
          } else if (response.data.user.emType === 'individual') {
            let nameIndi =
              response.data.user.firstName + ' ' + response.data.user.lastName
            setNameSender(nameIndi)
          } else {
            setNameSender(response.data.user.name)
          }
        })
      } catch (err) {
        console.log('debug: err', err)
      }
    }
  }, [selectedChat, chats])

  useEffect(() => {
    let employerMessage = messages.find(
      (v) => v.sender.first_name === 'employer'
    )
    if (employerMessage) {
      setIsChatNotStarted(false)
    }
  }, [messages])

  const handleSendMessage = useCallback(async () => {
    let chatConfig = {
      userSecret: profile.id,
      userName: profile.id,
      projectID: process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID,
    }
    await sendMessage(chatConfig, selectedChat.id, {
      text: newMessage,
      files: [],
      sender_username: 'Paul Kitonyi',
    })
    setNewMessage('')
  }, [newMessage])

  const deleteChatHandler = () => {
    deleteChat(creds, selectedChat.id)
  }

  const onClickProfile = () => {
    navigate(`/jobseeker/profile/${selectedChat.admin.username}`)
  }

  if (!selectedChat || !connected) return null

  if (isChatNotStarted) return <NotStartedChannelPanel />

  return (
    <div className="mt-[20px] md:mt-0 relative bg-white rounded-3xl py-[20px] px-[15px] md:px-8 md:py-8 md:ml-7 md:mr-15 w-full max-h-[calc(100vh-100px)] flex flex-col">
      <div className="justify-between flex">
        <p
          onClick={onClickProfile}
          className="text-black-100 leading-8 text-2xl font-medium cursor-pointer"
        >
          {nameSender}
        </p>
        <Popover className="relative">
          <Popover.Button>
            <DetailPopoverIcon className="cursor-pointer text-black-100" />
          </Popover.Button>

          <Popover.Panel className="absolute z-10 right-0 bg-white rounded-lg shadow-popover w-56 p-[10px] space-y-1">
            <Button
              style={{ justifyContent: 'flex-start' }}
              className="w-full justify-start rounded-md text-sm flex items-center space-x-3 px-3"
              variant="secondary"
              onClick={deleteChatHandler}
            >
              <DeleteIcon />
              <span>Delete Conversation</span>
            </Button>
            <Button
              style={{ justifyContent: 'flex-start' }}
              className="w-full  rounded-md text-sm flex items-center space-x-3 justify-start px-3"
              variant="error"
            >
              <BlockIcon />
              <span>Block User</span>
            </Button>
          </Popover.Panel>
        </Popover>
      </div>
      <div className="text-base leading-6 text-gray-100">
        {selectedChat?.title}
      </div>

      {/* Main Panel for the Message */}
      <div
        className="mt-10 flex-1 overflow-auto max-h-[400px]"
        style={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto' }}
      >
        {messages.map((v) => (
          <div className="flex space-2" key={v.id}>
            <div className="w-18 h-18 md:w-21 md:h-21 p-3">
              <AvatarRenderer sender={v.sender} />
            </div>
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <NameRenderer sender={v.sender} />

                <div className="text-gray-300 leading-4 text-xs leading-medium">
                  {moment(v.created).fromNow()}
                </div>
              </div>
              <div
                className={clsx(
                  profile.id === v.sender.username
                    ? 'bg-blue-200 text-white'
                    : 'text-gray-100 bg-[rgba(228,228,228,0.25)]',
                  ' text-sm  rounded-t-3xl rounded-br-md rounded-bl-3xl py-3 px-6'
                )}
              >
                {stripTags(v.text)}
              </div>
            </div>
          </div>
        ))}
        <div ref={bottomRef} />
      </div>

      <ChatForm
        handleSendMessage={handleSendMessage}
        value={newMessage}
        setValue={setNewMessage}
      />
    </div>
  )
}
