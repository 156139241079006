import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  acceptApplicantAPI,
  rejectApplicantAPI,
} from 'app/services/applicantAPI'
import {
  createApplicationAPI,
  fetchApplicantsAPI,
  withdrawApplicationAPI,
} from 'app/services/applicantAPI'
import {
  rejectInviteAPI,
  getInvitesList,
  approveInviteAPI,
  unRejectInviteAPI,
} from 'app/services/inviteAPI'

export const createApplication = createAsyncThunk(
  'applicants/createApplication',
  async (data) => {
    const response = await createApplicationAPI(data)
    return response.data
  }
)

export const fetchApplicantsList = createAsyncThunk(
  'applicants/fetchApplicantsList',
  async (data) => {
    const response = await fetchApplicantsAPI(data)
    return response.data
  }
)

export const rejectApplicant = createAsyncThunk(
  'applicants/rejectApplicant',
  async (data) => {
    const response = await rejectApplicantAPI(data)
    return response.data
  }
)

export const acceptApplicant = createAsyncThunk(
  'applicants/acceptApplicant',
  async (data) => {
    const response = await acceptApplicantAPI(data)
    return response.data
  }
)

export const fetchInvitesList = createAsyncThunk(
  'applicants/fetchInvitesList',
  async (data) => {
    const response = await getInvitesList(data)
    return response.data
  }
)

export const withdrawApplication = createAsyncThunk(
  'applicants/withdrawApplication',
  async (data) => {
    const response = await withdrawApplicationAPI(data)
    return response.data
  }
)

export const rejectInvite = createAsyncThunk(
  'invites/rejectInvite',
  async (data) => {
    const response = await rejectInviteAPI(data)
    return response.data
  }
)

export const unRejectInvite = createAsyncThunk(
  'invites/unRejectInvite',
  async (data) => {
    const response = await unRejectInviteAPI(data)
    return response.data
  }
)

export const approveInvite = createAsyncThunk(
  'invites/approveInvite',
  async (data) => {
    try {
      const response = await approveInviteAPI(data)
      return response.data
    } catch (err) {
      console.log('hey error', err)
    }
  }
)

const initialState = {
  applicants: [],
  invites: [], //used for contractor to get invites
  selectedApplicant: null,
  count: 0,
  invitesCount: 0,
  createLoading: 'idle' /** pending | succeeded | failed */,
  invitesFetchLoading: 'idle',
  rejectInviteLoading: 'idle',
  acceptInviteLoading: 'idle',
}

const applicantsSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    resetAllStatus: (state, action) => {
      state.createLoading = 'idle'
      state.invitesFetchLoading = 'idle'
      state.rejectInviteLoading = 'idle'
      state.acceptInviteLoading = 'idle'
    },
    setSelectApplicant: (state, action) => {
      state.selectedApplicant = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createApplication.fulfilled, (state, action) => {
      state.createLoading = 'succeeded'
      state.applicants = [...state.applicants, action.payload]
    })
    builder.addCase(createApplication.pending, (state, action) => {
      state.createLoading = 'pending'
    })
    builder.addCase(createApplication.rejected, (state, action) => {
      state.createLoading = 'failed'
    })

    //fetch list
    builder.addCase(fetchApplicantsList.fulfilled, (state, action) => {
      state.count = action.payload.count
      state.applicants = action.payload.applications
    })

    builder.addCase(withdrawApplication.fulfilled, (state, action) => {
      state.applicants = state.applicants.filter(
        (v) => v.id !== state.selectedApplicant.id
      )
      state.selectedApplicant = null
    })

    //fetch invites list for the contractor;
    builder.addCase(fetchInvitesList.fulfilled, (state, action) => {
      state.invites = action.payload.invites
      state.invitesCount = action.payload.count
      state.invitesFetchLoading = 'succeeded'
    })
    builder.addCase(fetchInvitesList.pending, (state, action) => {
      state.invitesFetchLoading = 'pending'
    })
    builder.addCase(fetchInvitesList.rejected, (state, action) => {
      state.invitesFetchLoading = 'failed'
    })

    //reject Invite
    builder.addCase(rejectInvite.fulfilled, (state, action) => {
      // state.invites = state.invites.filter(v => v.id !== action.payload.Attributes.id);
      state.invites = state.invites.map((v) => {
        if (v.id === action.payload.Attributes.id) {
          return {
            ...v,
            status: 'rejected',
          }
        }
        return v
      })
      state.selectedApplicant = null
      state.rejectInviteLoading = 'succeeded'
    })

    //un-reject Invite
    builder.addCase(unRejectInvite.fulfilled, (state, action) => {
      state.invites = state.invites.map((v) => {
        if (v.id === action.payload.Attributes.id) {
          return {
            ...v,
            status: 'pending',
          }
        }
        return v
      })
      state.selectedApplicant = null
    })

    //approve Invite
    builder.addCase(approveInvite.fulfilled, (state, action) => {
      state.acceptInviteLoading = 'succeeded'
      state.invites = state.invites.filter((v) => v.id !== action.payload.id)
      state.selectedApplicant = null
    })
    builder.addCase(approveInvite.pending, (state, action) => {
      state.rejectInviteLoading = 'pending'
    })
    builder.addCase(approveInvite.rejected, (state, action) => {
      state.rejectInviteLoading = 'failed'
    })

    builder.addCase(rejectApplicant.fulfilled, (state, action) => {
      state.applicants = state.applicants.map((v) => {
        if (v.id === action.payload.id) {
          return {
            ...v,
            status: 'rejected',
          }
        }
        return v
      })
    })
    builder.addCase(acceptApplicant.fulfilled, (state, action) => {
      state.applicants = state.applicants.map((v) => {
        if (v.id === action.payload.id) {
          return {
            ...v,
            status: 'accepted',
          }
        }
        return v
      })
    })
    //Clear data after user logout
    builder.addCase('auth/LogOut', (state) => {
      state.invites = []
      state.applicants = []
    })
  },
})

export const getInvitesListForEmployer = (state) => state.applicants.invites
export const getApplicantsList = (state) => state.applicants.applicants

export const { resetAllStatus, setSelectApplicant } = applicantsSlice.actions

export default applicantsSlice.reducer
