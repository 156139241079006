import React from 'react'

export default function PencilIcon({
  size = 14,
  className = 'text-green-100',
}) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_165)">
        <path
          d="M0.980647 14C0.841005 13.9997 0.703037 13.9696 0.575936 13.9118C0.448836 13.8539 0.335521 13.7697 0.243542 13.6646C0.151563 13.5595 0.0830331 13.4361 0.0425173 13.3024C0.00200158 13.1688 -0.00956913 13.0281 0.00857618 12.8896L0.35967 10.2066C0.391752 9.96382 0.502826 9.73831 0.675764 9.56488L9.92588 0.31476C10.1279 0.11327 10.4016 0.00012207 10.687 0.00012207C10.9723 0.00012207 11.2461 0.11327 11.4481 0.31476L13.6854 2.55203C13.8869 2.75408 14 3.02779 14 3.31314C14 3.59849 13.8869 3.8722 13.6854 4.07425L4.43526 13.3244C4.26186 13.4974 4.03633 13.6084 3.7935 13.6405L1.11053 13.9916C1.06747 13.9972 1.02408 14.0001 0.980647 14ZM1.70389 10.4702L1.42908 12.571L3.5299 12.2962L12.5129 3.31328L10.6869 1.48726L1.70389 10.4702Z"
          fill="currentColor"
        />
        <path
          d="M11.1251 6.35141C11.0354 6.35168 10.9465 6.33423 10.8635 6.30007C10.7806 6.26591 10.7052 6.21571 10.6417 6.15235L7.84767 3.35837C7.71945 3.23 7.64748 3.05597 7.64758 2.87454C7.64769 2.69311 7.71986 2.51916 7.84822 2.39094C7.97658 2.26273 8.15061 2.19075 8.33204 2.19086C8.51347 2.19096 8.68742 2.26313 8.81564 2.39149L11.6085 5.18438C11.7042 5.27998 11.7693 5.4018 11.7957 5.53442C11.8221 5.66704 11.8085 5.80451 11.7568 5.92944C11.705 6.05436 11.6174 6.16113 11.5049 6.23625C11.3925 6.31136 11.2603 6.35144 11.1251 6.35141Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_165">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
