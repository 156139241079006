import React from 'react'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'
export default function YourJobPostsContainer() {
  return (
    <div>
      <Helmet>
        <title>Employer Job Management - Remoterecruit.com</title>
        <meta
          name="description"
          content="Post and manage your remote job listings effectively to attract skilled professionals."
        />
      </Helmet>
      <Outlet />
    </div>
  )
}
