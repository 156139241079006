import React from 'react'

export default function ChatIcon2({ className = 'text-white', size = 14 }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1482_12308)">
        <path
          d="M5.82758 13.2883C5.47758 13.2883 5.14508 13.1133 4.91758 12.8042L4.21759 11.8708C4.21759 11.8767 4.18841 11.8592 4.17675 11.8592H3.96092C1.96592 11.8592 0.729248 11.3167 0.729248 8.6275V6.29417C0.729248 3.83834 2.22842 3.19667 3.48842 3.08584C3.62842 3.06834 3.79175 3.0625 3.96092 3.0625H7.69425C9.80591 3.0625 10.9259 4.18251 10.9259 6.29417V8.6275C10.9259 8.79667 10.9201 8.96 10.8967 9.1175C10.7917 10.36 10.1501 11.8592 7.69425 11.8592H7.46092L6.73758 12.8042C6.51008 13.1133 6.17758 13.2883 5.82758 13.2883ZM3.96092 3.9375C3.82675 3.9375 3.69842 3.94333 3.57591 3.955C2.22258 4.07167 1.60425 4.81251 1.60425 6.29417V8.6275C1.60425 10.6283 2.22259 10.9842 3.96092 10.9842H4.19425C4.45675 10.9842 4.75424 11.13 4.91758 11.34L5.61758 12.2792C5.74592 12.4542 5.90925 12.4542 6.03758 12.2792L6.73758 11.3458C6.90675 11.1183 7.17508 10.9842 7.46092 10.9842H7.69425C9.17591 10.9842 9.91675 10.36 10.0276 9.03C10.0451 8.89 10.0509 8.76167 10.0509 8.6275V6.29417C10.0509 4.66667 9.32175 3.9375 7.69425 3.9375H3.96092Z"
          fill="currentColor"
        />
        <path
          d="M5.82772 8.27751C5.50105 8.27751 5.24438 8.01501 5.24438 7.69417C5.24438 7.37334 5.50688 7.11084 5.82772 7.11084C6.14853 7.11084 6.41103 7.37334 6.41103 7.69417C6.41103 8.01501 6.15437 8.27751 5.82772 8.27751Z"
          fill="currentColor"
        />
        <path
          d="M7.69393 8.27751C7.36726 8.27751 7.1106 8.01501 7.1106 7.69417C7.1106 7.37334 7.3731 7.11084 7.69393 7.11084C8.01476 7.11084 8.27726 7.37334 8.27726 7.69417C8.27726 8.01501 8.01476 8.27751 7.69393 8.27751Z"
          fill="currentColor"
        />
        <path
          d="M3.96712 8.27751C3.64046 8.27751 3.38379 8.01501 3.38379 7.69417C3.38379 7.37334 3.64629 7.11084 3.96712 7.11084C4.28796 7.11084 4.55046 7.37334 4.55046 7.69417C4.55046 8.01501 4.28796 8.27751 3.96712 8.27751Z"
          fill="currentColor"
        />
        <path
          d="M10.4648 9.50234C10.3481 9.50234 10.2314 9.45567 10.1498 9.36817C10.0564 9.27484 10.0157 9.14067 10.0332 9.01234C10.0507 8.88984 10.0564 8.7615 10.0564 8.62734V6.294C10.0564 4.66651 9.32733 3.93734 7.69983 3.93734H3.96645C3.83229 3.93734 3.70397 3.94317 3.58147 3.95484C3.45314 3.97234 3.31896 3.92566 3.22563 3.83816C3.13229 3.74483 3.07979 3.61651 3.09145 3.48818C3.19645 2.22818 3.84395 0.729004 6.29977 0.729004H10.0332C12.1448 0.729004 13.2648 1.84901 13.2648 3.96068V6.294C13.2648 8.74984 11.7657 9.3915 10.5057 9.50234C10.4882 9.50234 10.4764 9.50234 10.4648 9.50234ZM4.03647 3.06234H7.69394C9.8056 3.06234 10.9257 4.18234 10.9257 6.294V8.5515C11.9173 8.3065 12.384 7.57734 12.384 6.294V3.96068C12.384 2.33318 11.6548 1.604 10.0273 1.604H6.29399C5.01063 1.604 4.2873 2.07067 4.03647 3.06234Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1482_12308">
          <rect width="14" height="14" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
