import React from 'react'
import VideoCard from './VideoCard'
import TextCard from './TextCard'
import whyRRCard from 'assets/img/why.png'
import TalentFromEveryCornerCard from './TalentFromEveryCornerCard'
import InversedTextCard from './InversedTextCard'
import TheRightFitTextCard from './TheRightFitTextCard'

export default function HeroSection() {
  return (
    <div className="flex items-center justify-center gap-[9.37px] relative top-16 h-[702px] mx-6 mb-[67px] mt-[57.73px] z-0">
      <div>
        <VideoCard
          name="Loyid Tsige"
          job="Sales Dev Rep"
          location="Ethiopia"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/c1ab/0c00/-a512-4bb2-8d37-ec2a7678ba0d?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T0st5GlVewY2rUKorRIDBj2AF8yFCxpnMjqGKgewLAMHe9sKZ7StHY6X~8HOioOYiPrcNZ1DTxnxxZbgYt3ast7Shmahz5Yz~H0rMt8VljKaz~qTJP1odNd174yBRoh0tlekmFC0iYU820I2fiDkJed4owjagaDgAlRSuVEDCknpRCZfoQcypbeQcCMcPsbLmpbyj56yXRRFFX0aiwgTadeBGTrO80ojZE21TwWWh~lUgJ2NXiSPfh5MQ0qltX0sQjH~JTNe-qQWK5Avb315DKcWvNG5gmn4oji3~f53Nquz8rr2XglVs9TFJClPPrzylcQNTxDWFG~ppkVd9eqJEQ__"
        />
        <TextCard
          upperText="- Hiring Now"
          lowerText="Without Borders"
          color="#FFC628"
          upperTextColor="#503047"
        />
        <VideoCard
          name="Tanveer kaur banwait"
          job="Sales Field"
          location="India"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/383e/31a9/-2013-4c00-b343-1344679baf7d?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BheTISr6qGvJxNoJ7BNYkQHhIJBN20bzNujUkZLJxNjYNNxv2uAg8JJiHkhxerQJ94DP~V92mj7qBKU-uhmi92yL610P9Z8pgWnF7Fhvgrtz6lw1rIaNnHL-lvpLdeWmXCa4PHzzoqJbIsWhGG41nDUuckY3R5k5w6zz-3SOmtiCVJ4j5CoQPs3A1xnOqwp3bDa9JHlB6zMSzSUxTVK3FpZv-Bsv-oR-HPPhgK01aNUYsywuXyzX3XSrw~4F5ts~6CqtAcS0TB6xZ0lUdDsFhx2JOJTYkINkeaK-xpH33oUULm7z86GU~jISROqentvA6eOhCLBHnaLAXtQBR-v3gA__"
        />
      </div>
      <div>
        <TheRightFitTextCard
          color="#A755FF"
          lowerText="The Right Fit"
          upperText="- Wherever talent is, Find"
        />
        <VideoCard
          name="Julius Nyule"
          job="IOS Developer"
          location="Kenya"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/57db/fc2b/-4c7c-4e76-9ecc-534b96a33a7d?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hMM30puvGT6J6piil3goRe1aTgrmSH~wDy-4C9HGDgKH3PcNMuFMhGdfim63qQMynMfj8CF4kfykz6n3QXe2Z3eeHCyLTG-5x4keQib23Hrd5e66cvLPCXpVFJkstHGncHorJ0b6tdgn6YyGiFhVck0ZAeLEE8NFHuUD1dRm8nb7ernlOyLSCpswWM5~5bT9t8lgGeI7cKA~fems0bYHqFtormZt-Ajlamxq-vUFO41T3AvRlaSSZ859VSTNMwQMUq-V6XWN9wYJrdY3xOlIAS2n-wiOjm63MGC6LSiC-pml0fkHISxc2nsF9MgRErMH69gv~axunK9Cz8SmhQO0mA__"
        />
        <TalentFromEveryCornerCard
          color="#503047"
          lowerText="Talent from Every Corner"
        />
      </div>
      <div>
        <VideoCard
          name="Hellen Cortes"
          job="Customer Service"
          location="Costa Rica"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/72f5/75de/-0650-4c6c-94a6-419bffb862d7?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AhXMzpdg~8ZVVChcigJBUpZMT~Z23LM7CEADyCg1uWHDZbnkTZDhPKYx3NHs~MXFvKYOnj4NjLCetDG0GPPnUZJINCRPeTaI4Q9xwTYXTWrhBiXBX8GTQuNC-~LGz32fsK8ezgG7Be8PHQuRTC6eAF9-p3xDj0ZzTD2KC2jNkrJe5MpOJjHBz3eA4U-FLaJruf5z755pX4Hun0DrHUh7Shl9tnlTJnGHfSawIFq7roeUSQnG25agFgTMefIXmKj3N5vasUY8UyBLuJuBLm1Fl3fxiFDshFIO53rFmowqG4VnTHFHaBUh5UnBciBwrOAXP39B3lHX5OzMEgEuFNpMdg__"
        />
        <InversedTextCard
          color="#FF8E47"
          lowerText="Skip Boring Resumes"
          upperText="Show Them"
          upperTextColor="#FFF6FA"
        />
        <VideoCard
          name="Serena Caravela"
          job="Sales"
          location="U.S.A"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/70c7/946d/-6fa1-46c4-9983-c082d3a46c58?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FISagZpGmbYa2YJ3jawXFPKT00zg82ENJTmcKCkRhy-X0EZN0fAW5RThtPJBR3fAl1CfkZ6b9YTVrb8aAHRgRVyxShrATw2VqeV3z5Yj6p4PA5jkv8b6w0stiKMdu4PVF4H40J1zALJRJO4iZYd8PQRRa5Rxr-6-igujDP4rrlDqi2PS2lrlujBUyQSUU185XLXmt8PlOu~nZrUYB4KjYi0sn-exOLG72~KMV51gcoiZpTSzNS7NruyQRc3Wid723ZIH6at5y4GQgOQOg0rQsFLgsDfOkZ6pTlGu8En1HCVMUclnplqqFMc1gFibTTmfjHeXpS4pRkiVpiyaISYXoQ__"
        />
      </div>
      <div>
        <TextCard
          upperText="- Watch Candidates"
          lowerText="Pre-Screen Candidates"
          color="#003BC5"
          upperTextColor="#D3C1CE"
        />
        <VideoCard
          name="Leul Gizaw Abebe"
          job="Engineer"
          location="Ethiopia"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/fffb/38e8/-90b8-42cd-93ad-40772cc8de3f?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bflL1AVQhPeTZuUIbBlKVG14G3VL1FMmylubYYy7qhg-66IKAUmty3KUKyE5zuUOabwQrUjK~gAHDiTNQOCnOj2xgAJuZYqZym46DMQMNl-pQbwj7ouEsmO-mUlME0wCDvlxEGXG3KmNx3k08h1Wv9t6QMXPzEyxMXAVUcdJDqx~qZaJjfittNu6ku94AfK8EoaySuFpiJP5zdjT4I~U4rgqTJ91KBrVHZqvQXJhTV43UbYTdLSy8IwctJ1~xhCqRyrKZvem9oRvm5FnOL6sXhrzED~go9j8d8gAwjhvrUuIlXVMyzi4ukDX~5SmzuO9wTWkJe6Ej1166geYXWw10g__"
        />
        <TextCard
          upperText="- With Remote Recruit"
          lowerText="Talent is Talent"
          color="#F05673"
          upperTextColor="#802265"
        />
      </div>
      <div>
        <VideoCard
          name="Pablo García-Rollán"
          job="Film Maker"
          location="Spain"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/e6ee/fc4c/-163f-4e94-a64a-edb5da55fcf6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PaBkSEAjUwXgqEas7EYdD4KJv48Yos-79uRP~hsYwvoODtA4jzg9T4DBAcaLRDvHQxOtNPyPGOOMhTIyRWWSve-j08z-HJ6e6-5PMrR0XDBbUZI7Yk1ZWTRRpv~EMKHhFCivJP6uSqVzzjZI8Aj7JdB~hG~iN38QOOqWXhno4vhSS36NpV4fjkb93AusdpslauU2erBk6coT8KgUmCPUHZfOpAhzyy9EgWsN8e4hiJmURXRCdqvo4nny3XMfCINHASHz-LeC-7QRLzYSPAR8Cg73IAxytzkjvIR2GsQcbqSpu2oe9uO2RNWIluMR02wVxi7IaJE84SWla3YAXnMKDw__"
        />
        <div className="mt-[9.89px]">
          <img src={whyRRCard} alt="Why Remote Recruit Card" />
        </div>
        {/* <TextCard /> */}
        <VideoCard
          name="Hazel Mushore"
          job="Sales Prof"
          location="Zimbabwe"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/4bb1/c7ac/-233b-4bf0-9d5d-795f0b6389fe?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aVTBT1GLQ6fBj1ZID-4AQfVDxdGLDEfYqqsZF0LBYixPcs5i2LtR9DdoXW~H2o07FZ7MXPRkj1Aa5IXyS5ud4QkeN9YvchsbtBJq2xlGjqBrk4n4gJSgqYv68e-UQABe9S6Vjz-x66t-mnJpJ9z32XMHEf4WnNhbE5WuSDvF4JkFPoatExPen4WbmZ~SIZDazOvVf3HSMT3yr4Wnk9EFq0XPUmWZyo98acZZ~xvih4e8DHpxg4iPufIVr1SVbgi2aGN0wTfEG7GyQSN8ydBg7QoYGe2Idmzo6ppvpuBDir2kQB5w6jz~dEYax8DNRnkVmdSWlUFfLQuUzQcqLRZvBg__"
        />
      </div>
      <div>
        <TextCard
          upperText="- Watch Candidates"
          lowerText="Pre-Screen Candidates"
          color="#EC7B34"
          upperTextColor="#D3C1CE"
        />
        <VideoCard
          name="Ana marzia"
          job="Cold Calling"
          location="Bangladesh"
          vid="https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/2a63/91c3/-4017-44f9-9f69-dd4eebab74e7?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Eh~kmUfwQKeg51egMG80c87~O8scCbqj6f6ZKVAy3fQDUkx741mPiT6f8cOdiFOzY~kRA2fOTvpRe7iGC3LairTk8mWOqTnLUGtTMvyqtizNLGF3jYJUpBe3AWVQH7hBM6JVtbeAKRm3VDd8zHb4SvKgMlA2i5WRkou72pWD-HFk6Y0hsKaU3WtazWFsV1tcu64HVLqfUUrBRcv1d-z4f7x-cHf87wMuzrQIN82bqA2aUmoeMiK-6-Z5-R9sdwZydPwsOug7AI5fPdUZ3tJPtZUOkzqS48TTMRQqbMEDVQkQplFVWr-gEBV3xf1n~R64iE-zxk0JYBhPvyRfoa36Lw__"
        />
        <TextCard
          upperText="- With Remote Recruit"
          lowerText="Talent is Talent"
          color="#A04EF8"
          upperTextColor="#503047"
        />
      </div>
    </div>
  )
}
