import React from 'react'

const Slider = ({ children, direction = 'left' }) => {
  return (
    <div className="carousel overflow-hidden whitespace-wrap">
      <div
        className={`carousel-track flex ${
          direction === 'right' ? 'scroll-right' : 'scroll-left'
        }`}
      >
        {React.Children.map(children, (child, index) => (
          <div key={index} className="carousel-item">
            {child}
          </div>
        ))}
        {React.Children.map(children, (child, index) => (
          <div key={index} className="carousel-item">
            {child}
          </div>
        ))}
        {React.Children.map(children, (child, index) => (
          <div key={index} className="carousel-item">
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Slider
