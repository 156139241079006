import React from 'react'

export default function CheckBadgeIcon({
  className = 'text-green-100',
  size = 14,
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_229)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0005 6.39722L12.0089 5.23055C11.8833 5.08258 11.8071 4.899 11.7911 4.70555C11.7639 4.31666 11.7055 3.69444 11.6667 3.18499C11.6496 2.96415 11.5538 2.75675 11.3968 2.60048C11.2399 2.44421 11.032 2.34942 10.8111 2.33333L9.29055 2.20888C9.0971 2.19289 8.91352 2.11674 8.76555 1.99111C8.48555 1.74999 7.98777 1.33388 7.59888 0.999439C7.43107 0.855421 7.21724 0.776245 6.99611 0.776245C6.77497 0.776245 6.56114 0.855421 6.39333 0.999439L5.22666 1.99111C5.07869 2.11674 4.89511 2.19289 4.70166 2.20888L3.18888 2.33333C2.96734 2.34954 2.75902 2.44488 2.60195 2.60195C2.44488 2.75902 2.34954 2.96734 2.33333 3.18888C2.29055 3.70222 2.23999 4.35555 2.20888 4.70944C2.19289 4.90289 2.11674 5.08647 1.99111 5.23444C1.75388 5.51833 1.33388 6.01222 0.999439 6.40111C0.855421 6.56892 0.776245 6.78275 0.776245 7.00388C0.776245 7.22502 0.855421 7.43885 0.999439 7.60666L1.99111 8.77333C2.11674 8.92129 2.19289 9.10488 2.20888 9.29833C2.23999 9.68722 2.29444 10.3094 2.33333 10.8189C2.35131 11.039 2.44744 11.2455 2.60434 11.401C2.76124 11.5565 2.96857 11.6507 3.18888 11.6667L4.70944 11.7911C4.90289 11.8071 5.08647 11.8833 5.23444 12.0089L6.4011 13.0006C6.56892 13.1446 6.78275 13.2237 7.00388 13.2237C7.22502 13.2237 7.43885 13.1446 7.60666 13.0006C7.99555 12.6661 8.48944 12.25 8.77333 12.0089C8.92129 11.8833 9.10488 11.8071 9.29833 11.7911L10.8111 11.6667C11.0326 11.6505 11.241 11.5551 11.398 11.398C11.5551 11.241 11.6505 11.0326 11.6667 10.8111C11.7094 10.2978 11.7639 9.64444 11.7911 9.29055C11.8071 9.0971 11.8833 8.91352 12.0089 8.76555C12.25 8.48555 12.6661 7.98777 13.0005 7.59888C13.1435 7.43134 13.2221 7.21831 13.2221 6.99805C13.2221 6.77779 13.1435 6.56476 13.0005 6.39722ZM6.53722 8.81999L4.81055 7.09333L5.46777 6.43611L6.53722 7.50166L8.53222 5.50666L9.18944 6.16388L6.53722 8.81999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_229">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
