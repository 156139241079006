import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

//custom components
import FilterBar from './components/FilterBar'
import Header from './components/Header'
import Results from './components/Results'
import Dropdown from 'components/dropdown/Dropdown'
import { PencilWithRectIcon } from 'components/Icons'

//redux
import { getApplicantsByJobID, getJobWithID } from 'app/store/jobSlice'
import { getUser } from 'app/store/profileSlice'
import {
  fetchInvitesListByJobID,
  getInvitesListByJobID,
  getCountInvitesListByJobID,
} from 'app/store/inviteSlice'
import { PAGINATION_LIST } from 'app/utils/list'

//assets
import AdjustIcon from 'assets/images/adjust-icon.png'
import FilterBarModal from './components/FilterModal'

export default function YourApplicants() {
  // this is for filters that are saved
  const languageStored =
    JSON.parse(localStorage.getItem('filter_ai_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_ai_location')) ?? []
  const maxHourlyRateStored =
    localStorage.getItem('filter_ai_maxHourlyRate') ?? ''
  const maxAnnualRateStored =
    localStorage.getItem('filter_ai_maxAnnualRate') ?? ''

  const { id } = useParams()
  const dispatch = useDispatch()
  const selectedJob = useSelector((state) => state.job.selectedJob)
  const user = useSelector(getUser)
  const navigate = useNavigate()

  const applicants = useSelector((state) => state.job.applicants)
  const applicantsCount = useSelector((state) => state.job.applicantsCount)
  const invites = useSelector(getInvitesListByJobID)
  const invitesCount = useSelector(getCountInvitesListByJobID)

  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState((prev) => {
    let tempParam = {}

    // languages
    if (languageStored.length !== 0) {
      tempParam['languages'] = languageStored.map((v) => v.id)
    }

    // locations
    if (locationsStored.length !== 0) {
      tempParam['countries'] = locationsStored.map((v) => v.id)
    }

    // max hourly
    if (maxHourlyRateStored !== '') {
      tempParam['hourly'] =
        maxHourlyRateStored.length >= 5
          ? Number(maxHourlyRateStored.replace(/,/g, ''))
          : Number(maxHourlyRateStored)
    }

    // max annual
    if (maxAnnualRateStored !== '') {
      tempParam['annual'] =
        maxAnnualRateStored.length >= 5
          ? Number(maxAnnualRateStored.replace(/,/g, ''))
          : Number(maxAnnualRateStored)
    }

    return tempParam
  })
  const [tabsData, setTabsData] = useState([
    { name: 'Your Applicants', id: 'applicants' },
    { name: 'Your Invites', id: 'invites' },
  ])
  const [tab, setTab] = useState(tabsData[0])
  const [showInvites, setShowInvites] = useState([])
  const [showApplicants, setShowApplicants] = useState([])
  const [showFilterModal, setShowFilterModal] = useState(false)
  //pending applicants, accepted applicants, rejected applicants, accepted invites, rejected invites, pending invites

  // new design
  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  const [currentPage, setCurrentPage] = useState(1)
  const [numberDataCount, setNumberDataCount] = useState(0)
  const [sortData, setSortData] = useState([
    { name: 'All', id: 'all' },
    { name: 'Denied', id: 'rejected' },
    { name: 'Applied', id: 'accepted' },
  ])
  const [sort, setSort] = useState(sortData[0])

  const fetchJobWithJobId = useCallback(async () => {
    if (user) {
      const params = {
        userId: user.id,
      }
      dispatch(getJobWithID({ id, data: params }))
    }
  }, [user])

  const fetchCandidates = useCallback(async () => {
    setLoading(true)
    const params = {
      jobId: id,
      offset: (currentPage - 1) * numberPerPage.name,
      limit: numberPerPage.name,
    }
    await dispatch(getApplicantsByJobID(params))
    const elementTop = document.getElementById('ai-top')
    elementTop?.scrollIntoView({ behavior: 'smooth' })
    setLoading(false)
  }, [currentPage, numberPerPage])

  const fetchInvitesListWithJobId = useCallback(async () => {
    setLoading(true)
    const params = {
      offset: (currentPage - 1) * numberPerPage.name,
      limit: numberPerPage.name,
      jobId: id,
    }
    await dispatch(fetchInvitesListByJobID(params))
    const elementTop = document.getElementById('ai-top')
    elementTop?.scrollIntoView({ behavior: 'smooth' })
    setLoading(false)
  }, [currentPage, numberPerPage])

  useEffect(() => {
    if (tab.id === 'invites') {
      setNumberDataCount(invitesCount)
    } else {
      setNumberDataCount(applicantsCount)
    }
  }, [tab, applicantsCount, invitesCount])

  useEffect(() => {
    if (!_.isEmpty(filter)) {
      setShowApplicants((prev) =>
        applicants
          .filter((v) =>
            filter.countries ? filter.countries.includes(v.user.country) : true
          )
          .filter((v) =>
            filter.languages ? filter.languages.includes(v.user.language) : true
          )
          .filter((v) =>
            filter.hourly
              ? parseInt(filter.hourly) > parseInt(v.user.hourly_rate)
              : true
          )
          .filter((v) =>
            filter.annual
              ? parseInt(filter.annual) > parseInt(v.user.annual_rate)
              : true
          )
          .filter((v) =>
            filter.skills
              ? filter.skills.every((v) => v.user.skills.includes(v))
              : true
          )
      )
      setShowInvites((prev) =>
        invites
          .filter((v) =>
            filter.countries ? filter.countries.includes(v.user.country) : true
          )
          .filter((v) =>
            filter.languages ? filter.languages.includes(v.user.language) : true
          )
          .filter((v) =>
            filter.hourly
              ? parseInt(filter.hourly) > parseInt(v.user.hourly_rate)
              : true
          )
          .filter((v) =>
            filter.annual
              ? parseInt(filter.annual) > parseInt(v.user.annual_rate)
              : true
          )
          .filter((v) =>
            filter.skills
              ? filter.skills.every((v) => v.user.skills.includes(v))
              : true
          )
      )
    } else {
      setShowInvites(invites)
      setShowApplicants(applicants)
    }
  }, [filter, invites, applicants])

  useEffect(() => {
    fetchJobWithJobId()
    fetchCandidates()
    fetchInvitesListWithJobId()
  }, [
    fetchJobWithJobId,
    fetchCandidates,
    fetchInvitesListWithJobId,
    tab,
    currentPage,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [numberPerPage])

  const onPageChange = (param) => {
    setCurrentPage(param)
  }

  const moveToEdit = () => {
    navigate(`/employer/job-posts/${id}/edit`)
  }

  const getCandidates = () => {
    if (tab.id === 'applicants') {
      let status = sort.id
      if (status !== 'all') {
        //applied dropdown filter will filter the pending/accepted status (rejected will be as is)
        if (sort.id !== 'rejected') {
          return showApplicants.filter(
            (v) => v.status === 'pending' || v.status === 'accepted'
          )
        } else {
          return showApplicants.filter((v) => v.status === status)
        }
      } else {
        return showApplicants
      }
    } else if (tab.id === 'invites') {
      let status = sort.id
      if (status !== 'all') {
        //applied dropdown filter will filter the pending/accepted status (rejected will be as is)
        if (sort.id !== 'rejected') {
          return showInvites.filter(
            (v) => v.status === 'pending' || v.status === 'accepted'
          )
        } else {
          return showInvites.filter((v) => v.status === status)
        }
      } else {
        return showInvites
      }
    }
    return []
  }

  // console.log('showApplicants', showApplicants)
  // console.log("applicantsCount", applicantsCount)

  const onShowFilterHandleModal = () => {
    setShowFilterModal(true)
  }

  if (!user) return null

  return (
    <div className="flex min-h-screen" id="ai-top">
      <div className="flex-1 px-[15px] md:px-16 border-r border-gray-200">
        <div className="py-[30px] md:py-12 flex-1 relative">
          <div className="text-black-100">
            <h5 className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
              Welcome {user.name}
            </h5>
            <h2 className="leading-[36px] md:leading-18 font-semibold text-[24px] md:text-5xl">
              Applicants & Invitations
            </h2>
          </div>
          <div className="mt-[20px] md:mt-12">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-[10px] md:space-x-14">
                <div className="leading-9 font-semibold text-green-100 text-2xl">
                  {selectedJob?.title}
                </div>
                <div
                  className="cursor-pointer w-[16px] md:w-[24px]"
                  onClick={moveToEdit}
                >
                  <PencilWithRectIcon className="text-green-100 w-full" />
                </div>
              </div>
              <div
                className="block md:hidden cursor-pointer"
                onClick={onShowFilterHandleModal}
              >
                <img src={AdjustIcon} alt="adjust-icon" />
              </div>
            </div>

            <div className="mt-[15px] md:mt-7.5">
              <div className="md:flex justify-between">
                <Header
                  tabsData={tabsData}
                  tab={tab}
                  setTab={setTab}
                  applicants={showApplicants}
                  invites={showInvites}
                />
                <div className="flex gap-5 md:mt-0 mt-5">
                  <div className="flex items-center space-x-3">
                    <div className="text-[#808191] font-semibold text-sm">
                      View
                    </div>
                    <div className="w-[70px]">
                      <Dropdown
                        data={PAGINATION_LIST}
                        label=""
                        value={numberPerPage}
                        onChange={(data) => {
                          setNumberPerPage(data)
                        }}
                        className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                        parentClassName="w-full"
                        labelClassName="text-[#1E3E85] font-semibold text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="text-[#808191] font-semibold text-sm">
                      Sort by
                    </div>
                    <div className="w-[100px]">
                      <Dropdown
                        data={sortData}
                        value={sort}
                        className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                        parentClassName="w-full"
                        labelClassName="text-[#1E3E85] font-semibold text-sm"
                        onChange={setSort}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-9">
              <Results
                loading={loading}
                candidates={getCandidates()}
                selectedJob={selectedJob}
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageSize={numberPerPage.name}
                count={numberDataCount}
                sort={sort}
                tab={tab}
              />
            </div>
          </div>
        </div>
      </div>
      <FilterBarModal
        isOpen={showFilterModal}
        closeModal={() => setShowFilterModal(false)}
        setFilter={setFilter}
      />
      <div className="pr-16 pl-9 hidden md:block md:w-[398px]">
        <FilterBar setFilter={setFilter} />
      </div>
    </div>
  )
}
