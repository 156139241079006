//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { v4 } from 'uuid'

//custom components
import Button from 'components/Button'
import { ModalCloseIcon } from 'components/Icons'
import UploadInput from 'components/Input/UploadInput'

//redux
import AuthService from 'app/services/auth.service'

export default function AcceptInvitationModal({
  isOpen,
  closeModal,
  handleAccept,
  setFileUrl,
  setResumeUploading,
  resumeUploading,
  handleAcceptLoading,
}) {
  const [fileS, setFile] = useState(null)
  function handleUploadChange(e) {
    setResumeUploading(true)
    const file = e.target.files[0]
    setFile(file)
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async () => {
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      const param = {
        fileContent: btoa(reader.result),
        fileName: `${v4()}.${extension}`,
      }
      AuthService.uploadFileToS3(param)
        .then((res) => {
          setFileUrl(res.data.url)
          setResumeUploading(false)
        })
        .catch((err) => {
          console.log(err)
          setResumeUploading(false)
        })
    }
    reader.onerror = () => {
      console.log('error on load image')
      setResumeUploading(false)
    }
  }

  useEffect(() => {
    setFileUrl('')
  }, [])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[ #1B1D21] bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[744px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white py-[70px] md:py-[95px] px-[25px] md:px-12 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer w-[50px] md:w-auto h-[50px] md:h-auto" />
                  </div>
                  <h3 className="text-black-100 text-[18px] md:text-2xl leading-[24px] md:leading-9 font-bold md:font-semibold text-center md:px-25">
                    Would you like to accept the invite and apply to this job?
                  </h3>
                  {/* <div className="mt-[30px] md:mt-9">
                    <UploadInput
                      label="Upload your resume"
                      className="space-y-3"
                      handleFileChange={handleUploadChange}
                      file={fileS}
                      setFile={setFile}
                    />
                  </div> */}
                  <div className="flex space-x-4 justify-center mt-9">
                    <Button
                      variant="secondary"
                      className="leading-5 font-bold text-sm px-16 w-full md:w-auto"
                      onClick={handleAccept}
                      disabled={handleAcceptLoading}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="error"
                      className="leading-5 font-bold text-sm px-16 w-full md:w-auto"
                      onClick={closeModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
