//packages
import { Dialog, Transition } from '@headlessui/react'
import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaFileVideo, FaFileAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { v4 } from 'uuid'
import * as yup from 'yup'
import AWS from 'aws-sdk'

//custom components
import { addProjectToProfile } from 'app/store/profileSlice'
import Button from 'components/Button'
import {
  CirclePlusIcon,
  CrossWithCircleIcon,
  ModalCloseIcon,
} from 'components/Icons'
import TextArea from 'components/Input/TextArea'
import TextField from 'components/Input/TextField'
import { AnimateSpinIcon } from 'components/Icons'

//redux & api
import { getUser } from 'app/store/profileSlice'

const schema = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
})

export default function AddNewProjectModal({ isOpen, closeModal }) {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await handleAddProject(values)
    },
  })

  const uploadProjectFiles = async () => {
    const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
    const REGION = process.env.REACT_APP_AWS_REGION

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    })

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    })

    const promise4UploadImages = files.map((v) => {
      let params = {
        Bucket: S3_BUCKET,
        Key: v.name,
        Body: v,
        // ACL: 'public-read',
      }
      return s3.upload(params).promise()
    })

    let responses = await Promise.all(promise4UploadImages)

    return responses
  }

  const handleAddProject = async (values) => {
    try {
      setLoading(true)
      formik.setSubmitting(true)
      let imageUrls = []

      let responses = await uploadProjectFiles()

      imageUrls = responses.map((v) => v.Location)

      const params = {
        userId: profile.id,
        title: values.title,
        description: values.description,
        imageUrls,
      }

      let resultAction = await dispatch(addProjectToProfile(params))

      if (addProjectToProfile.fulfilled.match(resultAction)) {
        toast.success('Added Project Successfully')
        formik.resetForm()
        setFiles([])
        closeModal()
      } else {
        if (resultAction.payload) {
          toast.error(
            'Added Project failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Added Project failed' + resultAction.error.message)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
      formik.setSubmitting(false)
    }
  }

  const handleFileChange = (e) => {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      const file = e.target.files[0]
      if (!file) {
        return
      }

      setFiles([...files, file])
    }
    reader.onerror = () => {
      console.log('error on load image')
    }
  }

  const handleRemoveFile = (fileIndex) => {
    setFiles((prev) => prev.filter((file, index) => index !== fileIndex))
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-15" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black-background-overlay bg-opacity-70">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[628px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white p-8 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer w-[70px] md:w-auto h-[70px] md:h-auto" />
                  </div>

                  {/* Body Section */}
                  <div>
                    <h3 className="text-black-100 leading-[24px] md:leading-6 text-[18px] md:text-lg font-medium">
                      Add New Project
                    </h3>
                    <p className="text-gray-100 text-[12px] md:text-sm leading-[18px] md:leading-4 font-medium mt-[10px] md:mt-4">
                      Please add a title and brief description of the project,
                      then upload some images that best exemplify the project.
                    </p>
                    <h4 className="text-[14px] md:text-sm text-black-100 leading-4 font-semibold mt-[20px] md:mt-10 mb-[15px] md:mb-8">
                      Details
                    </h4>
                    <TextField
                      value={formik.values['title']}
                      label="Title"
                      name="title"
                      id="title"
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      error={
                        formik.touched['title'] ? formik.errors['title'] : ''
                      }
                    />
                    <div className="mt-5">
                      <TextArea
                        value={formik.values['description']}
                        onChange={formik.handleChange}
                        label="Project Description"
                        name="description"
                        id="description"
                        disabled={formik.isSubmitting}
                        error={
                          formik.touched['description']
                            ? formik.errors['description']
                            : ''
                        }
                      />
                    </div>
                    <div className="h-[1px] bg-gray-200 mt-8"></div>
                    <div>
                      <div className="flex justify-between mt-8">
                        <h4 className="flex items-center text-black-100 font-semibold text-[14px] md:text-sm leading-[16px] md:leading-4 font-inter">
                          Uploads
                        </h4>
                        <div className="relative">
                          <CirclePlusIcon
                            size={26}
                            className="text-white cursor-pointer"
                            circleFillColor="#52B4DA"
                          />
                          <input
                            autoComplete="off"
                            onChange={handleFileChange}
                            type="file"
                            accept="image/*,video/*,.pdf,.doc,.docx,application/msword,application/pdf, application/vnd.ms-powerpoint,
                            .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .txt, .html"
                            disabled={formik.isSubmitting}
                            className="opacity-0 z-[10] absolute top-0 w-full h-full right-0 overflow-hidden"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-6 mt-9">
                        {files.map((file, index) => (
                          <div key={index} className="relative">
                            {file.type.startsWith('image/') ? (
                              // Render an image preview for image files
                              <img
                                className="w-44 h-32 bg-cover"
                                src={URL.createObjectURL(file)}
                                alt={`uploaded-portfolio-image-${index}`}
                              />
                            ) : (
                              // Render a generic file icon for non-image files
                              <div className="w-44 h-32 flex items-center justify-center bg-gray-200">
                                {file.type.startsWith('video/') ? (
                                  // Display a video icon for video files
                                  <FaFileVideo className="text-black-100 w-16 h-16" />
                                ) : (
                                  // Display a document icon for other files
                                  <FaFileAlt className="text-black-100 w-16 h-16" />
                                )}
                              </div>
                            )}
                            <div
                              className="absolute top-2 right-2 cursor-pointer"
                              onClick={() => handleRemoveFile(index)}
                            >
                              <CrossWithCircleIcon className="text-black-100 hover:scale-150 transition-all duration-300 w-7" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[30px] w-full flex justify-center">
                    <Button
                      variant="secondary"
                      className="w-full space-x-[10px] cursor-pointer text-[14px] md:text-sm py-[16px] md:py-5"
                      onClick={formik.handleSubmit}
                      disabled={formik.isSubmitting}
                    >
                      Create Project
                      {loading && <AnimateSpinIcon className="w-6 h-6 ml-2" />}
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
