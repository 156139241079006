import React from 'react'

function InversedTextCard({ upperText, lowerText, color, upperTextColor }) {
  return (
    <div
      className="flex flex-col justify-center items-start gap-[4.128px] h-[131.266px] w-[198.138px] py-[19.8px] px-[15.7px] rounded-[9.907px] mt-[9.89px]"
      style={{ background: color, color: upperTextColor }}
    >
      <p className="w-[164px] text-white text-[24px] not-italic font-bold leading-[21.36px] font-Oswald">
        {upperText}
      </p>
      <p className="text-[12.932px] text-center font-manrope not-italic leading-[14.5px] font-light tracking-[-.129px]">
        {lowerText}
      </p>
    </div>
  )
}

export default InversedTextCard
