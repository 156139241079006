//packages
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//custom components
import StripeCheckoutForm from './StripeCheckoutForm';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripePaymentForm({ token, onSuccessPaid, onBack }) {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: token,
  };

  if (!token) return null;

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckoutForm onSuccessPaid={onSuccessPaid} onBack={onBack} />
    </Elements>
  );
};