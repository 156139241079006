import React, { useMemo } from 'react'
import CategoryTag from './CategoryTag'
import SearchFilter from './SearchFilter'
import { BackIcon } from 'components/Icons'

function Search({ setKeywords, keywords = [], filter, setFilter }) {
  const tags = [
    'Development & IT',
    'Design & Creative',
    'Finance & Accounting',
    'Admin & Customer Support',
    'Sales & Marketing',
    'Legal',
    'Writing & Translation',
    'Engineering & Architecture',
  ]

  // console.log(filter)

  const renderSearchFilter = useMemo(
    () => (
      <SearchFilter
        keywords={keywords}
        setKeywords={setKeywords}
        filter={filter}
        setFilter={setFilter}
      />
    ),
    [keywords, filter, setKeywords, setFilter]
  )

  return (
    <div className="flex flex-col content-between h-[34.36rem] sm:h-[56.75rem] space-y-[10.75rem] sm:space-y-[10.75rem]">
      <div className="flex flex-col items-center">
        <p className="text-white text-center font-poppins text-2xl sm:text-[2rem] leading-9 sm:leading-[3.9375rem] not-italic font-bold mb-[1.25rem] sm:mb-[1.56rem]">
          Find Job Candidates & Contractors
        </p>
        {renderSearchFilter}
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-white text-center font-poppins text-2xl sm:text-[2.625rem] leading-9 sm:leading-[3.9375rem] not-italic font-bold uppercase mb-[1.25rem] sm:mb-[1.56rem]">
          Top categories
        </p>
        <div className="flex flex-wrap items-center justify-center gap-[0.44rem] sm:gap-[0.63rem] w-[400px] sm:w-[700px] mb-[2.5rem]">
          {tags.map((tag, index) => (
            <CategoryTag
              key={index}
              name={tag}
              setFilter={setFilter}
              filter={filter}
              setKeywords={setKeywords}
              keywords={keywords}
            />
          ))}
        </div>
        {/* <div>
          <p className="hidden sm:block text-white text-center text-sm font-poppins not-italic font-bold uppercase">
            ...Discover more...
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default Search
