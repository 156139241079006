import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from './components/Header'
// import IntroductionText from './components/IntroductionText'
import VideoCard from './components/VideoCard'
import Slider from 'components/Slider'
import Footer from './components/Footer'
import {
  homePageCandidatesList1,
  homePageCandidatesList2,
  homePageCandidatesList3,
  homePageCandidatesList4,
} from 'mock/homePageCandidatesList'
import { PAGINATION_LIST } from 'app/utils/list'
import compareProfiles from 'app/utils/profileRank'
import {
  getCandidates,
  getCandidatesCount,
  fetchCandidates,
} from 'app/store/candidateSlice'
import NoSearchIcon from 'assets/images/no-result-search.png'
import Search from './components/Search'
import Dropdown from 'components/dropdown/Dropdown'
import Results from 'views/employer/candidates/components/Results'
import SearchBar from './components/SearchBar'
import SearchFilter from './components/SearchFilter'
import { key } from 'fontawesome'
import IntroductionText from './components/IntroductionText'
import { Helmet } from 'react-helmet'

function NewHomePage() {
  const [loading, setLoading] = useState(false)
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_jobs_keywords')) ?? []
  const [keywords, setKeywords] = useState(keywordsStored)
  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = localStorage.getItem('can_page')
    return storedPage ? Number(storedPage) : 1
  })
  const [filter, setFilter] = useState({})
  const dispatch = useDispatch()
  const candidates = useSelector(getCandidates)
  const candidatesCount = useSelector(getCandidatesCount)

  const removeAutoScroll = localStorage.getItem('can_selected')
  const removeFilterKeywords = localStorage.getItem('filter_jobs_keywords')
  if (removeAutoScroll) {
    localStorage.removeItem('can_selected')
  }

  if (removeFilterKeywords) {
    localStorage.removeItem('filter_jobs_keywords')
  }

  const fetchList = useCallback(async () => {
    try {
      setLoading(true)
      let param = {
        offset: (currentPage - 1) * numberPerPage.name,
        limit: numberPerPage.name,
      }
      if (!_.isEmpty(filter)) {
        param = { ...param, filter }
      }

      if (keywords.length > 0) {
        const modifiedArray = keywords.map((item) =>
          item.replace(/^(skill-|job-)/, '')
        )
        param = { ...param, filter: { ...filter, skills: [...modifiedArray] } }
      }

      if (keywords.length > 0) {
        const removeAutoScroll = localStorage.getItem('can_selected')
        if (removeAutoScroll) {
          localStorage.removeItem('can_selected')
        }
        await dispatch(fetchCandidates(param))
      }
    } catch (err) {
      console.log('debug: err', err)
    } finally {
      setLoading(false)
    }
  }, [filter, currentPage, numberPerPage])

  useEffect(() => {
    const removeAutoScroll = localStorage.getItem('can_selected')
    if (removeAutoScroll) {
      localStorage.removeItem('can_selected')
    }
    fetchList()
  }, [fetchList, currentPage])

  const onPageChange = (param) => {
    localStorage.setItem('prev_can_page', currentPage)
    setCurrentPage(param)
    localStorage.setItem('can_page', param)
    const elementTop = document.getElementById('results-top')
    elementTop?.scrollIntoView({ behavior: 'smooth' })
  }

  const sortedCandidates = [...candidates].sort(compareProfiles)

  const slider1Cards = homePageCandidatesList1.map((candidate, i) => (
    <VideoCard
      name={candidate.name}
      location={candidate.location}
      bgImg={candidate.avatar}
      key={i}
    />
  ))

  const slider2Cards = homePageCandidatesList2.map((candidate, i) => (
    <VideoCard
      name={candidate.name}
      location={candidate.location}
      bgImg={candidate.avatar}
      key={i}
    />
  ))

  const slider3Cards = homePageCandidatesList3.map((candidate, i) => (
    <VideoCard
      name={candidate.name}
      location={candidate.location}
      bgImg={candidate.avatar}
      key={i}
    />
  ))

  const slider4Cards = homePageCandidatesList4.map((candidate, i) => (
    <VideoCard
      name={candidate.name}
      location={candidate.location}
      bgImg={candidate.avatar}
      key={i}
    />
  ))

  return (
    <div className="bg-[#1E3E85] min-h-[100vh] min-w-[100vw]">
      <Helmet>
        <title>
          Remote Recruit: Premier Platform for Remote Jobs and Hiring
        </title>
        <meta
          name="description"
          content="Explore Remote Recruit, the leading platform for remote job seekers and employers. Connect and thrive in a global marketplace of top talent and diverse opportunities."
        />
      </Helmet>
      <Header />
      {keywords.length !== 0 ? (
        <div className="flex justify-center items-center">
          <div>
            {!loading && candidates.length === 0 ? (
              <>
                <div className="flex flex-col items-center">
                  <p
                    className="text-white text-center font-poppins text-2xl sm:text-[2rem] leading-9 sm:leading-[3.9375rem] not-italic font-bold mb-[1.25rem] sm:mb-[1.56rem]"
                    id="results-top"
                  >
                    Find Job Candidates & Contractors
                  </p>
                  {/* <SearchBar
                    searchKeywords={searchKeywords}
                    setSearchKeywords={setSearchKeywords}
                    filter={filter}
                    setFilter={setFilter}
                  /> */}
                  <SearchFilter
                    keywords={keywords}
                    setKeywords={setKeywords}
                    filter={filter}
                    setFilter={setFilter}
                  />
                </div>
                <div className="py-[60px] md:py-25 w-[50vw] text-center bg-white rounded-3xl shadow-[14px_41px_50px_rgba(49,89,211,0.07)]">
                  <div className="w-full flex justify-center">
                    <img alt="no-search-image" src={NoSearchIcon} />
                  </div>
                  <p className="text-gray-100 leading-[24px] md:leading-6 text-[16px] md:text-xl mt-[60px] md:mt-16">
                    No results found, please try again
                  </p>
                </div>
              </>
            ) : (
              <div className="space-y-5 w-[100vw] sm:w-[50vw]">
                <div className="flex flex-col items-center">
                  <p
                    className="text-white text-center font-poppins text-2xl sm:text-[2rem] leading-9 sm:leading-[3.9375rem] not-italic font-bold mb-[1.25rem] sm:mb-[1.56rem]"
                    id="results-top"
                  >
                    Find Job Candidates & Contractors
                  </p>
                  {/* <SearchBar
                    searchKeywords={searchKeywords}
                    setSearchKeywords={setSearchKeywords}
                    filter={filter}
                    setFilter={setFilter}
                  /> */}
                  <div className="mb-4">
                    <SearchFilter
                      keywords={keywords}
                      setKeywords={setKeywords}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-sm font-semibold text-[#808191]">
                    <span className="text-white font-bold text-sm">
                      {candidatesCount}
                    </span>{' '}
                    candidates found
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="text-[#808191] font-semibold text-sm">
                      View
                    </div>
                    <div className="w-[70px]">
                      <Dropdown
                        data={PAGINATION_LIST}
                        label=""
                        value={numberPerPage}
                        onChange={(data) => {
                          setNumberPerPage(data)
                        }}
                        className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                        parentClassName="w-full"
                        labelClassName="text-[#1E3E85] font-semibold text-sm"
                      />
                    </div>
                    <div className="text-[#808191] text-sm font-semibold">
                      candidates per page
                    </div>
                  </div>
                </div>
                <Results
                  candidates={sortedCandidates}
                  loading={loading}
                  hideInviteBtn={true}
                  filter={filter}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  pageSize={numberPerPage.name}
                  count={candidatesCount}
                  hideResultsForText={true}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <IntroductionText />
          <div className="relative">
            <Slider direction="left">{slider1Cards}</Slider>
            <Slider direction="right">{slider2Cards}</Slider>
            <br />
            <Slider direction="left">{slider3Cards}</Slider>
            <Slider direction="right">{slider4Cards}</Slider>
          </div>
          <div className="absolute top-[270px] sm:top-[250px] left-1/2 transform -translate-x-1/2">
            <Search
              keywords={keywords}
              setKeywords={setKeywords}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        </>
      )}
      <Footer />
    </div>
  )
}

export default NewHomePage
