import clsx from 'clsx'
import React, { useState } from 'react'

export default function TextArea({
  className = '',
  label,
  error,
  labelClassName = 'block text-[12px] md:text-xs',
  labelTextClassName = 'text-gray-300',
  ...rest
}) {
  const [isFocus, setIsFocus] = useState(false)
  return (
    <div className={clsx('space-y-3 group', className)}>
      {label && (
        <label
          className={clsx(
            labelClassName,
            isFocus ? 'text-blue-100' : labelTextClassName
          )}
        >
          {label}
        </label>
      )}
      <textarea
        {...rest}
        onFocus={() => setIsFocus(true)}
        className={clsx(
          'leading-5 border-solid border-[2px] box-sizing ext-[#11142D] text-[14px] md:text-sm py-[18px] px-[15px] md:px-[23px] w-full bg-[rgba(228,228,228,0.25)] rounded-lg',
          isFocus ? 'border-blue-100' : 'border-transparent'
        )}
        onBlur={() => setIsFocus(false)}
      />
      {error && (
        <div
          className="text-red-300 text-[12px] md:text-xs font-semibold"
          style={{ marginTop: '6px' }}
        >
          {error}
        </div>
      )}
    </div>
  )
}
