import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'

//redux & apis
import { getProfile } from 'app/store/profileSlice'

//custom components
import CompanyIntroductionPanel from './components/CompanyIntroductionPanel'
import ContactInformation from './components/ContactInformation'
import TopHeader from './components/TopHeader'

export default function ContractorEmployerProfile() {
  const [user, setUser] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    const fetchUser = async () => {
      const result = await getProfile(id)
      setUser(result.data.user)
    }
    fetchUser()
  }, [id])

  if (!user) return null

  return (
    <div className="md:min-h-screen px-[15px] pb-[90px] py-[30px] md:py-12 md:pb-12 text-base max-w-[1055px] mx-auto">
      <TopHeader profile={user} />
      <div
        className={clsx(
          'space-y-[20px] md:space-y-0 mt-14',
          user.emType === 'individual'
            ? ''
            : 'md:grid md:gap-x-10 xl:gap-x-16 grid-cols-[23rem_minmax(600px,_1fr)]'
        )}
      >
        {user.emType === 'company' && (
          <div>
            <ContactInformation profile={user} />
          </div>
        )}
        <div>
          <CompanyIntroductionPanel profile={user} />
        </div>
      </div>
    </div>
  )
}





