import React from 'react'

export default function Right({ className = 'text-black', size = 14 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25019 2.91689C5.32696 2.91645 5.40306 2.93116 5.47413 2.96019C5.54521 2.98922 5.60985 3.032 5.66436 3.08606L9.16436 6.58606C9.273 6.69536 9.33398 6.8432 9.33398 6.99731C9.33398 7.15142 9.273 7.29927 9.16436 7.40856L5.66436 10.9086C5.55276 11.0041 5.40922 11.0541 5.26241 11.0484C5.1156 11.0427 4.97633 10.9819 4.87244 10.878C4.76855 10.7741 4.70769 10.6348 4.70202 10.488C4.69635 10.3412 4.74629 10.1977 4.84186 10.0861L7.92769 7.00023L4.84186 3.91439C4.75994 3.83315 4.70396 3.72945 4.68098 3.61639C4.65801 3.50333 4.66908 3.386 4.71278 3.27923C4.75649 3.17246 4.83087 3.08105 4.92653 3.01655C5.02219 2.95206 5.13482 2.91738 5.25019 2.91689Z"
        fill="currentColor"
      />
    </svg>
  )
}
