import React from 'react'

export default function CheckedCircleIcon({
  className = 'text-green-100',
  size = 14,
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1265_1735)">
        <path
          d="M7 0C3.14035 0 0 3.14035 0 7C0 10.8596 3.14035 14 7 14C10.8596 14 14 10.8596 14 7C14 3.14035 10.8596 0 7 0ZM10.9123 5.15789L6.4386 9.59649C6.17544 9.85965 5.75439 9.87719 5.47368 9.61403L3.10526 7.45614C2.82456 7.19298 2.80702 6.75439 3.05263 6.47368C3.31579 6.19298 3.75439 6.17544 4.03509 6.4386L5.91228 8.1579L9.91228 4.15789C10.193 3.87719 10.6316 3.87719 10.9123 4.15789C11.193 4.4386 11.193 4.87719 10.9123 5.15789Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1265_1735">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
