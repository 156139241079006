import React from 'react'

export default function EliminationDarkCircleIcon({ size = 20, className = '' }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.625C4.825 0.625 0.625 4.825 0.625 10C0.625 15.175 4.825 19.375 10 19.375C15.175 19.375 19.375 15.175 19.375 10C19.375 4.825 15.175 0.625 10 0.625ZM10 15.625C9.3125 15.625 8.75 15.0625 8.75 14.375C8.75 13.6875 9.3125 13.125 10 13.125C10.6875 13.125 11.25 13.6875 11.25 14.375C11.25 15.0625 10.6875 15.625 10 15.625ZM11.4313 5.93125L11.0125 10.9438C10.9688 11.4688 10.5312 11.875 10 11.875C9.46875 11.875 9.03125 11.4688 8.9875 10.9438L8.56875 5.93125C8.5 5.09375 9.15625 4.375 10 4.375C10.8 4.375 11.4375 5.025 11.4375 5.8125C11.4375 5.85 11.4375 5.89375 11.4313 5.93125Z"
        fill="#C83F3F"
      />
    </svg>
  )
}
