import React from 'react'

export default function SaveIcon({ size = 17, className }) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31_3761)">
        <path
          d="M16.3147 3.87282L13.1272 0.685321C13.0775 0.636084 13.0187 0.59713 12.954 0.570693C12.8892 0.544255 12.8199 0.530855 12.75 0.531259H11.6875V4.78126C11.6875 5.06305 11.5756 5.3333 11.3763 5.53256C11.177 5.73182 10.9068 5.84376 10.625 5.84376H6.375C6.09321 5.84376 5.82296 5.73182 5.6237 5.53256C5.42444 5.3333 5.3125 5.06305 5.3125 4.78126V0.531259H2.125C1.70231 0.531259 1.29693 0.699171 0.998049 0.998057C0.699162 1.29694 0.53125 1.70232 0.53125 2.12501V14.875C0.53125 15.2977 0.699162 15.7031 0.998049 16.002C1.29693 16.3008 1.70231 16.4688 2.125 16.4688H3.1875V11.6875C3.1875 11.2648 3.35541 10.8594 3.6543 10.5606C3.95318 10.2617 4.35856 10.0938 4.78125 10.0938H12.2188C12.6414 10.0938 13.0468 10.2617 13.3457 10.5606C13.6446 10.8594 13.8125 11.2648 13.8125 11.6875V16.4688H14.875C15.2977 16.4688 15.7031 16.3008 16.002 16.002C16.3008 15.7031 16.4688 15.2977 16.4688 14.875V4.25001C16.4692 4.18009 16.4558 4.11078 16.4293 4.04606C16.4029 3.98133 16.3639 3.92246 16.3147 3.87282Z"
          fill="currentColor"
        />
        <path
          d="M6.375 0.53125H10.625V4.78125H6.375V0.53125Z"
          fill="currentColor"
        />
        <path
          d="M12.2188 11.1562H4.78125C4.64035 11.1562 4.50523 11.2122 4.4056 11.3118C4.30597 11.4115 4.25 11.5466 4.25 11.6875V16.4688H12.75V11.6875C12.75 11.5466 12.694 11.4115 12.5944 11.3118C12.4948 11.2122 12.3596 11.1562 12.2188 11.1562Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_31_3761">
          <rect width="17" height="17" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
