import { createSlice } from '@reduxjs/toolkit'

const refreshSlice = createSlice({
  name: 'refresh',
  initialState: false,
  reducers: {
    toggleRefresh: (state) => !state,
  },
})

export const { toggleRefresh } = refreshSlice.actions
export const selectShouldRefresh = (state) => state.refresh
export default refreshSlice.reducer
