//packages
import clsx from 'clsx'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

//assets
import HomeLogo from 'assets/images/remote-recurit-logo.png'
import RLogo from 'assets/images/rr-logo.png'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon2,
  TiktokIcon,
  YoutubeIcon,
} from 'components/Icons'

const footerMenus = [
  {
    name: 'Site',
    subMenus: [
      { name: 'Home', link: '/' },
      { name: 'Features', link: '/features' },
      { name: 'Pricing', link: '/pricing' },
    ],
  },
  {
    name: 'Portal',
    subMenus: [
      { name: 'Sign Up', link: '/auth/register' },
      { name: 'Login', link: '/auth' },
    ],
  },
  {
    name: 'Company',
    subMenus: [
      { name: 'About', link: '/about' },
      { name: 'FAQ', link: '/faq' },
      { name: 'Terms of Service', link: '/terms-of-service' },
      { name: 'Privacy Policy', link: '/privacy-policy' },
    ],
  },
]

export default function Footer({ className = 'pt-72' }) {
  const navigate = useNavigate()
  return (
    <div
      className={clsx('relative footer-bg bg-cover bg-no-repeat', className)}
    >
      <div className="max-w-[1040px] mx-auto flex justify-between items-center pb-16 pl-[15px] md:pl-4 pr-[37px] md:pr-4 md:space-y-0 space-x-0 md:space-x-20">
        <div className="w-[97px] md:w-46" onClick={() => console.log('hey')}>
          <img
            className="w-48 cursor-pointer z-10"
            src={HomeLogo}
            alt="home-logo"
          />
        </div>
        <div className=" md:gap-0 md:flex justify-between md:space-x-20">
          {/* {footerMenus.map((menu) => (
            <div key={menu.name}>
              <h5 className="text-white leading-[34px] md:leading-8 text-[18px] md:text-xl font-medium">
                {menu.name}
              </h5>
              <ul className="mt-5 space-y-4">
                {menu.subMenus.map((subMenu) => (
                  <li
                    className="text-white leading-6 text-[12px] md:text-base font-light"
                    key={subMenu.name}
                  >
                    <Link to={subMenu.link}>{subMenu.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))} */}
          <div>
            {/* <h5 className="text-white leading-8 text-xl font-medium">
              Contact
            </h5> */}
            <ul className="mt-5 space-y-4">
              {/* <li className="text-white leading-6 text-base font-light truncate">
                hello@remoterecruit.com
              </li> */}
              <li className="flex space-x-4">
                <a target="_blank" href="https://www.facebook.com/RemoteRecruitOfficial" className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer">
                  <FacebookIcon />
                </a>
                <a target="_blank" href="https://www.instagram.com/remoterecruithq" className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer">
                  <InstagramIcon />
                </a>
                <a target="_blank" href="https://twitter.com/RemoteRecruitHQ" className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer">
                  <TwitterIcon2 />
                </a>
                <a target="_blank" href="https://www.tiktok.com/@teamremoterecruit" className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer">
                  <TiktokIcon />
                </a>
                <a target="_blank" href="https://www.youtube.com/@RemoteRecruit" className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer">
                  <YoutubeIcon />
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/remoterecruit-com-official" className="w-8 h-8 flex justify-center items-center social-icons rounded-full cursor-pointer">
                  <LinkedinIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex max-w-[270px] mx-auto justify-between items-center pb-10">
        <a href="/terms-of-service" className="text-[16px] text-[#FFFFFF]">
          Terms of service
        </a>
        <div className="w-[3px] h-[3px] bg-[#FFFFFF] " />
        <a href="/privacy-policy" className="text-[16px] text-[#FFFFFF]">
          Privacy policy
        </a>
      </div>
      <div className="w-full h-20 border-t-gray-700 border-t border-opacity-50 flex justify-center items-center">
        <img className="w-10" src={RLogo} />
      </div>
    </div>
  )
}
