//packages
import { Combobox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, useState } from 'react'

//custom components
import { CheckedCircleIcon, SearchIcon } from 'components/Icons'

//assets
import AdjustIcon from 'assets/images/adjust-icon.png'

export default function SearchAutoComplete({
  label,
  value,
  onChange,
  data = [],
  error = '',
  errorClassName = 'text-xs',
  disabled = false,
  placeholder = 'Search...',
  inputClassName = 'bg-[rgba(228,228,228,0.25)] px-16',
  onShowFilterModal,
}) {
  const [query, setQuery] = useState('')
  const filterdValues =
    query === ''
      ? data
      : data.filter((v) =>
          v.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .startsWith(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div className="relative space-y-3">
      {label && (
        <label className={clsx('block text-xs', 'text-gray-300')}>
          {label}
        </label>
      )}
      <Combobox disabled={disabled} value={value} onChange={onChange}>
        <div className="">
          <div className="flex space-x-[10px]">
            <div className="bg-[rgba(228,228,228,0.5)] rounded-2xl relative w-full">
              <Combobox.Input
                className={clsx(
                  'w-full border-none py-[18px] leading-5 text-gray-900 focus:ring-0 text-[14px] md:text-sm focus-visible:outline-none placeholder:text-gray-100',
                  inputClassName
                )}
                displayValue={(v) => v?.name}
                onChange={(event) => setQuery(event.target.value)}
                placeholder={placeholder}
                autoComplete="off"
              />
              <SearchIcon className="text-[#200E32] absolute top-[15px] md:top-[18px] left-[22px] md:left-[27px]" />
            </div>
            <div
              className="flex-none flex md:hidden justify-center items-center"
              onClick={onShowFilterModal}
            >
              <img
                className="w-[30px] h-[30px]"
                src={AdjustIcon}
                alt="adjust-icon"
              />
            </div>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {filterdValues.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filterdValues.map((v) => (
                  <Combobox.Option
                    key={v.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-gray-900' : 'text-gray-900'
                      }`
                    }
                    value={v}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {v?.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckedCircleIcon />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {error && (
        <div
          className={clsx(
            'text-red-300 text-[12px] md:text-xs font-semibold mt-1.5',
            errorClassName
          )}
        >
          {error}
        </div>
      )}
    </div>
  )
}
