import { makeStyles } from '@material-ui/core/styles'
import { CrossIcon } from 'components/Icons'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  upload: {
    border: '2px solid #1E3E85',
    borderRadius: '20px',
  },
}))

export default function UploadVideoCoverLetter({
  handleVideoUploadChange,
  isVideoUploading,
  progress,
  cancelUpload,
  fileName,
  disabled,
}) {
  const classes = useStyles()
  return (
    <div>
      <div className="px-[15px] md:px-8 max-w-full flex justify-between border-[2px] border-solid border-blue-100 rounded-2xl h-[56px] md:h-[56px] items-center">
        {fileName ? (
          <div className="flex-1 flex items-center max-w-[calc(100%-160px)] md:w-auto">
            <div className="whitespace-nowrap truncate flex-1">
              {fileName.split('/').pop()}
            </div>
            <div onClick={!isVideoUploading ? cancelUpload : null}>
              <CrossIcon className="text-red-200 cursor-pointer" />
            </div>
          </div>
        ) : (
          <div className="flex items-center font-medium leading-[16px] text-black-100 opacity-50 text-[12px] md:text-sm">
            {isVideoUploading
              ? `Uploading your cover letter video please wait...`
              : 'Upload your cover letter video here'}
          </div>
        )}
        <input
          className={classes.input}
          id="coverletter-upload"
          type="file"
          disabled={disabled}
          onChange={(e) => handleVideoUploadChange(e)}
          accept="video/mp4,video/x-m4v,video/*"
        />
        <label htmlFor="coverletter-upload">
          {fileName ? (
            <div className="w-[80px] md:w-[124px] cursor-pointer flex justify-center bg-green-100 rounded-2xl leading-5 text-sm font-bold text-white py-[10px]">
              Change
            </div>
          ) : (
            <div className="cursor-pointer w-[80px] md:w-[124px] flex justify-center bg-green-100 rounded-2xl leading-5 text-sm font-bold text-white py-[10px] disabled:opacity-50">
              {isVideoUploading ? `${progress}% Uploaded` : 'Upload'}
            </div>
          )}
        </label>
      </div>
    </div>
  )
}
