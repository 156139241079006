//packages
import React, { useState, useRef } from 'react'

//custom components
import Button from 'components/Button'
import { PaperClipIcon } from 'components/Icons'

export default function ChatForm({
  handleSendMessage,
  value,
  setValue,
  freelancer,
  onFileAttach,
}) {
  const inputRef = useRef(null)

  return (
    <div className="w-full">
      <div className="relative">
        <span className="text-blue-300 font-semibold text-[14px] absolute top-2 left-3">
          @{freelancer?.person?.last_name}
        </span>
        <textarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="bg-[rgba(228,228,228,0.25)] rounded-xl w-full h-32 md:h-30 focus-visible:outline-none py-5 px-4 pt-10 text-gray-100"
        />
      </div>

      <div className="flex justify-end mt-4">
        <div className="md:flex items-center gap-14 w-full">
          {/* <button 
            onClick={() => {
              const input = inputRef.current;
              if (input) {
                input.value = "";
                input.click();
              }
            }}
            className="flex text-blue-300 font-semibold text-[14px] gap-3 w-full md:w-auto justify-end md:mb-0 mb-3"
          >
            <PaperClipIcon />
            Attach File
          </button>
          <input
            type="file"
            ref={inputRef}
            className="file-input hidden"
            accept="image/jpeg,image/png"
            onChange={(e) => {
              const file = e.target?.files?.[0];
              if (file) {
                onFileAttach(file);
              }
            }}
          /> */}
          <Button
            onClick={handleSendMessage}
            variant="secondary"
            className="w-full rounded-2xl"
            disabled={!value}
          >
            Send Message
          </Button>
        </div>
      </div>
    </div>
  )
}
