import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInvitesWithJobId } from 'app/services/inviteAPI'

export const fetchInvitesListByJobID = createAsyncThunk(
  'applicants/fetchInvitesList',
  async (data) => {
    const response = await getInvitesWithJobId(data)
    return response.data
  }
)

const initialState = {
  invites: [],
  count: 0,
}

const inviteSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvitesListByJobID.fulfilled, (state, action) => {
      state.invites = action.payload.invites
      state.count = action.payload.count
    })

    //Clear data after user logout
    builder.addCase('auth/LogOut', (state) => {
      state.invites = []
    })
  },
})

export const getInvitesListByJobID = (state) => state.invite.invites
export const getCountInvitesListByJobID = (state) => state.invite.count

export const {} = inviteSlice.actions

export default inviteSlice.reducer
