import React from 'react'

export default function DocumentIcon({
  className = 'text-blue-100',
  size = 20,
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.81 10.8148C19.882 10.8553 19.8964 10.9412 19.8993 10.9739L19.9 10.987L19.82 16.1515C19.82 18.2116 18.2237 19.8916 16.2138 19.9946L16.0181 19.9996H3.97203C1.93664 19.9996 0.276827 18.384 0.175076 16.3496L0.170133 16.1515L0.100098 10.987C0.100098 10.987 0.100098 10.8655 0.190143 10.8148C0.270183 10.7642 0.370233 10.8249 0.380238 10.8351C2.40125 12.192 4.83246 13.0933 7.38374 13.468C7.74392 13.5186 8.1041 13.2857 8.20415 12.9211C8.4105 12.1427 9.04774 11.6134 9.82731 11.5503L9.98504 11.5439H10.0151C10.8655 11.5439 11.5758 12.0908 11.7959 12.9211C11.896 13.2857 12.2562 13.5186 12.6164 13.468C15.1676 13.0933 17.5988 12.192 19.6199 10.8351C19.6199 10.8351 19.6399 10.8249 19.6599 10.8148C19.6999 10.7946 19.7599 10.7844 19.81 10.8148ZM9.995 12.9922C9.61898 12.9922 9.30181 13.2815 9.25156 13.6495L9.24463 13.7517V15.058C9.24463 15.4833 9.5848 15.8175 9.995 15.8175C10.3802 15.8175 10.6897 15.5367 10.7386 15.1623L10.7454 15.058V13.7517C10.7454 13.3365 10.4152 12.9922 9.995 12.9922ZM11.2856 0C12.7864 0 14.017 1.1443 14.2071 2.61264H16.1881C18.2891 2.61264 20 4.34428 20 6.47085C20 6.47085 19.94 7.3711 19.92 8.62477C19.918 8.72401 19.8699 8.82122 19.7909 8.87996C19.3097 9.2354 18.8694 9.52907 18.8294 9.54932C17.1686 10.6632 15.2386 11.447 13.1826 11.8369C13.0485 11.8632 12.9165 11.7934 12.8484 11.6739C12.2721 10.6754 11.1956 10.0253 9.995 10.0253C8.8024 10.0253 7.71586 10.6683 7.12256 11.6678C7.05353 11.7853 6.92346 11.8531 6.7904 11.8278C4.75138 11.4369 2.82141 10.6541 1.17059 9.55945L0.210105 8.89109C0.130065 8.84046 0.08004 8.74932 0.08004 8.64806C0.050025 8.13161 0 6.47085 0 6.47085C0 4.34428 1.71086 2.61264 3.81191 2.61264H5.78289C5.97299 1.1443 7.2036 0 8.70435 0H11.2856ZM11.2857 1.51855H8.70441C8.03408 1.51855 7.46379 1.98438 7.30371 2.61222H12.6864C12.5263 1.98438 11.956 1.51855 11.2857 1.51855Z"
        fill="currentColor"
      />
    </svg>
  )
}
