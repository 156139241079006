const axios = require('axios')
const API_URL = `${process.env.REACT_APP_JOBS_API_URL}`
import { refreshToken } from 'app/services/auth.service'

const instance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
})

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['authorizationToken'] = token
    }
    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (
      error.response?.status === 401 ||
      (error.response?.status === 403 && !originalRequest._retry)
    ) {
      originalRequest._retry = true

      try {
        const newToken = await refreshToken()
        instance.defaults.headers.common['authorizationToken'] = newToken
        originalRequest.headers['authorizationToken'] = newToken
        return instance(originalRequest)
      } catch (refreshError) {
        console.error('Interceptor refresh token error:', refreshError)
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        window.location.href = '/auth' // Redirect to login page
      }
    }

    return Promise.reject(error)
  }
)

const ApiService = {
  get(url) {
    return instance
      .get(url)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason))
  },

  post(url, data) {
    return instance
      .post(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason))
  },

  put(url, data) {
    return instance
      .put(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason))
  },

  awaitAll() {
    return axios
      .all(Array.from(arguments))
      .then(axios.spread((...responses) => responses))
      .catch((reasons) => Promise.reject(reasons))
  },
}

export default ApiService
