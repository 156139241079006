//packages
import React, { useState } from 'react'
import clsx from 'clsx'
import { v4 } from 'uuid'
import heic2any from 'heic2any'
import AWS from 'aws-sdk'

//custom components
import { CheckBadgeIcon, DeleteIcon } from 'components/Icons'
import Button from 'components/Button'
// import CroppingImageModal from 'components/Dialog/CroppingImageModal'
import CropImageModal from 'components/Dialog/CropImageModal'
import { AnimateSpinIcon } from 'components/Icons'

//redux & api
import AuthService from 'app/services/auth.service'
import { CrossIcon } from 'components/Icons'
import { SpinIcon } from 'components/Icons'

import { fetchBlobFromUrl, blobToBase64, generateRandomString } from 'app/utils/function'

export default function CardHeader({ isPremium = true, profile, formik }) {
  const [isAvatarUploading, setIsAvatarUploading] = useState(false)
  const [isOpenCroppingImageModal, setIsOpenCroppingImageModal] =
    useState(false)
  const [avatarFileData, setAvatarFileData] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  const removeAvatar = () => {
    formik.setFieldValue('avatar', '')
  }

  const convertHEICToJpeg = async (heicFile) => {
    try {
      const arrayBuffer = await heicFile.arrayBuffer()
      const blob = await heic2any({
        blob: new Blob([new Uint8Array(arrayBuffer)]),
        toType: 'image/jpeg',
        quality: 0.8, // Optional: Set JPEG quality (0-1)
      })

      return URL.createObjectURL(blob)
    } catch (error) {
      console.error('HEIC to JPEG conversion failed:', error)
      return null
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      if (extension.toLowerCase() === 'heic') {
        try {
          const jpegURL = await convertHEICToJpeg(file)
          console.log("jpegURL", jpegURL)
          console.log('HEIC to JPEG conversion successful')
          // Handle the converted JPEG data as needed
          setAvatarFileData(jpegURL)
          setIsOpenCroppingImageModal(true)
        } catch (error) {
          console.error('HEIC to JPEG conversion failed:', error)
        }
      } else {
        setFileExtension(extension)
        const reader = new FileReader()
        reader.onload = handleFileLoad
        reader.readAsDataURL(file)
      }
    }
  }

  const handleFileLoad = (event) => {
    const fileData = event.target.result
    setAvatarFileData(fileData)
    setIsOpenCroppingImageModal(true)
  }

  async function handleCroppedAvatarUpload(url) {
    try {
      const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
      const REGION = process.env.REACT_APP_AWS_REGION

      setIsAvatarUploading(true)
      const result = await fetchBlobFromUrl(url)
      // const fileContent = await blobToBase64(result)
      setIsOpenCroppingImageModal(false)

      const fileName = generateRandomString(8) + ".png"
      const file = new File([result], fileName, { type: 'image/png' });

      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
        ContentType: file.type,
      };

      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err);
        } else {
          console.log('File uploaded successfully:', data);

          const uploadedFileName = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`
          formik.setFieldValue('avatar', uploadedFileName)
          setIsAvatarUploading(false)
        }
      });

      // const param = {
      //   fileContent,
      //   fileName: `${v4()}.${fileExtension}`,
      // }
      // let response = await AuthService.uploadFileToS3(param)
      // formik.setFieldValue('avatar', response.data.url)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div
      id="header"
      className={clsx(
        'relative bg-no-repeat p-6 rounded-t-[15px] md:rounded-t-[24px] md:p-10 bg-cover',
        isPremium ? 'bg-setting-premium-header' : 'bg-setting-header'
      )}
    >
      <button className=" rounded-2xl absolute top-[10px] md:top-3 right-[10px] md:right-3 flex items-center px-3 space-x-2 py-2.5 bg-white rounded-5 hover:bg-gray-200">
        <CheckBadgeIcon />{' '}
        {isPremium ? (
          <span className="text-black font-bold text-[12px] md:text-xs">
            Premium
          </span>
        ) : (
          <span className="text-black font-bold text-[12px] md:text-xs">
            Basic
          </span>
        )}
      </button>
      <div className="text-white text-[14px] mb-7.5 font-medium font-inter">
        Your Profile
      </div>
      <div className="flex items-center space-x-6">
        <div className="w-[70px] md:w-24 h-[70px] md:h-24 rounded-full bg-white flex justify-center items-center">
          {formik.values.avatar ? (
            <div className="relative">
              <img
                className="w-24 h-24 object-cover rounded-full"
                alt="user-avatar"
                src={formik.values.avatar}
              />
              <div
                onClick={() => formik.setFieldValue('avatar', '')}
                className="bottom-0 right-0 absolute cursor-pointer w-[24px] h-[24px] bg-blue-300 rounded-full flex justify-center items-center"
              >
                <CrossIcon className="text-white" size={20} />
              </div>
            </div>
          ) : (
            <div
              className={clsx(
                'w-24 h-24 font-semibold rounded-full bg-white flex justify-center items-center text-yellow-100 leading-10 text-5xl font-poppins relative',
                isAvatarUploading && 'blur-sm'
              )}
            >
              {isAvatarUploading ? (
                <SpinIcon className="h-5 w-5 text-black" />
              ) : (
                <span>
                  {' '}
                  {profile.name ? profile.name[0] : profile.firstName[0]}
                </span>
              )}
              <input
                type="file"
                className="absolute w-full h-full opacity-0"
                onChange={(e) => handleFileChange(e)}
                accept="image/*, .heic"
              />
            </div>
          )}
        </div>
        <div>
          <h3 className="leading-[27px] md:leading-5 text-white font-semibold md:font-bold text-[18px] md:text-[16px]">
            {`${profile.firstName} ${profile.lastName}`}
          </h3>
          <div className="space-x-5 items-center mt-4 hidden md:flex">
            <div className="relative">
              <input
                type="file"
                className="absolute w-full opacity-0"
                onChange={(e) => handleFileChange(e)}
                accept="image/*, .heic"
              />
              <Button
                className="px-4 disabled:opacity-50 font-bold leading-4 text-xs rounded-lg cursor-pointer"
                variant="upload"
                disabled={isAvatarUploading}
              >
                {isAvatarUploading && (
                  <AnimateSpinIcon size={15} className="text-green-100 mr-2" />
                )}
                Upload Image
              </Button>
            </div>

            <Button
              className="cursor-pointer flex items-center space-x-2 font-bold text-xs leading-4 text-white"
              variant="text"
              onClick={removeAvatar}
              disabled={isAvatarUploading}
            >
              <DeleteIcon />
              <span>Remove Profile Picture</span>
            </Button>
          </div>
        </div>
      </div>
      {/* <CroppingImageModal
        image={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      /> */}
      <CropImageModal
        imageData={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      />
    </div>
  )
}
