import React from 'react'
import JobIcon from 'assets/img/jobIcon.png'

function CarauselSection() {
  const videos = [
    {
      name: 'Pablo García-Rollán',
      role: 'Video Editor',
      country: 'Spain',
      image:
        'https://s3-figma-videos-production-sig.figma.com/video/1303537221962349078/TEAM/e6ee/fc4c/-163f-4e94-a64a-edb5da55fcf6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PaBkSEAjUwXgqEas7EYdD4KJv48Yos-79uRP~hsYwvoODtA4jzg9T4DBAcaLRDvHQxOtNPyPGOOMhTIyRWWSve-j08z-HJ6e6-5PMrR0XDBbUZI7Yk1ZWTRRpv~EMKHhFCivJP6uSqVzzjZI8Aj7JdB~hG~iN38QOOqWXhno4vhSS36NpV4fjkb93AusdpslauU2erBk6coT8KgUmCPUHZfOpAhzyy9EgWsN8e4hiJmURXRCdqvo4nny3XMfCINHASHz-LeC-7QRLzYSPAR8Cg73IAxytzkjvIR2GsQcbqSpu2oe9uO2RNWIluMR02wVxi7IaJE84SWla3YAXnMKDw__', // Replace with real image paths
    },
    {
      name: 'Silindo Mabena',
      role: 'Writer',
      country: 'South Africa',
      image:
        'https://s3-figma-videos-production-sig.figma.com/video/1264662543034652474/TEAM/bade/8a2c/-990f-40d2-8b91-8e7766aee292?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c2KZrrWWjqT1J8s9o9d~4Uw2pfOyVr2AHC-0DhBPu3GN0LCyV72IrHnph-dwtlqdKUAkUpJF0z0Kibs4nDOtDV5KLTY8WzeYPD-OFm5MvIX5yO7~UpNIsgZiRn7eTNM7bIrCYcy5Lq~WI6vMoFK~NYWkgtflKB41ev0cg38TZsGmLRtE1cS83Y-ccTnxTsGCuEB1wecEQMuSIZlEl4U9QriYajI1aUMv9Q5mv-Liq41hbGLgA2J0pGA8GZRzoALhL5r4PJU6GT0WY0NB547tOYPefipa2OAvVvhu37cqaQ9iUhFIM4rxZ1HWHk20y2BvtQ1DK44MPrY98RNTIFJqrw__',
    },
    {
      name: 'Tamara Mwikali Muli',
      role: 'Finance Lead',
      country: 'Kenya',
      image:
        'https://s3-figma-videos-production-sig.figma.com/video/1264662543034652474/TEAM/c01d/a229/-edaa-4975-8663-cc3466608eb1?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UzEFJXdg~mKMORvTJGD~YUx2JPx83UUdcJuSKBBd4x6H6wWf72yt8aTCsTXogkUPXl56MokcrDyVFN1OHYF4Xq1mrTiaT0rURKnekUWo6lA~NMJxnnhBqd3sMXX9xDqfM6KRx2DM6BGghTzWOAm0QUJsuHY5PsqSs097GYRj9Bsn2Pm7C5HbFKABLi1a2zHHhp~V6e3p74ypzz7uYtik6HyPGlQFsXcAqq9oTIh5p~XAy7wF6Jt~nKhJwFcY~vUcPtVSZ9SAid4w2D~fa1rvZ4gj9oOz7ct2djIadR1pfDHYZQKHUFTJh~MbZRGYknmkaXVlinXZALncw2VYrEp5nQ__',
    },
    {
      name: 'Muskan Elahi',
      role: 'Marketer',
      country: 'Pakistan',
      image:
        'https://s3-figma-videos-production-sig.figma.com/video/1264662543034652474/TEAM/b004/19c3/-7de6-418c-9f66-be3475abf358?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RMv4sdTLnF-m~WSOPek4yvK-BgGQky26q6Uz3ZOqZMIuFO70TLDqPkm4t7q2FBtTz3wnnVtoEc81boY1DDRPTU~4dqkcrGz1OF0FCfv3jIv6qOVg6erihKTXIC79j5CCWpQ~rQPROW0yhJHhse3bYzKrTGi2FL15AmflN6YqvVSfZL02XVnMwy8f5Ii~83jWGiEc-GjDVGOcxnRq4wxh46LrW7ShvznQJm8tAi6dagKagfQ6LvXqWoYc3SdfnQsKWraqjWH9w63onzdz5iCWxqEGOb8SAsJT8hvkmvOiVTohaeTDu5arYctmqYiEjjhKGxM3Uwnz8n2vM7MOTFxHrg__',
    },
    {
      name: 'Michael Getachew Dadi',
      role: 'Sales development rep',
      country: 'Philippines',
      image:
        'https://s3-figma-videos-production-sig.figma.com/video/1264662543034652474/TEAM/62a5/bac5/-5a4f-4d5a-9c6c-b3678324e0ef?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ND-TmTImUBLDy7Mx4ss0UyIcTL9fBM0iBXyyreWuhs-4KwAi3XDOkszp3fFafGNJphkeefNEaQqvZ14Nu7nhPPxg3c-FqFkI2IRdB1P4fUtAHPZ0DlsM78QIvK57InqVGpcd~BXuYoAS5inMIvvH8kNpqC6OOcX5S3a8RG-4uTAMRQipe1j7-Ew8Eb50g6BiNnxlv1Yhuja0WgLGnzbpDSGXHYJR4dLPw~b6Vru9lrTlXqr85YzykUAu8TKgfpc-CGwQBFWA2BrzYe6UjPtaAFywpfaDq5ERfu3E4yq2gZKN9U61TE-kpJm-pyU4XioSI4ST7ISeVOOaMP16bTFIFQ__',
    },
    {
      name: 'Maria Melanie Dela Cruz',
      role: 'Customer Service  ',
      country: 'Philippines',
      image:
        'https://s3-figma-videos-production-sig.figma.com/video/1264662543034652474/TEAM/5042/b491/-2ccf-47b0-8dcb-1e7ae3f555a5?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ex2QtFgzD0uhxTqMIbPiR7YXAIFrm85YC5u4dohjFBIOkJINh8~pCwtSdJqw6WXLJpa4hRSpBPDm9KL7Iv6BhKsAeOkH07inPcEAO5iayU3u8d6jmFCK9im7kpmZevHFrdNOTGApGX73uSw9G47ybFDMLEeOVGoDFo9fF3RlL1FoSjpF~OzRtPKmoxmonQKyrcVM-j6-TrQqcYIY0ekEQ~KKX4qm18f5G2hzQSHhTaxi9pUe6BwC3njc7meoVWcpG9mSmgdBoJonxLS47jxNGUXVz77ksV01U-cbujqVR5CTF5rNu2R~Nl7OyyHltGYSELecNBzxmuBruGjOpgnyrg__',
    },
  ]
  // Function to handle mouse scroll
  const handleScroll = (e) => {
    e.preventDefault()
    const container = e.currentTarget
    container.scrollLeft += e.deltaY // Adjust horizontal scroll based on mouse wheel movement
  }
  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col items-center mb-[96px]">
        <div className="flex flex-col items-center gap-[30px] pt-[62px] pb-[66.5px] px-[105px] bg-[#001035] rounded-[16px]">
          <p className="text-white text-center font-manrope text-[32px] not-italic font-medium leading-normal mb-[30px]">
            Tired of Bland Resumes? Show Recruiters the Real You!
          </p>
          <button className="flex justify-center items-center text-[#001A55] text-base  font-manrope font-normal not-italic leading-normal gap-[14.676px] px-[33.8px] py-[11.7px] bg-[#FDB900] rounded-[4.403px]">
            Join Remote Recruit today!
          </button>
        </div>
        <p className="w-[998px] text-white text-center font-manrope text-[56px] not-italic font-light leading-[67.2px] mt-[96px]">
          Explore{' '}
          <span className="text-[#FFC628] font-semibold">Real Talent</span> that
          fits your Vision
        </p>
        <p className="w-[732px] text-[#DBE6FF] text-center font-manrope text-[16px] not-italic font-normal leading-normal">
          Experience candidates' skills and personalities through video first.
          Find the right fit faster with a face-to-face connection from the
          start.
        </p>
        <div
          onWheel={handleScroll}
          className="flex flex-col relative w-full overflow-x-auto bg-[#0B0F2A] p-5 gap-5"
          // style={{ scrollBehavior: 'smooth' }}
          // style={{
          //   clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', // Curved effect
          //   scrollBehavior: 'smooth',
          // }}
        >
          <div className="flex gap-[10px]">
            {videos.map((video, index) => (
              <div className="relative w-full h-[380px]  mt-[8.46px]">
                {/* Video Section */}
                <video
                  className="w-full h-full object-cover"
                  src={video.image}
                  loop
                ></video>

                {/* Overlay Content */}
                <div className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-4 text-white">
                  <h3 className="font-bold text-lg">{video.name}</h3>
                  <div className="flex items-center justify-between mt-1">
                    {/* Role Section */}
                    <div className="flex items-center gap-1">
                      <img
                        src={JobIcon}
                        alt="Role Icon"
                        className="w-[9.967px] h-[10.323px]"
                      />
                      <span className="text-white text-center font-manrope text-[8.349px] not-italic font-medium leading-normal">
                        {video.role}
                      </span>
                    </div>

                    {/* Country Section */}
                    <div className="flex items-center gap-1">
                      <img
                        src="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/ethiopia_flag.png"
                        alt="Flag Icon"
                        className="w-[13.358px] h-[13.358px]"
                      />
                      <span className="text-white text-center font-manrope text-[8.331px] not-italic font-medium leading-normal">
                        {video.country}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="w-full h-[1121px] border-[1px] border-[#28C4FF] border-b-0 rounded-full"> */}
          {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1280"
              height="62"
              viewBox="0 0 1280 62"
              fill="none"
            >
              <path
                d="M1294 61.3639C1294 27.8381 995.598 0.660156 627.5 0.660156C259.402 0.660156 -39 27.8381 -39 61.3639"
                stroke="#28C4FF"
              />
            </svg> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default CarauselSection
