//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

//custom components
import Button from 'components/Button'
import { ModalCloseIcon } from 'components/Icons'

export default function NotificationPauseJobModal({
  isOpen,
  closeModal,
  selectedJob,
  handleDeactivateJob,
  loading,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[480px] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                <div className="bg-white pt-24 pb-20 px-12 relative">
                  <div
                    className="absolute right-0 top-[10px]"
                    onClick={closeModal}
                  >
                    <ModalCloseIcon className="text-black-100 hover:text-green-100 cursor-pointer" />
                  </div>
                  <p className="text-[16px] leading-[26px] text-center text-gray-100 mb-8">
                    Are you sure you want to deactivate your job post? Job seekers will no
                    longer be able to apply unless you re-activate the job.
                  </p>
                  <h3 className="text-black-100 text-[18px] leading-[36px] font-semibold text-center">
                    Do you want to proceed?
                  </h3>
                  <div className="flex space-x-[15px] justify-center mt-[23px]">
                    <Button
                      variant="secondary"
                      className="flex items-center justify-center leading-5 font-bold text-sm px-[63px] rounded-[16px]"
                      onClick={handleDeactivateJob}
                      disabled={loading}
                    >
                      {loading ? 'Deactivating...' : 'Yes'}
                    </Button>
                    <Button
                      variant="error"
                      className="leading-5 font-bold text-sm px-[63px]"
                      onClick={closeModal}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
