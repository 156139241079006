//packages
import React from 'react'
import clsx from 'clsx'

import { ArrowIcon } from 'components/Icons'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
  const { totalItems, currentSlide } = carouselState
  return (
    <div className="absolute w-full justify-between flex z-10 left-0 px-3">
      <div onClick={() => previous()}>
        <ArrowIcon className="cursor-pointer text-[#808191]" />
      </div>
      <div onClick={() => next()}>
        <ArrowIcon className="cursor-pointer text-[#808191] rotate-180" />
      </div>
    </div>
  )
}

export default function CompanyIntroductionPanel({ profile }) {
  if (!profile) return null

  return (
    <div
      className={clsx(
        'space-y-6',
        profile.emType === 'individual' ? 'md:mx-16' : ''
      )}
    >
      <div className="bg-white rounded-[15px] md:rounded-3xl shadow-candiate-bar px-[20px] py-[15px] md:px-4 md:py-4 xl:px-8 xl:py-8">
        <div className="text-black-100 leading-[24px] md:leading-6 font-medium text-[16px] md:text-lg">
          {/* {profile.emType === 'individual' ? 'About Me' : 'Our Story'} */}
          About Me
        </div>
        <div className="text-[rgba(17,20,45,0.8)] mt-[10px] md:mt-4 leading-[22px] md:leading-5 text-[13px] md:text-sm font-medium">
          {/* {profile.emType === 'individual'
            ? (
              <div
                class="content"
                dangerouslySetInnerHTML={{
                  __html: profile.IndividualDescription,
                }}
              />
            )
            : (
              <div
                class="content"
                dangerouslySetInnerHTML={{
                  __html: profile.bio,
                }}
              />
            )} */}
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: profile.IndividualDescription,
              }}
            />
        </div>
      </div>
    </div>
  )
}
