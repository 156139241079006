import React from 'react'

export default function LinkedinIcon({
  className = 'text-[#DFE1F2]',
  size = 16,
  color = '#fff',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="40"
      viewBox="0 0 39 40"
      fill="none"
    >
      <rect
        opacity="0.25"
        x="0.75"
        y="1.25"
        width="37.5"
        height="37.5"
        rx="18.75"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 26.5H13V17.5H16V26.5Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4911 15.5H14.474C13.5788 15.5 13 14.833 13 13.9995C13 13.1483 13.5964 12.5 14.5087 12.5C15.4209 12.5 15.9827 13.1483 16 13.9995C16 14.833 15.4209 15.5 14.4911 15.5Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.9999 26.4998H24.0519V21.7954C24.0519 20.6137 23.6253 19.8074 22.5581 19.8074C21.7437 19.8074 21.2586 20.351 21.0455 20.876C20.9675 21.0641 20.9484 21.3263 20.9484 21.5891V26.5H18C18 26.5 18.0389 18.5318 18 17.7067H20.9484V18.9522C21.3397 18.3535 22.0405 17.5 23.6057 17.5C25.5456 17.5 27 18.7571 27 21.458L26.9999 26.4998Z"
        fill="white"
      />
    </svg>
  )
}
