import React from 'react'

export default function DoubleCheckIcon({
  className = 'text-[#52B4DA]',
  size = 24,
}) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4935 6.34528C16.0279 5.8745 15.2693 5.86857 14.7964 6.33205L5.92643 15.0986L2.07056 11.1068C1.61067 10.6275 0.849313 10.6118 0.370044 11.0717C-0.109276 11.5315 -0.125009 12.2929 0.334881 12.7722C0.336964 12.7743 0.338996 12.7764 0.34108 12.7786L5.0425 17.6508C5.26749 17.8803 5.57501 18.0103 5.89643 18.0116H5.90966C6.22499 18.0106 6.52734 17.8858 6.75156 17.664L16.4826 8.04231C16.9528 7.57606 16.9576 6.81752 16.4935 6.34528Z"
        fill="currentColor"
      />
      <path
        d="M12.2591 17.6508C12.4835 17.8798 12.79 18.0097 13.1106 18.0116H13.1239C13.4392 18.0107 13.7415 17.8858 13.9658 17.6641L23.6969 8.04239C24.138 7.54572 24.0929 6.78556 23.5963 6.34448C23.146 5.9446 22.4695 5.93934 22.0131 6.33213L13.1467 15.0987L12.8304 14.7703C12.3705 14.291 11.6092 14.2752 11.1298 14.7351C10.6505 15.195 10.6347 15.9563 11.0946 16.4356C11.0967 16.4378 11.0988 16.44 11.1009 16.4421L12.2591 17.6508Z"
        fill="currentColor"
      />
    </svg>
  )
}
