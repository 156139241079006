//packages
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import StripePaymentForm from 'components/Forms/StripePaymentForm'
import PaymentForm from 'components/Forms/PaypalPaymentForm'
import { toast } from 'react-toastify'

//custom components
import Checkbox from 'components/Checkbox'

//api & redux
import { getPaypalToken, getClientSecret } from 'app/services/auth.service'
import { getUser, updateEmployerPlan } from 'app/store/profileSlice'

//assets
import Paypal from 'assets/img/Paypal.png'
import ActiveIcon from 'assets/images/active-icon.png'
import InActiveIcon from 'assets/images/inactive-icon-free-card.png'


export default function Upgrade() {
  const navigate = useNavigate()
  const [method, setMethod] = useState('stripe')
  const [clientToken, setClientToken] = useState(null)
  const [stripeClientToken, setStripeClientToken] = useState('')

  const dispatch = useDispatch()
  const profile = useSelector(getUser)

  useEffect(() => {
    const fetchStripeClientToken = async () => {
      let result = await getClientSecret()
      setStripeClientToken(result?.data?.paymentIntent?.client_secret)
    }
    fetchStripeClientToken()
  }, [])

  const fetchClientToken = useCallback(async () => {
    let response = await getPaypalToken()
    setClientToken(response.data.client_token)
  }, [setClientToken])

  useEffect(() => {
    fetchClientToken()
  }, [fetchClientToken])

  const onPaymentComplete = async () => {
    try {
      const param = {
        userId: profile.id,
        premium: true,
      }
      const resultAction = await dispatch(updateEmployerPlan(param))
      if (updateEmployerPlan.fulfilled.match(resultAction)) {
        toast.success('Upgraded Account Successfully')
      } else {
        if (resultAction.payload) {
          toast.error(
            'Upgrade account failed: ' + resultAction.payload.errorMessage
          )
        } else {
          toast.error('Upgrade account failed' + resultAction.error.message)
        }
      }
    } catch (err) {
      toast.error('There is error when upgrading your account.')
    }
  }

  return (
    <div className="md:overflow-auto md:h-screen w-full pb-[70px] md:pb-[139px]">
      <div className="w-full max-w-4xl mx-auto">
        <div className="text-black-100 py-12 mx-[15px] md:mx-auto max-w-[1075px]">
          <h1 className="text-[24px] md:text-[48px] font-semibold font-poppins">
            Upgrade Account
          </h1>
        </div>
        <div className="flex justify-center mx-[15px] md:mx-auto max-w-[1075px]">
          <div className="bg-white rounded-3xl shadow-setting md:min-w-[694px]">
            <div className="relative py-10 px-16">
              <h3 className="md:text-center text-black-100 text-[18px] md:text-2xl font-medium leading-[24px] md:leading-9 mb-14">
                You are going to upgrade your current subscription to a
                Premium plan.
              </h3>
              <div className="grid md:grid-cols-2 gap-x-[5px] gap-y-[20px] h-full">
                <div
                  className=''
                >
                  <div
                    className='rounded-2xl pt-5 px-8 h-full border-[2px] border-gray-400 border-solid pb-12'
                  >
                    <div className="membership-option">
                      <h4
                        className='tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold text-blue-100'
                      >
                        BASIC
                      </h4>
                      <h5
                        className='leading-[19px] text-[16px] font-bold md:mt-[9px] text-green-100'
                      >
                        Free
                      </h5>
                      {/* <div
                        className='space-y-5 md:space-y-3 mt-[20px] md:mt-4 mb-[24px] md:mb-0 text-black-100'
                      >
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          1 Active Job
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Basic Placement
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Up to 24-hour delay to job posts
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          View first 25 applicants that have applied to your job
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Send 5 free messages to job seekers
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Send up to 5 invites to job seekers
                        </h6>
                      </div> */}
                      <ul className="space-y-5 mt-[20px]">
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={ActiveIcon}
                            className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium mt-1"
                          />
                          1 Active Job
                        </li>
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={ActiveIcon}
                            className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium mt-1"
                          />
                          Basic List Placement
                        </li>
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={InActiveIcon}
                            className="w-5 h-5 mr-3 text-[#808191] text-base leading-6 font-medium mt-1"
                          />
                          Unlimited Job Applicants
                        </li>
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={InActiveIcon}
                            className="w-5 h-5 mr-3 text-[#808191] text-base leading-6 font-medium mt-1"
                          />
                          Invite Anyone to Apply to Your Jobs
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className='cursor-pointer md:p-[8px] md:border-[4px] md:border-blue-100 rounded-2xl border-solid pb-12'
                >
                  <div
                    className='rounded-2xl pt-5 px-8 h-full card-gradient'
                  >
                    <div className="membership-option">
                      <h4
                        className='tracking-[2px] leading-[36px] md:leading-[48px] text-[24px] md:text-3xl font-bold uppercase text-white'
                      >
                        Premium
                      </h4>
                      <h5
                        className='leading-[19px] text-[16px] font-bold md:mt-[9px] text-[#96F154]'
                      >
                        $79.99/month
                      </h5>
                      {/* <div
                        className='space-y-3 mt-[20px] md:mt-4 mb-[22px] md:mb-0 text-white'
                      >
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          25 active jobs
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Premium placement
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Instant job posting
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          View all applicants that have applied to your job
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Unlimited Messages to job seekers
                        </h6>
                        <h6 className="opacity-80 leading-[20px] md:leading-5 text-[14px] md:text-sm font-semibold">
                          Unlimited invites to jobseekers
                        </h6>
                      </div> */}
                      <ul className="space-y-5 mt-[20px] text-white">
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={ActiveIcon}
                            className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                          />
                          Unlimited Job Posts
                        </li>
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={ActiveIcon}
                            className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                          />
                          Instant Job Post Approval
                        </li>
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={ActiveIcon}
                            className="w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                          />
                          Premium List Placement
                        </li>
                        <li className="flex items-start font-poppins font-medium leading-[24px] text-[14px] md:text-[16px]">
                          <img
                            src={ActiveIcon}
                            className="mt-[2px] w-5 h-5 mr-3 text-[#323445] text-base leading-6 font-medium"
                          />
                          Unlimited Job Applicants
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-[40px] md:mt-12">
                <p className="text-[14px] md:text-sm leading-[16px] md:leading-4 text-black-100 font-semibold">
                  Payment Method
                </p>
                <div className="flex space-x-[17px] mt-[20px] md:mt-8">
                  <div
                    onClick={() => {
                      setMethod((prev) =>
                        prev === 'stripe' ? '' : 'stripe'
                      )
                    }}
                    className="flex-1 md:flex-none border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl"
                  >
                    <Checkbox
                      onChange={(e) => {
                        // e.stopPropagation()
                        // setMethod((prev) =>
                        //   prev === 'stripe' ? '' : 'stripe'
                        // )
                      }}
                      value={method === 'stripe'}
                      label={
                        <div className="text-black-100 text-[12px] md:text-base font-medium md:mt-1">
                          Credit Card
                        </div>
                      }
                    />
                  </div>
                  <div
                    onClick={() => {
                      setMethod((prev) =>
                        prev === 'paypal' ? '' : 'paypal'
                      )
                    }}
                    className="flex-1 md:flex-none border-[1px] border-solid border-gray-400 flex items-center px-[15px] py-[12px] rounded-2xl"
                  >
                    <Checkbox
                      onChange={(e) => {
                        // e.stopPropagation()
                        // setMethod((prev) =>
                        //   prev === 'paypal' ? '' : 'paypal'
                        // )
                      }}
                      value={method === 'paypal'}
                      label={
                        <img
                          alt="paypal"
                          className="w-[71px] md:w-auto"
                          src={Paypal}
                        />
                      }
                    />
                  </div>
                </div>
                <div>
                  {method === 'paypal' && (
                    <PaymentForm
                      clientToken={clientToken}
                      clientID={process.env.REACT_APP_PAYPAL_CLIENT_ID}
                      onPaymentComplete={onPaymentComplete}
                    />
                  )}
                  {method === 'stripe' && (
                    <StripePaymentForm
                      token={stripeClientToken}
                      onSuccessPaid={onPaymentComplete}
                      onBack={() => navigate('/employer/profile')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
