import clsx from 'clsx'
import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'

export default function NumberInput({
  className = '',
  label,
  error,
  errorClassName = 'text-xs',
  prefix,
  suffix,
  labelPos = 'up',
  ...rest
}) {
  const [isFocus, setIsFocus] = useState(false)
  return (
    <div className={clsx('space-y-3 group relative', className)}>
      {label && labelPos === 'up' && (
        <label
          className={clsx(
            'block text-[12px] md:text-xs',
            isFocus ? 'text-blue-100' : 'text-gray-300'
          )}
        >
          {label}
        </label>
      )}
      <div className="w-full relative">
        <NumericFormat
          {...rest}
          onFocus={() => setIsFocus(true)}
          className={clsx(
            'mt-[2px] leading-5 border-solid border-[2px] box-sizing text-black-100 text-[14px] md:text-sm py-[18px] w-full bg-[rgba(228,228,228,0.25)] rounded-lg',
            isFocus ? 'border-blue-100' : 'border-transparent',
            suffix ? 'pr-10 pl-6' : 'px-6'
          )}
          autoComplete="off"
          onBlur={() => setIsFocus(false)}
          thousandSeparator=","
        />
        {prefix && (
          <div
            className="absolute top-0 h-full left-3"
            style={{ color: '#52B4DA' }}
          >
            <div className="h-full items-center flex">{prefix}</div>
          </div>
        )}
        {suffix && (
          <div className="absolute top-0 right-3 h-full">
            <div className="h-full items-center flex">{suffix}</div>
          </div>
        )}
      </div>
      {label && labelPos === 'down' && (
        <label
          className={clsx(
            'block text-[12px] md:text-xs',
            isFocus ? 'text-blue-100' : 'text-gray-300'
          )}
        >
          {label}
        </label>
      )}
      {error && (
        <div
          className={clsx(
            'text-red-300 text-[12px] md:text-xs font-semibold',
            errorClassName
          )}
          style={{ marginTop: '6px' }}
        >
          {error}
        </div>
      )}
    </div>
  )
}
