//packages
import React from 'react'
import clsx from 'clsx'
//custom hooks
import { usePagination, DOTS } from 'hooks/usePagination'
//custom components
import { Left } from 'components/Icons'
import { Right } from 'components/Icons'
import { ThreeDotIcon } from 'components/Icons'

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount = 500,
    siblingCount = 1,
    currentPage = 1,
    pageSize = 10,
    toTop,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  let lastPage = paginationRange[paginationRange.length - 1]

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    if (currentPage === lastPage) return
    onPageChange(currentPage + 1)
    toTop()
  }

  const onPrevious = () => {
    if (currentPage === 1) return
    onPageChange(currentPage - 1)
    toTop()
  }

  return (
    <ul className="flex justify-center space-x-2">
      {/* Left navigation arrow */}
      <li
        className="w-[34px] h-[34px] border-[1px] border-[#E0E5F9] bg-[#F7F8FC] rounded-xl cursor-pointer flex justify-center items-center"
        onClick={onPrevious}
      >
        <Left />
      </li>
      {paginationRange.map((pageNumber, i) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li
              className="w-[34px] h-[34px] border-[1px] border-[#E0E5F9] bg-[#F7F8FC] rounded-xl cursor-pointer flex items-center justify-center"
              key={i}
            >
              <ThreeDotIcon />
            </li>
          )
        }

        // Render our Page Pills
        return (
          <li
            className={clsx(
              'font-bold text-[13px] w-[34px] h-[34px] border-[1px] rounded-xl cursor-pointer flex justify-center items-center',
              pageNumber === currentPage
                ? 'bg-[#52B4DA] border-[#52B4DA] text-white'
                : 'bg-[#F7F8FC] border-[#E0E5F9] text-black'
            )}
            onClick={() => {
              onPageChange(pageNumber)
              toTop()
            }}
            key={i}
          >
            {pageNumber}
          </li>
        )
      })}
      {/*  Right Navigation arrow */}
      <li
        className="w-[34px] h-[34px] flex justify-center items-center border-[1px] border-[#E0E5F9] bg-[#F7F8FC] rounded-xl cursor-pointer"
        onClick={onNext}
      >
        <Right />
      </li>
    </ul>
  )
}

export default Pagination
