import React, { useState, useEffect, useRef } from 'react'
import bgImg from 'assets/img/clientbg.png'

function GetStartedSection() {
  const [activeTab, setActiveTab] = useState('JobSeeker')
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handleVideoEnded = () => {
    setIsPlaying(false) // Show the SVGs when the video ends
    videoRef.current.src = videoRef.current.src // Reset the video source to trigger a refresh
  }

  useEffect(() => {
    // Listen for the 'ended' event to detect when the video playback is complete
    videoRef.current.addEventListener('ended', handleVideoEnded)

    // Clean up the event listener when the component unmounts
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('ended', handleVideoEnded)
      }
    }
  }, [])

  // Data for each tab
  const tabContent = {
    JobSeeker: {
      videoSrc:
        'https://remote-recruit-website-assets.s3.us-east-1.amazonaws.com/rr-jobseeker-help-optimised.mp4',
      text: 'Find your next job or contest opportunity and showcase your talent.',
      buttonText: 'Get Started',
    },
    Employer: {
      videoSrc:
        'https://remote-recruit-website-assets.s3.us-east-1.amazonaws.com/rr-employer-help-optimised.mp4',
      text: 'Post jobs or create contests to discover top talent from around the world.',
      buttonText: 'Get Started',
    },
  }

  return (
    <div id="getStarted" className="flex justify-center">
      <div className="flex flex-col items-center relative w-[1026px] h-[649px] mt-[76px] mb-[149px]">
        <p className="text-white text-center font-manrope text-5xl not-italic font-light mb-[34px]">
          Get Started with{' '}
          <span className="text-[#FFC628] font-medium">RemoteRecruit</span>
        </p>
        <div className="flex justify-around items-center py-[19px] px-[8px] w-[416px] h-[64px] bg-white rounded-[34px] gap-[27px] mb-[90px]">
          <button
            className={`flex gap-[10px] justify-center items-center rounded-[26px] px-[37px] py-[7px] w-[201px] h-[48px] text-[#11142D] text-center font-manrope text-[24px] not-italic font-medium leading-normal ${
              activeTab === 'JobSeeker'
                ? 'bg-[#172F57] text-white w-[127px] h-[34px]'
                : ''
            }`}
            onClick={() => setActiveTab('JobSeeker')}
          >
            JobSeeker
          </button>
          <button
            className={`flex gap-[10px] justify-center items-center rounded-[26px] px-[37px] py-[7px] w-[201px] h-[48px] text-[#11142D] text-center font-manrope text-[24px] not-italic font-medium leading-normal ${
              activeTab === 'Employer'
                ? ' bg-[#172F57] text-white w-[127px] h-[34px]'
                : ''
            }`}
            onClick={() => setActiveTab('Employer')}
          >
            Employer
          </button>
        </div>

        {/* Main Content */}
        <div className="flex w-[1026px] h-[395px] items-center">
          {/* Video Section */}
          <div className="relative pr-[48px] w-full h-full">
            <video
              src={tabContent[activeTab].videoSrc}
              alt={`${activeTab} Video`}
              className="w-full h-full object-cover rounded-[16px]"
              poster={!isPlaying ? bgImg : ''}
              style={{
                opacity: 0.56,
                background: `url(${bgImg}) #52B4DA 50% / cover no-repeat`,
              }}
              ref={videoRef}
            />
            {!isPlaying && (
              <button
                className="absolute inset-0 flex items-center justify-center"
                // style={{ filter: 'blur(8.699999809265137px)' }}
              >
                <div
                  className="flex items-center py-[25.6px] px-[29.9px] bg-[#507991] rounded-[35px] gap-[10.6px] shadow-lg"
                  // style={{ filter: 'blur(8.699999809265137px)' }}
                  onClick={handlePlayClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="56"
                    viewBox="0 0 48 56"
                    fill="none"
                  >
                    <path
                      d="M7.27754 0.833353C-1.36143 6.24098 -1.16941 51.6207 7.27754 55.1924C12.3737 57.3462 47.1291 38.9065 47.1291 28.9031C47.1291 18.4538 11.4466 -1.77605 7.27754 0.833353Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button>
            )}
          </div>

          {/* Text Section */}
          <div className="w-full text-center">
            <div
              className="flex justify-center items-center gap-[9.32px] px-[35.417px] py-[4.66px] rounded-[30.757px] border-t-[0.932px] border-l-[0.932px] border-r-[0.932px] border-white border-b-[0.932px] mb-[17px]"
              style={{
                borderBottomColor: '#11378F', // Blue color for the bottom border
                background:
                  'var(--gradient, linear-gradient(184deg, #FFF 82.02%, #0031A1 95.13%))',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                transform: 'rotate(0.056deg)',
              }}
            >
              <p className="text-white text-center font-manrope text-[18.64px] font-light leading-normal not-italic">
                Watch to know how to get started
              </p>
            </div>

            <h2 className=" text-white self-stretch font-manrope text-[32px] not-italic font-medium leading-normal tracking-[0.79px]">
              {tabContent[activeTab].text}
            </h2>
            <button className="bg-[#FFC628] flex px-[33.8px] py-[11.7px] justify-center items-center gap-[7px] rounded-[4.403px] mt-[17px]">
              {tabContent[activeTab].buttonText} →
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStartedSection
