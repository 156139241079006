//packages
import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment } from 'react'

//custom components
import { ChevronIcon } from 'components/Icons'

export default function Dropdown({
  label,
  value,
  onChange,
  data,
  btnClassName = 'bg-white border border-gray-500 text-black-100 text-opacity-50 text-sm font-semibold relative w-full cursor-default rounded-lg py-2 px-[18px] text-left h-full flex items-center',
  labelClassName = '',
  placeholder = '',
  parentClassName = 'h-full flex flex-col space-y-3',
}) {
  return (
    <div className={clsx(parentClassName)}>
      <label className="text-[12px] md:text-xs text-gray-300">{label}</label>
      <Listbox value={value} onChange={onChange}>
        <div className="relative mt-0 flex-1">
          <Listbox.Button className={btnClassName}>
            <span className={clsx('block truncate', labelClassName)}>
              {value ? value.name : placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-[10px] flex items-center pr-2">
              <ChevronIcon className="w-3 text-gray-400" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 z-20 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {data.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <div className="flex justify-between">
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {person.name}
                      </span>
                      <span>{person.number}</span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
