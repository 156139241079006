import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

//custom components
import NewHomeLogo from 'assets/img/New-Logo-Home.jpg'
import Button from 'components/Button'

export default function Header() {
  const navigate = useNavigate()

  const toSignUp = () => {
    navigate('/auth/register')
  }

  const toSignIn = () => {
    navigate('/auth')
  }

  return (
    <div className="flex justify-between px-[15px] py-16 sm:px-14 pt-[40px] sm:py-7 items-center bg-[#1E3E85]">
      <Link to="/" className="w-[123px] h-[50px] md:w-30.75">
        <div className="w-[489px] h-[494px] bg-[#FFF] rounded-full opacity-5 fixed top-[-388px] left-[-20px] hidden sm:block"></div>
        <img src={NewHomeLogo} alt="remoterecruit-logo" />
      </Link>
      <div className="flex items-center md:space-x-25.5">
        <ul className="hidden sm:flex space-x-11 items-center"></ul>
        <ul className="flex space-x-6 items-center">
          <li>
            <Button
              variant="text"
              className="text-gray-600 font-semibold text-[12px] md:text-sm px-0 md:px-4 py-[4px] md:py-2.5 leading-[18px] md:leading-4.5"
              onClick={toSignIn}
            >
              Sign In
            </Button>
          </li>
          <li className="mr-7 text-[rgba(255,255,255,0.5)] font-medium text-base cursor-pointer">
            <Button
              variant="secondary"
              className="text-gray-600 font-semibold leading-[18px] md:leading-4.5 text-[12px] md:text-sm px-[16px] md:px-4 py-[4px] md:py-2.5 flex-nowrap"
              onClick={toSignUp}
            >
              Sign Up
            </Button>
          </li>
        </ul>
      </div>
    </div>
  )
}
