import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//custom components
import Header from './components/Header'
import JobCard from './components/JobCard'
import JobCardSkelton from './components/JobCardSkelton'
import NewJobPostingModal from './components/NewJobPostingModal'
import UpgradeJobPostingModal from './components/UpgradeJobPostingModal'

//api
import { getJobsByUser } from 'app/store/jobSlice'
import { getUser } from 'app/store/profileSlice'
import { useDispatch, useSelector } from 'react-redux'

import NoSearchIcon from 'assets/images/no-result-search.png'
import Button from 'components/Button'
import { updateUserLastActivity } from 'app/services/auth.service'

export default function JobsWithCandidates() {
  const navigate = useNavigate()
  const [openNewJobPostingModal, setOpenNewJobPostingModal] = useState(false)
  const [upgradeModal, setUpgradeModal] = useState(false)
  const [selected, setSelected] = useState('active')

  const userId = useSelector((state) => state.auth.userId)
  const jobs = useSelector((state) => state.job.jobs)
  // console.log(jobs)
  const user = useSelector(getUser)

  const loading = useSelector((state) => state.job.loading)

  const dispatch = useDispatch()

  const fetchJobsList = useCallback(async () => {
    if (userId) {
      dispatch(getJobsByUser(userId))
    }
  }, [userId])

  useEffect(async () => {
    //Update user last activity
    if (userId) {
      await updateUserLastActivity(userId)
    }
  }, [openNewJobPostingModal])

  useEffect(() => {
    fetchJobsList()
  }, [fetchJobsList])

  const showJobPosting = () => {
    {
      /* PREMIUM start comment this out no premium user */
    }
    // if (user.plan === 'basic') {
    //   if (jobs.length < 1) {
    //     setOpenNewJobPostingModal(true)
    //   } else {
    //     setUpgradeModal(true)
    //   }
    // } else {
    //   setOpenNewJobPostingModal(true)
    // }
    {
      /* end comment this out no premium user */
    }

    setOpenNewJobPostingModal(true)
  }

  const upgradeNow = () => {
    navigate('/employer/upgrade')
  }

  const hasActiveJob =
    jobs.length !== 0 && jobs.filter((v) => v.status === 'active').length !== 0

  const noJobs =
    jobs.length === 0 || jobs.filter((v) => v.status === selected).length === 0

  return (
    <div className="py-[30px] pb-[70px] md:pb-12 md:py-12 pl-[15px] md:pl-16 pr-[15px] md:pr-15 flex-1 min-h-[calc(100vh-100px)]">
      <Header
        selected={selected}
        setSelected={setSelected}
        activeJobsNumber={jobs.filter((v) => v.status === 'active').length}
        inactiveJobsNumber={jobs.filter((v) => v.status === 'inactive').length}
        handleShowNowJobPosting={showJobPosting}
      />
      <div className="mt-[30px] md:mt-[26px]">
        {loading === 'pending' ? (
          <JobCardSkelton loading={loading} />
        ) : (
          <div>
            {noJobs ? (
              <div className="py-[43px] md:py-25 w-full text-center bg-white rounded-3xl shadow-[14px_41px_50px_rgba(49,89,211,0.07)]">
                <div className="w-full flex justify-center">
                  <img
                    alt="no-search-image"
                    className="w-[162px] md:w-auto"
                    src={NoSearchIcon}
                  />
                </div>
                {jobs.length === 0 ? (
                  <p className="text-gray-100 leading-[24px] md:leading-6 text-[16px] md:text-xl mt-[40px] md:mt-16 px-[40px] md:px-0">
                    You haven't created any jobs yet. Click the button below to
                    create your first job post.
                  </p>
                ) : (
                  <p className="text-gray-100 leading-[24px] md:leading-6 text-[16px] md:text-xl mt-[40px] md:mt-16 px-[40px] md:px-0">
                    You don't have any{' '}
                    <span className="capitalize">{selected}</span> jobs
                  </p>
                )}

                {!hasActiveJob && (
                  <div className="w-full flex justify-center mt-12">
                    <Button
                      onClick={() => setOpenNewJobPostingModal(true)}
                      variant="primary"
                      className="py-4.5 px-20 text-white leading-5 text-[14px] md:text-sm font-bold"
                    >
                      Create Job Post
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="space-y-[43px]">
                {jobs
                  .filter((v) => v.status === selected)
                  .map((job, idx) => (
                    <JobCard data={job} key={idx} />
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
      <NewJobPostingModal
        isOpen={openNewJobPostingModal}
        closeModal={() => setOpenNewJobPostingModal(false)}
      />
      <UpgradeJobPostingModal
        isOpen={upgradeModal}
        closeModal={() => setUpgradeModal(false)}
        upgradeNow={upgradeNow}
      />
    </div>
  )
}
