//packages
import React, { useCallback, useEffect, useState } from 'react'

//custom hooks
import useTagList from 'hooks/useTagList'

//custom components
import { CrossIcon, CrossIcon2 } from 'components/Icons'
import SearchAutoComplete from 'components/Select/SearchAutoComplete'

export default function SearchFilter({
  filter,
  setFilter,
  disabled,
  onShowFilterModal,
}) {
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_candidates_keywords')) ?? []
  const [keywords, setKeywords] = useState(keywordsStored)
  const [key, setKey] = useState(null)
  const [skillsTags, , , jobTitleTags] = useTagList()

  // Create a mapping object
  const tagMapping = {}

  const mergedData = [
    ...skillsTags.map((v) => {
      const uniqueId = `skill-${v.id}`
      tagMapping[uniqueId] = v
      return { id: uniqueId, name: v.label }
    }),
    ...jobTitleTags.map((v) => {
      const uniqueId = `job-${v.id}`
      tagMapping[uniqueId] = v
      return { id: uniqueId, name: v.label }
    }),
  ]

  useEffect(() => {
    if (keywords.length === 0) {
      setFilter((prev) => {
        const temp = { ...prev }
        delete temp.skills
        return temp
      })
    } else {
      const actualKeywords = keywords.map((key) => tagMapping[key].name)
      setFilter((prev) => ({
        ...prev,
        skills: actualKeywords,
      }))
    }
  }, [keywords, setFilter])

  const handleChange = useCallback(
    (value) => {
      if (keywords.includes(value.id)) {
        const newKeywords = keywords.filter((v) => v !== value.id)
        setKeywords(newKeywords)
        localStorage.setItem(
          'filter_jobs_keywords',
          JSON.stringify(newKeywords)
        )
      } else {
        const newKeywords = [...keywords, value.id]
        setKeywords(newKeywords)
        localStorage.setItem(
          'filter_jobs_keywords',
          JSON.stringify(newKeywords)
        )
      }
    },
    [keywords]
  )

  const handleRemoveKeyword = useCallback(
    (key) => {
      const newKeywords = keywords.filter((v) => v !== key)
      setKeywords(newKeywords)
      localStorage.setItem('filter_jobs_keywords', JSON.stringify(newKeywords))
    },
    [keywords]
  )

  const getLabelById = (id) => {
    const item = mergedData.find((item) => item.id === id)
    return item ? item.name : ''
  }

  return (
    <div className="space-y-[12px] md:space-y-6">
      <SearchAutoComplete
        disabled={disabled}
        data={mergedData}
        value={key}
        onChange={handleChange}
        placeholder="Search by skill or job title"
        onShowFilterModal={onShowFilterModal}
      />
      {keywords.length > 0 && (
        <div>
          {keywords.map((key) => (
            <div
              key={key}
              className="group hover:border-blue-100 transition-all cursor-pointer duration-200 hover:bg-blue-100 inline-flex py-[6px] md:py-1.5 px-[15px] md:px-[17px] border-[2px] border-solid border-gray-500 rounded-xl space-x-4 box-border mr-2"
            >
              <span className="text-[12px] md:text-[14px] leading-[24px] group-hover:text-white text-black-100 font-semibold">
                {getLabelById(key)}
              </span>
              <div
                className="cursor-pointer flex items-center"
                onClick={() => handleRemoveKeyword(key)}
              >
                <CrossIcon className="hidden md:block text-[#000000] group-hover:text-white" />
                <CrossIcon2 className="block md:hidden" />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
