import React from 'react'

export default function TickIcon({ className = 'text-black', size = 14 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M13.6747 1.28809C13.2415 0.854353 12.5381 0.854627 12.1044 1.28809L5.03702 8.35576L1.89587 5.21463C1.46213 4.78089 0.759044 4.78089 0.325304 5.21463C-0.108435 5.64837 -0.108435 6.35146 0.325304 6.7852L4.25157 10.7115C4.46831 10.9282 4.7525 11.0368 5.03672 11.0368C5.32094 11.0368 5.6054 10.9285 5.82213 10.7115L13.6747 2.85863C14.1084 2.42519 14.1084 1.7218 13.6747 1.28809Z"
        fill="#52B4DA"
      />
    </svg>
  )
}
