import React from 'react'

export default function SkeletonCandidateItem() {
  return (
    <div className="py-[30px] pl-[5px] flex space-x-[30px] animate-pulse">
      <div className="w-[100px] h-[100px] bg-gray-300 rounded-full" />
      <div className="space-y-3 flex-1">
        <div className="flex items-center space-x-[9px] h-[24px] bg-gray-300" />
        <div className="flex items-center space-x-[10px] bg-gray-300 h-[18px]" />
        <div className="text-sm leading-6 text-gray-100 h-[70px] bg-gray-300" />
        <div className="flex space-x-[5px] bg-gray-300 h-[30px]" />
      </div>
      <div className="w-[110px] h-32 bg-gray-300" />
    </div>
  )
}
