import React from 'react'

function CategoryCard({ color, image, text }) {
  return (
    <div
      className={`flex flex-col justify-between w-[247px] h-[320px]`}
      style={{ backgroundColor: color }}
    >
      <div className="flex justify-center items-center h-[69.3px]">
        <div className="flex flex-col gap-[3px] w-[206px] h-full mt-[27.33px]">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <path
                d="M17.1667 0.503418C7.96195 0.503418 0.5 7.96527 0.5 17.1701C0.5 26.3748 7.96195 33.8368 17.1667 33.8368C26.3715 33.8368 33.8333 26.3748 33.8333 17.1701C33.8333 7.96527 26.3715 0.503418 17.1667 0.503418ZM11.3838 19.6196C11.8721 20.1079 11.8721 20.8989 11.3838 21.3872C11.1396 21.6313 10.8198 21.7534 10.5 21.7534C10.1802 21.7534 9.86035 21.6313 9.61621 21.3872L6.28288 18.0539C5.7946 17.5656 5.7946 16.7746 6.28288 16.2863L9.61621 12.953C10.1045 12.4647 10.8955 12.4647 11.3838 12.953C11.8721 13.4412 11.8721 14.2323 11.3838 14.7205L8.93424 17.1701L11.3838 19.6196ZM19.9824 13.7749L16.6491 21.5516C16.4456 22.0252 15.9842 22.3084 15.4992 22.3084C15.3348 22.3084 15.168 22.2759 15.0076 22.2075C14.3729 21.9357 14.0791 21.2 14.3509 20.5653L17.6842 12.7886C17.9561 12.1538 18.6909 11.8592 19.3257 12.1326C19.9604 12.4045 20.2542 13.1401 19.9824 13.7749ZM28.0505 18.0539L24.7171 21.3872C24.473 21.6313 24.1532 21.7534 23.8333 21.7534C23.5135 21.7534 23.1937 21.6313 22.9495 21.3872C22.4613 20.8989 22.4613 20.1079 22.9495 19.6196L25.3991 17.1701L22.9495 14.7205C22.4613 14.2323 22.4613 13.4412 22.9495 12.953C23.4378 12.4647 24.2288 12.4647 24.7171 12.953L28.0505 16.2863C28.5387 16.7746 28.5387 17.5656 28.0505 18.0539Z"
                fill="white"
              />
            </svg>
          </div>
          <div>
            <p className="text-white font-manrope not-italic text-2xl font-bold leading-normal">
              {text}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-[158px]">
        <img
          src={image}
          alt="Category Card Image"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  )
}

export default CategoryCard
