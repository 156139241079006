import React, { useState } from 'react'

const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div
      className="relative inline-block z-50"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 flex-shrink-0"
          style={{
            minWidth: '217px',
            minHeight: '100px',
            borderRadius: '7px',
            background: '#FFF',
            boxShadow: '0px 5px 10px 0px rgba(49, 89, 211, 0.07)',
            bottom: 'calc(100% + 19px)',
          }}
        >
          <div
            style={{
              minWidth: '187px',
              color: '#808191',
              fontFamily: 'Inter, sans-serif',
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: 'normal',
            }}
            className="mx-auto mt-2"
          >
            {text}
          </div>
        </div>
      )}
    </div>
  )
}

export default Tooltip
