import clsx from 'clsx'
import React from 'react'

export default function CheckboxV2({
  value,
  variant = 'secondary',
  label,
  onChange,
  disabled = false,
  className = 'flex items-center mr-4 mb-2',
  labelClassName = '',
  inputboxClassName = 'bg-white border-[2px] rounded border-gray-400 w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 border-solid focus-within:border-blue-100',
}) {
  switch (variant) {
    case 'secondary':
      return (
        <div className={className}>
          <input
            type="checkbox"
            value={value}
            checked={value}
            onChange={onChange}
            className="opacity-0 absolute h-4 w-4"
            disabled={disabled}
          />
          <div
            id="v2-checkbox"
            className={clsx(inputboxClassName, 'text-white')}
          >
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.59502 9.48304C4.48047 9.59826 4.32419 9.66255 4.16185 9.66255C3.99951 9.66255 3.84322 9.59826 3.72868 9.48304L0.269259 6.02306C-0.089753 5.66405 -0.089753 5.08189 0.269259 4.72355L0.702429 4.29027C1.06155 3.93126 1.64304 3.93126 2.00205 4.29027L4.16185 6.45017L9.99792 0.613986C10.357 0.254974 10.9391 0.254974 11.2975 0.613986L11.7307 1.04727C12.0897 1.40628 12.0897 1.98833 11.7307 2.34678L4.59502 9.48304Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <label
            className={clsx(
              'select-none text-black-100 text-[14px] md:text-sm',
              value ? '' : 'opacity-50'
            )}
          >
            {label}
          </label>
        </div>
      )
    case 'primary':
      return (
        <div className={className}>
          <input
            type="checkbox"
            value={value}
            onChange={onChange}
            checked={value}
            className="opacity-0 absolute h-4 w-4"
            disabled={disabled}
          />
          <div
            id="v1-checkbox"
            className="border-[2px] rounded border-gray-400 w-[20px] h-[20px] flex flex-shrink-0 justify-center items-center mr-2 border-solid focus-within:border-blue-100"
          >
            <svg
              width="10"
              height="9"
              viewBox="0 0 10 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 5.375L1.5 3.375L0 4.875L3.5 8.375L10 1.875L8.5 0.375L3.5 5.375Z"
                fill="white"
              />
            </svg>
          </div>
          <label
            className={clsx(
              labelClassName
                ? labelClassName
                : 'select-none text-black-100 opacity-50 text-[14px] md:text-sm'
            )}
          >
            {label}
          </label>
        </div>
      )
  }
}
