import { FileIcon, MessageIcon, ProfileIcon, SearchIcon, SettingIcon } from '.'

export default function IconRender({ type, ...rest }) {
  switch (type) {
    case 'search':
      return <SearchIcon {...rest} />
    case 'message':
      return <MessageIcon {...rest} />
    case 'profile':
      return <ProfileIcon {...rest} />
    case 'setting':
      return <SettingIcon {...rest} />
    case 'file':
      return <FileIcon {...rest} />
    default:
      return null
  }
}
