//packages
import clsx from 'clsx'
import React, { useState, useRef } from 'react'
import ReactPlayer from 'react-player'
import _ from 'lodash'

//custom components
import Button from 'components/Button'
import ResumeModal from 'components/Modal/ResumeModal'
import ProgressBar from 'components/ProgressBar'
import Dropdown from 'components/dropdown/Dropdown'
import SimpleDropDown from 'components/SimpleDropDown/SimpleDropDown'

export default function AboutSection({
  className,
  profile,
  redirectTo,
  showResume = true,
  showEmail = true,
}) {
  const [showResumeModal, setShowResumeModal] = useState(false)
  const viewResume = () => {
    setShowResumeModal(true)
  }

  const handleCoverLetterLanguageSelect = (option) => {
    console.log('Selected option:', option)
  }

  const handleIntroVideoLanguageSelect = (option) => {
    console.log('Selected option:', option)
  }

  const [isIntroVideoPlaying, setIsIntroVideoPlaying] = useState(false)
  const [isCoverLetterVideoPlaying, setIsCoverLetterVideoPlaying] =
    useState(false)
  const introVideoRef = useRef(null)
  const coverLetterVideoRef = useRef(null)

  const handlePlayIntroVideo = () => {
    if (introVideoRef.current) {
      introVideoRef.current.play()
      setIsIntroVideoPlaying(true)
    }
  }

  const handlePlayCoverLetterVideo = () => {
    if (coverLetterVideoRef.current) {
      coverLetterVideoRef.current.play()
      setIsCoverLetterVideoPlaying(true)
    }
  }

  const handleIntroVideoEnded = () => {
    if (introVideoRef.current) {
      introVideoRef.current.pause()
      introVideoRef.current.currentTime = 0 // Reset video to the beginning
      introVideoRef.current.src = introVideoRef.current.src // Reset video source to show the poster
      setIsIntroVideoPlaying(false)
    }
  }

  const handleCoverLetterVideoEnded = () => {
    if (coverLetterVideoRef.current) {
      coverLetterVideoRef.current.pause()
      coverLetterVideoRef.current.currentTime = 0 // Reset video to the beginning
      coverLetterVideoRef.current.src = coverLetterVideoRef.current.src // Reset video source to show the poster
      setIsCoverLetterVideoPlaying(false)
    }
  }

  // console.log('debug: profile', profile)

  return (
    <div
      className={clsx(
        'md:flex md:space-x-16 w-full space-y-[10px] md:space-y-0',
        className
      )}
    >
      {/* Section for about left side */}

      <div className="bg-white rounded-3xl p-8 w-full md:w-96">
        <h3 className="font-medium leading-[24px] md:leading-6 text-black-100 text-[16px] md:text-lg">
          About {profile?.name}
        </h3>

        {!profile?.isHide && (
          <div className="grid gap-6 grid-cols-[132px_1fr] text-[14px] md:text-sm mt-8">
            <div className="text-gray-100 leading-4 font-medium">
              Minimum Expected Salary
            </div>
            {profile?.annual_rate || profile?.annual_rate !== '' ? (
              <div className="text-green-100 leading-[20px] md:leading-5 font-semibold ">
                ${profile?.annual_rate} /year
              </div>
            ) : (
              <div className="text-green-100 leading-[20px] md:leading-5 font-semibold ">
                --
              </div>
            )}

            <div className="text-gray-100 leading-4 font-medium">
              Minimum Expected Hourly Rate
            </div>
            {profile?.hourly_rate || profile?.hourly_rate !== '' ? (
              <div className="text-green-100 leading-[20px] md:leading-5 font-semibold ">
                ${profile?.hourly_rate} /hour
              </div>
            ) : (
              <div className="text-green-100 leading-[20px] md:leading-5 font-semibold ">
                --
              </div>
            )}
          </div>
        )}

        <div className="grid gap-6 grid-cols-[132px_1fr] text-[14px] md:text-sm mt-6">
          {profile?.job_title && (
            <>
              <div className="text-gray-100 leading-[20px] md:leading-4 font-medium">
                Professional Experience Tagline
              </div>
              <div className="text-black-100 leading-[20px] md:leading-5 font-semibold ">
                {profile?.job_title}
              </div>
            </>
          )}

          {profile?.num_experience && (
            <>
              <div className="text-gray-100 leading-[20px] md:leading-4 font-medium">
                Years of Experience
              </div>
              <div className="text-black-100 leading-[20px] md:leading-5 font-semibold ">
                {profile?.num_experience} Years
              </div>
            </>
          )}

          <div className="text-gray-100 leading-[20px] md:leading-4 font-medium">
            Location
          </div>
          <div className="text-black-100 leading-[20px] md:leading-5 font-semibold ">
            {profile?.country}
          </div>
        </div>

        {showEmail && (
          <div className="text-[14px] md:text-sm space-y-4 mt-6">
            <div className="text-gray-100 leading-[20px] md:leading-4 font-medium">
              Email
            </div>
            <div className="text-blue-300 leading-[20px] md:leading-5 font-light">
              {profile?.email}
            </div>
          </div>
        )}

        {_.isArray(profile?.language) && profile?.language?.length > 0 && (
          <div className="text-[14px] md:text-sm space-y-4 mt-6">
            <div className="text-gray-100 leading-[20px] md:leading-4 font-medium">
              Languages
            </div>
            <div className="flex flex-wrap">
              {profile?.language?.map((lang, index) => (
                <div
                  key={index}
                  className="text-black-100 leading-4 font-semibold text-[12px] mr-2 p-2 border-[1px] border-[#E4E4E4] rounded-lg mb-2"
                >
                  {lang}
                </div>
              ))}
            </div>
          </div>
        )}

        {_.isArray(profile?.skills) && profile?.skills?.length > 0 && (
          <div className="text-[14px] md:text-sm space-y-4 mt-6">
            <div className="text-gray-100 leading-[20px] md:leading-4 font-medium">
              Skills
            </div>
            <div className="flex flex-wrap">
              {profile?.skills?.map((skill, index) => (
                <div
                  key={index}
                  className="text-black-100 leading-4 font-semibold text-[12px] mr-2 p-2 border-[1px] border-[#E4E4E4] rounded-lg mb-2"
                >
                  {skill}
                </div>
              ))}
            </div>
          </div>
        )}
        {profile?.resume && (
          <Button
            onClick={showResume ? viewResume : redirectTo}
            variant="secondary"
            className="w-full py-4 text-[14px] md:text-sm font-bold mt-8"
          >
            View Resume
          </Button>
        )}
      </div>

      {/* Section for right side */}
      <div className="flex-1 space-y-8">
        {profile?.video && (
          <div className="h-[30.375rem] sm:min-w-[38.4375rem]  bg-white rounded-3xl shadow-[14px_41px_100px_rgba(49,89,211,0.15)] p-8">
            <div className="flex">
              <div>
                <h3 className="text-[#11142D] font-poppins leading-6 text-lg font-bold">
                  Intro Video{' '}
                  {/* <span className="ml-[19rem] 2xl:ml-[49rem] text-[#808191] font-inter font-medium text-sm">
                    Language:
                  </span> */}
                </h3>
              </div>
              {/* <div className="">
                <SimpleDropDown
                  options={['English', 'French']}
                  onSelect={handleIntroVideoLanguageSelect}
                />
              </div> */}
            </div>
            <div className="relative flex md:max-w-[30.975rem] md:max-h-[18.75rem] mx-auto my-10 rounded-3xl bg-[#1B1D21] mt-[1.12rem] mb-[1.87rem] items-center justify-center">
              {/* {!isIntroVideoPlaying && (
                <svg
                  onClick={handlePlayIntroVideo}
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  className="absolute z-10 cursor-pointer"
                  style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <g clipPath="url(#clip0_572_7973)">
                    <rect
                      width="56"
                      height="56"
                      rx="16"
                      fill="#E4E4E4"
                      fillOpacity="0.49776"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M38 27.8005C38 27.5005 37.8 27.2005 37.6 27.0005L19.6 14.0005C19.3 13.8005 18.9 13.7005 18.5 13.9005C18.2 14.1005 18 14.4005 18 14.8005V40.8005C18 41.2005 18.2 41.5005 18.5 41.7005C18.7 41.8005 18.8 41.8005 19 41.8005C19.2 41.8005 19.4 41.7005 19.6 41.6005L37.6 28.6005C37.8 28.4005 38 28.1005 38 27.8005Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_572_7973">
                      <rect width="56" height="56" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )} */}
              <video
                // ref={introVideoRef}
                src={profile.video}
                preload="metadata"
                // controls={isIntroVideoPlaying}
                controls={true}
                type="video/mp4"
                onEnded={handleIntroVideoEnded}
                // poster="https://remote-recruit-email-temp.s3.amazonaws.com/IntroVideoCover.png"
                className="h-[20rem] w-[20rem] sm:min-w-[33.9375rem] sm:h-[18.75rem] rounded-3xl flex-shrink-0"
              ></video>
            </div>
            {/* <h3 className="text-[#11142D] font-poppins leading-6 text-base font-bold mb-[1.25rem]">
              Video Grading
            </h3>
            <div className="flex items-center flex-nowrap justify-between  mb-[0.62rem] text-[rgba(17, 20, 45, 0.80)] text-sm font-bold font-inter">
              <p>Confidence</p>
              <div className="w-[21.4375rem]">
                <ProgressBar
                  bgColor="#DCF0F8"
                  progressColor="bg-[#52B4DA]"
                  percent="90"
                />
              </div>
            </div>
            <div className="flex items-center flex-nowrap justify-between mb-[0.63rem] text-[rgba(17, 20, 45, 0.80)] text-sm font-bold font-inter">
              <p>Dialect</p>{' '}
              <div className="w-[21.4375rem]">
                <ProgressBar
                  bgColor="#f4d9d9"
                  progressColor="bg-[#c83f3f]"
                  percent="75"
                />
              </div>
            </div>
            <div className="flex items-center flex-nowrap justify-between text-[rgba(17, 20, 45, 0.80)] text-sm font-bold font-inter">
              <p>Experience</p>
              <div className="w-[21.4375rem]">
                <ProgressBar
                  bgColor="#d2d8e7"
                  progressColor="bg-[#1E3E85]"
                  percent="65"
                />
              </div>
            </div> */}
          </div>
        )}
        <div className="h-fit bg-white rounded-3xl shadow-[14px_41px_100px_rgba(49,89,211,0.15)] p-8">
          <h3 className="text-black-100 leading-[24px] md:leading-6 text-[16px] md:text-lg font-medium">
            {profile?.name}'s Bio
          </h3>
          <div className="mt-4 leading-5 font-medium text-[13px] md:text-sm marker:text-[rgba(17,20,45,0.804566)]">
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: profile?.bio,
              }}
            />
          </div>
        </div>
        {profile?.coverLetterVideo && (
          <div className="h-[34.375rem] min-w-[38.4375rem] bg-white rounded-3xl shadow-[14px_41px_100px_rgba(49,89,211,0.15)] p-8">
            <div className="flex">
              <div>
                <h3 className="text-[#11142D] font-poppins leading-6 text-lg font-bold">
                  Cover Letter Video{' '}
                  <span className="ml-[15rem] 2xl:ml-[49rem] text-[#808191] font-inter font-medium text-sm">
                    Language:
                  </span>
                </h3>
              </div>
              <div className="">
                <SimpleDropDown
                  options={['English', 'French']}
                  onSelect={handleCoverLetterLanguageSelect}
                />
              </div>
            </div>
            <div className="relative flex md:max-w-[33.9375rem] md:max-h-[18.75rem] mx-auto my-10 rounded-3xl bg-[#1B1D21] mt-[1.12rem] mb-[1.87rem] items-center justify-center">
              {!isCoverLetterVideoPlaying && (
                <svg
                  onClick={handlePlayCoverLetterVideo}
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  className="absolute z-10 cursor-pointer"
                  style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <g clipPath="url(#clip0_572_7973)">
                    <rect
                      width="56"
                      height="56"
                      rx="16"
                      fill="#E4E4E4"
                      fillOpacity="0.49776"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M38 27.8005C38 27.5005 37.8 27.2005 37.6 27.0005L19.6 14.0005C19.3 13.8005 18.9 13.7005 18.5 13.9005C18.2 14.1005 18 14.4005 18 14.8005V40.8005C18 41.2005 18.2 41.5005 18.5 41.7005C18.7 41.8005 18.8 41.8005 19 41.8005C19.2 41.8005 19.4 41.7005 19.6 41.6005L37.6 28.6005C37.8 28.4005 38 28.1005 38 27.8005Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_572_7973">
                      <rect width="56" height="56" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <video
                ref={coverLetterVideoRef}
                src={profile.coverLetterVideo}
                preload="metadata"
                controls={isCoverLetterVideoPlaying}
                type="video/mp4"
                onEnded={handleCoverLetterVideoEnded}
                poster="https://remote-recruit-email-temp.s3.amazonaws.com/CoverLetterVideoCover.png"
                className="w-[33.9375rem] h-[18.75rem] rounded-3xl flex-shrink-0"
              ></video>
            </div>
            <h3 className="text-[#11142D] font-poppins leading-6 text-base font-bold mb-[1.25rem]">
              Video Grading
            </h3>
            <div className="flex items-center flex-nowrap justify-between  mb-[0.62rem] text-[rgba(17, 20, 45, 0.80)] text-sm font-bold font-inter">
              <p>Confidence</p>
              <div className="w-[21.4375rem]">
                <ProgressBar
                  bgColor="#DCF0F8"
                  progressColor="bg-[#52B4DA]"
                  percent="90"
                />
              </div>
            </div>
            <div className="flex items-center flex-nowrap justify-between mb-[0.63rem] text-[rgba(17, 20, 45, 0.80)] text-sm font-bold font-inter">
              <p>Dialect</p>{' '}
              <div className="w-[21.4375rem]">
                <ProgressBar
                  bgColor="#f4d9d9"
                  progressColor="bg-[#c83f3f]"
                  percent="75"
                />
              </div>
            </div>
            <div className="flex items-center flex-nowrap justify-between text-[rgba(17, 20, 45, 0.80)] text-sm font-bold font-inter">
              <p>Experience</p>
              <div className="w-[21.4375rem]">
                <ProgressBar
                  bgColor="#d2d8e7"
                  progressColor="bg-[#1E3E85]"
                  percent="65"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <ResumeModal
        isOpen={showResumeModal}
        closeModal={() => setShowResumeModal(false)}
        pdfUrl={profile?.resume}
      />
    </div>
  )
}
