import { createSlice } from '@reduxjs/toolkit'
import AuthService from 'app/services/auth.service'

export const submitRegister = (data, userRole, uploadData) => {
  return AuthService.createUser({
    name: data.name,
    email: data.email,
    password: data.password,
    language: data.language,
    loc: data.loc,
    website: data.website,
    bio: data.bio,
    job_title: data.job_title,
    num_experience: data.num_experience,
    skills: data.skills,
    work_status: data.work_status,
    rate: data.rate,
    interest: data.interest,
    avatar: uploadData.pictureUrl,
    resume: uploadData.fileUrl ? uploadData.fileUrl : '',
    resume_filename: uploadData.resume_filename
      ? uploadData.resume_filename
      : '',
    type: userRole,
  })
}

const initialState = {
  userId: '',
  userType: '',
  token: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.token = action.payload.token
      state.userId = action.payload.id
      state.userType = action.payload.type
    },
    LogOut: (state) => {
      // Clear user-related data when logging out
      state.token = ''
      state.userId = ''
      state.userType = ''
    },
  },
  extraReducers: {},
})

export const { setUserInfo, LogOut } = authSlice.actions

export default authSlice.reducer
