import React from 'react'

export default function JobDescriptionPanel({ description }) {
  return (
    <div className="bg-white rounded-3xl w-full py-[15px] px-[20px] md:p-8 space-y-[10px] md:space-y-6 h-full mb-20">
      <p className="text-[16px] md:text-lg text-black-100 leading-[24px] md:leading-6 font-medium">
        Job Description
      </p>
      <div className="font-inter text-[rgba(17,20,45,0.8045)] leading-[22px] md:leading-5 text-[13px] md:text-sm font-medium">
        <div
          class="content"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  )
}
