import React from 'react'

//assets
import Header from 'views/home/components/Header'
import Footer from 'views/home/components/Footer'
import PremiumRRLogo from 'assets/images/premium-icon-card.png'
import { Helmet } from 'react-helmet'

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>Remote Recruit - Terms of Service - Remoterecruit.com</title>
        <meta
          name="description"
          content="Familiarize yourself with the terms of service to ensure you make the most of Remote Recruit."
        />
      </Helmet>
      <div className="h-46 w-full mobile-auth-bg md:bg-auth-bg bg-no-repeat bg-top sm:bg-[length:100%_38vh] md:bg-[length:100%_48vh] relative z-50">
        <Header active="Home" />
        <div className="flex flex-col items-center justify-center">
          <p className="text-white text-4xl font-bold">Terms of Service</p>
          <p className="text-white text-sm font-medium opacity-80">
            Effective date: July 30, 2023
          </p>
        </div>
        <div className="hidden sm:block absolute top-78 lg:left-44 xl:left-44 md:left-0 sm:left-0 w-60 flex-shrink-0 rounded-2xl bg-white shadow-box-shadow-custom">
          <p className="p-5">1. Introduction</p>
          <p className="p-5">
            2. The Platform Generally; Accessing the Platform.
          </p>
          <p className="p-5">3. User Content.</p>
          <p className="p-5">4. Platform Rules.</p>
          <p className="p-5">5. Linking to and from the Platform.</p>
          <p className="p-5">6. Intellectual Property Rights.</p>
          <p className="p-5">7. Terms Specific to Employers.</p>
          <p className="p-5">8. Terms Specific to Job Seekers.</p>
          <p className="p-5">9. Claims of Infringing Content.</p>
          <p className="p-5">10. Indemnification.</p>
          <p className="p-5">11. Disclaimer.</p>
          <p className="p-5">12. Limitation of Liability.</p>
          <p className="p-5">
            13. Class Action Waiver; Agreement to Arbitrate.
          </p>
          <p className="p-5">14. Miscellaneous.</p>
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 gap-4 z-20">
        <div className="hidden sm:block col-span-1 w-60 h-max flex-shrink-0 rounded-3xl bg-gray-200 shadow-box-shadow z-20"></div>
        <div className="col-span-2 sm:w-5/6 sm:px-0 p-12">
          <section>
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              1. Introduction
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4 mb-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. Background
              </p>
              <p>
                Remote Recruit.com, Inc. (“<b>Remote Recruit</b>,” “<b>us</b>,”
                or “<b>we</b>”) provides the Platform, as defined below, to
                individuals who are searching for a position (a “
                <b>Job Seeker</b>”) and individuals and entities who are looking
                for Job Seekers to hire for a job (an “<b>Employer</b>”), as
                more specifically outlined in these Terms. A “<b>User</b>” means
                any individual or entity who accesses or uses the Platform, and
                includes a Job Seeker or Employer. These Terms of Use (the “
                <b>Terms</b>”) are a legally binding agreement between you
                (hereinafter, “<b>you</b>” or “<b>your</b>”) and Remote Recruit
                and governs your use of and access to remoterecruit.com and the
                services specifically offered by us on that website
                (collectively, the “<b>Platform</b>”). You agree that you have
                read and understand our Privacy Policy which incorporated herein
                by reference.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. Acceptance
              </p>
              <p>
                By using or accessing the Platform, you acknowledge that you
                have read and understand these Terms and are accepting and
                agreeing to be bound by: (a) these Terms; (b) the mandatory
                arbitration provision, set forth in Section 13; and (c) the
                waiver of a right to class actions, set forth in Section 13. If
                you are using and/or accessing the Platform on behalf of an
                entity, you represent and warrant that you have the legal
                authority to bind that entity, your acceptance of the Terms is
                deemed an acceptance by that entity, and “you” and “your” will
                refer to that entity.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                C. Changes to these Terms.
              </p>
              <p>
                We may revise the Terms from time to time in our sole
                discretion. We will notify you in advance of material changes to
                these Terms by posting the revised terms on the Platform and/or
                by emailing you if you are a registered User. All revised Terms
                are effective when we publish the revised Terms on the Platform,
                or the effective date as stated in the notice, if applicable.
                Your continued use of the Platform following the effective date
                of the revised Terms means that you accept and agree to the
                modified Terms. We expect that you will check this page from
                time to time so that you are aware of any changes as they are
                binding on you. If you do not agree to any of the provisions
                contained in the Terms, then you may not access or use (or
                continue to access or use) the Platform.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              2. The Platform Generally; Accessing the Platform.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. The Platform.
              </p>
              <p>
                Remote Recruit provides the Platform to connect Job Seekers and
                Employers. Many of the features available on the Platform are
                provided by us at no charge to Job Seekers or Employers. For
                example, Job Seekers may post their resume or other information
                on the Platform and Employers may post positions for which they
                are looking to fill. By accessing and using the Platform, you
                understand that: (i) we solely provide the Platform to connect
                Job Seekers and Employers; (ii) we exercise no control or
                ownership over User Content, as defined below, and specifically
                disclaim the accuracy, completeness, or legality of such
                content; and (iii) we exercise no control over the integrity,
                conduct, responsibility of Users. If you are involved in a
                dispute or disagreement with another User (including a dispute
                between a Job Seeker and an Employer), any such dispute must be
                resolved exclusively between you and the other User. We disclaim
                any liability or responsibility for disputes between Users and
                will not assist or be involved in any such dispute unless as
                specifically set forth in these Terms.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. Eligibility.
              </p>
              <p>
                <ul className="list-[upper-roman]">
                  <li>
                    To access or use the Platform, you must be 18 years of age
                    or the age of majority in the jurisdiction in which you
                    reside, as you are forming a binding contract with Remote
                    Recruit. You may not use the Platform if we previously
                    terminated or suspended your access to the Platform and such
                    access has not be reinstated.
                  </li>
                  <li>
                    The owner of the Platform is based in the United States. We
                    make no claims that the Platform or any of its content is
                    accessible, appropriate, or lawful outside of the United
                    States. If you access the Platform from outside the United
                    States, you do so at your own risk and are responsible for
                    compliance with all local laws.
                  </li>
                </ul>
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                C. Accessing the Platform and Account Security.
              </p>
              <p>
                <ul className="list-[upper-roman]">
                  <li>
                    To access the Platform, you will need to provide certain
                    information to register for an account. You represent and
                    warrant that the information you provide when registering
                    your account and/or updating your account is accurate and
                    complete. You agree that all information you provide to
                    register for the Platform or otherwise, including but not
                    limited to information provided through the use of any
                    interactive features on the Platform, is governed by our
                    Privacy Policy and you consent to all actions that we may
                    take with respect to your information, as set forth in the
                    Privacy Policy.
                  </li>
                  <li>
                    You are solely responsible for any activity that takes place
                    under your account. Your account information is personal to
                    you; you agree to keep your login credentials (or any other
                    information related to our security procedures) confidential
                    and you may not disclose any such information to any third
                    party or allow any third party to access the Platform
                    through your account. You agree to notify us immediately if
                    there is any unauthorized access to your account or any
                    other breach of account security. You further agree to sign
                    out of your account at the end of each session and that you
                    will exercise caution when accessing your account from a
                    public or shared computer so that third parties are not able
                    to ascertain your login credentials.
                  </li>
                  <li>
                    We may, in our sole and absolute discretion, disable or
                    terminate your account for any or no reason. You agree that
                    we have no liability whatsoever for disabling or terminating
                    your account, including liability for any lost data or
                    content because of such disablement or termination. Upon
                    termination, all provisions of these Terms will survive
                    except for the provisions that grant you access to the
                    Platform.
                  </li>
                </ul>
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                D. Contact via Email.
              </p>
              <p>
                By providing your email address, you consent to us sending you
                Platform-related emails as well as other emails that are not
                commercial in nature (i.e., notices required by law). You may
                opt out of Platform-related email communications at any time by
                clicking the “unsubscribe” option at the bottom of any email
                from us.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                E. Updates to or Unavailability of the Platform.
              </p>
              <p>
                We may update the Platform or its content from time to time. At
                any given time, the Platform and its contents may not be up to
                date, and we are under no obligation to update the Platform or
                its contents. We also reserve the right to disable the Platform
                in our sole discretion without notice. You agree that we have no
                liability whatsoever for any unavailability of the Platform.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              3. User Content.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. Generally.
              </p>
              <p>
                The Platform allows Users to User Content. Premium Employers
                will have access to additional features. The Platform also
                allows Users to directly message each other; engage in
                discussions on group message boards; and conduct “virtual
                interviews”, among other interactive features. “
                <b>User Content</b>” means any data, material, or content that a
                User posts, publishes, messages, transmits, makes available,
                displays on the Platform, or otherwise provides to us or other
                Users. Examples of User Data that may be posted on the Platform
                include resumes and video submissions (for Job Seekers) and job
                postings (for Employers). If you post User Content, it must
                comply with the Platform Rules set forth in Section 4 below.
              </p>
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. User Content Disclaimer.
              </p>
              <p>
                We have no responsibility or liability for User Content. User
                Content may be false, misleading, or incomplete. You agree that
                we have no obligation or liability with respect to publishing,
                monitoring, or removing User Content. The User who posts User
                Content is solely responsibility for any such content, including
                any consequences of publishing such content on the Platform.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                C. Monitoring and Enforcement.
              </p>
              <p>
                We reserve the right, in our sole discretion, to:
                <ul className="list-[upper-roman]">
                  <li>Monitor and screen User Content;</li>
                  <li>
                    Remove or refuse to publish any User Content for any reason
                    or no reason;
                  </li>
                  <li>
                    Take any action (including legal action and referrals to law
                    enforcement, if applicable) with respect to any User Content
                    that we deem necessary or appropriate;
                  </li>
                  <li>
                    Disclose a User’s identity or other information about a User
                    to any third party who claims that User Content violates
                    their rights; or
                  </li>
                  <li>
                    Terminate or suspend a User’s access to the Platform for any
                    or no reason.
                  </li>
                </ul>
                Without limiting the foregoing, we have the right to fully
                cooperate with any law enforcement authorities or a court order
                requesting or directing us to disclose the identity or any other
                information of a User posting User Content. YOU HEREBY WAIVE AND
                HOLD HARMLESS REMOTE RECRUIT FROM ANY CLAIMS RESULTING FROM ANY
                ACTION TAKEN BY REMOTE RECRUIT PURSUANT TO THIS SECTION 3.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                D. The U.S. Communications Decency Act.
              </p>
              <p>
                Under Section 230(1) of the U.S. Communications Decency Act, “No
                provider or user of an interactive computer service shall be
                treated as the publisher or speaker of any information provided
                by another information content provider.” Additionally, under
                Section 230(2), “No provider or user of an interactive computer
                service shall be held liable on account of: (A) any action
                voluntarily taken in good faith to restrict access to or
                availability of material that the provider or user considers to
                be obscene, lewd, lascivious, filthy, excessively violent,
                harassing, or otherwise objectionable, whether or not such
                material is constitutionally protected; or (B) any action taken
                to enable or make available to information content providers or
                others the technical means to restrict access to material
                described in Section 230(1).”
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                E. License to User Content.
              </p>
              <p>
                You agree that any User Content that you post is not
                confidential or proprietary. By providing User Content, you
                grant us a nonexclusive, worldwide, perpetual, fully paid,
                royalty-free, transferable, sublicensable right to use,
                reproduce, modify, perform, display, distribute, prepare
                derivative works from, and otherwise disclose to third parties
                any such content. If you post User Content, you represent and
                warrant that: (i) you own or control all rights in and to the
                User Content and have the right to grant the license granted
                herein; and (ii) such User Content will comply with these Terms.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              4. Platform Rules.
            </p>
            <b>
              You may only access and use the Platform in accordance with these
              Terms and in compliance with all applicable federal, state, local,
              or international laws or regulations.
            </b>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. Generally Prohibited Conduct.
              </p>
              <p>
                You are strictly prohibited from, directly or indirectly,
                engaging in the following behavior:
                <ul className="list-[upper-roman]">
                  <li>
                    Using the Platform in any manner that could disable,
                    overburden, damage, harm, compromise, disrupt, impede, or
                    impair the Platform;
                  </li>
                  <li>
                    Interfering with any User’s access or use of the Platform;
                  </li>
                  <li>
                    Using any process to monitor or copy any material on the
                    Platform, or using an automatic device, including a robot,
                    spider, or similar process to access the Platform;
                  </li>
                  <li>
                    Attempting to gain unauthorized access to any parts of the
                    Platform or any server or database used to operate the
                    Platform;
                  </li>
                  <li>
                    Using the Platform for any commercial communications,
                    including, but not limited to, transmitting any advertising
                    or promotional material, including any junk mail, chain
                    letter, spam, or similar solicitations;
                  </li>
                  <li>
                    Reverse engineering, modifying, adapting, reformatting, or
                    decompiling any parts of the Platform;
                  </li>
                  <li>Engaging in deceptive, false, or fictitious conduct;</li>
                  <li>
                    Harming anyone or violating the rights of anyone, including
                    the right of publicity, right of privacy, or intellectual
                    property rights;
                  </li>
                  <li>
                    Promoting or encouraging illegal or unlawful activity; or
                  </li>
                  <li>
                    Gaining unauthorized access to personally identifiable
                    information.
                  </li>
                </ul>
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. Prohibited User Content.
              </p>
              <p>
                The standards set forth below are intended to demonstrate
                prohibited User Content but this list is not exhaustive. User
                Content may not:
                <ul className="list-[upper-roman]">
                  <li>
                    Contain any material which is unlawful, defamatory, obscene,
                    indecent, abusive, offensive, harassing, violent, hateful,
                    inflammatory, or otherwise objectionable;
                  </li>
                  <li>
                    Promote sexually explicit or pornographic material,
                    violence, or discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation, or age;
                  </li>
                  <li>
                    Contain proprietary information, including trade secrets,
                    confidential information, or the intellectual property of a
                    third party;
                  </li>
                  <li>Be harmful to anyone (including minors); or</li>
                  <li>
                    Include identification information (e.g., passport
                    information, driver’s license information, social security
                    numbers, etc.).
                  </li>
                </ul>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              5. Linking to and from the Platform.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. Linking to the Platform.
              </p>
              <p>
                You may link to the Platform’s homepage, provided that you do so
                in a way that is fair and legal, and does not damage our
                reputation or take advantage of it. You may not link to our
                Platform that suggests any form of association, approval, or
                endorsement by us.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. Linking from the Platform.
              </p>
              <p>
                If the Platform contains links to any other sites and resources
                provided by third parties, these links are provided for your
                convenience only. This includes links contained in User Content,
                advertisements (including banner advertisements) and sponsored
                links. We have no control over the content of those sites or
                resources, and disclaim all responsibility and liability for
                them or for any loss or damage that may arise from your use of
                them. If you decide to access any of the third party websites
                linked in the Platform, you do so at your own risk and subject
                to any terms and conditions of use for those sites.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              6. Intellectual Property Rights.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. Proprietary Rights.
              </p>
              <p>
                These Terms provide a limited license for you to access the
                Platform in accordance with these Terms. We do not transfer to
                you or any third party any ownership or intellectual property
                right in the Platform, including software, source code,
                programs, products, documentation, graphics, user interfaces,
                visual interfaces, illustrations, logos, patents, trademarks,
                service marks, copyrights, photographs, audio, information,
                text, displays, images, video and audio, and the design,
                selection and arrangement thereof, or content. The Remote
                Recruit name, logo, trademarks, and all related names, logos,
                product or service names, designs, slogans, are our trademarks.
                You may not use such marks without our prior written consent.
                All other names, logos, product and service names, designs, and
                slogans on the Platform, if applicable, are the trademarks of
                their respective owners. The Platform and its entire contents,
                features, functionality are protected by United States and
                international copyright, trademark, patent, trade secret, and
                other intellectual property laws.
                <br />
                <br />
                Except as specifically provided under these Terms, nothing
                herein creates a license in or to our intellectual property
                rights. You agree that you will not sell, license, rent, modify,
                distribute, copy, reproduce, display, edit, or create derivative
                works from any materials or content accessible on the Platform.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. Feedback.
              </p>
              <p>
                If you provide us with suggestions, comments, or ideas about the
                Platform ( “<b>Feedback</b>”), you agree that we own any such
                Feedback and we may use the Feedback in any manner and without
                limitation, attribution, or compensation in any form.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              7. Terms Specific to Employers.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. Premium Employer Features.
              </p>
              <p>
                While many of the Platform’s features are available for free,
                there are certain premium services available to Employers for a
                fee, such as our “Virtual Interview” feature, AI assessments and
                grades of Job Seeker videos, premium placement of Employer job
                posts, and other features to more effectively sort through and
                rank Job Seeker Job Seekers (“<b>Premium Employer Features</b>
                ”). Premium Employer Features are offered on a month-to-month
                term. Unless and until terminated in accordance with this
                Section 6, your subscription will automatically renew upon the
                conclusion of each monthly term for successive terms of the same
                duration. You may terminate your Premium Employer account at any
                time by emailing us at team@remoterecruit.com. Upon notification
                of termination, your Premium Employer Features will terminate at
                the completion of the billing cycle in which you gave notice of
                termination. Remote Recruit may terminate your subscription with
                30 days’ notice by email, in its sole discretion.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. Payment.
              </p>
              <p>
                If you are an Employer and choose to purchase Premium Employer
                Features, you agree to pay the initial fee and recurring monthly
                fee at the then-current subscription rate set forth on the
                Platform monthly in advance. Subscription fees are
                non-refundable in any case, and you accept responsibility for
                all recurring charges until the effective date of cancellation
                of your Premium Employer Features.
                <br />
                <br />
                We do not collect or store your payment information directly. We
                use a third party payment processor to collect fees due for
                Premium Employer Features, such as Stripe, Inc. for credit card
                payments or other third-party payment platforms such as Apple
                Pay or PayPal (in each case, the “Payment Processor”). You agree
                that any such payments are subject to the terms, conditions, and
                privacy policies of the applicable Payment Processor. You must
                have a valid payment method on file when using Premium Employer
                Features. We reserve the right to correct, (or to instruct our
                Payment Processor to correct), any errors or mistakes in
                payment, even if the payment has already been requested or
                received.
                <br />
                <br />
                We reserve the right to modify our fees for Premium Employer
                Features from time to time. If we do so, we will provide at
                least 30 days’ notice of upcoming changes via email. New pricing
                will take effect beginning with your next billing cycle
                following the completion of the notice period described above.
                If your payment method cannot be verified, is invalid, or is
                otherwise not acceptable, your Premium Employer Features may be
                suspended or canceled at the conclusion of the current billing
                cycle.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                C. No Employment Relationship with RemoteRecruit.
              </p>
              <p>
                Employers are solely responsible for any job postings on the
                Platform. Remote Recruit is not deemed an employer with respect
                to your use of the Platform. We disclaim any responsibility or
                liability for any employment decisions or conduct made by any
                User posting jobs on the Platform. Employers must comply with
                all employment-related laws and requirements, including those
                related to payroll, tax withholding, and other requirements, for
                Job Seekers that they hire.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              8. Terms Specific to Job Seekers.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                <b>
                  As a Job Seeker, you may use the Platform to: (A) search for
                  jobs; (B) apply for jobs; (C) include your resume, CV, or
                  similar information (collectively, “Qualifications”) in our
                  Job Seeker database; and (D) sign up to receive alerts when a
                  particular job is posted. You understand that any User
                  Content, which includes Qualifications, may be available to
                  other Users on the Platform. You consent to our sharing your
                  Qualifications, and you give us a perpetual, irrevocable,
                  royalty-free, worldwide license, and right to sublicense, in
                  your Qualifications to share or otherwise use such
                  Qualifications as required to provide the services available
                  on the Platform. More information about the how we treat the
                  information you share can be found in the Privacy Policy.
                </b>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              9. Claims of Infringing Content.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                <b>
                  We will respond to alleged infringement notices that comply
                  with the requirements of the Digital Millennium Copyright Act
                  of 1998. If you believe in good faith that content hosted on
                  the Platform infringes on your copyright or trademark, please
                  contact us at team@remoterecruit.com.
                </b>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              10. Indemnification.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                <b>
                  You agree to defend, indemnify, and hold harmless Remote
                  Recruit, its affiliates, its officers, directors, employees,
                  contractors, agents, licensors, service providers, successors
                  and assigns, from and against all claims, liabilities,
                  damages, judgments, awards, losses, costs, expenses, or fees
                  (including reasonable attorneys’ fees) arising out of or
                  relating to your use of the Platform, including any User
                  Content you post, or breach of these Terms.
                </b>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              11. Disclaimer.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                <b>
                  You understand and agree that we do not guarantee or warrant
                  that the content available on the Platform will be free of
                  viruses or other destructive code. You are responsible for
                  implementing sufficient procedures and checkpoints to satisfy
                  your particular requirements for anti-virus protection and for
                  maintaining an external mechanism from the Platform to
                  reconstruct any lost data or content. WE DISCLAIM ALL
                  LIABILITY FOR ANY LOSS OR DAMAGE CAUSED BY YOUR USE OF THE
                  PLATFORM, INCLUDING DAMAGE CAUSED BY A DENIAL-OF-SERVICE
                  ATTACK, VIRUS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT
                  MAY INFECT YOUR COMPUTER SYSTEM, PROGRAM, DATA, OR OTHER
                  PROPRIETARY MATERIAL DUE TO YOUR USE OF THE PLATFORM OR ANY
                  DATA OR CONTENT YOU OBTAIN FROM THE PLATFORM.
                  <br />
                  <br />
                  YOUR USE OF THE PLATFORM, ITS CONTENT AND ANY ITEMS OR
                  SERVICES OBTAINED THROUGH THE PLATFORM IS AT YOUR OWN RISK.
                  THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                  BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                  IMPLIED. NEITHER REMOTE RECRUIT NOR ANY PARTY ASSOCIATED WITH
                  REMOTE RECRUIT MAKES ANY WARRANTY OR REPRESENTATION WITH
                  RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
                  ACCURACY, OR AVAILABILITY OF THE PLATFORM. WITHOUT LIMITING
                  THE FOREGOING, NEITHER REMOTE RECRUIT NOR ANY PARTY ASSOCIATED
                  WITH REMOTE RECRUIT REPRESENTS OR WARRANTS THAT THE PLATFORM,
                  ITS CONTENTS, OR ANY SERVICES OR ITEMS OBTAINED FROM THE
                  PLATFORM WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                  UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
                  PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                  VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE PLATFORM OR
                  ANY SERVICES OR ITEMS OBTAINED FROM THE PLATFORM WILL
                  OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                  <br />
                  <br />
                  WE HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
                  OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED
                  TO, ANY WARRANTIES OR MERCHANTIBILITY, NON-INFRINGEMENT, OR
                  FITNESS FOR A PARTICULAR PURPOSE. THE FOREGOING DOES NOT
                  AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED
                  UNDER APPLICABLE LAW.
                </b>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              12. Limitation of Liability.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                <b>
                  IN NO EVENT WILL REMOTE RECRUIT, OUR AFFILIATES, OUR
                  LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
                  DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
                  THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
                  INABILITY TO USE, THE PLATFORM, ANY CONTENT AVAILABLE VIA THE
                  PLATFORM, OR ANY WEBSITES LINKED TO THE PLATFORM, ANY CONTENT
                  ON THE PLATFORM, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
                  INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT
                  NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
                  DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
                  OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                  DATA, AND WHETHER CAUSED BY TORT, NEGLIGENCE, BREACH OF
                  CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING
                  DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
                  LIMITED UNDER APPLICABLE LAW.
                </b>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              13. Class Action Waiver; Agreement to Arbitrate.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p>
                <b>
                  If you have a dispute or claim against us, you are required to
                  submit such dispute or claim on an individual basis (and not
                  as a plaintiff or class member in any class action
                  proceeding). You agree that any and all disputes or claims
                  that have arisen or may arise between you and Remote Recruit,
                  whether relating to the Platform, these Terms, or otherwise,
                  shall be resolved exclusively through final and binding
                  arbitration under the Rules of Arbitration of the American
                  Arbitration Association, applying the law of the State of
                  Virginia.
                </b>
              </p>
            </div>
          </section>
          <section className="mt-10">
            <p className="text-[#11142D] font-poppins text-lg font-medium">
              14. Miscellaneous.
            </p>
            <div className="text-[#6D6E7A] font-poppins text-base font-normal leading-7 mt-4">
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                A. Comments and Concerns.
              </p>
              <p>
                The Platform is operated by RemoteRecruit.com, Inc. Unless
                otherwise set forth above, all feedback, comments, requests for
                technical support and other communications regarding the
                Platform should be directed to team@remoterecruit.com.
              </p>
              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                B. Governing Law and Jurisdiction.
              </p>
              <p>
                All matters relating to the Platform and these Terms, including
                any dispute or claim arising therefrom or related thereto shall
                be governed by and construed in accordance with the internal
                laws of the State of Virginia without giving effect to any
                choice or conflict of law provision or rule (whether of the
                State of Virginia or any other jurisdiction). Any suit, action,
                or proceeding based on any matter arising out of or in
                connection with, these Terms or the transactions contemplated
                hereby, must be brought in the federal courts of the United
                States of America or the courts of Virginia. Each of the parties
                hereby irrevocably consents to the jurisdiction of such courts
                (and of the appropriate appellate courts therefrom) in any such
                suit, action, or proceeding.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                C. Assignment.
              </p>
              <p>
                These Terms are personal to you. Accordingly, you may not
                assign, transfer, or delegate any of your rights or obligations
                under these Terms without our prior written consent.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                D. Relationship of the Parties.
              </p>
              <p>
                Nothing in these Terms may be construed as making either party
                the partner, joint venturer, employer, employee, contractor, or
                agent of the other. Neither party may hold itself out to any
                third party as having any authority to make any statements,
                representations, or commitments of any kind on behalf of the
                other party.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                E. Waiver and Severability.
              </p>
              <p>
                No waiver by Remote Recruit of any term or condition set forth
                in these Terms shall be deemed a further or continuing waiver of
                such term or condition or a waiver of any other term or
                condition, and any failure of Remote Recruit to assert a right
                or provision under these Terms is not a waiver of such right or
                provision. If any provision of these Terms is held by a court or
                other tribunal of competent jurisdiction to be invalid, illegal,
                or unenforceable, then such provision will be eliminated or
                limited to the minimum extent such that the remaining provisions
                of these Terms will continue in full force and effect.
              </p>

              <p className="text-[#11142D] font-poppins text-sm font-medium mt-4">
                F. Entire Agreement.
              </p>
              <p>
                These Terms and our Privacy Policy constitute the entire
                agreement between you and Remote Recruit with respect to the
                subject matter hereof and supersede all prior and
                contemporaneous understandings, agreements, representations, and
                warranties, both written and oral, with respect to the Platform.
                Any reference herein to “including” means “including without
                limitation.”
              </p>
            </div>
          </section>
        </div>
      </div>
      <section className="pt-[50px] md:pt-36">
        <h2 className="text-black-100 font-semibold leading-[30px] md:leading-18 text-[20px] md:text-5xl text-center">
          Help Is One Click Away
        </h2>
        <div className="relative p-5 md:space-x-10 z-10 md:flex max-w-[345px] md:max-w-[860px] mx-auto bg-white shadow-[14px_41px_100px_rgba(49,89,211,0.12)] rounded-[20px] md:rounded-[49px]">
          <div className="mx-auto w-28 md:w-60 md:h-60 flex-none">
            <img
              className="w-full h-1 md:w-60 md:h-60 object-cover"
              src={PremiumRRLogo}
              alt="premium-card"
            />
            <div className="flex flex-col justify-center items-center h-0">
              {/* <p className="flex flex-col justify-center items-center w-40 h-10 flex-shrink-0 text-center font-poppins text-2xl font-semibold leading-[36px] tracking-[0.2px] bg-gradient-to-br from-[#52B4DA] via-transparent to-[#1E3E85] bg-clip-text text-transparent">
                Premium
              </p>
              <p class="text-[rgba(17, 20, 45, 0.50)] font-poppins text-center text-[1.125rem] font-normal leading-[2.1875rem] flex w-[12.4375rem] h-[2rem] flex-col items-center justify-center flex-shrink-0">
                Additional Cost
              </p> */}
            </div>
          </div>
          <div className="flex items-center justify-center mt-[30px] md:mt-0">
            <div className="space-y-5">
              <h3 className="text-black-100 leading-9 text-[18px] md:text-xl font-medium">
                Find Out Which Plan is Right for You
              </h3>
              <p className="text-base text-[rgba(17,20,45,0.8)] leading-8">
                For businesses that want unlimited access to candidates and the
                ability to post multiple jobs at once, check out the premium
                plan. But feel free to dip your toe in the basic plan first if
                you want--no fees, no paywall.
              </p>
            </div>
          </div>
          <div className="absolute w-full px-[10px] md:px-[35px] -z-10 left-[-35px] -bottom-4 md:-bottom-6">
            <div className="shadow-[14px_41px_100px_rgba(49,89,211,0.12)] rounded-[20px] md:rounded-[49px] h-[100px] bg-white opacity-50"></div>
          </div>
        </div>
      </section>

      <div className="-mt-32">
        <Footer className="z-0 pt-[160px] sm:pt-[200px] xl:pt-64 2xl:pt-80" />
      </div>
    </>
  )
}

export default TermsOfService
