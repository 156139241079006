import React from 'react'

export default function JobCardSkelton() {
  return (
    <div className="w-full h-auto md:h-[348px] shadow-[14px_41px_50px_rgba(49,89,211,0.07)] rounded-3xl bg-white py-[25px] px-[30px]">
      <div className="w-full bg-gray-300 h-[32px] space-x-5 animate-pulse"></div>
      <div className="hidden md:grid grid-cols-6 gap-x-5 mt-[25px]">
        {new Array(6).fill(0).map((v, idx) => (
          <div
            className="bg-[#F7F8FC] rounded-xl border border-gray-500 pt-5 pb-[17px] w-full animate-pulse h-[160px]"
            key={idx}
          ></div>
        ))}
      </div>
      <div className="grid md:hidden grid-cols-2 gap-x-5 mt-[25px]">
        {new Array(2).fill(0).map((v, idx) => (
          <div
            className="bg-[#F7F8FC] rounded-xl border border-gray-500 pt-5 pb-[17px] w-full animate-pulse h-[160px]"
            key={idx}
          ></div>
        ))}
      </div>
    </div>
  )
}
