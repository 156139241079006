import React from 'react'

//custom packages
import { EliminationCircleIcon } from 'components/Icons'

export default function NotStartedChannelPanel() {
  return (
    <div className="mt-2 bg-white rounded-3xl text-center md:pt-24 md:pb-9 md:px-8 md:ml-7 md:mr-15 min-h-[calc(100vh-500px)] flex flex-col justify-center">
      <div className="flex items-center justify-center">
        <div className="w-[40px] md:w-12 h-[40px] md:h-12">
          <EliminationCircleIcon className="text-black-100 w-full h-full" />
        </div>
      </div>
      <div className="text-black-100 font-medium text-[14px] md:text-2xl px-[25px] md:px-24 leading-[24px] md:leading-9 opacity-50 mt-5">
        Only employers can start a conversation. If this employer messages you,
        we will be sure to notify you immediately.
      </div>
    </div>
  )
}
