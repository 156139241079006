import React from 'react'
import { useParams } from 'react-router-dom'

//assets
import Header from 'views/home/components/Header'
import Footer from 'views/home/components/Footer'
import DefaultAvatar from 'assets/images/default-avatar.png'
import { dummyBlogs } from 'views/home/dummyBlog'

const BlogPost = () => {
  const { id } = useParams()

  const blog = dummyBlogs.filter((e) => e.id === id)

  return (
    <>
      <div className="w-full mobile-auth-bg md:bg-auth-bg bg-no-repeat bg-top sm:bg-[length:100%_38vh] md:bg-[length:100%_48vh] relative z-50 pb-10">
        <Header active="Home" />
        <div className="flex flex-col items-center justify-center">
          <p className="text-white text-4xl font-bold">Blog Post</p>
          <p className="text-white text-sm font-medium opacity-80">
            Date posted: {blog[0].date_post}
          </p>
        </div>
      </div>
      <div className="w-full md:w-[1280px] mx-auto z-20 pt-20 pb-[200px] px-10">
        <section>
          <div className="w-full h-[550px] flex-none shadow-[0px_4px_8px_rgba(225,227,242,0.32)]">
            <img
              className="w-full h-full rounded-lg object-cover"
              src={blog[0].image ? blog[0].image : DefaultAvatar}
            />
          </div>
          <p className="text-black-100 font-medium text-[26px] leading-10 my-10">
            {blog[0].title}
          </p>
          <div class="content blog" dangerouslySetInnerHTML={{ __html: blog[0].content }} />
        </section>
      </div>

      <div className="-mt-32">
        <Footer className="z-0 pt-[160px] sm:pt-[200px] xl:pt-64 2xl:pt-80" />
      </div>
    </>
  )
}

export default BlogPost
