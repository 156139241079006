import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

//components
import SearchInput from 'components/Input/SearchInput'
import ApplicationSubmittedList from './ApplicationSubmittedList'
import InvitationsList from './InvitationList'
import Dropdown from 'components/dropdown/Dropdown'
import Avatar from 'components/Avatar'

//redux & apis
import {
  setSelectApplicant,
  getApplicantsList,
  getInvitesListForEmployer,
} from 'app/store/applicantsSlice'
import { markInviteNotificationAPI } from 'app/services/inviteAPI'
import { toggleRefresh } from 'app/store/refreshSlice'
import { markApplicationNotificationAPI } from 'app/services/applicantAPI'

export default function Sidebar({scrollToDiv}) {
  const dispatch = useDispatch()
  const applicants = useSelector(getApplicantsList)
  const invites = useSelector(getInvitesListForEmployer)
  const deniedApplications = applicants.filter(
    (item) => item.status === 'rejected'
  )
  const selectedApplication = useSelector(
    (state) => state.applicants.selectedApplicant
  )

  const [tabsData, setTabsData] = useState([
    { name: 'Your Applications', id: 'applicants', number: applicants.length },
    { name: 'Your Invitations', id: 'invites', number: invites.length },
    {
      name: 'Denied Applications',
      id: 'rejectedApplicants',
      number: deniedApplications.length,
    },
    // { name: 'Denied Invitations', id: 'rejectedInvitations' },
  ])

  const [tab, setTab] = useState(tabsData[0])

  const handleSelectOnAll = useCallback(
    (item, type) => {
      dispatch(setSelectApplicant({ ...item, type: type }))
    },
    [dispatch]
  )
  const handleSelectApplicant = useCallback(
    async (item) => {
      dispatch(setSelectApplicant({ ...item, type: 'applicant' }))
      if (!item.statusUpdateViewed) {
        await markApplicationNotificationAPI({ id: item.id })
      }
      dispatch(toggleRefresh())
      scrollToDiv()
    },
    [dispatch]
  )
  const handleSelectInvitation = useCallback(
    async (item) => {
      dispatch(setSelectApplicant({ ...item, type: 'invitation' }))
      if (!item.statusUpdateViewed) {
        await markInviteNotificationAPI({ inviteId: item.id })
      }
      dispatch(toggleRefresh())
      scrollToDiv()
    },
    [dispatch]
  )

  const onChange = (data) => {
    setTab(data)
    dispatch(setSelectApplicant(null))
  }

  return (
    <div className="w-full md:pr-7 md:border-r-[1px] border-solid border-y-0 border-l-0 border-[#E4E4E4] md:min-h-[calc(100vh-200px)] space-y-3">
      <Dropdown
        data={tabsData}
        value={tab}
        labelClassName="text-black-100"
        className="text-black-100 w-auto bg-white border pt-4.5 pb-5 border-gray-500 pr-10 min-w-[320px]"
        onChange={onChange}
      />
      {tab.id === 'applicants' && (
        <div className="space-y-2 mt-2.5">
          {applicants.map((v) => {
            let bgStatus, textStatus, bgSpanStatus
            switch (v.status) {
              case 'rejected':
                bgStatus = 'bg-[#edd3d7]'
                textStatus = 'Denied'
                bgSpanStatus = 'bg-red-200'
                break
              case 'accepted':
                bgStatus = 'bg-[#f7f8fc]'
                textStatus = 'Accepted'
                bgSpanStatus = 'bg-blue-300'
                break
              case 'pending':
                bgStatus = 'bg-[#d6eaf5]'
                textStatus = 'Submitted'
                bgSpanStatus = 'bg-gray-100'
                break
              default:
                bgStatus = 'bg-[#F7F8FC]'
            }

            return (
              <div
                onClick={() => handleSelectApplicant(v)}
                className={clsx(
                  selectedApplication?.id === v.id ? 'bg-blue-100' : bgStatus,
                  'flex w-full space-x-[15px] p-[15px] rounded-2xl border border-solid border-gray-400 hover:bg-blue-100 group'
                )}
                key={v.id}
              >
                <div className="basis-12">
                  <Avatar
                    className="w-12 h-12 object-cover rounded-lg"
                    image={v?.creator?.logo}
                  />
                </div>

                <div className="flex items-center w-full">
                  <div
                    className={clsx(
                      selectedApplication?.id === v.id
                        ? 'text-white'
                        : 'text-black-100',
                      'leading-[18px] w-full md:leading-4 text-[14px] flex gap-3 justify-between md:text-sm font-semibold items-center group-hover:text-white font-inter'
                    )}
                  >
                    {v?.job?.title}
                    <span
                      className={`p-2 text-white font-semibold text-[13px] rounded-lg ${
                        textStatus === 'Submitted' && v?.waitlisted
                          ? 'bg-[#FFB242]'
                          : bgSpanStatus
                      }`}
                    >
                      {textStatus === 'Submitted' && v?.waitlisted
                        ? 'Waitlisted'
                        : textStatus}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
          {applicants.length === 0 && (
            <div className=" text-gray-700 text-sm px-4">No applications</div>
          )}
        </div>
      )}
      {tab.id === 'rejectedApplicants' && (
        <div className="space-y-2 mt-2.5">
          {applicants
            .filter((v) => v.status === 'rejected')
            .map((v) => {
              let bgStatus, textStatus, bgSpanStatus
              switch (v.status) {
                case 'rejected':
                  bgStatus = 'bg-[#edd3d7]'
                  textStatus = 'Denied'
                  bgSpanStatus = 'bg-red-200'
                  break
                case 'accepted':
                  bgStatus = 'bg-[#f7f8fc]'
                  textStatus = 'Accepted'
                  bgSpanStatus = 'bg-blue-300'
                  break
                case 'pending':
                  bgStatus = 'bg-[#d6eaf5]'
                  textStatus = 'Submitted'
                  bgSpanStatus = 'bg-gray-100'
                  break
                default:
                  bgStatus = 'bg-[#F7F8FC]'
              }

              return (
                <div
                  onClick={() => handleSelectApplicant(v)}
                  className={clsx(
                    selectedApplication?.id === v.id ? 'bg-blue-100' : bgStatus,
                    'flex w-full space-x-[15px] p-[15px] rounded-2xl border border-solid border-gray-400 hover:bg-blue-100 group'
                  )}
                  key={v.id}
                >
                  <div className="basis-12">
                    <Avatar
                      className="w-12 h-12 object-cover rounded-lg"
                      image={v?.creator?.logo}
                    />
                  </div>

                  <div className="flex items-center w-full">
                    <div
                      className={clsx(
                        selectedApplication?.id === v.id
                          ? 'text-white'
                          : 'text-black-100',
                        'leading-[18px] w-full md:leading-4 text-[14px] flex gap-3 justify-between md:text-sm font-semibold items-center group-hover:text-white font-inter'
                      )}
                    >
                      {v?.job?.title}
                      <span
                        className={`p-2 text-white font-semibold text-[13px] rounded-lg ${bgSpanStatus}`}
                      >
                        {textStatus === 'Pending' && v?.waitlisted
                          ? 'Waitlisted'
                          : textStatus}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          {applicants.filter((v) => v.status === 'rejected').length === 0 && (
            <div className=" text-gray-700 text-sm px-4">No applications</div>
          )}
        </div>
      )}
      {tab.id === 'invites' && (
        <div className="space-y-2 mt-2.5">
          {invites.map((v) => {
            let bgStatus, textStatus, bgSpanStatus
            switch (v.status) {
              case 'rejected':
                bgStatus = 'bg-[#edd3d7]'
                textStatus = 'Denied'
                bgSpanStatus = 'bg-red-200'
                break
              case 'accepted':
                bgStatus = 'bg-[#f7f8fc]'
                textStatus = 'Submitted'
                bgSpanStatus = 'bg-blue-300'
                break
              case 'pending':
                bgStatus = 'bg-[#d6eaf5]'
                textStatus = 'Pending'
                bgSpanStatus = 'bg-gray-100'
                break
              default:
                bgStatus = 'bg-[#F7F8FC]'
            }

            return (
              <div
                onClick={() => handleSelectInvitation(v)}
                className={clsx(
                  selectedApplication?.id === v.id ? 'bg-blue-100' : bgStatus,
                  'flex w-full space-x-[15px] p-[15px] rounded-2xl border border-solid border-gray-400 hover:bg-blue-100 group'
                )}
                key={v.id}
              >
                <div className="basis-[48px]">
                  <Avatar
                    className="w-12 h-12 object-cover rounded-lg"
                    image={v?.creator?.logo}
                  />
                </div>
                <div className="flex items-center w-full">
                  <div
                    className={clsx(
                      selectedApplication?.id === v.id
                        ? 'text-white'
                        : 'text-black-100',
                      'leading-4 w-full text-sm font-semibold flex justify-between items-center group-hover:text-white'
                    )}
                  >
                    {v?.job?.title}
                    <span
                      className={`p-2 text-white font-semibold text-[13px] rounded-lg ${
                        textStatus === 'Pending' && v?.waitlisted
                          ? 'bg-[#FFB242]'
                          : bgSpanStatus
                      }`}
                    >
                      {textStatus === 'Pending' && v?.waitlisted
                        ? 'Waitlisted'
                        : textStatus}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
          <div>
            {invites.length === 0 && (
              <div className=" text-gray-700 text-sm px-4">No Invitations</div>
            )}
          </div>
        </div>
      )}
      {tab.id === 'rejectedInvitations' && (
        <div className="space-y-2 mt-2.5">
          {invites
            .filter((v) => v.status === 'rejected')
            .map((v) => {
              let bgStatus, textStatus, bgSpanStatus
              switch (v.status) {
                case 'rejected':
                  bgStatus = 'bg-[#edd3d7]'
                  textStatus = 'Denied'
                  bgSpanStatus = 'bg-red-200'
                  break
                case 'accepted':
                  bgStatus = 'bg-[#f7f8fc]'
                  textStatus = 'Accepted'
                  bgSpanStatus = 'bg-blue-300'
                  break
                case 'pending':
                  bgStatus = 'bg-[#d6eaf5]'
                  textStatus = 'Pending'
                  bgSpanStatus = 'bg-gray-100'
                  break
                default:
                  bgStatus = 'bg-[#F7F8FC]'
              }

              return (
                <div
                  onClick={() => handleSelectInvitation(v)}
                  className={clsx(
                    selectedApplication?.id === v.id ? 'bg-blue-100' : bgStatus,
                    'flex w-full space-x-[15px] p-[15px] rounded-2xl border border-solid border-gray-400 hover:bg-blue-100 group'
                  )}
                  key={v.id}
                >
                  <div className="basis-[48px]">
                    <Avatar
                      className="w-12 h-12 object-cover rounded-lg"
                      image={v?.creator?.logo}
                    />
                  </div>
                  <div className="flex items-center w-full">
                    <div
                      className={clsx(
                        selectedApplication?.id === v.id
                          ? 'text-white'
                          : 'text-black-100',
                        'leading-4 w-full text-sm font-semibold flex justify-between items-center group-hover:text-white'
                      )}
                    >
                      {v?.job?.title}
                      <span
                        className={`p-2 text-white font-semibold text-[13px] rounded-lg ${bgSpanStatus}`}
                      >
                        {textStatus}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          <div>
            {invites.filter((v) => v.status === 'rejected').length === 0 && (
              <div className=" text-gray-700 text-sm px-4">No Invitations</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
