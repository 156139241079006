import React from 'react'

export default function BackCircleIcon({
  className = 'text-[#52B4DA]',
  size = 30,
}) {
  return (
    <svg
      className={className}
      width={size}
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0C6.71529 0 0 6.71529 0 15C0 23.2837 6.71529 30 15 30C23.2847 30 30 23.2837 30 15C30 6.71529 23.2847 0 15 0ZM21.5625 15.9375H11.6381L15.5015 19.8009C15.8681 20.1675 15.8681 20.7609 15.5015 21.1265C15.135 21.4931 14.5416 21.4931 14.1759 21.1265L8.8725 15.8231C8.64843 15.5991 8.57814 15.2906 8.62966 15C8.57809 14.7094 8.64843 14.4009 8.87343 14.1759L14.1769 8.8725C14.5434 8.50593 15.1369 8.50593 15.5025 8.8725C15.8691 9.23907 15.8691 9.8325 15.5025 10.1981L11.6381 14.0625H21.5625C22.08 14.0625 22.5 14.4825 22.5 15C22.5 15.5175 22.08 15.9375 21.5625 15.9375Z"
        fill="currentColor"
      />
    </svg>
  )
}
