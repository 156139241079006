import React from 'react'

export default function CrossWithCircleIcon({
  size = 16,
  className = 'text-black-100',
}) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="8" cy="8.14286" rx="8" ry="8.14286" fill="white" />
      <path
        d="M9.60039 6.54272L6.40039 9.74272"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.60039 9.74272L6.40039 6.54272"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
