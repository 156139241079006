import React from 'react'

export default function LogoutIcon({
  className = 'text-[#1E3E85]',
  size = 12,
}) {
  return (
    <svg
      width={size}
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2506_13089)">
        <path
          d="M5.98008 10.9835H1.49501C1.21992 10.9835 0.99668 10.7602 0.99668 10.4852V1.51505C0.99668 1.23996 1.21995 1.01672 1.49501 1.01672H5.98008C6.25566 1.01672 6.47841 0.793973 6.47841 0.518395C6.47841 0.242816 6.25566 0.0200195 5.98008 0.0200195H1.49501C0.670758 0.0200195 0 0.690801 0 1.51505V10.4851C0 11.3094 0.670758 11.9802 1.49501 11.9802H5.98008C6.25566 11.9802 6.47841 11.7574 6.47841 11.4818C6.47841 11.2062 6.25566 10.9835 5.98008 10.9835Z"
          fill="#1E3E85"
        />
        <path
          d="M11.8516 5.64523L8.82169 2.65519C8.62634 2.46183 8.3104 2.46434 8.11704 2.66018C7.92368 2.85602 7.92568 3.17147 8.12204 3.36483L10.2873 5.50169H4.48514C4.20957 5.50169 3.98682 5.72444 3.98682 6.00002C3.98682 6.2756 4.20957 6.49837 4.48514 6.49837H10.2873L8.12204 8.63524C7.9257 8.8286 7.9242 9.14405 8.11704 9.33989C8.21471 9.43856 8.34329 9.48839 8.47186 9.48839C8.59845 9.48839 8.72501 9.44055 8.82169 9.34486L11.8516 6.35482C11.9463 6.26114 12.0001 6.13355 12.0001 6C12.0001 5.8665 11.9468 5.73942 11.8516 5.64523Z"
          fill="#1E3E85"
        />
      </g>
      <defs>
        <clipPath id="clip0_2506_13089">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
