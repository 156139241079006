import React, { useState } from 'react'

import { ChatEngine, getOrCreateChat } from 'react-chat-engine'

const DirectChatPage = () => {
  const [username, setUsername] = useState('')

  function createDirectChat(creds) {
    // getOrCreateChat(
    //   creds,
    //   { is_direct_chat: true, usernames: [username] },
    //   () => setUsername('')
    // )
  }

  function renderChatForm(creds) {
    return (
      <div>
        <input
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="off"
        />
        <button onClick={() => createDirectChat(creds)}>Create</button>
      </div>
    )
  }

  return (
    <ChatEngine
      height="50vh"
      userName="matthew"
      userSecret="test"
      projectID={process.env.REACT_APP_CHAT_ENGINE_PROJECT_ID}
      renderNewChatForm={(creds) => renderChatForm(creds)}
    />
  )
}

export default DirectChatPage
