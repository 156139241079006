import { Listbox, Transition } from '@headlessui/react'
import { ChevronIcon } from 'components/Icons'
import { Fragment, useState } from 'react'
import clsx from 'clsx'

export default function SelectJobs({
  data = [],
  selected,
  setSelected,
  placeholder = 'Select...',
  applicantJobs = [],
  inviteJobs = [],
}) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className="relative mt-1">
          {open ? (
            <Listbox.Button className="border-[2px] border-solid border-blue-100 border-b-[0px] rounded-t-[16px] py-[18px] px-[23px] w-full">
              <span className="block truncate text-gray-100 leading-5 text-sm font-semibold">
                Select Jobs
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-[25px] flex items-center">
                <ChevronIcon className="text-[#29292C]" />
              </span>
            </Listbox.Button>
          ) : (
            <Listbox.Button className="bg-[rgba(228,228,228,0.25)] rounded-2xl py-[18px] px-[23px] w-full">
              <span className="block truncate text-gray-100 leading-5 text-sm font-semibold">
                {selected?.name ? selected?.name : placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-[25px] flex items-center">
                <ChevronIcon className="text-[#29292C]" />
              </span>
            </Listbox.Button>
          )}

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-10 rounded-t-[0px] rounded-b-[16px] border-[2px] border-t-0 border-blue-100 border-solid absolute max-h-[130px] w-full overflow-auto rounded-md bg-white py-1 text-base sm:text-sm outline-none space-y-[5px] px-[23px]">
              {data.map((item, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-[17px] px-[20px] border-gray-500 border-solid border-[1px]`
                  }
                  value={item}
                  disabled={inviteJobs.includes(item.id) || applicantJobs.includes(item.id)}
                >
                  {({ selected }) => (
                    <div className="relative">
                      <span
                        className={clsx(
                          applicantJobs.includes(item.id)
                            ? 'text-[#52B4DA]'
                            : 'text-black-100',
                          inviteJobs.includes(item.id) &&
                            !applicantJobs.includes(item.id)
                            ? 'text-[#B8B9C0]'
                            : 'text-black-100',
                          `block truncate leading-5 text-sm font-medium`
                        )}
                      >
                        {item.name}
                      </span>
                      {applicantJobs.includes(item.id) ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pl-3 text-[#35BE35]">
                          Applied
                        </span>
                      ) : null}
                      {inviteJobs.includes(item.id) &&
                        !applicantJobs.includes(item.id) && (
                          <span className="absolute inset-y-0 right-0 flex items-center pl-3 text-[#52B4DA]">
                            Invited
                          </span>
                        )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}
