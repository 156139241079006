import React from 'react'
import LoginForm from './LoginForm'

import AuthCard from 'components/Card/AuthCard'
import { Link } from 'react-router-dom'
import 'styles/registration.styles.scss'

const LoginCard = ({ open, handleClose }) => {
  return (
    <AuthCard>
      <h2 className="text-black-100 text-[24px] md:text-m4xl font-semibold leading-[36px] md:leading-13">
        Sign In
      </h2>
      <div className="mt-[10.5px] md:mt-5 text-gray-300 leading-[16px] text-[12px] md:text-xs">
        New User?{' '}
        <Link
          to="/auth/register"
          className="text-[#0049C6] ml-4 font-medium font-inter text-[12px]"
        >
          Create an account
        </Link>
      </div>
      <LoginForm handleClose={handleClose} />
    </AuthCard>
  )
}

export default LoginCard
