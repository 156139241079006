//packages
import clsx from 'clsx'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterBarModal from './components/FilterBarModal'

//custom components
import Button from 'components/Button'
import NewJobPostingModal from '../your-job-posts/components/NewJobPostingModal'
import FilterBar from './components/FilterBar'
import InviteToJobModal from './components/InviteToJobModal'
import NoJobPostModal from './components/NoJobPostModal'
import Results from './components/Results'
import SearchFilter from './components/SearchFilter'
import Dropdown from 'components/dropdown/Dropdown'

//redux
import { fetchCandidates } from 'app/store/candidateSlice'
import { getJobsByUser } from 'app/store/jobSlice'
import { getUser } from 'app/store/profileSlice'

//assets
import NoSearchIcon from 'assets/images/no-result-search.png'

import { PAGINATION_LIST } from 'app/utils/list'
import { getCandidates } from 'app/store/candidateSlice'
import { getCandidatesCount } from 'app/store/candidateSlice'
import compareProfiles from 'app/utils/profileRank'
import { updateUserLastActivity } from 'app/services/auth.service'
import { Helmet } from 'react-helmet'

export default function Candidates() {
  // this is for filters that are saved
  const languageStored =
    JSON.parse(localStorage.getItem('filter_candidates_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_candidates_location')) ?? []
  const maxHourlyRateStored =
    localStorage.getItem('filter_candidates_maxHourlyRate') ?? ''
  const maxAnnualRateStored =
    localStorage.getItem('filter_candidates_maxAnnualRate') ?? ''
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_candidates_keywords')) ?? []
  const can_page = localStorage.getItem('can_page')
    ? Number(localStorage.getItem('can_page'))
    : ''

  const [openNewJobPostingModal, setOpenNewJobPostingModal] = useState(false)
  const [openNoJobPostModal, setOpenNoJobPostModal] = useState(false)
  const [filter, setFilter] = useState((prev) => {
    let tempParam = {}

    // languages
    if (languageStored.length !== 0) {
      tempParam['languages'] = languageStored.map((v) => v.id)
    }

    // locations
    if (locationsStored.length !== 0) {
      tempParam['countries'] = locationsStored.map((v) => v.id)
    }

    // max hourly
    if (maxHourlyRateStored !== '') {
      tempParam['hourly'] =
        maxHourlyRateStored.length >= 5
          ? Number(maxHourlyRateStored.replace(/,/g, ''))
          : Number(maxHourlyRateStored)
    }

    // max annual
    if (maxAnnualRateStored !== '') {
      tempParam['annual'] =
        maxAnnualRateStored.length >= 5
          ? Number(maxAnnualRateStored.replace(/,/g, ''))
          : Number(maxAnnualRateStored)
    }

    // keywords
    if (keywordsStored.length !== 0) {
      tempParam['skills'] = keywordsStored.map((v) => v)
    }

    return tempParam
  })
  const [userIdForInvite, setUserIdForInvite] = useState('')
  const [jobForInvite, setJobForInvite] = useState(null)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showFilterBarModal, setShowFilterBarModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  const [currentPage, setCurrentPage] = useState(can_page !== '' ? can_page : 1)

  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.userId)
  const profile = useSelector(getUser)
  const candidates = useSelector(getCandidates)
  const candidatesCount = useSelector(getCandidatesCount)
  const jobs = useSelector((state) => state.job.jobs)

  // Sort the candidates based on the custom comparator
  const sortedCandidates = [...candidates].sort(compareProfiles)

  const isBasicPlan = false

  useEffect(() => {})

  const abortControllerRef = React.useRef(null)

  const fetchList = useCallback(async () => {
    try {
      // Cancel any ongoing request
      if (abortControllerRef.current && !_.isEmpty(filter)) {
        abortControllerRef.current.abort()
        console.log('Aborting previous request...')
        console.log(
          'Request aborted:',
          abortControllerRef.current.signal.aborted
        )
      }

      // Create new AbortController for this request
      abortControllerRef.current = new AbortController()

      // Verify if the signal is properly set up
      console.log('Signal is set:', abortControllerRef.current.signal)

      setLoading(true)
      let param = {
        offset: (currentPage - 1) * numberPerPage.name,
        limit: numberPerPage.name,
      }
      if (!_.isEmpty(filter)) {
        param = { ...param, filter }
      }

      // Pass the signal to your fetch call
      await dispatch(
        fetchCandidates({ ...param, signal: abortControllerRef.current.signal })
      )

      if (userId) {
        await updateUserLastActivity(userId)
      }
    } catch (err) {
      // Only log errors that aren't from aborting
      if (err.name !== 'AbortError') {
        console.log('debug: err', err)
      }
    } finally {
      setLoading(false)
      // Reset the abortControllerRef to null after fetch completes or errors
      abortControllerRef.current = null
    }
  }, [filter, currentPage, numberPerPage])

  // Cleanup effect to abort any pending request when component unmounts
  useEffect(() => {
    return () => {
      if (abortControllerRef.current && !_.isEmpty(filter)) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  useEffect(async () => {
    fetchList()
  }, [fetchList, currentPage])

  useEffect(async () => {
    if (can_page === '') {
      setCurrentPage(1)
    } else {
      setCurrentPage(can_page)
    }
  }, [numberPerPage])

  const fetchJobsList = useCallback(async () => {
    if (userId && jobs.length === 0) {
      dispatch(getJobsByUser(userId))
    }
  }, [userId, jobs])

  //Update user last
  useEffect(() => {}, [])

  useEffect(() => {
    fetchJobsList()
  }, [])

  useEffect(() => {
    dispatch(getJobsByUser(userId))
  }, [])

  const openInviteModal = (param) => {
    if (jobs.length === 0) {
      // setOpenNewJobPostingModal(true)
      setOpenNoJobPostModal(true)
    } else {
      setJobForInvite(null)
      setUserIdForInvite(param)
      setShowInviteModal(true)
    }
  }

  const onShowFilterModal = () => {
    setShowFilterBarModal(true)
  }

  const onHandleReset = () => {
    setFilter(null)
  }

  const onPageChange = (param) => {
    localStorage.setItem('can_selected', 'res0')
    localStorage.setItem('prev_can_page', currentPage)
    setCurrentPage(param)
    localStorage.setItem('can_page', param)
  }

  if (!profile) return null

  return (
    <div className="md:flex max-h-screen px-[15px] md:px-16 pb-[40px] md:pb-0">
      <Helmet>
        <title>Find Remote Talent - Remoterecruit.com</title>
        <meta
          name="description"
          content="Discover skilled remote candidates from our global talent pool to fill your job openings."
        />
      </Helmet>
      <div className="py-[30px] md:py-12 flex-1 md:border-r relative h-full border-r-gray-200 border-solid border-y-0 border-l-0 md:pr-11">
        <div className="text-black-100">
          <h5 className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
            Welcome, {profile.firstName}!
          </h5>
          <h2 className="leading-[36px] md:leading-18 font-semibold text-[24px] md:text-5xl">
            Let's Find Some Help
          </h2>
        </div>
        <div className={clsx(isBasicPlan && 'blur-sm')}>
          <SearchFilter
            disabled={isBasicPlan}
            filter={filter}
            setFilter={setFilter}
            onShowFilterModal={onShowFilterModal}
          />
        </div>
        <div className={clsx('mt-[38px] pb-[47px]', isBasicPlan && 'blur-sm')}>
          {!loading && candidates.length === 0 ? (
            <div className="py-[60px] md:py-25 w-full text-center bg-white rounded-3xl shadow-[14px_41px_50px_rgba(49,89,211,0.07)]">
              <div className="w-full flex justify-center">
                <img alt="no-search-image" src={NoSearchIcon} />
              </div>
              <p className="text-gray-100 leading-[24px] md:leading-6 text-[16px] md:text-xl mt-[60px] md:mt-16">
                No results found, please try again
              </p>
            </div>
          ) : (
            <div className="space-y-5">
              <div className="flex justify-between items-center">
                <div className="text-sm font-semibold text-[#808191]">
                  <span className="text-[#1E3E85] font-bold text-sm">
                    {candidatesCount}
                  </span>{' '}
                  candidates found
                </div>
                <div className="flex items-center space-x-3">
                  <div className="text-[#808191] font-semibold text-sm">
                    View
                  </div>
                  <div className="w-[70px]">
                    <Dropdown
                      data={PAGINATION_LIST}
                      label=""
                      value={numberPerPage}
                      onChange={(data) => {
                        setNumberPerPage(data)
                      }}
                      className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                      parentClassName="w-full"
                      labelClassName="text-[#1E3E85] font-semibold text-sm"
                    />
                  </div>
                  <div className="text-[#808191] text-sm font-semibold">
                    candidates per page
                  </div>
                </div>
              </div>
              <Results
                candidates={sortedCandidates}
                loading={loading}
                handleInvite={openInviteModal}
                filter={filter}
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageSize={numberPerPage.name}
                count={candidatesCount}
              />
            </div>
          )}
        </div>
        {isBasicPlan && (
          <Button
            variant="secondary"
            className="text-white leading-5 text-sm font-bold px-9 py-4.5 absolute bottom-[50px] left-[calc(50%-50px)]"
          >
            Upgrade to see
          </Button>
        )}
      </div>
      <div
        className={clsx(
          'hidden md:block md:w-[398px]',
          isBasicPlan && 'blur-sm'
        )}
      >
        <FilterBar
          disabled={isBasicPlan}
          filter={filter}
          setFilter={setFilter}
          setCurrentPage={setCurrentPage}
          onReset={onHandleReset}
        />
      </div>
      <InviteToJobModal
        isOpen={showInviteModal}
        jobForInvite={jobForInvite}
        setJobForInvite={setJobForInvite}
        closeModal={() => {
          setShowInviteModal(false)
          setUserIdForInvite('')
        }}
        userIdForInvite={userIdForInvite}
        setUserIdForInvite={setUserIdForInvite}
      />
      <NoJobPostModal
        isOpen={openNoJobPostModal}
        closeModal={() => {
          setOpenNoJobPostModal(false)
        }}
        openNewJobPost={() => {
          setOpenNewJobPostingModal(true)
          setOpenNoJobPostModal(false)
        }}
      />
      <NewJobPostingModal
        isOpen={openNewJobPostingModal}
        closeModal={() => setOpenNewJobPostingModal(false)}
      />
      <FilterBarModal
        isOpen={showFilterBarModal}
        closeModal={() => setShowFilterBarModal(false)}
        filter={filter}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
        disabled={isBasicPlan}
        onHandleReset={onHandleReset}
      />
    </div>
  )
}
