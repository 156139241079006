//packages
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

//custom components
import SidebarMenu from 'components/nav/Sidebar'
import MobileNavigationBar from 'components/nav/MobileNavigationBar'

//redux & apis
import { getUser } from 'app/store/profileSlice'

export default function EmployerLayout({ className }) {
  const profile = useSelector(getUser)

  if (profile && profile?.type !== 'employer') {
    return <Navigate to="/auth" />
  }

  return (
    <div className="block md:flex">
      <SidebarMenu type="employer" />
      <div className="md:min-h-screen w-full md:overflow-auto bg-[#F7F8FC]">
        <Outlet />
      </div>
      <div className="block md:hidden">
        <MobileNavigationBar />
      </div>
    </div>
  )
}
