//packages
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

//custom components
import Button from 'components/Button'

//assets
import EmptyMarkPng from 'assets/images/empty-status-candidates.png'

//redux & api
import { getUser } from 'app/store/profileSlice'

export default function EmptyScreen({ tab, sort }) {
  const profile = useSelector(getUser)
  const navigate = useNavigate()
  const navigateToFindCandidatesPage = () => {
    navigate(`/${profile.type}/find-candidates`)
  }
  return (
    <div className="pb-[68px] pt-[50px]">
      <div className="flex justify-center">
        <img src={EmptyMarkPng} />
      </div>
      <h6 className="text-[#808191] text-[20px] font-inter leading-[24px] text-center mt-[50px]">
        {`You have no ${sort.name !== "All" ? sort.name : ""} ${tab.id}`}
      </h6>
      <div className="flex justify-center mt-[58px]">
        <Button
          variant="primary"
          className="px-[79px] py-[18px]"
          onClick={navigateToFindCandidatesPage}
        >
          Find Candidates
        </Button>
      </div>
    </div>
  )
}
