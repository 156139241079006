import React from 'react'

export default function SponsorIcon({ className = 'text-white', size = 14 }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2151 0H2.78545C2.38594 0 2.06348 0.322462 2.06348 0.721973V10.2132C2.06348 10.5414 2.22328 10.8467 2.49152 11.0351L6.50946 13.8459C6.80339 14.0514 7.19719 14.0514 7.49112 13.8459L11.5091 11.0351C11.7773 10.8467 11.9371 10.5414 11.9371 10.2132V0.721973C11.9371 0.322462 11.6146 0 11.2151 0ZM10.0309 5.41908L8.65826 6.58622L9.08631 8.34121C9.16621 8.67224 8.8095 8.93477 8.52128 8.75499L7.00029 7.79902L5.47644 8.75214C5.18822 8.93192 4.83152 8.66938 4.91142 8.33836L5.33947 6.58337L3.96686 5.41622C3.71003 5.19649 3.84415 4.7713 4.18374 4.74847L5.97298 4.6172L6.64929 2.94211C6.7777 2.62821 7.22002 2.62821 7.34843 2.94211L8.02475 4.6172L9.81399 4.74847C10.1536 4.77415 10.2905 5.19935 10.0309 5.41908Z"
        fill="currentColor"
      />
    </svg>
  )
}
