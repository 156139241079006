import React from 'react'

export default function SearchIcon({ className = 'text-white', size = 20 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.7" clipPath="url(#clip0_9_34)">
        <path
          d="M8.04937 16.0918C9.837 16.0919 11.5735 15.4955 12.9838 14.397L18.3044 19.7176C18.7019 20.1015 19.3353 20.0905 19.7191 19.693C20.0936 19.3053 20.0936 18.6906 19.7191 18.3029L14.3985 12.9823C17.1242 9.47351 16.4895 4.41943 12.9807 1.69368C9.47194 -1.03208 4.41791 -0.397345 1.69215 3.11144C-1.03361 6.62022 -0.398871 11.6743 3.10991 14.4001C4.52252 15.4974 6.26057 16.0927 8.04937 16.0918ZM3.77429 3.77274C6.13538 1.41161 9.96346 1.41156 12.3246 3.77265C14.6857 6.13374 14.6858 9.96182 12.3247 12.3229C9.96359 14.6841 6.13551 14.6841 3.77437 12.323C3.77433 12.323 3.77433 12.323 3.77429 12.3229C1.4132 9.97907 1.39929 6.16494 3.74317 3.80385C3.75353 3.79345 3.76389 3.78309 3.77429 3.77274Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9_34">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
