import clsx from 'clsx'
import { SpinIcon } from 'components/Icons'
import TextField from 'components/Input/TextField'
import { getNames } from 'country-list'
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import heic2any from 'heic2any'
import AWS from 'aws-sdk'
import { Editor } from '@tinymce/tinymce-react'

import DefaultAvatar from 'assets/img/davatar.png'

import useTagList from 'hooks/useTagList'

import AuthService from 'app/services/auth.service'
import TextArea from 'components/Input/TextArea'
import AutoComplete from 'components/Select/AutoComplete'
import TagInput from 'components/Input/TagInput'
// import CroppingImageModal from 'components/Dialog/CroppingImageModal'
import CropImageModal from 'components/Dialog/CropImageModal'
import Dropdown from 'components/dropdown/Dropdown'

import {
  fetchBlobFromUrl,
  blobToBase64,
  generateRandomString,
} from 'app/utils/function'
import { COMPANIES_LIST } from 'app/utils/list'
import Checkbox from 'components/Checkbox'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

const EmployerAboutForm = (props) => {
  const [avatarUploading, setAvatarUploading] = useState(false)
  const [logoUploading, setLogoUploading] = useState(false)
  const [isOpenCroppingImageModal, setIsOpenCroppingImageModal] =
    useState(false)
  const [avatarFileData, setAvatarFileData] = useState('')
  const [fileExtension, setFileExtension] = useState('')
  const [uploadWhat, setUploadWhat] = useState(null)

  const [skillsTags, industryTags] = useTagList()

  const formik = props.formik

  const convertHEICToJpeg = async (heicFile) => {
    try {
      const arrayBuffer = await heicFile.arrayBuffer()
      const blob = await heic2any({
        blob: new Blob([new Uint8Array(arrayBuffer)]),
        toType: 'image/jpeg',
        quality: 0.8, // Optional: Set JPEG quality (0-1)
      })

      return URL.createObjectURL(blob)
    } catch (error) {
      console.error('HEIC to JPEG conversion failed:', error)
      return null
    }
  }

  const handleFileChange = async (event, type) => {
    setUploadWhat(type)
    const file = event.target.files[0]
    if (file) {
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      if (extension.toLowerCase() === 'heic') {
        try {
          const jpegURL = await convertHEICToJpeg(file)
          console.log('jpegURL', jpegURL)
          console.log('HEIC to JPEG conversion successful')
          // Handle the converted JPEG data as needed
          setAvatarFileData(jpegURL)
          setIsOpenCroppingImageModal(true)
        } catch (error) {
          console.error('HEIC to JPEG conversion failed:', error)
        }
      } else {
        setFileExtension(extension)
        const reader = new FileReader()
        reader.onload = handleFileLoad
        reader.readAsDataURL(file)
      }
    }
  }

  const handleFileLoad = (event) => {
    const fileData = event.target.result
    setAvatarFileData(fileData)
    setIsOpenCroppingImageModal(true)
  }

  async function handleCroppedAvatarUpload(url) {
    try {
      if (uploadWhat === 'avatar') {
        setAvatarUploading(true)
      } else {
        setLogoUploading(true)
      }

      const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
      const REGION = process.env.REACT_APP_AWS_REGION

      const result = await fetchBlobFromUrl(url)
      // const fileContent = await blobToBase64(result)
      setIsOpenCroppingImageModal(false)

      const fileName = generateRandomString(8) + '.png'
      const file = new File([result], fileName, { type: 'image/png' })

      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: REGION,
      })

      const params = {
        Bucket: S3_BUCKET,
        Key: file.name,
        Body: file,
        ContentType: file.type,
      }

      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file:', err)
        } else {
          console.log('File uploaded successfully:', data)

          const uploadedFileName = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`

          if (uploadWhat === 'avatar') {
            formik.setFieldValue('avatar', uploadedFileName)
            setAvatarUploading(false)
          } else {
            formik.setFieldValue('logo', uploadedFileName)
            setLogoUploading(false)
          }
        }
      })

      // const param = {
      //   fileContent,
      //   fileName: `${v4()}.${fileExtension}`,
      // }
      // let response = await AuthService.uploadFileToS3(param)
      // console.log('debug: response', response)
      // if (uploadWhat === 'avatar') {
      //   formik.setFieldValue('avatar', response.data.url)
      // } else {
      //   formik.setFieldValue('logo', response.data.url)
      // }
    } catch (err) {
      console.log(err)
    }
  }

  console.log('debug: formik.values', formik.values)

  const removeAvatar = () => {
    formik.setFieldValue('avatar', '')
    setAvatarFileData('')
  }

  const removeLogo = () => {
    formik.setFieldValue('logo', '')
    setAvatarFileData('')
  }

  const isIndividualUser = formik.values.type === 'individual'

  return (
    <div>
      <header>
        <h3 className="text-[18px] md:text-lg leading-[24px] md:leading-6 text-black-100 font-medium font-poppins">
          Please tell us more about yourself and/or your company
        </h3>
      </header>
      <div className="mt-10">
        <h3 className="font-semibold text-[14px] text-blue-100 uppercase mb-8">
          Personal Information
        </h3>
        <div className="grid md:grid-cols-1 gap-x-[18px] gap-y-[20px] mt-[20px]">
          <div className="space-y-[15px] md:space-y-6">
            <h5 className="font-inter font-bold text-[12px] leading-[16px] text-[#B2B3BD]">
              Profile Image
            </h5>
            {/* <div className="bg-[#F8F8F8] py-[15px] md:py-4 px-[20px] md:px-5 rounded-md border-[1px] border-[#E0E5F9]"> */}
            <div className="flex md:space-x-[10px] space-x-[30px] items-center">
              <div
                className={clsx(
                  'w-[80px] h-[80px] relative flex-none',
                  avatarUploading && 'blur-sm'
                )}
              >
                <img
                  alt="contact avatar"
                  className="w-full h-full rounded-full object-cover"
                  src={
                    formik.values.avatar ? formik.values.avatar : DefaultAvatar
                  }
                />
                {avatarUploading && (
                  <div className="w-full h-full absolute top-0 right-0 flex justify-center items-center">
                    <SpinIcon className="h-5 w-5 text-black" />
                  </div>
                )}
              </div>
              <div className="md:flex">
                <div className=" mr-0 md:w-[166px] relative mb-4">
                  <button
                    disabled={avatarUploading}
                    className="bg-green-100 py-[10px] rounded-2xl md:ml-[25px] w-[120px] text-white font-bold leading-5 text-center disabled:opacity-60 h-[40px] text-[14px]"
                  >
                    Upload
                  </button>
                  {!avatarUploading && (
                    <input
                      onChange={(e) => handleFileChange(e, 'avatar')}
                      type="file"
                      className="w-[120px] h-full absolute top-0 left-0 opacity-0 cursor-pointer"
                      accept="image/*, .heic"
                    />
                  )}
                </div>
                <div className=" md:w-[166px] relative">
                  <button
                    onClick={removeAvatar}
                    disabled={avatarUploading || !avatarFileData}
                    className="bg-red-200 py-[10px] rounded-2xl w-[120px] mr-[10px] text-white font-bold leading-5 text-center disabled:opacity-60 h-[40px] text-[14px]"
                  >
                    Delete
                  </button>
                  {/* {!avatarUploading && (
                    <input
                      onChange={(e) => handleFileChange(e, 'avatar')}
                      className="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer"
                    />
                  )} */}
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>

      <section id="individual-info" className="mt-[20px] md:mt-[50px]">
        <div className="grid md:grid-cols-2 gap-x-[18px] gap-y-[20px]">
          <TextField
            name="firstName"
            id="firstName"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={
              formik.touched['firstName'] && formik.errors['firstName']
                ? formik.errors['firstName']
                : ''
            }
            label="First Name"
          />
          <TextField
            name="lastName"
            id="lastName"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={
              formik.touched['lastName'] && formik.errors['lastName']
                ? formik.errors['lastName']
                : ''
            }
            label="Last Name"
          />
          <AutoComplete
            data={_.map(getNames(), (v, k) => {
              return {
                id: v,
                name: v,
              }
            })}
            label="Country"
            value={formik.values['location']}
            name="location"
            id="location"
            optionClassName="text-[13px]"
            onChange={(value) => {
              formik.setFieldValue('location', value)
            }}
            error={
              formik.touched['location'] && formik.errors['location']
                ? formik.errors['location']
                : ''
            }
          />
        </div>
        <div className="mt-5">
          {/* <TextArea
            name="bio"
            id="bio"
            onChange={formik.handleChange}
            value={formik.values.bio}
            error={
              formik.touched['bio'] && formik.errors['bio']
                ? formik.errors['bio']
                : ''
            }
            label="Bio"
          /> */}
          <label class="text-[#1E3E85] font-inter text-[12px] leading-[16px] font-medium">
            Bio
          </label>
          {/* <Editor
            apiKey='9grr4uefxu0r2uuiir8y6v503y2jfccj2t325h3m2ly34s0n'
            // onInit={(evt, editor) => editorRef.current = editor}
            onEditorChange={(content, editor) => {
              formik.handleChange({ target: { name: 'IndividualDescription', value: content } })
            }}
            value={formik.values.IndividualDescription}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          /> */}
          <RichTextEditor
            name="IndividualDescription"
            value={formik.values.IndividualDescription}
            onChange={formik.handleChange}
            placeholder="Enter your Bio"
          />
          {formik.errors['IndividualDescription'] &&
          formik.touched['IndividualDescription'] ? (
            <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-3">
              {formik.errors['IndividualDescription']}
            </div>
          ) : (
            ''
          )}
        </div>
      </section>

      <div className="h-[1px] mt-[20px] md:mt-[60px] w-full border-b border-[#E0E5F9] mb-10" />
      <h3 className="font-semibold text-[14px] text-blue-100 uppercase mb-5">
        Company Information
      </h3>
      <section id="select-type" className="mt-[20px] md:mt-[24px]">
        <div className="space-y-[20px] mb-10">
          <p className="text-[#B2B3BD] text-[12px] font-medium font-inter">
            Do you represent a company?
          </p>
          <div className="flex items-center space-x-[44px]">
            <Checkbox
              sizeClass="w-[16px] h-[16px]"
              variant="primary"
              label="Yes"
              labelClass="font-inter text-[12px] font-semibold"
              rectClass="border-blue-400 focus-within:border-blue-500 w-[16px] h-[16px]"
              value={formik.values.type === 'company'}
              onChange={(e) => formik.setFieldValue('type', 'company')}
            />
            <Checkbox
              sizeClass="w-[16px] h-[16px]"
              variant="primary"
              label="No"
              labelClass="font-inter text-[12px] font-semibold"
              rectClass="border-blue-400 focus-within:border-blue-500 w-[16px] h-[16px]"
              value={isIndividualUser}
              onChange={(e) => formik.setFieldValue('type', 'individual')}
            />
          </div>
        </div>
      </section>
      <section id="company-info" className="relative">
        <div className="relative">
          {isIndividualUser && (
            <div className="absolute top-0 left-0 w-full h-full z-10 bg-white bg-opacity-60"></div>
          )}
          <h5 className="font-inter font-bold text-[12px] leading-[16px] text-[#B2B3BD]">
            Company Logo
          </h5>
          {/* <div className="bg-[#F8F8F8] py-4 px-5 rounded-md mt-[15px] md:mt-6 border-[1px] border-[#E0E5F9]"> */}
          <div className="flex md:space-x-[10px] space-x-[30px] items-center py-8">
            <div
              className={clsx(
                'w-[80px] h-[80px] relative flex-none',
                logoUploading && 'blur-sm'
              )}
            >
              <img
                alt="contact avatar"
                className="w-full h-full rounded-full object-cover"
                src={formik.values.logo ? formik.values.logo : DefaultAvatar}
              />
              {logoUploading && (
                <div className="w-full h-full absolute top-0 right-0 flex justify-center items-center">
                  <SpinIcon className="h-5 w-5 text-black" />
                </div>
              )}
            </div>
            <div className="md:flex">
              <div className="mr-0 md:w-[166px] relative mb-4">
                <button
                  disabled={logoUploading}
                  className="bg-green-100 py-[10px] rounded-2xl w-[120px] md:ml-[25px] text-white font-bold leading-5 text-center disabled:opacity-60 h-[40px] text-[14px]"
                >
                  Upload
                </button>
                {!logoUploading && (
                  <input
                    onChange={(e) => handleFileChange(e, 'logo')}
                    type="file"
                    className="w-[120px] h-full absolute top-0 left-0 opacity-0 cursor-pointer"
                    accept="image/*, .heic"
                  />
                )}
              </div>
              <div className="md:w-[166px] relative">
                <button
                  disabled={logoUploading || !avatarFileData}
                  onClick={removeLogo}
                  className="bg-red-200 py-[10px] rounded-2xl w-[120px] text-white font-bold leading-5 text-center disabled:opacity-60 h-[40px] text-[14px]"
                >
                  Delete
                </button>
                {/* {!logoUploading && (
                  <input
                    onChange={(e) => handleFileChange(e, 'logo')}
                    type="file"
                    className="w-[120px] h-full absolute top-0 left-0 opacity-0 cursor-pointer"
                    accept="image/*, .heic"
                  />
                )} */}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        {isIndividualUser && (
          <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-60 z-10"></div>
        )}
        <div className="pb-8">
          <div className="grid md:grid-cols-2 gap-x-4 gap-y-[10px] md:gap-y-5">
            <TextField
              name="companyName"
              id="companyName"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                formik.touched['companyName'] && formik.errors['companyName']
                  ? formik.errors['companyName']
                  : ''
              }
              label="Company Name"
            />
            <AutoComplete
              data={_.map(getNames(), (v, k) => {
                return {
                  id: v,
                  name: v,
                }
              })}
              label="HQ Country"
              value={formik.values['country']}
              name="country"
              id="country"
              optionClassName="text-[13px]"
              onChange={(value) => {
                formik.setFieldValue('country', value)
              }}
              error={
                formik.touched['country'] && formik.errors['country']
                  ? formik.errors['country']
                  : ''
              }
            />
            <div className="mt-1">
              <Dropdown
                parentClassName="space-y-[6px]"
                label="Company Size (Employees)"
                btnClassName="bg-[rgba(228,228,228,0.25)] bg-[#F8F8F8] h-[61px] text-black-100 text-opacity-50 text-sm font-semibold relative w-full cursor-default rounded-lg py-1 px-[18px] text-left flex items-center"
                data={COMPANIES_LIST}
                value={formik.values.companySize}
                onChange={(data) => formik.setFieldValue('companySize', data)}
                className="bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
              />
              <div>
                {formik.touched['companySize'] &&
                formik.errors['companySize'] ? (
                  <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-[6px]">
                    {formik.errors['companySize']}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div>
              <TextField
                name="website"
                id="website"
                onChange={formik.handleChange}
                value={formik.values.website}
                error={
                  formik.touched['website'] && formik.touched['website']
                    ? formik.errors['website']
                    : ''
                }
                label="Website"
              />
            </div>
          </div>
          <div className="mt-5">
            <TagInput
              label="Industry Tags"
              list={_.map(industryTags, (v, k) => {
                return {
                  id: v.id,
                  name: v.name,
                }
              })}
              placeholder="Select industry tags"
              name="industry"
              id="industry"
              onChange={(value) => {
                formik.setFieldValue('industry', value)
              }}
              value={formik.values.industry}
              limitText="*Required at least 1 tag"
            />
            {formik.errors['industry'] && formik.touched['industry'] ? (
              <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-2.5">
                {formik.errors['industry']}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="mt-5">
            {/* <TextArea
              name="description"
              id="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={
                formik.touched['description'] && formik.errors['description']
                  ? formik.errors['description']
                  : ''
              }
              label="Description"
            /> */}
            <label class="text-[#1E3E85] font-inter text-[12px] leading-[16px] font-medium">
              Description
            </label>
            {/* <Editor
              apiKey="9grr4uefxu0r2uuiir8y6v503y2jfccj2t325h3m2ly34s0n"
              // onInit={(evt, editor) => editorRef.current = editor}
              onEditorChange={(content, editor) => {
                formik.handleChange({
                  target: { name: 'description', value: content },
                })
              }}
              value={formik.values.description}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            /> */}
            <RichTextEditor
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              placeholder="Enter your Description"
            />
            {formik.errors['description'] && formik.touched['description'] ? (
              <div className="text-red-300 text-[12px] md:text-xs font-semibold mt-3">
                {formik.errors['description']}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>

      {/* <CroppingImageModal
        image={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      /> */}
      <CropImageModal
        imageData={avatarFileData}
        isOpen={isOpenCroppingImageModal}
        onComplete={handleCroppedAvatarUpload}
        closeModal={() => setIsOpenCroppingImageModal(false)}
      />
    </div>
  )
}

export default EmployerAboutForm
