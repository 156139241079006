import React from 'react'

export default function EliminationCircleIcon({ size = 48, className = '' }) {
  return (
    <svg
      className={className}
      width={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0.5625C19.3645 0.5625 14.8331 1.93709 10.9788 4.51243C7.12456 7.08778 4.12051 10.7482 2.34658 15.0309C0.572655 19.3135 0.108514 24.026 1.01286 28.5724C1.9172 33.1189 4.1494 37.295 7.4272 40.5728C10.705 43.8506 14.8812 46.0828 19.4276 46.9872C23.974 47.8915 28.6865 47.4274 32.9692 45.6534C37.2518 43.8795 40.9122 40.8755 43.4876 37.0212C46.0629 33.1669 47.4375 28.6355 47.4375 24C47.4375 17.784 44.9682 11.8226 40.5728 7.42719C36.1774 3.0318 30.216 0.5625 24 0.5625ZM24 44.3125C19.9826 44.3125 16.0554 43.1212 12.715 40.8892C9.37462 38.6573 6.77111 35.4849 5.23371 31.7733C3.6963 28.0616 3.29405 23.9775 4.07781 20.0372C4.86157 16.097 6.79615 12.4776 9.6369 9.63689C12.4777 6.79614 16.097 4.86156 20.0372 4.0778C23.9775 3.29404 28.0616 3.69629 31.7733 5.2337C35.4849 6.7711 38.6573 9.37461 40.8892 12.715C43.1212 16.0554 44.3125 19.9826 44.3125 24C44.3125 29.3872 42.1725 34.5538 38.3631 38.3631C34.5538 42.1724 29.3872 44.3125 24 44.3125Z"
        fill="currentColor"
      />
      <path d="M22.4375 11.5H25.5625V30.25H22.4375V11.5Z" fill="currentColor" />
      <path
        d="M22.4375 33.375H25.5625V36.5H22.4375V33.375Z"
        fill="currentColor"
      />
    </svg>
  )
}
