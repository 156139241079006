//packages
import React, { useState } from 'react'
import { v4 } from 'uuid'

//custom packages
import TagInput from 'components/Input/TagInput'
import TextInput from 'components/Input/TextInput'
import NumberInput from 'components/Input/NumberInput'
import UploadResume from 'components/Upload/UploadResume'

//custom hooks
import useTagList from 'hooks/useTagList'

//api & redux
import AuthService from 'app/services/auth.service'

export default function SkillsAndExperiencePanel({ formik }) {
  const [skillsTags, industryTags] = useTagList()
  const [resumeUploading, setResumeUploading] = useState(false)
  const [fileName, setFileName] = useState(formik.values.resume)
  
  function handleUploadChange(e) {
    setResumeUploading(true)
    const file = e.target.files[0]
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async () => {
      const array = file.name.split('.')
      const extension = array[array.length - 1]
      const param = {
        fileContent: btoa(reader.result),
        fileName: `${v4()}.${extension}`,
      }
      AuthService.uploadFileToS3(param)
        .then((res) => {
          setResumeUploading(false)
          formik.setFieldValue('resume', res.data.url)
          setFileName(res.data.url)
        })
        .catch((err) => {
          setResumeUploading(false)
          console.log(err)
        })
    }
    reader.onerror = () => {
      console.log('error on load image')
    }
  }

  return (
    <div className="space-y-6 px-0">
      <div className="grid md:grid-cols-2 gap-4">
        <TextInput
          label="Professional Experience Tagline"
          id="job_title"
          name="job_title"
          value={formik.values.job_title}
          onChange={(e) => formik.setFieldValue('job_title', e.target.value)}
          error={formik.touched['job_title'] ? formik.errors['job_title'] : ''}
          placeholder="Expert full-stack developer"
        />
        <NumberInput
          id="num_experience"
          name="num_experience"
          value={formik.values.num_experience}
          label="Years of Experience"
          onChange={(e) =>
            formik.setFieldValue('num_experience', e.target.value)
          }
          error={
            formik.touched['num_experience']
              ? formik.errors['num_experience']
              : ''
          }
        />
      </div>
      <div>
        <p className="text-[12px] md:text-xs leading-[16px] md:leading-4 text-gray-300 font-medium">
          Skills
        </p>
        <div className="mt-4">
          <TagInput
            list={skillsTags}
            placeholder="Please choose your skills"
            id="skills"
            name="skill"
            value={formik.values['skills']}
            onChange={(value) => formik.setFieldValue('skills', value)}
            error={formik.touched['skills'] ? formik.errors['skills'] : ''}
          />
          {formik.touched['skills'] && formik.errors['skills'] && (
            <div className="text-red-300 font-semibold mt-1 text-xs">
              {formik.errors['skills']}
            </div>
          )}
        </div>
      </div>
      <div>
        <p className="text-[12px] md:text-xs leading-[16px] md:leading-4 text-gray-300 font-medium">
          Resume
        </p>
        <div className="my-4">
          <UploadResume
            handleUploadChange={handleUploadChange}
            fileName={fileName}
            disabled={resumeUploading}
            cancelUpload={() => {
              setFileName('')
            }}
          />
        </div>
      </div>
    </div>
  )
}
