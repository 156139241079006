import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

//custom components
import {
  SearchIcon,
  DocumentIcon,
  SettingIcon,
  ProfileIcon,
  MessageIcon,
} from 'components/Icons'

//redux & apis
import { getUser } from 'app/store/profileSlice'

export default function MobileNavigationBar() {
  const profile = useSelector(getUser)

  if (!profile) return null

  return (
    <div className="fixed bottom-[15px] w-full">
      {profile.type === 'employer' && (
        <div className="flex w-[calc(100%-30px)] mx-[15px] justify-evenly h-[56px] bg-white rounded-[20px] shadow-[rgba(14px_20px_40px_rgba(49,89,211,0.07))]">
          <NavLink
            to={`/${profile.type}/find-candidates`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <SearchIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/${profile.type}/job-posts`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <DocumentIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/${profile.type}/setting`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <SettingIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/${profile.type}/messages`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <MessageIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/${profile.type}/profile`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <ProfileIcon size={17} className="" />
          </NavLink>
        </div>
      )}
      {profile.type === 'contractor' && (
        <div className="flex w-[calc(100%-30px)] mx-[15px] justify-evenly h-[56px] bg-white rounded-[20px] shadow-[rgba(14px_20px_40px_rgba(49,89,211,0.07))]">
          <NavLink
            to={`/jobseeker/jobs`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <SearchIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/jobseeker/your-applications`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <DocumentIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/jobseeker/setting`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <SettingIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/jobseeker/messages`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <MessageIcon size={20} className="" />
          </NavLink>
          <NavLink
            to={`/jobseeker/profile`}
            className={({ isActive, isPending }) =>
              isActive
                ? 'flex itmes-center text-[#52B4DA]'
                : 'flex items-center text-blue-100'
            }
          >
            <ProfileIcon size={17} className="" />
          </NavLink>
        </div>
      )}
    </div>
  )
}
